import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'

const js = `await integrationApp
  .integration('{INTEGRATION_KEY}')
  .open()
`

export function OpenIntegrationUiCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.integrationKeyWithoutConnection,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .integration(parameters.INTEGRATION_KEY)
          .open()
      }}
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Continous Data Import from External Apps",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Data Source",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Field Mapping",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Full Data Import",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Incremental Data Import",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Extending Functionality",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Live Examples",
    "attributes": {},
    "children": []
  }]
}];
import {SCENARIOS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const TITLE = 'Continuous Data Import';
export const DESCRIPTION = 'Import data collection from an external app and keep it up-to-date.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    img: "img",
    strong: "strong",
    h2: "h2",
    code: "code",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Link, DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "continous-data-import-from-external-apps",
      children: "Continous Data Import from External Apps"
    }), "\n", _jsx(_components.p, {
      children: "This guide will show you how to import data from an external app and keep it up-to-date in your app."
    }), "\n", _jsxs(_components.p, {
      children: ["If you want to see the end-result right away, you can start with a pre-built ", _jsx(Link, {
        path: SCENARIOS_ROUTE,
        children: "scenario"
      }), " or check out one of the examples in our ", _jsx(_components.a, {
        href: "https://examples.integration.app",
        children: "Examples App"
      }), "."]
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/continuously-import-scenarios.png",
      alt: "Continuous Import Scenarios"
    }), "\n", _jsx(_components.p, {
      children: "Otherwise, follow the steps below."
    }), "\n", _jsxs(_components.p, {
      children: ["To make this guide specific, we will import ", _jsx(_components.strong, {
        children: "Users"
      }), ", but you can use the same approach for any other type of data."]
    }), "\n", _jsx(_components.h2, {
      id: "data-source",
      children: "Data Source"
    }), "\n", _jsxs(_components.p, {
      children: ["First, we will create a Universal ", _jsx(DocLink, {
        path: "engine/blueprints/data-sources",
        children: "Data Source"
      }), " that points to Users in every app we want to import users from."]
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/create-users-data-source.png",
      alt: "Create Data Source"
    }), "\n", _jsx(_components.p, {
      children: "By selecting Users data model, you will instruct integration.app to automatically pick the most appropriate data collection in each external app this Data Source is used for."
    }), "\n", _jsx(_components.h2, {
      id: "field-mapping",
      children: "Field Mapping"
    }), "\n", _jsxs(_components.p, {
      children: ["To keep field mapping configuration in one place and let your customers change it if needed, we will create a ", _jsx(DocLink, {
        path: "engine/blueprints/field-mappings",
        children: "Field Mapping"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Select Users data source we created in the previous step."
    }), "\n", _jsxs(_components.p, {
      children: ["You can then define default field mapping that will be used across all applications.\nYou can leverage our ", _jsx(DocLink, {
        path: "engine/references/udm",
        children: "Unified Data Model"
      }), " to pre-populate mappings for standard fields across all applications."]
    }), "\n", _jsx(_components.p, {
      children: "This screenshot shows the simplest possible configuration for \"Import Users\" field mapping that maps fields from the external app to \"id\" and \"name\" fields that your app will receive."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/users-field-mapping.png",
      alt: "Users Field Mapping"
    }), "\n", _jsx(_components.h2, {
      id: "full-data-import",
      children: "Full Data Import"
    }), "\n", _jsx(_components.p, {
      children: "To perform the initial import of all users from the external app (and full re-imports in the future if you need to), we will use \"List Data Record\" action."
    }), "\n", _jsx(_components.p, {
      children: "Create a new action that looks like this:"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/get-all-users-action.png",
      alt: "Get all Users Action"
    }), "\n", _jsx(_components.p, {
      children: "Let's break it down."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Data Source"
      })
    }), "\n", _jsx(_components.p, {
      children: "Select the Users Data Source we created in the first step. We will re-use this data source in the following steps, so it's good to keep it consistent."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Pagination"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If an external application has a lot of users, this action will return users page by page. Each response will contain a ", _jsx(_components.code, {
        children: "cursor"
      }), " that you can use to fetch the next page."]
    }), "\n", _jsx(_components.p, {
      children: "To make it work, you need to:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Add ", _jsx(_components.code, {
          children: "cursor"
        }), " to the input schema of the action."]
      }), "\n", _jsxs(_components.li, {
        children: ["Use ", _jsx(_components.code, {
          children: "cursor"
        }), " in the pagination config of the action."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Field Mapping"
      })
    }), "\n", _jsx(_components.p, {
      children: "Select the Import Users field mapping we created in the second step. This will ensure that the data you receive is mapped consistently to your data schema across different blueprints."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Testing"
      })
    }), "\n", _jsx(_components.p, {
      children: "To test this action, apply it to an external app you have an account in, create a connection, and run the sample code in the right panel:"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/run-get-all-users-action.png",
      alt: "Run Action"
    }), "\n", _jsx(_components.h2, {
      id: "incremental-data-import",
      children: "Incremental Data Import"
    }), "\n", _jsxs(_components.p, {
      children: ["To receive incremental updates from external apps, we will create a ", _jsx(DocLink, {
        path: "engine/blueprints/flows",
        children: "Flow"
      }), "\ntriggered by change events in the Users data source."]
    }), "\n", _jsx(_components.p, {
      children: "The flow will look like this:"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/get-updated-users-flow.png",
      alt: "Run Action"
    }), "\n", _jsxs(_components.p, {
      children: ["It will be triggered by ", _jsx(_components.code, {
        children: "data-record-created"
      }), ", ", _jsx(_components.code, {
        children: "data-record-updated"
      }), ", and ", _jsx(_components.code, {
        children: "data-record-deleted"
      }), " events in the Users data source.\nThen it will fetch the full user record (except for deleted users) and send them to your application."]
    }), "\n", _jsx(_components.p, {
      children: "Step that fetches the user record will look like this:"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/get-updated-users-flow-node.png",
      alt: "Run Action"
    }), "\n", _jsx(_components.p, {
      children: "It uses the same Data Source and Field Mapping as we used in \"Get All Users\" action above."
    }), "\n", _jsx(_components.p, {
      children: "The step that sends users to your app looks like this:"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/get-updated-users-send-to-my-app.png",
      alt: "Run Action"
    }), "\n", _jsx(_components.p, {
      children: "It simply sends the user record to the URL specified by you in the body of the POST request."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Testing"
      })
    }), "\n", _jsx(_components.p, {
      children: "To test this flow, apply it to an external app you have an account in, create a connection, and make a change in one of the users: create, update, or delete it.\nYou should see a flow run and a request sent to the URL you specified."
    }), "\n", _jsx(_components.h2, {
      id: "extending-functionality",
      children: "Extending Functionality"
    }), "\n", _jsx(_components.p, {
      children: "There are many things you can add to the basic functionality described here:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["To configure requests made to your app (add authentication, etc), see ", _jsx(DocLink, {
          path: "overview/getting-started/backend/your-app-api"
        }), "."]
      }), "\n", _jsxs(_components.li, {
        children: ["Use ", _jsx(DocLink, {
          path: "ux/field-mappings",
          children: "Field Mapping UX"
        }), " to let your users customize how fields are mapped from external app to your app."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/blueprints/app-data-schemas"
        }), " will let you define custom per-customer field schemas that will be used in the field mapping."]
      }), "\n", _jsxs(_components.li, {
        children: ["If you want to not just import data, but send updates back, check out ", _jsx(DocLink, {
          path: "solutions/bi-directional-sync"
        })]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "live-examples",
      children: "Live Examples"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["\n", _jsx("a", {
          href: "https://examples.integration.app/continuous-import-of-files",
          target: "_blank",
          children: "Continuous Import of Files"
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

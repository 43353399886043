import { SvgIconType } from '@integration-app/ui/SvgIcon'
import { CommonRoundedButton } from 'components/FlowBuilder/Graph/elements/NodeBody/CommonRoundedButton'
import classes from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBody.module.css'
import { unlinkNodes } from 'components/FlowBuilder/helpers/linking'
import { useGenericFlow } from '../../../../../../../components/FlowBuilder/useGenericFlow'

export function UnlinkNodeButton({
  portalFlowNodeKey,
  parentFlowNodeKey,
}: {
  portalFlowNodeKey: string
  parentFlowNodeKey: string
}) {
  const { flow, patchFlow } = useGenericFlow()

  async function handleUnlink() {
    const source = parentFlowNodeKey
    const target = portalFlowNodeKey

    const updatedFlow = unlinkNodes(flow, source, target)

    await patchFlow(updatedFlow).catch(console.error)
  }

  return (
    <CommonRoundedButton
      onClick={handleUnlink}
      iconType={SvgIconType.Link}
      className={classes.commonRoundedButtonTopRight}
    />
  )
}

import { PropsWithChildren } from 'react'
import { Popup, PopupRootProps } from './index'

export function AddEntryPopup({
  children,
  ...props
}: PropsWithChildren<PopupRootProps>) {
  return (
    <Popup size={'md'} open {...props}>
      <Popup.Content>{children}</Popup.Content>
    </Popup>
  )
}

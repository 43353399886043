/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Field Mapping",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "See Also",
    "attributes": {},
    "children": []
  }]
}];
export const DESCRIPTION = 'Drop-in UI for a single field mapping.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {DocGrid, CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.OpenFieldMappingInstanceConfiguration) _missingMdxReference("CodeExample.OpenFieldMappingInstanceConfiguration", true);
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "field-mapping",
      children: "Field Mapping"
    }), "\n", _jsx(_components.p, {
      children: "To let your user connect or configure a single Field Mapping, do the following:"
    }), "\n", _jsx(CodeExample.OpenFieldMappingInstanceConfiguration, {}), "\n", _jsx(_components.h2, {
      id: "see-also",
      children: "See Also"
    }), "\n", _jsx(DocGrid, {
      children: _jsx(DocGrid.Link, {
        path: "ux/field-mappings/custom"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Filter",
  "attributes": {},
  "children": []
}];
export const DESCRIPTION = 'Stop or continue flow depending on the input';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "filter",
      children: "Filter"
    }), "\n", _jsx(_components.p, {
      children: "This node only passes through inputs that match the filter. The filter can be provided by you, your user, or both."
    }), "\n", _jsx(_components.p, {
      children: "If node input does not match the filter, it will be skipped. If all inputs are skipped, the flow run will stop."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;

import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `await integrationApp.connection('{INTEGRATION_KEY}').archive()`

export function ArchiveConnectionCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{
        js,
      }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .archive()
      }}
    />
  )
}

import structuredClone from '@ungap/structured-clone'
import { Flow } from '@integration-app/sdk'

export function linkNodes(flow: Flow, source: string, target: string): Flow {
  // TODO: add link indexes

  const updatedFlow = structuredClone(flow) as Flow

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const links = updatedFlow.nodes[source].links ?? []

  const linkExists = links.find((link) => link.key === target)

  if (!linkExists) {
    const emptyLink = links.find((link) => !link.key)
    if (emptyLink) {
      // use existing empty link is such exists
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      updatedFlow.nodes[source].links = [
        ...links.map((link) => {
          if (!link.key) {
            link.key = target
          }

          return link
        }),
      ]
    } else {
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      updatedFlow.nodes[source].links = [...links, { key: target }]
    }
  }

  return updatedFlow
}

export function unlinkNodes(flow: Flow, source: string, target: string): Flow {
  const updatedFlow = structuredClone(flow) as Flow

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (!updatedFlow.nodes[source] || !updatedFlow.nodes[target]) {
    return updatedFlow
  }

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const links = updatedFlow.nodes[source].links ?? []

  // unlinking is actually erasing key, we want to keep actual link
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  updatedFlow.nodes[source].links = links.map((link) => {
    if (link.key === target) {
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
      link.key = null
    }

    return link
  })

  return updatedFlow
}

import { SchemaBuilder, SimpleRadio } from '@integration-app/ui'

import { CollapsibleSection } from '../../../../components/Section'
import { useFieldMappingContext } from '../contexts/field-mapping-context'

enum InternalSchemaOption {
  Parent = 'parent',
  Custom = 'custom',
}

export function FieldMappingChildInternalSchema() {
  const { fieldMapping, onChange } = useFieldMappingContext()

  let selectedOption = InternalSchemaOption.Parent

  if (fieldMapping.appSchema) {
    selectedOption = InternalSchemaOption.Custom
  }

  function handleSelectOption(option: InternalSchemaOption) {
    switch (option) {
      case InternalSchemaOption.Parent:
        return onChange({
          appSchema: undefined,
        })
      case InternalSchemaOption.Custom:
        return onChange({
          appSchema: {
            type: 'object',
          },
        })
    }
  }

  return (
    <div className='flex flex-col'>
      <CollapsibleSection open>
        <CollapsibleSection.Header>Internal Fields</CollapsibleSection.Header>

        <p>Data schema in your app to map fields to/from.</p>

        <div className='my-4'>
          <SimpleRadio
            label='Same as universal field mapping'
            checked={selectedOption === InternalSchemaOption.Parent}
            onCheck={() => handleSelectOption(InternalSchemaOption.Parent)}
          />

          <SimpleRadio
            label='Same as universal field mapping + custom fields'
            checked={selectedOption === InternalSchemaOption.Custom}
            onCheck={() => handleSelectOption(InternalSchemaOption.Custom)}
          />
        </div>

        {selectedOption === InternalSchemaOption.Custom && (
          <SchemaBuilder
            schema={fieldMapping.appSchema}
            onChange={(appSchema) =>
              onChange({
                appSchema,
              })
            }
          />
        )}
      </CollapsibleSection>
    </div>
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "App Data Schemas",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Referencing Schema",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "User-specific Schema",
    "attributes": {},
    "children": []
  }]
}];
import DocLink from "components/Docs/DocLink";
import {APP_DATA_SCHEMA_INSTANCES_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const DESCRIPTION = 'Reusing data schemas between integration elements';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    code: "code",
    pre: "pre",
    img: "img"
  }, _provideComponents(), props.components), {Link} = _components;
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "app-data-schemas",
      children: "App Data Schemas"
    }), "\n", _jsxs(_components.p, {
      children: ["App Data Schemas is a ", _jsx(DocLink, {
        path: "engine/references/data-schemas",
        children: "Data Schema"
      }), " that can be shared across multiple places in your integrations.\nIt can be used for ", _jsx(DocLink, {
        path: "engine/blueprints/field-mappings"
      }), " or as a component for any other schema used in an integration."]
    }), "\n", _jsx(_components.h2, {
      id: "referencing-schema",
      children: "Referencing Schema"
    }), "\n", _jsxs(_components.p, {
      children: ["You can reference App Data Schema in other schemas using ", _jsx(_components.code, {
        children: "$ref"
      }), " formula:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-json",
        children: "{\n  \"type\": \"object\",\n  \"properties\": {\n    \"name\": {\"type\": \"string\"},\n    \"user\": {\n      \"$ref\": \"schemas/user\" // Reference to the \"user\" App Data Schema\n    }\n  }\n}\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "user-specific-schema",
      children: "User-specific Schema"
    }), "\n", _jsx(_components.p, {
      children: "Whenever schema is used in a context of a specific user, and you provided an HTTP request, it will be executed on behalf of the current user:"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/app-data-schema-request.png",
      alt: ""
    }), "\n", _jsxs(_components.p, {
      children: ["The response to this request should be a JSON-encoded ", _jsx(DocLink, {
        path: "engine/references/data-schemas",
        children: "\nData Schema"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can find the latest request and response for a given user on the ", _jsx(Link, {
        path: APP_DATA_SCHEMA_INSTANCES_ROUTE,
        children: "App Data Schema Instances"
      }), " page."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

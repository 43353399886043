import { pickFieldsFromSchema } from '@integration-app/sdk'
import FieldList from '@integration-app/ui/DataBuilder/FieldList'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/geBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { CollapsibleSection } from '../Section'
import { useGenericConfig } from './contexts/generic-config-context'
import { schemaWithTitle } from '@integration-app/sdk'

export function LookupQueryConfig() {
  const {
    variablesSchema,
    config,
    patchConfig,
    udm,
    udmSpec,
    dataCollectionSpec,
    integration,
  } = useGenericConfig()

  const isUniversal = !integration

  const hasUdm = !!udm
  const hasDataCollection = !!dataCollectionSpec
  const universalLookupFields =
    udmSpec?.match?.fields ?? udmSpec?.find?.queryFields ?? []
  const integrationSpecificFields =
    dataCollectionSpec?.match?.fields ??
    dataCollectionSpec?.find?.queryFields ??
    []
  const lookupFields = isUniversal
    ? universalLookupFields
    : integrationSpecificFields

  const fieldsSchema = isUniversal
    ? udmSpec?.fieldsSchema
    : dataCollectionSpec?.fieldsSchema

  const hasLookupFields = lookupFields.length > 0
  const lookupConfig = config?.lookup ?? {}

  const lookupFieldsSchema = schemaWithTitle(
    pickFieldsFromSchema(fieldsSchema, lookupFields),
    'Lookup Fields',
  )

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Lookup Query</CollapsibleSection.Header>
      <p>
        Set up a default query used to find the record. It can be later
        customized for each connection this integration is applied to.
      </p>
      <div className='mt-2'></div>
      {isUniversal ? (
        hasUdm ? (
          hasLookupFields ? (
            <FieldList
              schema={lookupFieldsSchema}
              variablesSchema={variablesSchema}
              value={lookupConfig.universalQuery}
              onChange={(universalQuery) =>
                patchConfig({
                  lookup: {
                    ...lookupConfig,
                    universalQuery,
                  },
                })
              }
            />
          ) : (
            <div className='italic'>
              Data Model "{udm}" does not have fields to find by.
            </div>
          )
        ) : (
          <div className='italic'>Please configure a data source first.</div>
        )
      ) : hasDataCollection ? (
        hasLookupFields ? (
          <FieldList
            schema={lookupFieldsSchema}
            variablesSchema={variablesSchema}
            value={lookupConfig.query}
            onChange={(query) =>
              patchConfig({
                lookup: {
                  ...lookupConfig,
                  query,
                },
              })
            }
          />
        ) : (
          <div className='italic'>
            Data Collection "{dataCollectionSpec?.name}" does not have fields to
            lookup by.
          </div>
        )
      ) : (
        <div>Please configure a data source first.</div>
      )}
    </CollapsibleSection>
  )
}

export const getLookupQueryConfigErrors: NodeConfigurationErrorsGetter = ({
  runTimeVariablesSchema,
  config,
}) => {
  return getBrokenVariablesConfigurationErrors(config?.query, [
    runTimeVariablesSchema,
  ])
}

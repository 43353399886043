import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'
import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .fieldMapping('{FIELD_MAPPING_KEY}')
  .patch('{INPUT}')
`

const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/field-mappings/{FIELD_MAPPING_KEY}',
  {
    method: 'PATCH',
    body: '{INPUT}',
  },
)

export function PatchFieldMappingInstanceCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FIELD_MAPPING_KEY: CodeParamType.FieldMappingKey,
        INPUT: CodeParamType.Object,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .fieldMapping(parameters.FIELD_MAPPING_KEY)
          .patch(parameters.INPUT)
      }}
    />
  )
}

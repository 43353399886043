/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "API Proxy",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "REST API",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "JavaScript SDK",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Headers",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Error Handling",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Providing Full API URL",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'API Proxy';
export const DESCRIPTION = 'Make requests to the underlying application API when you want to do something custom.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "api-proxy",
      children: "API Proxy"
    }), "\n", _jsxs(_components.p, {
      children: ["You can make requests to the external application's API using ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connections"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "rest-api",
      children: "REST API"
    }), "\n", _jsx(_components.p, {
      children: "To make a Proxy request using REST API, simply prefix the request with"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-text",
        children: "https://api.integration.app/connections/<connectionId>/proxy\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Let's say you created a connection to the Hubspot CRM and want to make a request to the following hubspot endpoint:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-text",
        children: "https://api.hubapi.com/crm/v3/owners/\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "you would instead make a request to"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-text",
        children: "https://api.integration.app/connections/<connectionId>/proxy/owners/\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "The HTTP method and body you use for this request will be proxied to the underlying application's API."
    }), "\n", _jsx(_components.h2, {
      id: "javascript-sdk",
      children: "JavaScript SDK"
    }), "\n", _jsxs(_components.p, {
      children: ["To make a Proxy request using JavaScript SDK, use ", _jsx(_components.code, {
        children: "proxy"
      }), " property of the Connection:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const response = integrationApp.connection('connection-id').proxy.get('/owners/')\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["You can call ", _jsx(_components.code, {
        children: ".get"
      }), ", ", _jsx(_components.code, {
        children: ".post"
      }), ", ", _jsx(_components.code, {
        children: ".patch"
      }), ", ", _jsx(_components.code, {
        children: ".put"
      }), " and ", _jsx(_components.code, {
        children: ".delete"
      }), " requests of the ", _jsx(_components.code, {
        children: "proxy"
      }), " property.\nFirst parameter is an API path and the second is an optional object with the request payload for ", _jsx(_components.code, {
        children: "post"
      }), ", ", _jsx(_components.code, {
        children: "patch"
      }), ", and ", _jsx(_components.code, {
        children: "put"
      }), " methods."]
    }), "\n", _jsx(_components.h2, {
      id: "headers",
      children: "Headers"
    }), "\n", _jsxs(_components.p, {
      children: ["You will need to provide all the headers required for a successful request (including ", _jsx(_components.code, {
        children: "accept"
      }), " and ", _jsx(_components.code, {
        children: "content-type"
      }), ").\nOur proxy will add headers required for authentication and making correct API requests to a given application.\nThese headers are defined in the API client configuration of each connector.\nSome headers will be removed by our engine for technical reasons. These are:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "host,"
      }), "\n", _jsx(_components.li, {
        children: "x-request-id,"
      }), "\n", _jsx(_components.li, {
        children: "x-real-ip,"
      }), "\n", _jsx(_components.li, {
        children: "x-forwarded-for,"
      }), "\n", _jsx(_components.li, {
        children: "x-forwarded-host,"
      }), "\n", _jsx(_components.li, {
        children: "x-forwarded-port,"
      }), "\n", _jsx(_components.li, {
        children: "x-forwarded-proto,"
      }), "\n", _jsx(_components.li, {
        children: "x-forwarded-scheme,"
      }), "\n", _jsx(_components.li, {
        children: "x-scheme,"
      }), "\n", _jsx(_components.li, {
        children: "x-original-forwarded-for,"
      }), "\n", _jsx(_components.li, {
        children: "x-different-name,"
      }), "\n", _jsx(_components.li, {
        children: "x-request-start,"
      }), "\n", _jsx(_components.li, {
        children: "x-using-nginx-controller,"
      }), "\n", _jsx(_components.li, {
        children: "x-amzn-trace-id,"
      }), "\n", _jsx(_components.li, {
        children: "forwarded-for-header,"
      }), "\n", _jsx(_components.li, {
        children: "proxy-protocol,"
      }), "\n", _jsx(_components.li, {
        children: "real_ip_header,"
      }), "\n", _jsx(_components.li, {
        children: "real_ip_recursive,"
      }), "\n", _jsx(_components.li, {
        children: "cdn-loop,"
      }), "\n", _jsx(_components.li, {
        children: "cf-ipcountry,"
      }), "\n", _jsx(_components.li, {
        children: "cf-ray,"
      }), "\n", _jsx(_components.li, {
        children: "cf-visitor,"
      }), "\n", _jsx(_components.li, {
        children: "cf-connecting-ip,"
      }), "\n", _jsx(_components.li, {
        children: "connection,"
      }), "\n", _jsx(_components.li, {
        children: "authorization,"
      }), "\n", _jsx(_components.li, {
        children: "content-length."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "error-handling",
      children: "Error Handling"
    }), "\n", _jsx(_components.p, {
      children: "Proxy will return the same status code, headers, and body that the underlying application's API returns. The only exception is handling credential refreshes.\nProxy will attempt to refresh credentials once if they are expired and then retry the request. If something goes wrong during the refresh, the original error from the API will be returned."
    }), "\n", _jsx(_components.h2, {
      id: "providing-full-api-url",
      children: "Providing Full API URL"
    }), "\n", _jsx(_components.p, {
      children: "If you want to use the full API URL without us modifying it, you can use the following request:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-text",
        children: "POST https://api.integration.app/connections/<connectionIdOrKey>/request\n\n{\n    \"path\": \"https://api.hubapi.com/crm/v3/owners/\",\n    \"method\": \"POST\"\n    \"data\": \"...\",\n    \"headers\": {\n        \"additional-header\": \"value\"\n    }\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

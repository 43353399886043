import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'

const js = `
const { items: flowInstances } = await integrationApp.connection('{INTEGRATION_KEY}').flows.list()

const { items: actionInstances } = await integrationApp.connection('{INTEGRATION_KEY}').actions.list()

const { items: dataSourceInstances } = await integrationApp.connection('{INTEGRATION_KEY}').dataSources.list()

const { items: fieldMappingInstances } = await integrationApp.connection('{INTEGRATION_KEY}').fieldMappings.list()
`

export function ListInstancesByIntegrationCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{
        js,
      }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        const { items: flowInstances } = await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .flows.list()

        const { items: actionInstances } = await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .actions.list()

        const { items: dataSourceInstances } = await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .dataSources.list()

        const { items: fieldMappingInstances } = await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .fieldMappings.list()

        return {
          flowInstances,
          actionInstances,
          dataSourceInstances,
          fieldMappingInstances,
        }
      }}
    />
  )
}

import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `const flowInstance = await integrationApp
  .connection('{INTEGRATION_KEY}')
  .flow('{FLOW_KEY}')
  .patch({
    parameters: '{PARAMETERS}'
  })
`

export function PatchFlowInstanceParametersCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{
        js,
      }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FLOW_KEY: CodeParamType.FlowKey,
        PARAMETERS: {
          type: CodeParamType.Object,
          default: '<flow instance parameters>',
        },
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .flow(parameters.FLOW_KEY)
          .patch({
            parameters: parameters.PARAMETERS,
          })
      }}
    />
  )
}

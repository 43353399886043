import { useState } from 'react'
import { DataLinkTable } from '@integration-app/sdk'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import Button from '@integration-app/ui/Button'
import { SimpleInput } from '@integration-app/ui'
import { useSyncKeyWithName } from 'components/IntegrationElements/hooks/useSyncKeyWithName'
import useIntegrationElement from 'components/IntegrationElements/hooks/useIntegrationElement'
import { AddEntryPopup } from 'routes/components/Popup/AddEntryPopup'
import { Popup } from 'routes/components/Popup'
import { ContentRows } from 'components/ContentRows'

export function PopupCreateDataLinkForm({
  open,
  onClose,
  onCreate,
}: {
  open: boolean
  onClose: () => void
  onCreate: (item: DataLinkTable) => void
}) {
  function handleOnCreate(dataLinkTable: DataLinkTable) {
    onClose()
    onCreate(dataLinkTable)
  }

  if (!open) return null

  return (
    <AddEntryPopup onClose={onClose}>
      <CreateDataLinkForm onCreate={handleOnCreate} />
    </AddEntryPopup>
  )
}

function CreateDataLinkForm({
  onCreate,
}: {
  onCreate: (item: DataLinkTable) => void
}) {
  const { createItem } = useIntegrationElement<DataLinkTable>({
    route: 'data-link-tables',
  })
  const [item, setItem] = useState<Partial<DataLinkTable>>({})

  function handleChange(data) {
    setItem((prevItem) => ({ ...prevItem, ...data }))
  }

  const handleNameChange = useSyncKeyWithName(handleChange)

  async function handleCreate() {
    const createdDataLinkTable = await createItem({ ...item })
    onCreate(createdDataLinkTable)
  }

  const isCreateDataValid = !!item.key && !!item.name

  return (
    <form onSubmit={useEventStopPropagation(handleCreate)}>
      <Popup.Header>
        <h2>Create Data Link Table</h2>
      </Popup.Header>

      <ContentRows>
        <SimpleInput
          label={'Name'}
          value={item.name}
          onChange={handleNameChange}
          autoFocus
        />

        <SimpleInput
          label={'Key'}
          value={item.key}
          onChange={(key) => handleChange({ key })}
        />
      </ContentRows>
      <Popup.Footer>
        <Button
          disabled={!isCreateDataValid}
          onClick={handleCreate}
          type={'submit'}
        >
          Create Data Link Table
        </Button>
      </Popup.Footer>
    </form>
  )
}

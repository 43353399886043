import { makeDataField } from '@integration-app/ui/DataBuilder'
import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { getBrokenVariablesConfigurationErrors } from '../configuration-errors/geBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../configuration-errors/types'

export function TransformData() {
  const { variablesSchema, patchConfig, config } = useGenericConfig()

  const field = makeDataField({
    variablesSchema,
    value: config.output,
    schema: {
      title: 'Output',
    },
  })

  return (
    <div>
      <h3>Output</h3>

      <p>Configure data this node returns</p>

      <AdminDataBuilderForm
        field={field}
        onChange={(output) => patchConfig({ output })}
      />
    </div>
  )
}

export const getTransformDataNodeErrors: NodeConfigurationErrorsGetter = ({
  config,
  runTimeVariablesSchema,
}) => {
  const errors = []

  const brokenVariablesErrors = getBrokenVariablesConfigurationErrors(
    config?.output,
    [runTimeVariablesSchema],
  )

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'NodeConfigurationErrorData' is n... Remove this comment to see the full error message
  errors.push(...brokenVariablesErrors)

  return errors
}

import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'
import useWorkspace from '../../Workspaces/workspace-context'

export function CreateAsymmetricJwtCodeExample() {
  const { workspace } = useWorkspace()

  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      variables={{
        WORKSPACE_KEY: workspace?.key ?? '<WORKSPACE_KEY>',
      }}
      variablesSpec={{
        WORKSPACE_KEY: {
          type: CodeParamType.String,
          title: 'Workspace Key',
        },
      }}
      parameters={{
        USER_ID: CodeParamType.String,
        USER_NAME: CodeParamType.String,
      }}
    />
  )
}

const js = `import jwt from 'jsonwebtoken'

// Your workspace key and secret.
// You can find them on the Settings page.
const WORKSPACE_KEY = '{WORKSPACE_KEY}'

// Do not expose your private key to anyone. You should only use it to sign tokens.
const PRIVATE_KEY = \`<YOUR PRIVATE KEY>\`

const tokenData = {
  // Identifier of user or organization.
  id: '{USER_ID}',
  // Human-readable name (it will simplify troubleshooting)
  name: '{USER_NAME}',
  // (optional) Any user fields you want to attach to your user.
  fields: {
    userField: '<user field value>'
  }
}

const options = {
  issuer: WORKSPACE_KEY,
  // To prevent token from being used for too long
  expiresIn: 7200,

  algorithm: 'ES256' // or any other asymmetric algorithm you prefer (RS*, ES*, PS*)
}

const token = jwt.sign(tokenData, PRIVATE_KEY, options)
`

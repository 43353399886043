/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Connections",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Integrations",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Inspecting Connections",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Creating a Connection",
    "attributes": {},
    "children": []
  }]
}];
import DocLink from "components/Docs/DocLink";
import {INTEGRATIONS_ROUTE, CONNECTIONS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const TITLE = 'Connections';
export const DESCRIPTION = 'Connecting your app with third-party apps';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components), {Link} = _components;
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "connections",
      children: "Connections"
    }), "\n", _jsxs(_components.p, {
      children: ["Connections ", _jsx(_components.strong, {
        children: "are"
      }), " used to access external applications on behalf of your users."]
    }), "\n", _jsx(_components.h2, {
      id: "integrations",
      children: "Integrations"
    }), "\n", _jsxs(_components.p, {
      children: ["Integrations represent external applications and parameters used to connect to them. You can manage the list of integrations on the ", _jsx(Link, {
        path: INTEGRATIONS_ROUTE,
        children: "Integrations"
      }), " page."]
    }), "\n", _jsx(_components.p, {
      children: "Default parameters for integrations let your users create Connections with third-party apps using Integration.app credentials. You can change them for branding purposes."
    }), "\n", _jsx(_components.h2, {
      id: "inspecting-connections",
      children: "Inspecting Connections"
    }), "\n", _jsxs(_components.p, {
      children: ["You can see the list of connections in your workspace and information about each on the ", _jsx(Link, {
        path: CONNECTIONS_ROUTE,
        children: "Connections"
      }), " page."]
    }), "\n", _jsx(_components.h2, {
      id: "creating-a-connection",
      children: "Creating a Connection"
    }), "\n", _jsx(_components.p, {
      children: "For testing, you can create connections for your test customer right from the console."
    }), "\n", _jsxs(_components.p, {
      children: ["You can create connections from inside your app using ", _jsx(DocLink, {
        path: "ux/connection",
        children: "Connection UI"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["In rare cases, you may want to create a connection on behalf of your customer manually. You can do it from the ", _jsx(Link, {
        path: CONNECTIONS_ROUTE,
        children: "Connections"
      }), " page."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Running Custom Code",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Custom Code Example",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Logging",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Running Custom Code';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "running-custom-code",
      children: "Running Custom Code"
    }), "\n", _jsx(_components.p, {
      children: "Integration Engine allows you to run custom code written in Javascript or Tuypescript."
    }), "\n", _jsx(_components.p, {
      children: "You can use it to implement complex logic that is hard to do with mapping and existing abstractions."
    }), "\n", _jsx(_components.h2, {
      id: "custom-code-example",
      children: "Custom Code Example"
    }), "\n", _jsx(_components.p, {
      children: "Custom code must export a default function (that can be async).\nThis function will be called with arguments that depend on the execution context (see the full list below)."
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-javascript",
        children: "export default async function myCustomFunction({\n    externalApiClient,\n    internalApiClient\n}) {\n    // Get data from an external app associated with the current integration\n    const externalData = await externalApiClient.get('/some-data')\n\n    // Pass this data to your app's API\n    await internalApiClient.post('/some-data', externalData)\n}\n"
      })
    }), "\n", _jsx("table", {
      className: 'stripe w-full',
      children: _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "externalApiClient"
            })
          }), "\n", _jsx("td", {
            children: "API client you can use to make requests to external app."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "internalApiClient"
            })
          }), "\n", _jsx("td", {
            children: "API client you can use to make requests to your app."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "engineApiClient"
            })
          }), "\n", _jsx("td", {
            children: "API client you can use to make requests to the integration engine."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "credentials"
            })
          }), "\n", _jsx("td", {
            children: "Credentials from the current connection."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "input"
            })
          }), "\n", _jsx("td", {
            children: "Input of the action or flow node this code is being executed for (if it runs in the context of an action or a flow node)."
          })]
        })]
      })
    }), "\n", _jsx(_components.h2, {
      id: "logging",
      children: "Logging"
    }), "\n", _jsxs(_components.p, {
      children: ["Anything you write to stdout will be stored as execution logs of the custom code. The simplest way to do it is to use ", _jsx(_components.code, {
        children: "console.log"
      }), ":"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-javascript",
        children: "export default function myCustomFunction() {\n    console.log('Hello, world!')\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "If you want to output complex data, you should JSON-serialize it:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-javascript",
        children: "export default function myCustomFunction() {\n    console.log(JSON.stringify({hello: 'world'}))\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;

import UiTab from '@integration-app/ui/Tab'
import classes from './Tab.module.css'
import { ExampleBlock } from './ExampleBlock'

const Group = ({ children, ...props }) => (
  <UiTab.Group as={ExampleBlock} {...props}>
    {children}
  </UiTab.Group>
)

const List = ({ children, ...props }) => (
  <UiTab.List as={ExampleBlock.Header} className={classes.tabList} {...props}>
    {children}
  </UiTab.List>
)

const TabItem = ({ children, ...props }) => (
  <UiTab {...props} className={classes.tab}>
    {children}
  </UiTab>
)

const Panels = ({ children, ...props }) => (
  <UiTab.Panels
    as={ExampleBlock.Section}
    className={classes.tabPanelWrapper}
    {...props}
  >
    {children}
  </UiTab.Panels>
)

type TabType = typeof TabItem & {
  Group: typeof Group
  List: typeof List
  Panels: typeof Panels
  Panel: typeof UiTab.Panel
}

export const Tab: TabType = Object.assign(TabItem, {
  Group: Group,
  List: List,
  Panels: Panels,
  Panel: UiTab.Panel,
})

import ExampleCodeBlock from '../../Docs/ExampleCodeBlock'

const js = `const { items: integrations } = await integrationApp.integrations.find()`

export function ListIntegrationsCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{
        js,
      }}
      parameters={{}}
      run={async ({ integrationApp }) => {
        const { items: integrations } = await integrationApp.integrations.find()

        return { integrations }
      }}
    />
  )
}

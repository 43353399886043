import { useState } from 'react'
import Button from '@integration-app/ui/Button'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import { Cell, Row } from '@integration-app/ui/DataBuilderUI'
import { useSyncKeyWithName } from 'components/IntegrationElements/hooks/useSyncKeyWithName'
import { SimpleInput } from '@integration-app/ui'
import { Popup } from 'routes/components/Popup'
import { IntegrationComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationComboboxSelect'
import { StepHeader } from './StepHeader'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'

export function StepSelectNameAndKeyForElementAndCreate<
  CreateElementRequest extends {
    integrationId?: string
    key: string
    name: string
  },
>({
  elementName,
  onBack,
  onCreate,
  integrationId,
  defaultCreateData,
  isCreateDataValid = (data: Partial<CreateElementRequest>) =>
    !!data.name && !!data.key,
  AdditionalInputs,
}: {
  elementName: string
  onBack(): void
  onCreate(data: CreateElementRequest): Promise<void>
  integrationId?: string
  defaultCreateData: CreateElementRequest
  isCreateDataValid?(data: Partial<CreateElementRequest>): boolean

  AdditionalInputs?: JSX.Element
}) {
  const [item, setItem] = useState<CreateElementRequest>(defaultCreateData)

  function handleChange(data: any) {
    return setItem((prevItem) => ({ ...prevItem, ...data }))
  }

  const [creating, setCreating] = useState(false)
  const [creationError, setCreationError] = useState<Error | null>(null)

  async function handleCreate() {
    try {
      setCreationError(null)
      setCreating(true)
      return await onCreate(item)
    } catch (e) {
      setCreationError(e)
    } finally {
      setCreating(false)
    }
  }

  const handleNameChange = useSyncKeyWithName(handleChange)

  return (
    <form onSubmit={useEventStopPropagation(handleCreate)}>
      <StepHeader
        elementName={elementName}
        onBack={onBack}
        integrationId={integrationId}
      />

      <div className='flex flex-col gap-2'>
        <SimpleInput
          label={'Name'}
          value={item.name}
          onChange={handleNameChange}
          autoFocus
        />

        <SimpleInput
          label={'Key'}
          value={item.key}
          onChange={(key) => handleChange({ key })}
          tabIndex={2}
        />

        {AdditionalInputs && <>{AdditionalInputs}</>}

        {integrationId && (
          <Row>
            <Cell.Name>Integration</Cell.Name>
            <Cell.Input>
              <IntegrationComboboxSelect
                useId
                value={item.integrationId}
                onChange={(integrationId) =>
                  handleChange({ key: '', integrationId })
                }
                deletable={false}
              />
            </Cell.Input>
          </Row>
        )}
      </div>

      <Popup.Footer className='flex gap-2 items-end'>
        <Button
          disabled={!isCreateDataValid(item) || creating}
          loading={creating}
          onClick={handleCreate}
          type='submit'
        >
          Create {elementName}
        </Button>

        {creationError && (
          <div className='border border-error text-error rounded px-2 py-1 mx-4 flex items-center'>
            <SvgIcon
              type={SvgIconType.AlertTriangle}
              className='w-6 h-6 flex-grow-0 flex-shrink-0'
            />
            <span className='font-bold ml-2'>{creationError.message}</span>
          </div>
        )}
      </Popup.Footer>
    </form>
  )
}

import { DataLinkDirection } from '@integration-app/sdk'
import useFlowNode from 'components/FlowBuilder/flow-node-context'
import { ContentRows } from 'components/ContentRows'
import SidePanel from 'components/Layout/SidePanel'
import SimpleSelect from 'components/SimpleSelect'
import { DataLinkTableConfigWithCreatePopup } from './DataLinkTableConfigWithCreatePopup'

function DataLinkDirectionSelect({ value, onChange, includeBoth = false }) {
  const options = [
    {
      label: 'Import (External App to Your App)',
      value: DataLinkDirection.IMPORT,
    },
    {
      label: 'Export (Your App to External App)',
      value: DataLinkDirection.EXPORT,
    },
  ]

  if (includeBoth) {
    options.push({
      label: 'Both',
      value: DataLinkDirection.BOTH,
    })
  }

  return (
    <SimpleSelect
      name='Data Link Direction'
      value={value}
      options={options}
      onChange={onChange}
    />
  )
}

export function DataLinkTableAndDirectionConfig() {
  const { node, patchNodeConfig } = useFlowNode()

  return (
    <SidePanel.Content>
      <ContentRows>
        <DataLinkTableConfigWithCreatePopup
          dataLinkTableKey={node.config?.dataLinkTable?.key}
          onChange={(dataLinkTableKey) =>
            patchNodeConfig({ dataLinkTable: { key: dataLinkTableKey } })
          }
        />
        <DataLinkDirectionSelect
          value={node.config?.direction}
          includeBoth={true}
          onChange={(direction) => patchNodeConfig({ direction })}
        />
      </ContentRows>
    </SidePanel.Content>
  )
}

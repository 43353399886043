import {
  HTMLAttributes,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import Popover from '@integration-app/ui/Popover'
import { useClickOutsideElements } from '@integration-app/ui/hooks/useWindowEvent'
import {
  DisclosureSectionProvider,
  useDisclosureSectionHeader,
} from './DisclosureSectionContext'
import classes from './DisclosureSection.module.css'

function DisclosureSectionContainer({
  open,
  accordion,
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement> & { open?: boolean; accordion?: boolean }) {
  return (
    <DisclosureSectionProvider accordion={accordion} open={open}>
      <div
        className={clsx(
          classes.section,
          accordion && classes.section__accordion,
          className,
        )}
        {...props}
      >
        {children}
      </div>
    </DisclosureSectionProvider>
  )
}

type StatusType = 'error'

function DisclosureSectionHeader({
  children,
  tooltip,
  open,
  className,
  status,
  ...props
}: HTMLAttributes<HTMLHeadingElement> & {
  open?: boolean
  tooltip?: string | ReactElement
  status?: StatusType
}) {
  const { toggleStatus, isOpen } = useDisclosureSectionHeader()

  useEffect(() => {
    if (open) {
      toggleStatus()
    }
  }, [])

  return (
    <div
      className={clsx(
        classes.section_header,
        isOpen && classes.section_header__open,
      )}
      onClick={toggleStatus}
      data-status={status}
    >
      <h4 className={className} {...props}>
        {children}
      </h4>
      <div className={classes.actionBar}>
        {tooltip && <HelpToolip>{tooltip}</HelpToolip>}
        <ToggleButton isOpen={isOpen} />
      </div>
    </div>
  )
}

function DisclosureSectionContent({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className={clsx(classes.section_content, className)}>
      {children}
    </div>
  )
}

export const DisclosureSection = Object.assign(DisclosureSectionContainer, {
  Header: DisclosureSectionHeader,
  Content: DisclosureSectionContent,
})

function ToggleButton({ isOpen }: { isOpen: boolean }) {
  return (
    <button
      className={clsx(
        classes.actionBar_button,
        classes.actionBar_button__toggle,
      )}
    >
      <SvgIcon
        type={isOpen ? SvgIconType.ChevronDown : SvgIconType.ChevronRight}
      />
    </button>
  )
}

function HelpToolip({ children }: { children: string | ReactElement }) {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  const popoverRef = useRef(null)

  useClickOutsideElements([ref, popoverRef], () => setIsOpen(false))

  function togglePopover() {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <button
        ref={ref}
        onClick={togglePopover}
        className={clsx(
          classes.actionBar_button,
          classes.actionBar_button__tooltip,
        )}
      >
        <SvgIcon type={SvgIconType.QuestionMark} />
      </button>
      <Popover
        ref={popoverRef}
        isOpen={isOpen}
        refElement={ref}
        className={classes.tooltip}
      >
        {children}
      </Popover>
    </>
  )
}

import ExampleCodeBlock from 'components/Docs/ExampleCodeBlock'

export function InitSdkWithCredentialsCodeExample() {
  const js = `import { IntegrationAppClient } from '@integration-app/sdk'

const integrationApp = new IntegrationAppClient({
  token: '{TOKEN}',
  credentials: {
    apiKey: '{USER_API_KEY}',
  }
})
`

  return <ExampleCodeBlock customCodes={{ js }} />
}

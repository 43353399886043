import ExampleCodeBlock from 'components/Docs/ExampleCodeBlock'

const js = `await integrationApp.open()`

export function OpenUiCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      run={async ({ integrationApp }) => {
        return await integrationApp.open()
      }}
    />
  )
}

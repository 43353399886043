import classes from './RoundButton.module.css'
import clsx from 'clsx'
import { HTMLAttributes } from 'react'

export function RoundButton({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLButtonElement>) {
  const classNames = clsx(classes.roundButton, className)
  return (
    <button className={classNames} {...props}>
      {children}
    </button>
  )
}

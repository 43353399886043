import { useCallback, useEffect } from 'react'

export function useScroll(
  element: Element | Window | null,
  cb: ({ scrollX, scrollY }: { scrollX: number; scrollY: number }) => void,
) {
  const onScroll = useCallback(() => {
    if (!element) return
    cb({
      scrollX: getElementScrollLeft(element),
      scrollY: getElementScrollTop(element),
    })
  }, [element, cb])

  useEffect(() => {
    if (!element) return

    element.addEventListener('scroll', onScroll)
    return () => {
      element.removeEventListener('scroll', onScroll)
    }
  }, [element, onScroll])
}

function getElementScrollTop(element: Element | Window) {
  if (element === window) {
    return window.scrollY
  }
  return (element as HTMLElement).scrollTop
}

function getElementScrollLeft(element: Element | Window) {
  if (element === window) {
    return window.scrollX
  }
  return (element as HTMLElement).scrollLeft
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Custom Connection UI",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "1. Get Connector Specification",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "2. Connection Parameters UI",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "3. Creating a Connection",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "4. Putting it all together",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "5. Re-connecting",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Multiple Connections for the same Integration",
    "attributes": {},
    "children": []
  }]
}];
import {CodeExample} from '../../../../../components/CodeExamples';
export const TITLE = 'Custom Connection UI';
export const DESCRIPTION = 'Building "Connect" UI that fully blends into your app';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    code: "code",
    ul: "ul",
    li: "li",
    blockquote: "blockquote"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.ConnectionParametersUi) _missingMdxReference("CodeExample.ConnectionParametersUi", true);
  if (!CodeExample.ConnectionWidgetUi) _missingMdxReference("CodeExample.ConnectionWidgetUi", true);
  if (!CodeExample.GetConnectorSpec) _missingMdxReference("CodeExample.GetConnectorSpec", true);
  if (!CodeExample.IntegrationConnect) _missingMdxReference("CodeExample.IntegrationConnect", true);
  if (!CodeExample.OpenNewConnection) _missingMdxReference("CodeExample.OpenNewConnection", true);
  if (!CodeExample.OpenNewConnectionAllowMultipleConnections) _missingMdxReference("CodeExample.OpenNewConnectionAllowMultipleConnections", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "custom-connection-ui",
      children: "Custom Connection UI"
    }), "\n", _jsx(_components.p, {
      children: "Pre-built connection UI looks like this:"
    }), "\n", _jsx(CodeExample.OpenNewConnection, {}), "\n", _jsx(_components.p, {
      children: "You can implement a custom version of this UI using this guide."
    }), "\n", _jsx(_components.h2, {
      id: "1-get-connector-specification",
      children: "1. Get Connector Specification"
    }), "\n", _jsxs(_components.p, {
      children: ["Connector Specification contains information about: which parameters should user provide to create a connection (if any).\nYou can get it by calling ", _jsx(_components.code, {
        children: "getConnectorSpec"
      }), " method of the integration:"]
    }), "\n", _jsx(CodeExample.GetConnectorSpec, {}), "\n", _jsxs(_components.p, {
      children: ["Now, look at the ", _jsx(_components.code, {
        children: "ui"
      }), " property of the ", _jsx(_components.code, {
        children: "connectorSpec"
      }), " object. It contains the following fields:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "schema"
        }), " - ", _jsx(DocLink, {
          path: "engine/references/data-schemas",
          children: "Data Schema"
        }), " of the parameters required to create a connection. It can be ", _jsx(_components.code, {
          children: "null"
        }), " if no parameters are required."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "description"
        }), " - a human-readable description explaining the parameters."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "helpUri"
        }), " - link to documentation explaining how to get the parameters."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "test"
        }), " (boolean) - whether you can test the connection parameters (see below)."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "2-connection-parameters-ui",
      children: "2. Connection Parameters UI"
    }), "\n", _jsxs(_components.p, {
      children: ["You can use your preferred way of converting ", _jsx("a", {
        href: "https://json-schema.org/",
        target: "_blank",
        children: "JSON Schema"
      }), " into a form.\nFor example, you can extract a list of fields from the schema and simply display an input for each:"]
    }), "\n", _jsx(CodeExample.ConnectionParametersUi, {}), "\n", _jsx(_components.p, {
      children: "If a connection doesn't have parameters, you can skip this step."
    }), "\n", _jsx(_components.h2, {
      id: "3-creating-a-connection",
      children: "3. Creating a Connection"
    }), "\n", _jsx(_components.p, {
      children: "When you collected connection parameters, you can create a connection:"
    }), "\n", _jsx(CodeExample.IntegrationConnect, {}), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsxs(_components.p, {
        children: ["This code may open a new window if it is required by the authentication process.\nMake sure this code runs inside a user action handler (e.g. ", _jsx(_components.code, {
          children: "onClick"
        }), "),\notherwise the browser may block the new window."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "4-putting-it-all-together",
      children: "4. Putting it all together"
    }), "\n", _jsx(_components.p, {
      children: "Here is a simple UI component that displays the connection parameters and create a new connection:"
    }), "\n", _jsx(CodeExample.ConnectionWidgetUi, {}), "\n", _jsx(_components.h2, {
      id: "5-re-connecting",
      children: "5. Re-connecting"
    }), "\n", _jsx(_components.p, {
      children: "When a connection becomes disconnected, you can re-connect it using the same code as when creating a new connection:"
    }), "\n", _jsx(CodeExample.IntegrationConnect, {}), "\n", _jsx(_components.h2, {
      id: "multiple-connections-for-the-same-integration",
      children: "Multiple Connections for the same Integration"
    }), "\n", _jsxs(_components.p, {
      children: ["If you want to let your customers create multiple connections for the same integration,\nset ", _jsx(_components.code, {
        children: "allowMultipleConnections"
      }), " option of the ", _jsx(_components.code, {
        children: "connect"
      }), " method call to ", _jsx(_components.code, {
        children: "true"
      }), "."]
    }), "\n", _jsx(CodeExample.OpenNewConnectionAllowMultipleConnections, {})]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

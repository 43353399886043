/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Connecting your backend to Integration App",
  "attributes": {},
  "children": []
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components), {DocGrid} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "connecting-your-backend-to-integration-app",
      children: "Connecting your backend to Integration App"
    }), "\n", _jsx(_components.p, {
      children: "To connect your backend to integration.app, you need one or both of these:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsx(_components.li, {
        children: "Make requests from your backend to integration.app."
      }), "\n", _jsx(_components.li, {
        children: "Configure integration.app to make requests to your backend."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Here is how you do it."
    }), "\n", _jsxs(DocGrid, {
      cols: 2,
      children: [_jsx(DocGrid.Link, {
        path: "overview/getting-started/backend/rest-api"
      }), _jsx(DocGrid.Link, {
        path: "overview/getting-started/backend/your-app-api"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

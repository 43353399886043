import { ReactFlowProvider } from 'reactflow'
import { FlowPreviewGraph } from './Graph'
import { Flow } from '@integration-app/sdk'
import { FlowPreviewProvider } from './flow-preview-context'

export function FlowPreview({ flowNodes }: { flowNodes: Flow['nodes'] }) {
  return (
    <FlowPreviewProvider flowNodes={flowNodes}>
      <ReactFlowProvider>
        <FlowPreviewGraph />
      </ReactFlowProvider>
    </FlowPreviewProvider>
  )
}

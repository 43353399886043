import {
  pickFieldsFromSchema,
  removeRequiredFieldsFromSchema,
  schemaWithTitle,
} from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { CollapsibleSection } from '../Section'
import { useGenericConfig } from './contexts/generic-config-context'

export function ListFilterConfig() {
  const {
    integration,
    dataCollectionSpec,
    udmSpec,
    patchConfig,
    config,
    variablesSchema,
  } = useGenericConfig()

  const hasUnifiedFilterFields = !!udmSpec?.list?.filterFields?.length
  const hasCollectionFilterFields =
    !!dataCollectionSpec?.list?.filterFields?.length

  const hasFilterFields = integration
    ? hasCollectionFilterFields
    : hasUnifiedFilterFields

  if (!hasFilterFields) {
    return null
  }

  const filterFieldsSchema = schemaWithTitle(
    removeRequiredFieldsFromSchema(
      pickFieldsFromSchema(
        dataCollectionSpec?.fieldsSchema,
        dataCollectionSpec?.list?.filterFields,
      ),
    ),
    'Filter by Fields',
  )

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Filter</CollapsibleSection.Header>

      {integration ? (
        <DataBuilderForm
          onChange={(filter) => patchConfig({ filter })}
          field={makeDataField({
            variablesSchema,
            value: config.filter,
            schema: filterFieldsSchema,
          })}
        />
      ) : (
        <DataBuilderForm
          onChange={(unifiedFilter) => patchConfig({ unifiedFilter })}
          field={makeDataField({
            variablesSchema,
            value: config.unifiedFilter,
            schema: filterFieldsSchema,
          })}
        />
      )}
    </CollapsibleSection>
  )
}

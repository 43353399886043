import DataSourceSelect from '../../../../../../../components/DataSources/DataSourceSelect'
import SimpleSelect from '../../../../../../../components/SimpleSelect'
import { FieldMappingDirection } from '@integration-app/sdk'
import { useFieldMappingContext } from '../contexts/field-mapping-context'
import { FieldMappingInternalSchema } from './FieldMappingInternalSchema'
import { HBox } from '@integration-app/ui/Layout'
import { ElementPageWrapperWithCodeMode } from '../../../../components/ElementPageWrapperWithCodeMode'

import { CollapsibleSection } from '../../../../components/Section'
import { FrozenFieldsSection } from './FrozenFieldsSection'
import { ImportFieldMappingForm } from './ImportFieldMappingForm'
import { ExportFieldMappingForm } from './ExportFieldMappingForm'
import { routeTo } from '../../../../routes-constants'
import { NavLink } from 'react-router-dom'
import { FieldMappingChildInternalSchema } from './FieldMappingChildInternalSchema'

export function FiledMappingConfiguration() {
  const { fieldMapping, onCodeChange, code } = useFieldMappingContext()

  const isUniversal = !fieldMapping.integrationId

  const title = `Configure ${
    isUniversal ? 'Universal' : fieldMapping.integration?.name
  } Field Mapping`

  const isChild = !!fieldMapping.universalFieldMappingId

  return (
    <HBox>
      <ElementPageWrapperWithCodeMode
        title={title}
        key={fieldMapping.id} // to force rerender for code/ui switch
        code={code}
        onCodeChange={onCodeChange}
      >
        {isChild ? <ChildFieldMapping /> : <ParentFieldMapping />}
      </ElementPageWrapperWithCodeMode>
    </HBox>
  )
}

function ParentFieldMapping() {
  const { fieldMapping, onChange } = useFieldMappingContext()

  return (
    <>
      <CollapsibleSection open>
        <CollapsibleSection.Header>Direction</CollapsibleSection.Header>

        <p>
          Does it map fields from your app to external app or the other way
          around.
        </p>

        <SimpleSelect
          name='Direction'
          value={fieldMapping.direction}
          options={FIELD_MAPPING_DIRECTION_OPTIONS}
          onChange={(direction: FieldMappingDirection) =>
            onChange({ direction })
          }
        />
      </CollapsibleSection>

      {fieldMapping?.direction && (
        <>
          <CollapsibleSection open>
            <CollapsibleSection.Header>
              External Fields
            </CollapsibleSection.Header>

            <p>Where in the external app do you want to map fields from/to</p>

            <DataSourceSelect
              integrationId={fieldMapping.integrationId}
              dataSourceKey={fieldMapping.dataSourceKey}
              onChange={(dataSourceKey) => onChange({ dataSourceKey })}
            />
          </CollapsibleSection>

          <FieldMappingInternalSchema
            schema={fieldMapping.appSchema}
            onChange={(appSchema: any) => onChange({ appSchema })}
          />

          {fieldMapping.direction === FieldMappingDirection.BOTH ? (
            <CollapsibleSection open>
              <CollapsibleSection.Header>Mapping</CollapsibleSection.Header>

              <ExportFieldMappingForm />

              <ImportFieldMappingForm />
            </CollapsibleSection>
          ) : fieldMapping.direction === FieldMappingDirection.EXPORT ? (
            <ExportFieldMappingForm />
          ) : (
            <ImportFieldMappingForm />
          )}

          <FrozenFieldsSection />

          {/* Just some padding at the bottom */}
          <div className='py-6'> </div>
        </>
      )}
    </>
  )
}

function ChildFieldMapping() {
  const { fieldMapping } = useFieldMappingContext()

  return (
    <>
      <div className='mb-8'>
        This field mapping is based on a{' '}
        <NavLink
          className='link'
          to={`${routeTo.fieldMapping(fieldMapping.universalFieldMappingId!)}`}
        >
          universal field mapping
        </NavLink>
        . You can edit direction, external, and internal fields schema there.
      </div>

      <CollapsibleSection open>
        <CollapsibleSection.Header>External Fields</CollapsibleSection.Header>

        <p>Where in the external app do you want to map fields from/to</p>

        <DataSourceSelect
          integrationId={fieldMapping.integrationId}
          dataSourceKey={fieldMapping.dataSourceKey}
          disabled
        />
      </CollapsibleSection>

      <FieldMappingChildInternalSchema />

      <CollapsibleSection open>
        <CollapsibleSection.Header>Mapping</CollapsibleSection.Header>
        {fieldMapping.direction === FieldMappingDirection.BOTH ? (
          <>
            <CollapsibleSection open>
              <CollapsibleSection.Header>
                Export: Your App → External App
              </CollapsibleSection.Header>
              <ExportFieldMappingForm />
            </CollapsibleSection>
            <CollapsibleSection open>
              <CollapsibleSection.Header>
                Import: External App → Your App
              </CollapsibleSection.Header>
              <ImportFieldMappingForm />
            </CollapsibleSection>
          </>
        ) : fieldMapping.direction === FieldMappingDirection.EXPORT ? (
          <ExportFieldMappingForm />
        ) : (
          <ImportFieldMappingForm />
        )}
      </CollapsibleSection>
    </>
  )
}

export const FIELD_MAPPING_DIRECTION_OPTIONS = [
  {
    value: FieldMappingDirection.IMPORT,
    label: 'Import (from external app to yours)',
  },
  {
    value: FieldMappingDirection.EXPORT,
    label: 'Export (from your app to external app)',
  },
  {
    value: FieldMappingDirection.BOTH,
    label: 'Bi-directional',
  },
]

import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'
import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .integration('{INTEGRATION_KEY}')
  .getDataCollection('{DATA_COLLECTION_KEY}')`

const rest = httpCodeExamples(
  '/integrations/{INTEGRATION_KEY}/data/{DATA_COLLECTION_KEY}',
)

export function GetIntegrationDataCollectionCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        DATA_COLLECTION_KEY: CodeParamType.String,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .integration(parameters.INTEGRATION_KEY)
          .getDataCollection(parameters.DATA_COLLECTION_KEY)
      }}
    />
  )
}

import {
  FieldMappingDirection,
  UNIFIED_DATA_MODELS,
} from '@integration-app/sdk'
import {
  ActionBarToggleOnOff,
  CollapsibleSection,
} from '../../../../components/Section'
import { useFieldMappingContext } from '../contexts/field-mapping-context'
import { makeFieldForSchema } from './helper'
import AdminDataBuilderForm from '../../../../../../../components/AdminDataBuilderForm'

export function FrozenFieldsSection() {
  const { fieldMapping, onChange } = useFieldMappingContext()

  const isEnabled = !!(
    fieldMapping.frozenExportFields ?? fieldMapping.frozenImportFields
  )

  return (
    <>
      <CollapsibleSection open>
        <CollapsibleSection.Header>Frozen Fields</CollapsibleSection.Header>
        <CollapsibleSection.ActionBar>
          <ActionBarToggleOnOff
            checked={isEnabled}
            onChange={(value) => {
              void onChange(
                value
                  ? {
                      frozenExportFields: [],
                      frozenImportFields: [],
                    }
                  : {
                      frozenExportFields: undefined,
                      frozenImportFields: undefined,
                    },
              )
            }}
          />
        </CollapsibleSection.ActionBar>

        {isEnabled ? (
          <>
            {fieldMapping.direction === FieldMappingDirection.BOTH ? (
              <>
                <CollapsibleSection open>
                  <CollapsibleSection.Header>
                    Export: Your App → External App
                  </CollapsibleSection.Header>
                  <FrozenExportFields />
                </CollapsibleSection>
                <CollapsibleSection open>
                  <CollapsibleSection.Header>
                    Import: External App → Your App
                  </CollapsibleSection.Header>
                  <FrozenImportFields />
                </CollapsibleSection>
              </>
            ) : fieldMapping.direction === FieldMappingDirection.EXPORT ? (
              <FrozenExportFields />
            ) : (
              <FrozenImportFields />
            )}
          </>
        ) : (
          <p>
            Enable to freeze some of the mapped fields and make them not
            editable by your customers.
          </p>
        )}
      </CollapsibleSection>
    </>
  )
}

function FrozenExportFields() {
  const {
    fieldMapping,
    isUniversal,
    dataSource,
    dataCollectionSpec,
    onChange,
  } = useFieldMappingContext()

  const externalSchema = isUniversal
    ? dataSource?.udm
      ? UNIFIED_DATA_MODELS[dataSource?.udm]?.fieldsSchema
      : undefined
    : dataCollectionSpec?.fieldsSchema

  return (
    <AdminDataBuilderForm
      field={makeFieldForSchema(
        externalSchema,
        fieldMapping.frozenExportFields ?? [],
      )}
      onChange={(frozenExportFields) => onChange({ frozenExportFields })}
    />
  )
}

function FrozenImportFields() {
  const { fieldMapping, appSchema, onChange } = useFieldMappingContext()

  return (
    <AdminDataBuilderForm
      field={makeFieldForSchema(
        appSchema,
        fieldMapping.frozenImportFields ?? [],
      )}
      onChange={(frozenImportFields) => onChange({ frozenImportFields })}
    />
  )
}

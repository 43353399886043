import { UNIFIED_DATA_MODELS, buildDataSchema } from '@integration-app/sdk'
import { CollapsibleSection } from '../../Section'
import { useGenericConfig } from '../contexts/generic-config-context'
import { useDataSource } from '@integration-app/react'

import { IncludeRawFieldsBlock } from './IncludeRawFieldsBlock'
import { CustomImportFieldMapping } from './CustomImportFieldMapping'
import { SimpleRadio } from '@integration-app/ui'
import { SharedImportFieldMapping } from './SharedImportFieldMapping'

enum ImportFieldMappingUnitConfigOption {
  None = 'none',
  Custom = 'custom',
  Shared = 'shared',
}

export function IntegrationLevelImportFieldMappingUnitConfig() {
  const {
    patchConfig,
    patchFieldMappingConfig,
    fieldMappingConfig,
    dataCollectionSpec,
    parentConfig,
  } = useGenericConfig()

  async function handleSelectOption(
    option: ImportFieldMappingUnitConfigOption,
  ) {
    switch (option) {
      case ImportFieldMappingUnitConfigOption.None:
        return patchConfig({
          fieldMapping: undefined,
        })
      case ImportFieldMappingUnitConfigOption.Custom:
        return patchConfig({
          fieldMapping: {
            defaultValue: fieldMappingConfig.defaultValue ?? {},
          },
        })
      case ImportFieldMappingUnitConfigOption.Shared:
        return patchConfig({
          fieldMapping: {
            key: null,
          },
        })
    }
  }

  const { dataSource: parentDataSource } = useDataSource(
    parentConfig?.dataSource?.key
      ? {
          key: parentConfig?.dataSource?.key,
        }
      : undefined,
  )

  let fieldsSchema = {
    type: 'object',
    additionalProperties: true,
  }

  const parentUdm = parentDataSource?.udm ?? parentConfig?.dataSource?.udm

  if (parentUdm && parentConfig?.fieldMapping?.defaultUnifiedValue) {
    // If this action inherits from a universal action, use the parent's fields schema as a guide
    // for the field mapping.
    const parentFieldsSchema = buildDataSchema(
      parentConfig?.fieldMapping?.defaultUnifiedValue,
      UNIFIED_DATA_MODELS[parentUdm]?.fieldsSchema,
    )

    if (parentFieldsSchema) {
      fieldsSchema = structuredClone(parentFieldsSchema)
      // But allow additional properties
      fieldsSchema.additionalProperties = true
    }
  }

  let selectedOption = ImportFieldMappingUnitConfigOption.None

  if (fieldMappingConfig?.defaultValue !== undefined) {
    selectedOption = ImportFieldMappingUnitConfigOption.Custom
  }

  if (fieldMappingConfig?.key !== undefined) {
    selectedOption = ImportFieldMappingUnitConfigOption.Shared
  }

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Field Mapping</CollapsibleSection.Header>
      <p className='mb-4'>
        Configure the contents of the <code>fields</code> property of data
        records returned by this action.
      </p>

      <SimpleRadio
        label='Map to a custom schema'
        checked={selectedOption === ImportFieldMappingUnitConfigOption.Custom}
        onCheck={() =>
          handleSelectOption(ImportFieldMappingUnitConfigOption.Custom)
        }
      />
      <SimpleRadio
        label='Use shared field mapping config'
        checked={selectedOption === ImportFieldMappingUnitConfigOption.Shared}
        onCheck={() =>
          handleSelectOption(ImportFieldMappingUnitConfigOption.Shared)
        }
      />
      <SimpleRadio
        label='Keep original fields returned from a connector'
        checked={selectedOption === ImportFieldMappingUnitConfigOption.None}
        onCheck={() =>
          handleSelectOption(ImportFieldMappingUnitConfigOption.None)
        }
      />

      {selectedOption === ImportFieldMappingUnitConfigOption.Shared && (
        <SharedImportFieldMapping />
      )}

      {selectedOption === ImportFieldMappingUnitConfigOption.Custom && (
        <CustomImportFieldMapping
          value={fieldMappingConfig?.defaultValue}
          onChange={(defaultValue) => patchFieldMappingConfig({ defaultValue })}
          internalFieldsSchema={fieldsSchema}
          externalFieldsSchema={dataCollectionSpec?.fieldsSchema}
        />
      )}

      {selectedOption !== ImportFieldMappingUnitConfigOption.None && (
        <IncludeRawFieldsBlock />
      )}
    </CollapsibleSection>
  )
}

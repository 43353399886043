import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `await integrationApp.integration('{INTEGRATION_KEY}').openNewConnection()`

export function OpenNewConnectionCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .integration(parameters.INTEGRATION_KEY)
          .openNewConnection()
      }}
    />
  )
}

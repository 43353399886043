import SimpleSelect from 'components/SimpleSelect'
import { useGenericConfig } from '../common-configs/contexts/generic-config-context'
import useWorkspace from '../../../../../components/Workspaces/workspace-context'
import useSWR from 'swr'

export default function ConnectorEventSelect({ value, onChange }) {
  const { engineAdminFetcher } = useWorkspace()
  const { integrationId } = useGenericConfig()

  const { data: connectorEvents } = useSWR(
    integrationId ? `/integrations/${integrationId}/events` : null,
    engineAdminFetcher,
  )

  const appEventOpoptionsions = connectorEvents?.map(({ key, name }) => ({
    value: key,
    label: name,
  }))

  return (
    <div className={'mt-2'}>
      <SimpleSelect
        name='Connector Event'
        value={value}
        options={appEventOpoptionsions}
        onChange={onChange}
      />
    </div>
  )
}

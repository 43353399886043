import DataSourceSelect from 'components/DataSources/DataSourceSelect'
import { ContentRows } from 'components/ContentRows'

import { useGenericConfig } from '../contexts/generic-config-context'
export function SharedDataSource() {
  const { patchDataSourceConfig, dataSourceConfig, integrationId } =
    useGenericConfig()

  async function handleChange(key: string | undefined) {
    await patchDataSourceConfig(
      key
        ? { key, udm: undefined, defaultPath: undefined } // clean up udm and defaultPath
        : { key: undefined },
    )
  }

  return (
    <ContentRows>
      <DataSourceSelect
        integrationId={integrationId}
        dataSourceKey={dataSourceConfig.key}
        onChange={handleChange}
      />
    </ContentRows>
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "External Apps",
  "attributes": {},
  "children": []
}];
import {INTEGRATIONS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const TITLE = 'External Apps';
export const DESCRIPTION = 'Adding external apps you want to integrate with and configuring their connectors.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Link, DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "external-apps",
      children: "External Apps"
    }), "\n", _jsxs(_components.p, {
      children: ["You can manage external apps you want to integrate with from the ", _jsx(Link, {
        path: INTEGRATIONS_ROUTE,
        children: "Apps"
      }), " section of the console."]
    }), "\n", _jsx(_components.p, {
      children: "There are a few terms we will use when talking about external apps:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "External App - an actual application like Salesforce or Slack."
      }), "\n", _jsxs(_components.li, {
        children: ["Connector - a package of metadata, mappings, and custom code used to interact with an external app in a consistent way. See ", _jsx(DocLink, {
          path: "engine/external-apps/connectors"
        }), " and ", _jsx(DocLink, {
          path: "connector-builder"
        }), " for details.."]
      }), "\n", _jsxs(_components.li, {
        children: ["Integration is an external app + connector of a specific version + configuration parameters needed to connect your app to the external app bi-directionally. See ", _jsx(DocLink, {
          path: "engine/external-apps/integrations"
        }), " for details."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

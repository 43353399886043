import { HTMLAttributes } from 'react'
import clsx from 'clsx'
import classes from './ToDoSection.module.css'

export function ToDoShowAllButton({
  className,
  ...props
}: HTMLAttributes<HTMLButtonElement>) {
  return (
    <div>
      <button className={clsx(classes.button, className)} {...props} />
    </div>
  )
}

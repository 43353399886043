/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 2,
  "value": "Custom Flow UI",
  "attributes": {},
  "children": []
}];
export const TITLE = 'Custom Flow UI';
export const DESCRIPTION = 'Managing customer flows instances from inside your applications.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components), {DocGrid} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      id: "custom-flow-ui",
      children: "Custom Flow UI"
    }), "\n", _jsx(_components.p, {
      children: "To build custom white-label UI for a flow, use one of the following articles:"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "ux/flows/enable-disable"
      }), _jsx(DocGrid.Link, {
        path: "ux/flows/flow-parameters"
      })]
    }), "\n", _jsx(_components.p, {
      children: "To build custom UI for components of the flow, follow the following articles:"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "ux/data-sources/custom"
      }), _jsx(DocGrid.Link, {
        path: "ux/field-mappings/custom"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

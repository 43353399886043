import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import classes from './SectionWithArrowInCircle.module.css'

type SectionWithCircleArrowLinkProps = {
  to: string
}

type SectionWithCircleArrowButtonProps = {
  onClick?: () => void
}

export function SectionWithArrowInCircle({
  children,
  ...props
}: PropsWithChildren<
  SectionWithCircleArrowLinkProps | SectionWithCircleArrowButtonProps
>) {
  return (
    <div className={classes.container}>
      <div className={classes.content}>{children}</div>
      <CircleWithArrow {...props} />
    </div>
  )
}

function CircleWithArrow(
  props: SectionWithCircleArrowLinkProps | SectionWithCircleArrowButtonProps,
) {
  if (isLink(props)) {
    return (
      <NavLink to={props?.to} className={classes.circle}>
        <SvgIcon type={SvgIconType.ChevronRight} />
      </NavLink>
    )
  }

  if (isButton(props)) {
    return (
      <button onClick={props?.onClick} className={classes.circle}>
        <SvgIcon type={SvgIconType.ChevronRight} />
      </button>
    )
  }

  return null
}

function isLink(
  item: SectionWithCircleArrowLinkProps | SectionWithCircleArrowButtonProps,
): item is SectionWithCircleArrowLinkProps {
  return (item as SectionWithCircleArrowLinkProps).to !== undefined
}

function isButton(
  item: SectionWithCircleArrowLinkProps | SectionWithCircleArrowButtonProps,
): item is SectionWithCircleArrowButtonProps {
  return (item as SectionWithCircleArrowButtonProps).onClick !== undefined
}

import { Pane, VBox } from '@integration-app/ui/Layout'
import { CodeModeSwitcher, useCodeMode } from 'components/CodeModeSwitcher'
import { Content } from 'components/Layout/Main'
import { PropsWithChildren } from 'react'
import YAMLEditor from '../../../../components/Editors/YAMLEditor'

export function ElementPageWrapperWithCodeMode({
  title,
  children,
  onCodeChange,
  code,
}: PropsWithChildren<{
  title: string
  code: any
  onCodeChange(data: string): void
}>) {
  const { codeMode, toggleCodeMode } = useCodeMode()

  if (codeMode) {
    return (
      <Wrapper
        codeMode={codeMode}
        toggleCodeMode={toggleCodeMode}
        title={title}
      >
        <YAMLEditor value={code} onChange={onCodeChange} />
      </Wrapper>
    )
  }

  return (
    <Wrapper title={title} codeMode={codeMode} toggleCodeMode={toggleCodeMode}>
      {children}
    </Wrapper>
  )
}

function Wrapper({
  codeMode,
  toggleCodeMode,
  children,
  title,
}: PropsWithChildren<{
  title: string
  codeMode: boolean
  toggleCodeMode(): void
}>) {
  return (
    <Content className='min-h-full h-0'>
      <VBox>
        <Pane className='flex items-center justify-between mb-2'>
          <h1>{title}</h1>

          <CodeModeSwitcher
            codeMode={codeMode}
            onToggle={toggleCodeMode}
            className='mt-1'
          />
        </Pane>

        {codeMode ? (
          <Pane stretch>
            <div
              style={{
                // magic fix to avoid constant re-rendering of the editor
                // when scrollbar is always visible (like on windows)
                height: 'calc(100% - 1rem)',
              }}
            >
              {children}
            </div>
          </Pane>
        ) : (
          <Pane>{children}</Pane>
        )}
      </VBox>
    </Content>
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Authentication",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Access Token",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Admin Token",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Read More",
    "attributes": {},
    "children": []
  }]
}];
import {DocGrid} from "components/Docs/DocLinksGrid";
import {CodeExample} from '../../../../../components/CodeExamples';
import {SETTINGS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const TITLE = 'Authentication';
export const DESCRIPTION = 'Accessing integration engine from your application.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    blockquote: "blockquote",
    code: "code"
  }, _provideComponents(), props.components), {Link, DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.CreateAdminJwt) _missingMdxReference("CodeExample.CreateAdminJwt", true);
  if (!CodeExample.CreateAsymmetricJwt) _missingMdxReference("CodeExample.CreateAsymmetricJwt", true);
  if (!CodeExample.CreateJwt) _missingMdxReference("CodeExample.CreateJwt", true);
  if (!CodeExample.GetSelfViaToken) _missingMdxReference("CodeExample.GetSelfViaToken", true);
  if (!DocGrid) _missingMdxReference("DocGrid", false);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "authentication",
      children: "Authentication"
    }), "\n", _jsx(_components.p, {
      children: "To access integration engine through the Rest API or Front-end SDK, you need an access token that incapsulates information about:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Workspace you want to interact with."
      }), "\n", _jsx(_components.li, {
        children: "Permissions you want to have."
      }), "\n", _jsx(_components.li, {
        children: "Your customer that is using the integration."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Here is how to get the token."
    }), "\n", _jsx(_components.h2, {
      id: "access-token",
      children: "Access Token"
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsx(_components.p, {
        children: "Never generate authentication token on your front-end.\nIt contains Workspace Secret that should never be exposed to your users.\nOnly generate it on the backend."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "To authenticate, create a JSON Web Token with your user's data:"
    }), "\n", _jsx(CodeExample.CreateJwt, {}), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsxs(_components.p, {
        children: ["Default algorithm for signing tokens is HS256, but we recommend to go with more secure option like ES256 or RS256.\nWe support all the algorithms supported by ", _jsx("a", {
          href: "https://github.com/auth0/node-jsonwebtoken#algorithms-supported",
          children: "jsonwebtoken"
        }), " library."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["You can find your Workspace Key and Secret on the ", _jsx(Link, {
        path: SETTINGS_ROUTE,
        children: "Settings"
      }), " page."]
    }), "\n", _jsxs(_components.p, {
      children: ["Then use this token to initialize ", _jsx(DocLink, {
        path: "overview/getting-started/front-end/javascript",
        children: "Javascript SDK"
      }), " or in the REST API requests."]
    }), "\n", _jsx(_components.p, {
      children: "Authentication token contains information integration.app needs to know about your user:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "id"
        }), " - user ID, unique within the workspace."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "name"
        }), " - username to identify them user in the integration.app UI."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "fields"
        }), " - any additional information you want to store about the user. For example:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "API key for integration.app to call your API on behalf of this user."
          }), "\n", _jsx(_components.li, {
            children: "User preferences for integrations."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["The token is signed by the Secret Key you can find in your ", _jsx(Link, {
        path: SETTINGS_ROUTE,
        children: "Workspace Settings"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["Alternatively, you can use public/private key pair to sign the token. To do that, you need to pass the private key to the ", _jsx(_components.code, {
        children: "sign"
      }), " method instead of the secret\nand provide the public key in the ", _jsx(Link, {
        path: SETTINGS_ROUTE,
        children: "Workspace Settings"
      }), ". This is more secure option, and we recommend to use it."]
    }), "\n", _jsx(CodeExample.CreateAsymmetricJwt, {}), "\n", _jsx(_components.p, {
      children: "If you want to set up integrations not for a single user, but for a larger entity like organization,\nyou should use the organization ID instead of an individual user ID."
    }), "\n", _jsx(_components.p, {
      children: "To check that you generated the token correctly, paste it here:"
    }), "\n", _jsx(CodeExample.GetSelfViaToken, {}), "\n", _jsxs(_components.p, {
      children: ["You can also use the JWT token explorer at ", _jsx("a", {
        href: "https://jwt.io/",
        children: "jwt.io"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "admin-token",
      children: "Admin Token"
    }), "\n", _jsx(_components.p, {
      children: "When you want to make admin-level actions like editing users, you need to generate an Admin Token. It is generated in exactly the same way, except:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["You MUST add ", _jsx(_components.code, {
          children: "isAdmin"
        }), " claim to it with any non-empty value."]
      }), "\n", _jsxs(_components.li, {
        children: ["You SHOULD NOT add ", _jsx(_components.code, {
          children: "id"
        }), " claim to it - admin token should not be attached to any user."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Here is an example of generating an admin token:"
    }), "\n", _jsx(CodeExample.CreateAdminJwt, {}), "\n", _jsx(_components.h2, {
      id: "read-more",
      children: "Read More"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/deployments/customers"
      }), _jsx(DocGrid.Link, {
        path: "overview/getting-started/front-end/javascript"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import { forwardRef, PropsWithChildren, ReactNode } from 'react'
import clsx from 'clsx'
import classes from './layout.module.css'

export function Header({ children }) {
  return <div className={classes.header}>{children}</div>
}

export const Content = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ className?: string; id?: string; noPadding?: boolean }>
>(({ children, className, id, noPadding }, ref) => {
  return (
    <div
      className={clsx(
        classes.content,
        className,
        noPadding && classes.contentNoPadding,
      )}
      ref={ref}
      id={id}
    >
      {children}
    </div>
  )
})

export function ContentColumn({
  children,
  size = 'medium',
  className,
}: {
  children: ReactNode
  size?: 'large' | 'medium' | 'small'
  className?: string
}) {
  return (
    <div
      className={clsx(
        classes.contentColumn,
        size === 'small' && classes.contentColumnSmall,
        size === 'medium' && classes.contentColumnMedium,
        size === 'large' && classes.contentColumnLarge,
        className,
      )}
    >
      {children}
    </div>
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Integration User Experirence",
  "attributes": {},
  "children": [{
    "depth": 4,
    "value": "List of Integrations",
    "attributes": {},
    "children": []
  }, {
    "depth": 4,
    "value": "Data Source Configuration",
    "attributes": {},
    "children": []
  }, {
    "depth": 4,
    "value": "Field Mapping Configuration",
    "attributes": {},
    "children": []
  }, {
    "depth": 4,
    "value": "Connection UI",
    "attributes": {},
    "children": []
  }, {
    "depth": 4,
    "value": "Single Integration",
    "attributes": {},
    "children": []
  }, {
    "depth": 4,
    "value": "Flow Configuration",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Integration UX';
export const DESCRIPTION = 'Build the exact user experience you need with pre-built or fully custom UI components.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components), {DocPanel} = _components;
  if (!DocPanel) _missingMdxReference("DocPanel", true);
  if (!DocPanel.Container) _missingMdxReference("DocPanel.Container", true);
  if (!DocPanel.Link) _missingMdxReference("DocPanel.Link", true);
  if (!DocPanel.Links) _missingMdxReference("DocPanel.Links", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "integration-user-experirence",
      children: "Integration User Experirence"
    }), "\n", _jsx(_components.p, {
      children: "Each of your customers has different integration setup. Integration UI is a key part of your product's experience.\nEach integration you build on our platform comes with full customization capabilities."
    }), "\n", _jsx(_components.p, {
      children: "You can use our drop-in UI components or build fully custom white-label UI."
    }), "\n", _jsxs(DocPanel.Container, {
      children: [_jsxs(DocPanel, {
        src: "/images/docs/ui-integration-list.png",
        children: [_jsx("h4", {
          children: "List of Integrations"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/integration-list',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/integration-list/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-data-source.png",
        children: [_jsx("h4", {
          children: "Data Source Configuration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/data-sources',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/data-sources/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-field-mapping.png",
        children: [_jsx("h4", {
          children: "Field Mapping Configuration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/field-mappings',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/field-mappings/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-connection.png",
        children: [_jsx("h4", {
          children: "Connection UI"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/connection',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/connection/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-single-integration.png",
        children: [_jsx("h4", {
          children: "Single Integration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/integration',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/integration/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      }), _jsxs(DocPanel, {
        src: "/images/docs/ui-flow.png",
        children: [_jsx("h4", {
          children: "Flow Configuration"
        }), _jsxs(DocPanel.Links, {
          children: [_jsx(DocPanel.Link, {
            to: 'ux/flows',
            icon: 'drop-in',
            children: "Drop-in UI"
          }), "\n", _jsx(DocPanel.Link, {
            to: 'ux/flows/custom',
            icon: 'custom',
            children: "Custom UI"
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

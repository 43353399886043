import { useState } from 'react'
import { DataSource } from '@integration-app/sdk'
import { StepSelectUdm } from './StepSelectUdm'
import { StepCreateDataSource } from './StepCreateDataSource'
import { DataSourceType } from './index'

type CreateFlowProps = {
  title?: string
  dataSourceType: DataSourceType
  integrationId?: string
  readOnlyIntegration?: boolean
  onBack?: () => void
  onCreate: (dataSource: DataSource) => void
}

export function StepCreateFlow({ dataSourceType, ...props }: CreateFlowProps) {
  return dataSourceType === 'universal' ? (
    <CreateUniversalDataSource {...props} />
  ) : (
    <CreateIntegrationSpecificDataSource {...props} />
  )
}

export function CreateUniversalDataSource({
  title,
  onBack,
  onCreate,
}: Omit<
  CreateFlowProps,
  'dataSourceType' | 'integrationId' | 'readOnlyIntegration'
>) {
  const [udmSelectionComplete, setUdmSelectionComplete] = useState(false)
  const [selectedUdm, setSelectedUdm] = useState<string>()

  function handleOnBackFromUdmSelectionStep() {
    setUdmSelectionComplete(false)
  }

  function handleOnSelectUdm(udm: string) {
    setSelectedUdm(udm)
    setUdmSelectionComplete(true)
  }

  if (!udmSelectionComplete) {
    return (
      <StepSelectUdm
        title={title}
        selectedUdm={selectedUdm}
        onSelect={handleOnSelectUdm}
        // FIXME: strictNullCheck temporary fix
        // @ts-expect-error TS(2322): Type '(() => void) | undefined' is not assignable ... Remove this comment to see the full error message
        onBack={onBack}
      />
    )
  }

  return (
    <StepCreateDataSource
      title={title}
      udm={selectedUdm}
      onCreate={onCreate}
      onBack={handleOnBackFromUdmSelectionStep}
      universal
    />
  )
}

export function CreateIntegrationSpecificDataSource({
  title,
  integrationId,
  readOnlyIntegration,
  onBack,
  onCreate,
}: Omit<CreateFlowProps, 'dataSourceType'>) {
  return (
    <StepCreateDataSource
      title={title}
      integrationId={integrationId}
      readOnlyIntegration={readOnlyIntegration}
      onCreate={onCreate}
      onBack={onBack}
    />
  )
}

import {
  DataSource,
  FieldMapping,
  FieldMappingDirection,
} from '@integration-app/sdk'
import { AddEntryPopup } from 'routes/components/Popup/AddEntryPopup'
import { StepSelectFieldMappingType } from 'routes/Workspaces/Workspace/Blueprints/FieldMappings/components/PopupCreateFieldMappingForm/StepSelectFieldMappingType'
import { useState } from 'react'
import { FieldMappingType } from './types'
import { StepCreateFieldMapping } from './StepCreateFieldMapping'

export function PopupCreateFieldMappingForm({
  open,
  onClose,
  onCreate,
}: {
  open: boolean
  onClose: () => void
  onCreate: (item: FieldMapping) => void
}) {
  const [step1Complete, setStep1Complete] = useState(false)
  const [fieldMappingType, setFieldMappingType] =
    useState<FieldMappingType>('universal')
  const [integrationId, setIntegrationId] = useState<string>()

  function handleStep1Complete() {
    setStep1Complete(true)
  }

  function handleOnBack() {
    setStep1Complete(false)
  }

  function handleOnCreate(fieldMapping: FieldMapping) {
    onClose()
    onCreate(fieldMapping)
  }

  if (!open) return null

  return (
    <AddEntryPopup onClose={onClose}>
      {!step1Complete && (
        <StepSelectFieldMappingType
          fieldMappingType={fieldMappingType}
          onFieldMappingTypeChange={setFieldMappingType}
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          integrationId={integrationId}
          onIntegrationIdChange={setIntegrationId}
          onSubmit={handleStep1Complete}
        />
      )}
      {step1Complete && (
        <StepCreateFieldMapping
          integrationId={integrationId}
          onCreate={handleOnCreate}
          onBack={handleOnBack}
        />
      )}
    </AddEntryPopup>
  )
}

type PopupCreateFieldMappingProps = {
  title?: string
  open: boolean
  onClose: () => void
  onCreate: (item: DataSource) => void
  integrationId: string
  dataSourceKey?: string
  direction?: FieldMappingDirection
  readOnlyIntegration?: boolean
  readOnlyDataSource?: boolean
}

export function PopupCreateIntegrationSpecificFieldMapping({
  open,
  onCreate,
  onClose,
  ...props
}: PopupCreateFieldMappingProps) {
  function handleOnCreate(dataSource: DataSource) {
    onCreate(dataSource)
    onClose()
  }

  if (!open) return null

  return (
    <AddEntryPopup onClose={onClose}>
      <StepCreateFieldMapping onCreate={handleOnCreate} {...props} />
    </AddEntryPopup>
  )
}

export function PopupCreateUniversalFieldMapping({
  open,
  onClose,
  onCreate,
  ...props
}: Omit<
  PopupCreateFieldMappingProps,
  'integrationId' | 'readOnlyIntegration'
>) {
  function handleOnCreate(dataSource: DataSource) {
    onCreate(dataSource)
    onClose()
  }

  if (!open) return null

  return (
    <AddEntryPopup onClose={onClose}>
      <StepCreateFieldMapping onCreate={handleOnCreate} {...props} />
    </AddEntryPopup>
  )
}

import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'
import useFlowContext from '../../../contexts/flow-context'
import { useFlow } from '@integration-app/react'
import useDocs from '../../../routes/Docs/components/docs-context'
import { FlowNodeType, FlowRun } from '@integration-app/sdk'
import { useIntegrationContext } from 'routes/Workspaces/Workspace/ExternalApps/Integrations/Integration/contexts/integration-context'
import { httpCodeExamples } from '../http-code-examples'

export function RunFlowCodeExample({
  parametersExpandedByDefault,
  showNodeKeyInExample = false,
  onAfterRun,
}: {
  parametersExpandedByDefault?: boolean
  showNodeKeyInExample?: boolean
  onAfterRun?: (flowRun: FlowRun) => Promise<any>
}) {
  const { parameters } = useDocs()
  const { integration } = useIntegrationContext()

  const integrationKey = integration?.key ?? parameters?.INTEGRATION_KEY
  const nodeKey = parameters?.NODE_KEY

  const { flow: flowFromContext } = useFlowContext()
  const { flow: flowFromHook } = useFlow(
    parameters?.FLOW_KEY && integrationKey
      ? {
          key: parameters?.FLOW_KEY,
          integrationKey,
        }
      : undefined,
  )

  const flow = flowFromContext ?? flowFromHook

  let triggerKey = nodeKey
  if (!triggerKey) {
    for (const [key, node] of Object.entries(flow?.nodes ?? {})) {
      if (node.type === FlowNodeType.ApiTrigger) {
        triggerKey = key
        break
      }
    }
  }

  const inputParamName = `INPUT/${flow?.id}/${triggerKey}`
  const inputSchema = triggerKey
    ? flow?.nodes?.[triggerKey]?.inputSchema
    : undefined

  const nodeKeyParam = showNodeKeyInExample
    ? `nodeKey: '{NODE_KEY}',\n    `
    : ''

  const jsCode = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .flow('{FLOW_KEY}')
  .run({${nodeKeyParam}
    input: '{INPUT}'
  })
`

  const otherExamples = httpCodeExamples(
    '/connections/{INTEGRATION_KEY}/flows/{FLOW_KEY}/run',
    {
      method: 'POST',
      body: '{INPUT}',
      ...(showNodeKeyInExample ? { nodeKey: '{NODE_KEY}' } : {}),
    },
  )

  return (
    <ExampleCodeBlock
      parametersExpandedByDefault={parametersExpandedByDefault}
      customCodes={{ js: jsCode, ...otherExamples }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FLOW_KEY: CodeParamType.FlowKey,
        ...(showNodeKeyInExample ? { NODE_KEY: CodeParamType.String } : {}),
        [inputParamName]: {
          title: 'Input',
          type: CodeParamType.DataSchema,
          schema: inputSchema,
          default: null,
        },
      }}
      run={async ({ integrationApp, parameters }) => {
        const result = await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .flow(parameters.FLOW_KEY)
          .run({
            nodeKey: triggerKey,
            input: parameters[inputParamName],
          })

        await onAfterRun?.(result)

        return result
      }}
    />
  )
}

import { AppDataSchema, CreateAppDataSchemaRequest } from '@integration-app/sdk'
import useWorkspace from 'components/Workspaces/workspace-context'
import { AddEntryPopup } from '../../../../../../components/Popup/AddEntryPopup'
import { StepSelectNameAndKeyForElementAndCreate } from '../../../../components/CreateElementPopup/StepSelectNameAndKeyForElementAndCreate'

export function CreateAppDataSchemaPopup({
  open,
  onClose,
  onCreate,
}: {
  open: boolean
  onClose: () => void
  onCreate: (created: AppDataSchema) => Promise<void>
}) {
  const { engineAdminClient } = useWorkspace()

  if (!open) return null

  return (
    <AddEntryPopup onClose={onClose}>
      <StepSelectNameAndKeyForElementAndCreate<CreateAppDataSchemaRequest>
        elementName='App Data Schema'
        onBack={() => {}}
        defaultCreateData={{ key: '', name: '' }}
        isCreateDataValid={(data) => !!data.name && !!data.key}
        onCreate={async (data: CreateAppDataSchemaRequest) => {
          const created = await engineAdminClient.appDataSchemas.create(data)

          return await onCreate(created)
        }}
      />
    </AddEntryPopup>
  )
}

import { ExampleBlock } from 'components/Docs/CodeBlockUI'

export default function PreTagHighlighted({ children }) {
  const { className, children: code } = children?.props
  const language = /language-(\w+)/.exec(className || '')?.[1]

  if (!language || typeof code !== 'string') {
    return <pre>{children}</pre>
  }
  return (
    <ExampleBlock>
      <ExampleBlock.Section>
        <ExampleBlock.CodeBlock code={code} language={language} />
      </ExampleBlock.Section>
    </ExampleBlock>
  )
}

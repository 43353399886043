import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'
import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .dataCollection('{DATA_COLLECTION_KEY}')
  .get()`

const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/data/{DATA_COLLECTION_KEY}',
)

export function GetConnectionDataCollectionCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        DATA_COLLECTION_KEY: CodeParamType.String,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .dataCollection(parameters.DATA_COLLECTION_KEY)
          .get()
      }}
    />
  )
}

import { ComponentType } from 'react'

export type DocArticleType = {
  title: string
  path: string
  rawPath: string
  parentPath?: string
  parentRawPath?: string
  description?: string
  Component?: ComponentType<any>
  toc?: any
  menuTitle?: string
  parent?: DocArticleType
  children?: DocArticleType[]
}

export type ArticleListMapType = Map<string, DocArticleType>

export type TOCLink = {
  depth: number
  value: string
  slug?: string
  attributes: Record<string, string>
  children: TOCLink[]
}

export type DocMenuLinkType = {
  path: string
  title?: string
  links?: DocMenuType[]
}

export type DocMenuSectionType = {
  isSection: boolean
  title: string
  links?: DocMenuType[]
}

export type DocMenuType = DocMenuLinkType | DocMenuSectionType

export function isDocMenuSection(
  item: DocMenuType,
): item is DocMenuSectionType {
  return (item as DocMenuSectionType).isSection !== undefined
}

type MenuItemGenericType = {
  title: string
  links?: MenuItemType[]
  isActive?: boolean
  hasChildren?: boolean
  isExpand?: boolean
}

export type MenuItemHeaderType = MenuItemGenericType & {
  isSection?: boolean
}

export type MenuItemLinkType = MenuItemGenericType & {
  path: string
  to: string
  isExternalLink?: boolean
}

export type MenuItemType = MenuItemHeaderType | MenuItemLinkType

export function isMenuItemLinkType(
  item: MenuItemType,
): item is MenuItemLinkType {
  return !!(item as MenuItemLinkType)?.to
}

export function isMenuItemHeaderType(
  item: MenuItemType,
): item is MenuItemHeaderType {
  return !!(item as MenuItemHeaderType)?.isSection
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Events",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Implementation Types",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Webhook",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Global Webhook",
      "attributes": {},
      "children": []
    }]
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    h3: "h3",
    strong: "strong",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "events",
      children: "Events"
    }), "\n", _jsx(_components.p, {
      children: "Events let you get notified about things that happen in the external applications."
    }), "\n", _jsx(_components.p, {
      children: "Each event has:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Name"
      }), "\n", _jsx(_components.li, {
        children: "Parameters (optional)"
      }), "\n", _jsx(_components.li, {
        children: "Payload schema"
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "implementation-types",
      children: "Implementation Types"
    }), "\n", _jsx(_components.h3, {
      id: "webhook",
      children: "Webhook"
    }), "\n", _jsx(_components.p, {
      children: "This type of events subscribes to a webhook then receives event notifications. The following functions can be implemented for the webhook events:"
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "subscribe"
      })
    }), "\n", _jsx(_components.p, {
      children: "There is a function that proceeds subscription to events in External App."
    }), "\n", _jsx(_components.p, {
      children: "Arguments:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "webhookUri"
        }), " - string, URI webhooks should be sent to. It should be passed to the External App."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "parameters"
        }), " - object, parameters (matching ", _jsx(_components.code, {
          children: "parametersSchema"
        }), ") of this particular event."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Result:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "state"
        }), " - object, subscription state.  This state will be saved and passed as ", _jsx(_components.code, {
          children: "state"
        }), " to ", _jsx(_components.code, {
          children: "unsubscribe"
        }), " and ", _jsx(_components.code, {
          children: "handle"
        }), " functions."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "nextRefreshTimestamp"
        }), " - number, optional, timestamp in milliseconds when the next refresh should be performed. It is used to refresh the subscription if the external app requires it."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Here is an example of ", _jsx(_components.code, {
        children: "subscribe"
      }), " function, where:"]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.code, {
        children: "webhookUri"
      }), " - is a URI where External App will send request with notifications about changes."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.code, {
        children: "<subscription_path>"
      }), " - is path to subscribe (based on External App documentation)."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.code, {
        children: "'<some_key>': '<some_value>'"
      }), " - other body properties (based on External App documentation)."]
    }), "\n", _jsxs(_components.p, {
      children: ["This function should return a state with the webhook unique identifier as ", _jsx(_components.code, {
        children: "webhookId"
      }), "."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-javascript",
        children: "\n// Example: Subscribe to a webhook and return the webhookId.\nexport default async function subscribe({ apiClient, webhookUri, parameters }) {\n  const body = { webhookUri, '<some_key>': '<some_value>'}\n  const response = await apiClient.post(parameters.path, body)\n\n  return {\n    state: {\n      webhookId: response?.id\n    }\n  }\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "handle"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["There is a function that proceeds notification requests from External App.\nIf External App require a response you can specify parameters (", _jsx(_components.code, {
        children: "headers"
      }), ", ", _jsx(_components.code, {
        children: "data"
      }), ") for the response in ", _jsx(_components.code, {
        children: "response"
      }), " property (optional).\nIt can be a case when External API require to confirm connectorWebhookUri."]
    }), "\n", _jsx(_components.p, {
      children: "Arguments:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "query"
        }), " - object, webhook request query received from External App"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "data"
        }), " - object, webhook request body received from External App"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "headers"
        }), " - object, webhook request headers received from External App"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "state"
        }), " - object, state returned from ", _jsx(_components.code, {
          children: "subscribe"
        }), " function."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "parameters"
        }), " - object, parameters (matching ", _jsx(_components.code, {
          children: "parametersSchema"
        }), ") of this particular event."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Result:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "events"
        }), " - list of objects, list of events extracted from the webhook.  ", _jsx(_components.code, {
          children: "event"
        }), " - object with ", _jsx(_components.code, {
          children: "type"
        }), " and ", _jsx(_components.code, {
          children: "record"
        }), " fields (see example below)."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "response"
        }), " - object, optional http response with properties:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "headers"
            }), " - object"]
          }), "\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "data"
            }), " - http body (based on ", _jsx(_components.code, {
              children: "Content-Type"
            }), ")"]
          }), "\n"]
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "state"
        }), " - a new value of the state if it needs to be updated."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Example of ", _jsx(_components.code, {
        children: "result"
      }), ":"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-json",
        children: "{\n\t\"events\": [\n\t\t{\n\t\t\t\"record\": {\n\t\t\t\t\"id\": \"1\",\n\t\t\t\t...other fields that match collection fieldsSchema\n      }\n\t\t}\n  ],\n\t\"response\": {\n\t\t\"headers\": {\n\t\t\t\"x-response-header\": \"header value\"\n\t\t},\n\t\t\"data\": {\n\t\t\t\"responseField\": \"value\"\n\t\t}\n\t}\n}\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Example of ", _jsx(_components.code, {
        children: "handle"
      }), " function:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-javascript",
        children: "// Example: Handle a webhook and return events.\nexport default async function handle({ body, query, parameters }) {\n\n  // Do some stuff here\n\n  return {\n    events: [\n      {\n        type: 'created',  // Possible values: 'created', 'updated', 'deleted'\n        record: body.record,\n      },\n    ],\n    response: {\n      headers: {\n        'Content-Type': 'text/plain',\n      },\n      data: query?.data,\n    },\n  }\n}\n\n"
      })
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "unsubscribe"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["This function should send a request to delete webhook by ", _jsx(_components.code, {
        children: "webhookId"
      }), " received in ", _jsx(_components.code, {
        children: "subscription"
      }), " method."]
    }), "\n", _jsx(_components.p, {
      children: "Arguments:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["state - state returned from ", _jsx(_components.code, {
          children: "subscribe"
        })]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "parameters"
        }), " - object, parameters (matching ", _jsx(_components.code, {
          children: "parametersSchema"
        }), ") of this particular event."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Example of ", _jsx(_components.code, {
        children: "unsubscribe"
      }), " function:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-javascript",
        children: "// Example: Unsubscribe from a webhook.\nexport default async function unsubscribe({ apiClient, state, parameters }) {\n  await apiClient.delete(`${parameters.path}/${state.webhookId}`)\n}\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "refresh"
      }), " (optional)"]
    }), "\n", _jsxs(_components.p, {
      children: ["Some applications require refreshing the subscription to keep it active.\nThis method should be implemented if the ", _jsx(_components.code, {
        children: "nextRefreshTimestamp"
      }), " is returned from the ", _jsx(_components.code, {
        children: "subscribe"
      }), " method."]
    }), "\n", _jsx(_components.p, {
      children: "Arguments:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "state"
        }), " - state returned from ", _jsx(_components.code, {
          children: "subscribe"
        })]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "parameters"
        }), " - object, parameters (matching ", _jsx(_components.code, {
          children: "parametersSchema"
        }), ") of this particular event."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Response:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "state"
        }), " - updated state information if it needs to be updated."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "nextRefreshTimestamp"
        }), " - number, timestamp in milliseconds when the next refresh should be performed."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Example of ", _jsx(_components.code, {
        children: "refresh"
      }), " function:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-javascript",
        children: "// Example: Unsubscribe from a webhook.\nexport default async function refresh({ apiClient, state, parameters }) {\n  await apiClient.post(`${parameters.path}/${state.webhookId}/refresh`)\n  return {\n    nextRefreshTimestamp: Date.now() + 1000 * 60 * 60 * 24, // Refresh in 24 hours\n  }\n}\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "global-webhook",
      children: "Global Webhook"
    }), "\n", _jsx(_components.p, {
      children: "Some external apps require webhooks to be configured on the global level rather than per-connection."
    }), "\n", _jsx(_components.p, {
      children: "In this case, integration.app will provide a single URL per event type and then route the events to the correct connection and subscription when received."
    }), "\n", _jsx(_components.p, {
      children: "The following functions can be implemented for the global webhook events:"
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Get Events Selector"
      })
    }), "\n", _jsx(_components.p, {
      children: "This function returns parameters that will be used to route the event to the correct connection."
    }), "\n", _jsx(_components.p, {
      children: "Arguments:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "parameters"
        }), " - object, parameters (matching ", _jsx(_components.code, {
          children: "parametersSchema"
        }), ") of this particular event."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Result:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "globalWebhookKey"
        }), " - key of the global webhook defined in the connector (see ", _jsx(DocLink, {
          path: "connector-builder/global-webhooks"
        }), ")"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "globalWebhookEventSelector"
        }), " - string that is used to route the event to the correct subscription."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["When an event received to the global webhook with key ", _jsx(_components.code, {
        children: "globalWebhookKey"
      }), ", it will generate a set of selectors for the event.\nIf one of the selectors matches the selector returned by this function, the event will be routed to the corresponding subscription."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

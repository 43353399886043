import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `await integrationApp
  .integration('{INTEGRATION_KEY}')
  .openNewConnection({
    allowMultipleConnections: true
  })
`

export function OpenNewConnectionAllowMultipleConnectionsCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.integrationKeyWithoutConnection,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .integration(parameters.INTEGRATION_KEY)
          .openNewConnection({
            allowMultipleConnections: true,
          })
      }}
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Cannot Find userId in webhook body",
  "attributes": {},
  "children": []
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "cannot-find-userid-in-webhook-body",
      children: "Cannot Find userId in webhook body"
    }), "\n", _jsx(_components.p, {
      children: "When using a global webhook, you need to send ID of the user the event belongs to in the body of the webhook.\nAdditionally, you need to configure the webhook to tell how to extract the user ID from the body of the webhook."
    }), "\n", _jsxs(_components.p, {
      children: ["For more details, see ", _jsx(DocLink, {
        path: "engine/blueprints/app-events"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

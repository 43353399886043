import {
  ItemComboBoxGenericValue,
  ItemComboBoxGenericValuePlaceholder,
  ItemComboboxSelectProps,
  ItemComboBoxTagValue,
} from './CommonItemComboboxSelect'
import ComboBox from '@integration-app/ui/ComboBox'
import { toHeaderCase } from 'js-convert-case'

const BOOLEAN_COMBOBOX_SCHEMA = { type: 'boolean' }

export function BooleanComboboxSelect({
  name,
  value,
  onChange,
  hasRightSibling = false,
}: ItemComboboxSelectProps<string>) {
  const readableName = toHeaderCase(name)

  return (
    <ComboBox
      value={value}
      schema={BOOLEAN_COMBOBOX_SCHEMA}
      onChange={onChange}
      valueComponent={
        <ItemComboBoxTagValue<string>
          hasRightSibling={hasRightSibling}
          value={value}
          valueComponent={
            <ItemComboBoxGenericValue
              title={readableName}
              value={value?.toString()}
            />
          }
          valuePlaceholderComponent={
            <ItemComboBoxGenericValuePlaceholder title={readableName} />
          }
        />
      }
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Operations",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Getting a list of operations",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Getting details of a single operation",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Running an operation",
    "attributes": {},
    "children": []
  }]
}];
import {CodeExample} from '../../../../../../../components/CodeExamples';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.GetOperation) _missingMdxReference("CodeExample.GetOperation", true);
  if (!CodeExample.GetOperationsList) _missingMdxReference("CodeExample.GetOperationsList", true);
  if (!CodeExample.RunOperation) _missingMdxReference("CodeExample.RunOperation", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "operations",
      children: "Operations"
    }), "\n", _jsx(_components.p, {
      children: "Operations represent each individual thing you can do with a connected application, like:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Get a list of users"
      }), "\n", _jsx(_components.li, {
        children: "Send a message"
      }), "\n", _jsx(_components.li, {
        children: "Delete a task"
      }), "\n", _jsx(_components.li, {
        children: "...etc"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Each operation has the following structure:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "key - a unique identifier for the operation. You can use this to reference the operation in your code."
      }), "\n", _jsx(_components.li, {
        children: "name - human-readable name of the operation. You can display it to your users."
      }), "\n", _jsx(_components.li, {
        children: "description - detailed description of the operation in Markdown format."
      }), "\n", _jsx(_components.li, {
        children: "tags - a list of strings that can be used to categorize operations."
      }), "\n", _jsxs(_components.li, {
        children: ["inputSchema - a ", _jsx(DocLink, {
          path: "engine/references/data-schemas",
          children: "Data Schema"
        }), " describing the operation input."]
      }), "\n", _jsxs(_components.li, {
        children: ["outputSchema - a ", _jsx(DocLink, {
          path: "engine/references/data-schemas",
          children: "Data Schema"
        }), " describing the operation output."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "getting-a-list-of-operations",
      children: "Getting a list of operations"
    }), "\n", _jsx(_components.p, {
      children: "To get a list of operations supported by a given integration, run the following code:"
    }), "\n", _jsx(CodeExample.GetOperationsList, {}), "\n", _jsx(_components.h2, {
      id: "getting-details-of-a-single-operation",
      children: "Getting details of a single operation"
    }), "\n", _jsx(_components.p, {
      children: "To get specification of a single operation, run the following code:"
    }), "\n", _jsx(CodeExample.GetOperation, {}), "\n", _jsx(_components.h2, {
      id: "running-an-operation",
      children: "Running an operation"
    }), "\n", _jsx(_components.p, {
      children: "To run an operation for a specific connection, run the following code:"
    }), "\n", _jsx(CodeExample.RunOperation, {})]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

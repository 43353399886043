import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `const dataSourceInstance = await integrationApp
  .connection('{INTEGRATION_KEY}')
  .dataSource('{DATA_SOURCE_KEY}')
  .patch({
    collectionKey: '{COLLECTION_KEY}',
    collectionParameters: '{COLLECTION_PARAMETERS}',
  })
`

export function PatchDataSourceInstanceCollectionCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        DATA_SOURCE_KEY: CodeParamType.DataSourceKey,
        COLLECTION_KEY: CodeParamType.String,
        COLLECTION_PARAMETERS: CodeParamType.Object,
      }}
      run={async ({ integrationApp, parameters }) => {
        const dataSourceInstance = await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .dataSource(parameters.DATA_SOURCE_KEY)
          .patch({
            collectionKey: parameters.COLLECTION_KEY,
            collectionParameters: parameters.COLLECTION_PARAMETERS,
          })

        return { dataSourceInstance }
      }}
    />
  )
}

import { Connection, Integration } from '@integration-app/sdk'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import useWorkspace from 'components/Workspaces/workspace-context'
import useApi from 'hooks/useApi'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react'
import useSWR from 'swr'
import { BlockLogoLoader } from '../../../../../../../components/Loader'

export const IntegrationProvider = ({
  children,
  integrationId,
}: PropsWithChildren<{
  integrationId: string
}>) => {
  const { apiFetcher } = useApi()
  const { testCustomerClient, engineAdminClient, engineAdminFetcher } =
    useWorkspace()

  const accessor = engineAdminClient.integration(integrationId)
  const { data: integration, mutate: refresh } = useSWR<Integration>(
    integrationId ? `/integrations/${integrationId}` : null,
    engineAdminFetcher,
  )

  const { data: parameters, mutate: refreshParameters } = useSWR<Integration>(
    integrationId ? `/integrations/${integrationId}/parameters` : null,
    engineAdminFetcher,
  )

  const { data: connector, isLoading: isConnectorLoading } = useSWR(
    integration?.connectorId ? `/connectors/${integration.connectorId}` : null,
    apiFetcher,
  )

  const outdated =
    !isConnectorLoading &&
    connector &&
    integration &&
    !connector.workspaceId && // "Update from store" should not work for custom connectors
    integration.baseUri !== connector.baseUri

  function connect() {
    return testCustomerClient.integration(integrationId).openNewConnection()
  }

  const debouncedPatch = useCallback(
    AwesomeDebouncePromise(async (data) => {
      return await engineAdminClient.patch(
        // FIXME: strictNullCheck temporary fix
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        `/integrations/${integration.id}`,
        data,
      )
    }, 500),
    [integration?.id],
  )

  async function patch(data) {
    await refresh(debouncedPatch(data), {
      optimisticData: { ...integration, ...data },
      revalidate: true,
    })
    await refreshParameters()
  }

  async function update() {
    if (!outdated) {
      return
    }

    await engineAdminClient.post(
      `/integrations/${integration.id}/update-connector`,
    )
    await refresh()
    await refreshParameters()
  }

  async function switchConnector(connectorId: string) {
    await engineAdminClient.patch(`/integrations/${integration?.id}`, {
      connectorId,
    })
    await refresh()
    await refreshParameters()
  }

  async function switchVersion(connectorVersionId: string) {
    await engineAdminClient.post(
      `/integrations/${integration?.id}/switch-connector-version`,
      {
        connectorVersionId,
      },
    )
    await refresh()
    await refreshParameters()
  }

  async function resetParameters() {
    await accessor.resetParameters()
    await refresh()
    await refreshParameters()
  }

  if (!integration) {
    return <BlockLogoLoader />
  }

  return (
    <IntegrationContext.Provider
      value={{
        integration,
        outdated,
        patch,
        connect,
        switchVersion,
        switchConnector,
        update,
        refresh: async () => {
          await refresh()
        },
        resetParameters,
        parameters,
      }}
    >
      {integration && children}
    </IntegrationContext.Provider>
  )
}

interface PatchIntegrationData {
  baseUri: string
  name: string
  key: string
  oAuthCallbackUri: string
  parameters: Record<string, any>
  areParametersCustomized: boolean
  logoUri: string
}

const IntegrationContext = createContext<{
  integration: Integration
  outdated: boolean
  parameters: any
  update(): Promise<void>
  patch(data: Partial<PatchIntegrationData>): Promise<void> // TODO: fix param types
  connect(): Promise<Connection>
  switchVersion(connectorVersionId: string): Promise<void>
  switchConnector(connectorId: string): Promise<void>
  resetParameters(): Promise<void>
  refresh(): Promise<void>
}>({} as any)

export function useIntegrationContext() {
  return useContext(IntegrationContext)
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "API Trigger",
  "attributes": {},
  "children": []
}];
export const TITLE = 'API Trigger';
export const DESCRIPTION = 'Launch flow using an API request with optional input.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components), {CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.RunFlowInstance) _missingMdxReference("CodeExample.RunFlowInstance", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "api-trigger",
      children: "API Trigger"
    }), "\n", _jsx(_components.p, {
      children: "This triggers lets you launch a Flow Instance with optional input using API or SDK."
    }), "\n", _jsx(_components.p, {
      children: "You can provide an optional input schema and use it in the flow nodes as variables."
    }), "\n", _jsx(_components.p, {
      children: "To launch a Flow Instance that has API trigger, you can use the following code:"
    }), "\n", _jsx(CodeExample.RunFlowInstance, {})]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

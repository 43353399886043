import { makeDataField } from '@integration-app/ui'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { CollapsibleSection } from '../Section'
import { useGenericConfig } from './contexts/generic-config-context'

export function SearchQueryConfig() {
  const { patchConfig, config, variablesSchema } = useGenericConfig()

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Search Query</CollapsibleSection.Header>

      <DataBuilderForm
        onChange={(query) => patchConfig({ query })}
        field={makeDataField({
          variablesSchema,
          value: config.query,
          schema: { type: 'string', title: 'Query' },
        })}
      />
    </CollapsibleSection>
  )
}

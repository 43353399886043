import useIntegrationElement from 'components/IntegrationElements/hooks/useIntegrationElement'
import { DataLinkTable } from '@integration-app/sdk'
import SimpleSelect, { SimpleSelectOptionType } from 'components/SimpleSelect'

export function DataLinkTableConfig({
  dataLinkTableKey,
  onChange,
  additionalOptions = [],
}: {
  dataLinkTableKey: string
  onChange: (value: string) => void
  additionalOptions?: SimpleSelectOptionType[]
}) {
  const { items } = useIntegrationElement<DataLinkTable>({
    route: 'data-link-tables',
  })

  const dataLinkOptions = items?.map((ds) => ({
    value: ds.key,
    label: ds.name,
  }))

  const options = [...dataLinkOptions, ...(additionalOptions ?? [])]

  return (
    <SimpleSelect
      name='Data Link Table'
      value={dataLinkTableKey}
      options={options}
      onChange={onChange}
    />
  )
}

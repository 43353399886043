/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Find Data Link",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "See Also",
    "attributes": {},
    "children": []
  }]
}];
export const DESCRIPTION = 'Find relation between an object in your app and a corresponding object in an external app';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink, DocGrid} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "find-data-link",
      children: "Find Data Link"
    }), "\n", _jsxs(_components.p, {
      children: ["This node finds uses a ", _jsx(DocLink, {
        path: "engine/blueprints/data-links",
        children: "Data Link"
      }), " between an object in your app and an object in an external app\nto find the matching object on the opposite side."]
    }), "\n", _jsxs(_components.p, {
      children: ["It should be used together with ", _jsx(DocLink, {
        path: "engine/blueprints/flows/nodes/create-data-link"
      })]
    }), "\n", _jsx(_components.p, {
      children: "The node outputs the ID of the matching records if one found, otherwise its output is empty."
    }), "\n", _jsx(_components.h2, {
      id: "see-also",
      children: "See Also"
    }), "\n", _jsx(DocGrid, {
      children: _jsx(DocGrid.Link, {
        path: "engine/blueprints/data-links"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

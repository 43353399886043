import ExampleUIBlock from '../../Docs/ExampleUIBlock'
import { CodeParamType } from '../../Docs/codeBlock-types'
import { useConnectorSpec, useIntegrationApp } from '@integration-app/react'
import { useState } from 'react'
import { SimpleForm, SimpleLayout } from '../../Docs/SimpleLayout'

export function ConnectionWidgetUiCodeExample() {
  return (
    <ExampleUIBlock
      customCodes={{ jsx }}
      Component={Component}
      parameters={{
        INTEGRATION_KEY: CodeParamType.integrationKeyWithoutConnection,
        PARAMETERS: CodeParamType.Object,
      }}
    />
  )
}

const jsx = `import { useState } from 'react'
import { useConnectorSpec, useIntegrationApp } from '@integration-app/react'

function Component({ parameters }) {
  // For this to work, don't forget to wrap this component into <IntegrationAppProvider/>
  const integrationApp = useIntegrationApp()

  const {
    data: connectorSpec,
    loading,
    error,
  } = useConnectorSpec(parameters.INTEGRATION_KEY)

  const [connectionParameters, setConnectionParameters] = useState({})
  const [connecting, setConnecting] = useState(false)
  const [connection, setConnection] = useState(null)

  // Wait until the spec loads
  if (loading) {
    return <p>Loading...</p>
  }

  // If something bad happened - show the error
  if (error) {
    return <p>Error: {error.message}</p>
  }

  // Simplified way to get the list of connection parameters.
  const schema = connectorSpec?.ui ? connectorSpec?.ui?.schema : null

  const fields = schema ? Object.keys(schema.properties) : []

  async function connect() {
    setConnecting(true)
    try {
      const connection = await integrationApp
        .integration(parameters.INTEGRATION_KEY)
        .connect({ parameters: connectionParameters })

      setConnection(connection)
    } finally {
      setConnecting(false)
    }
  }

  return (
    <div>
      {fields.length > 0 && (
        <div>
          <strong>Connection Parameters:</strong>
          {fields.map((field) => (
            <div key={field}>
              <label>{field}</label>
              <input
                type='text'
                value={connectionParameters[field] || ''}
                onChange={(e) =>
                  setConnectionParameters({
                    ...connectionParameters,
                    [field]: e.target.value,
                  })
                }
              />
            </div>
          ))}
        </div>
      )}

      {connecting ? (
        <span>Connecting...</span>
      ) : (
        <button onClick={connect}>Connect</button>
      )}

      {connection && (
        <div>
          <strong>Connected!</strong>
          <br />
          Connection Id: {connection.id}
        </div>
      )}
    </div>
  )
}`

function Component({ parameters }) {
  // For this to work, don't forget to wrap this component into <IntegrationAppProvider/>
  const integrationApp = useIntegrationApp()

  const {
    data: connectorSpec,
    loading,
    error,
  } = useConnectorSpec(parameters.INTEGRATION_KEY)

  const [connectionParameters, setConnectionParameters] = useState({})
  const [connecting, setConnecting] = useState(false)
  const [connection, setConnection] = useState(null)

  // Wait until the spec loads
  if (loading) {
    return <p>Loading...</p>
  }

  // If something bad happened - show the error
  if (error) {
    return <p>Error: {error.message}</p>
  }

  // Simplified way to get the list of connection parameters.
  const schema = connectorSpec?.ui ? connectorSpec?.ui?.schema : null
  // @ts-expect-error TS(2769): No overload matches this call.
  const fields = schema ? Object.keys(schema.properties) : []

  async function connect() {
    setConnecting(true)
    try {
      const connection = await integrationApp
        .integration(parameters.INTEGRATION_KEY)
        .connect({ parameters: connectionParameters })
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2345): Argument of type 'Connection' is not assignable to... Remove this comment to see the full error message
      setConnection(connection)
    } finally {
      setConnecting(false)
    }
  }

  return (
    <SimpleLayout>
      {fields.length > 0 && (
        <SimpleForm>
          <strong>Connection Parameters:</strong>
          {fields.map((field) => (
            <div key={field}>
              <label>{field}</label>
              <input
                type='text'
                value={connectionParameters[field] || ''}
                onChange={(e) =>
                  setConnectionParameters({
                    ...connectionParameters,
                    [field]: e.target.value,
                  })
                }
              />
            </div>
          ))}
        </SimpleForm>
      )}

      {connecting ? (
        <span>Connecting...</span>
      ) : (
        <button onClick={connect}>Connect</button>
      )}

      {connection && (
        <div>
          <strong>Connected!</strong>
          <br />
          {/* FIXME: strictNullCheck temporary fix */}
          {/* @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'. */}
          Connection Id: {connection.id}
        </div>
      )}
    </SimpleLayout>
  )
}

import { CustomizeImplementation } from './CustomizeImplementation'
import { DataCollectionMethodParametersConfig } from './DataCollectionMethodParametersConfig'
import { DataSourceUnitConfig } from './DataSourceUnitConfig'
import { ExportFieldMappingUnitConfig } from './ExportFieldMappingUnitConfig'
import { RecordIdFieldConfig } from './RecordIdFieldConfig'
import { useGenericConfig } from './contexts/generic-config-context'

export function UpdateDataRecordUnitConfig() {
  const { dataCollectionSpec } = useGenericConfig()

  const apiRequests = dataCollectionSpec?.update?.apiRequests

  return (
    <>
      <DataSourceUnitConfig />

      <DataCollectionMethodParametersConfig methodKey='update' />

      <RecordIdFieldConfig />

      <ExportFieldMappingUnitConfig isUpdate />

      <CustomizeImplementation apiRequests={apiRequests} />
    </>
  )
}

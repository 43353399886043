import { DataSourceUnitConfig } from '../../../../../../../components/common-configs/DataSourceUnitConfig'
import { ImportFieldMappingUnitConfig } from '../../../../../../../components/common-configs/ImportFieldMappingUnitConfig'

export function DataRecordCreatedTrigger() {
  return (
    <>
      <DataSourceUnitConfig configureEvents />

      <ImportFieldMappingUnitConfig />
    </>
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Flow Runs",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Get Flow Run Output",
    "attributes": {},
    "children": []
  }]
}];
import {FLOW_RUNS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const DESCRIPTION = 'Records of integration flow launches';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink, Link, CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.GetFlowRunOutput) _missingMdxReference("CodeExample.GetFlowRunOutput", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "flow-runs",
      children: "Flow Runs"
    }), "\n", _jsxs(_components.p, {
      children: ["Whenever a ", _jsx(DocLink, {
        path: "engine/blueprints/flows",
        children: "Flow Instance"
      }), " is started, a Flow Run is created."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can find the list of Flow Runs on the ", _jsx(Link, {
        path: FLOW_RUNS_ROUTE,
        children: "Flow Runs"
      }), " page."]
    }), "\n", _jsx(_components.h2, {
      id: "get-flow-run-output",
      children: "Get Flow Run Output"
    }), "\n", _jsx(CodeExample.GetFlowRunOutput, {})]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

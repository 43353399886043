import useApp from 'components/Workspaces/workspace-context'
import useSWR from 'swr'
import { useCallback, useState } from 'react'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {
  buildRouteUrl,
  IRouteParams,
  IIntegrationElementBasicFields,
} from './helpers'
import { swrLaggyMiddleware } from 'hooks/swr-laggy-middleware'

export default function useIntegrationElement<
  Item extends Partial<IIntegrationElementBasicFields>,
  CreateItem = any,
  UpdateItem = any,
>(itemsRouteParams: IRouteParams) {
  const { engineAdminClient, engineAdminFetcher } = useApp()

  const [selectedId, setSelectedId] = useState('')

  const itemsRoute = buildRouteUrl(itemsRouteParams)
  const {
    data: { items } = { items: [] },
    isLoading,
    mutate,
  } = useSWR<{
    items: Item[]
  }>(itemsRoute, engineAdminFetcher, { use: [swrLaggyMiddleware] })

  const selectedItem = items?.find(({ id }) => id === selectedId)

  async function createItem(createData: CreateItem) {
    const newItem = (await engineAdminClient.post(
      `/${itemsRouteParams.route}`,
      createData,
    )) as Item

    await mutate(
      {
        items: [newItem, ...items],
      },
      false,
    )

    return newItem
  }

  async function deleteItem(id: string, archive = false) {
    await engineAdminClient.delete(`/${itemsRouteParams.route}/${id}`)

    if (archive) {
      // if archive is true, then we want to keep item in the list, but mark it as archived
      await mutate(
        {
          items: items.map((item) => {
            if (item.id === id) {
              item.archivedAt = new Date()
            }

            return item
          }),
        },
        false,
      )
    } else {
      await mutate(
        {
          items: items.filter((e) => e.id !== id),
        },
        false,
      )
    }
  }

  const debouncedUpdate = useCallback(
    AwesomeDebouncePromise(
      (async (data) => {
        await engineAdminClient.put(
          `/${itemsRouteParams.route}/${selectedId}`,
          data,
        )
      }) as any,
      2000,
    ),
    [selectedId],
  )

  async function putItem(id: string, updateData: Partial<UpdateItem>) {
    const item = items.find((item) => item.id === id)

    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2322): Type '{ id?: string | undefined; key?: string | un... Remove this comment to see the full error message
    const updatedItem: Item = {
      ...item,
      ...updateData,
    }

    await engineAdminClient.put(`/${itemsRouteParams.route}/${id}`, updatedItem)

    void mutate(
      {
        items: items.map((item) => (item.id === id ? updatedItem : item)),
      },
      false,
    )
  }

  async function patchItem(id: string, data: Partial<UpdateItem>) {
    const item = items.find((item) => item.id === id)

    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2322): Type '{ id?: string | undefined; key?: string | un... Remove this comment to see the full error message
    const updatedItem: Item = {
      ...item,
      ...data,
    }

    await engineAdminClient.patch(`/${itemsRouteParams.route}/${id}`, data)

    void mutate(
      {
        items: items.map((item) => (item.id === id ? updatedItem : item)),
      },
      false,
    )
  }

  async function updateSelectedItem(updateData: Partial<UpdateItem>) {
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2322): Type '{ id?: string | undefined; key?: string | un... Remove this comment to see the full error message
    const updatedItem: Item = {
      ...selectedItem,
      ...updateData,
    }

    void mutate(
      {
        items: items.map((item) =>
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          item.id === selectedItem.id ? updatedItem : item,
        ),
      },
      false,
    )

    await debouncedUpdate(updatedItem)
  }

  function selectItem(id: string) {
    setSelectedId(id)
  }

  return {
    items,
    loading: isLoading,
    selectedItem,
    selectItem,
    updateSelectedItem,

    createItem,
    deleteItem,
    putItem,
    patchItem,

    mutateItems: mutate,
  }
}

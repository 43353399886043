/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Action Instances",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Run",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Get",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "List",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Update",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Set up / Refresh",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Reset",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Delete",
    "attributes": {},
    "children": []
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    code: "code"
  }, _provideComponents(), props.components), {CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.ArchiveActionInstance) _missingMdxReference("CodeExample.ArchiveActionInstance", true);
  if (!CodeExample.GetActionInstance) _missingMdxReference("CodeExample.GetActionInstance", true);
  if (!CodeExample.ListActionInstances) _missingMdxReference("CodeExample.ListActionInstances", true);
  if (!CodeExample.PatchActionInstance) _missingMdxReference("CodeExample.PatchActionInstance", true);
  if (!CodeExample.ResetActionInstance) _missingMdxReference("CodeExample.ResetActionInstance", true);
  if (!CodeExample.RunActionInstance) _missingMdxReference("CodeExample.RunActionInstance", true);
  if (!CodeExample.SetupActionInstance) _missingMdxReference("CodeExample.SetupActionInstance", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "action-instances",
      children: "Action Instances"
    }), "\n", _jsx(_components.p, {
      children: "Before you can configure or run action for a specific user and connection, an Action Instance needs to be created.\nIn many cases it is created automatically when you first try to access the action for a given connection."
    }), "\n", _jsx(_components.h2, {
      id: "run",
      children: "Run"
    }), "\n", _jsx(_components.p, {
      children: "To run an action, do the following:"
    }), "\n", _jsx(CodeExample.RunActionInstance, {}), "\n", _jsxs(_components.p, {
      children: ["This request will return result of the action that matches its ", _jsx(_components.code, {
        children: "outputSchema"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "get",
      children: "Get"
    }), "\n", _jsx(CodeExample.GetActionInstance, {}), "\n", _jsx(_components.h2, {
      id: "list",
      children: "List"
    }), "\n", _jsx(_components.p, {
      children: "To list all the actions for the current customer, do the following:"
    }), "\n", _jsx(CodeExample.ListActionInstances, {}), "\n", _jsx(_components.h2, {
      id: "update",
      children: "Update"
    }), "\n", _jsx(_components.p, {
      children: "To update a customer action, do the following:"
    }), "\n", _jsx(CodeExample.PatchActionInstance, {}), "\n", _jsxs(_components.p, {
      children: ["Whenever you call this method, the action will be marked as customized (", _jsx(_components.code, {
        children: "isCustomized"
      }), " set to true). To reset it back to default, use the ", _jsx(_components.code, {
        children: "reset"
      }), " method below."]
    }), "\n", _jsx(_components.h2, {
      id: "set-up--refresh",
      children: "Set up / Refresh"
    }), "\n", _jsx(_components.p, {
      children: "Setting up a customer action will re-fetch all the dependencies (data sources, schemas, etc.) and recalculate dynamic fields."
    }), "\n", _jsx(CodeExample.SetupActionInstance, {}), "\n", _jsx(_components.h2, {
      id: "reset",
      children: "Reset"
    }), "\n", _jsx(_components.p, {
      children: "Resetting a customer action brings it to a default state, erasing all the customer-level configuration."
    }), "\n", _jsx(CodeExample.ResetActionInstance, {}), "\n", _jsx(_components.h2, {
      id: "delete",
      children: "Delete"
    }), "\n", _jsx(CodeExample.ArchiveActionInstance, {})]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

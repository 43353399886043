/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Connector Builder",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Authentication",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "API Specification",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Operations",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Data Collections",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Testing",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Publishing",
    "attributes": {},
    "children": []
  }]
}];
export const DESCRIPTION = 'How to build your own connector or modify an existing one.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "connector-builder",
      children: "Connector Builder"
    }), "\n", _jsx(_components.p, {
      children: "You can use connector builder to view and modify existing connectors or build new ones from scratch."
    }), "\n", _jsx(_components.h2, {
      id: "authentication",
      children: "Authentication"
    }), "\n", _jsx(_components.p, {
      children: "Every connector must have authentication configured.\nIt specifies how user interface for connecting the external application looks like,\nhow to get API credentials, and how to access the API using the credentials."
    }), "\n", _jsxs(_components.p, {
      children: ["Full article: ", _jsx(DocLink, {
        path: "connector-builder/authentication"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "api-specification",
      children: "API Specification"
    }), "\n", _jsx(_components.p, {
      children: "API specification describes possible requests and responses that the API supports."
    }), "\n", _jsx(_components.p, {
      children: "You can find and upload an existing API specification or generate one from the API documentation using built-in AI."
    }), "\n", _jsx(_components.p, {
      children: "Having API specification as a part of a connector allows to configure API requests and responses with an easy-to-use UI - both in connector builder\nand as a part of building an integration."
    }), "\n", _jsx(_components.p, {
      children: "You can make API calls without API specification, but you will have to configure them manually, which is less straightforward."
    }), "\n", _jsxs(_components.p, {
      children: ["Full article: ", _jsx(DocLink, {
        path: "connector-builder/api"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "operations",
      children: "Operations"
    }), "\n", _jsxs(_components.p, {
      children: ["Full article: ", _jsx(DocLink, {
        path: "connector-builder/operations"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "data-collections",
      children: "Data Collections"
    }), "\n", _jsxs(_components.p, {
      children: ["Full article:  ", _jsx(DocLink, {
        path: "connector-builder/data"
      })]
    }), "\n", _jsx(_components.p, {
      children: "If you are curious how a complete connector looks, you can clone one of the existing connectors and explore it."
    }), "\n", _jsx(_components.h2, {
      id: "testing",
      children: "Testing"
    }), "\n", _jsx(_components.p, {
      children: "After configuring authentication, you will be able to test the connector right from the connector builder.\nTo do this, create a test integration and then a connection."
    }), "\n", _jsx(_components.p, {
      children: "This integration and connection are linked to your workspace. This will allow you to run any of your integrations with a connector you build right away."
    }), "\n", _jsx(_components.h2, {
      id: "publishing",
      children: "Publishing"
    }), "\n", _jsx(_components.p, {
      children: "When your connector is ready, you can publish it by creating an integration in one of your workspaces.\nThis will create a copy of the current version of the connector to make sure you do not accidentally break your production integration.\nThe published integration will remain unchanged until you re-publish the connector next time."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import { PropsWithChildren } from 'react'
import classes from './MarkdownViewer.module.css'
import clsx from 'clsx'

export function DocArticleStyleWrapper({
  children,
  className,
}: PropsWithChildren<{
  className?: string
}>) {
  return <div className={clsx(classes.content, className)}>{children}</div>
}

import { SimpleInput } from '@integration-app/ui'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'

export function ScheduleTrigger() {
  const { config, patchConfig } = useGenericConfig()

  return (
    <div>
      <SimpleInput
        label='Interval (minutes)'
        value={config.intervalMinutes}
        onChange={(intervalMinutes) => patchConfig({ intervalMinutes })}
      />
    </div>
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Authentication",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "User Interface and Input",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Authentication Type",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Client Credentials",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "OAuth2",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "API Client",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Testing",
    "attributes": {},
    "children": []
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    code: "code",
    h3: "h3",
    strong: "strong"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "authentication",
      children: "Authentication"
    }), "\n", _jsx(_components.p, {
      children: "It specifies how user interface for connecting the external application looks like,\nhow to get API credentials, and how to access the API using the credentials."
    }), "\n", _jsx(_components.h2, {
      id: "user-interface-and-input",
      children: "User Interface and Input"
    }), "\n", _jsx(_components.p, {
      children: "You can configure how connection UI looks like for your users and ask them for inputs you need to connect to the API."
    }), "\n", _jsx("img", {
      src: "/images/docs/connector-builder-authentication.png",
      style: {
        maxWidth: 500,
        border: '1px solid gray'
      }
    }), "\n", _jsx(_components.p, {
      children: "In some cases, like standard oAuth authentication, you don't need to ask users for anything, and you can skip configuring the UI."
    }), "\n", _jsx(_components.p, {
      children: "You can configure the following:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Description - short description of what is happening and what is required from the user."
      }), "\n", _jsxs(_components.li, {
        children: ["Connection Parameters - ", _jsx(DocLink, {
          path: "engine/references/data-schemas",
          children: "Data Schema"
        }), " of input you want the user to enter."]
      }), "\n", _jsx(_components.li, {
        children: "Help URL - link to a relevant help article where user can learn more about the authentication process."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "authentication-type",
      children: "Authentication Type"
    }), "\n", _jsx(_components.p, {
      children: "To get credentials to use for accessing the API, you need to configure authentication. Start with picking the authentication type:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "client-credentials"
        }), " authentication lets you ask for user input, optionally transform it into connection credentials, and use to access the API."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "oauth2"
        }), " implements the ", _jsx("a", {
          href: "https://oauth.net/2/",
          target: "_blank",
          children: "OAuth 2.1"
        }), " protocol with optional modifications."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "oauth1"
        }), " implements the ", _jsx("a", {
          href: "https://oauth.net/1/",
          target: "_blank",
          children: "OAuth 1.0"
        }), " protocol with optional modifications (it is deprecated and rarely used)."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "If not sure which one to pick, go with \"Client Credentials\"."
    }), "\n", _jsx(_components.h3, {
      id: "client-credentials",
      children: "Client Credentials"
    }), "\n", _jsx(_components.p, {
      children: "This authentication type is the simplest one. It lets you ask for user input, optionally transform it into connection credentials, and use to access the API."
    }), "\n", _jsx(_components.p, {
      children: "By default, user input is used as connection credentials. If you want to do something with the user input, you can add mapping or a custom script to do it."
    }), "\n", _jsx(_components.p, {
      children: "This script or mapping receives the following input parameters:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "connectorParameters"
        }), " - parameters configured for the connector in your workspace."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "connectionParameters"
        }), " - input provided by the user in the connection UI."]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "oauth2",
      children: "OAuth2"
    }), "\n", _jsx(_components.p, {
      children: "To implement oAuth2 authentication, you will have to provide the following information:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Authorization URI"
      }), "\n", _jsx(_components.li, {
        children: "Token URI"
      }), "\n", _jsx(_components.li, {
        children: "Client Id and Client Secret (in most cases, you want to reference the Connector Parameters here instead of hard-coding the values)"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "There is a number of additional parameters you can configure to customize the implementation - read their descriptions for details."
    }), "\n", _jsx(_components.p, {
      children: "Some applications provide non-standard oAuth2 implementations. Connector builder lets you customize or override every step of the process:"
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Get OAuth Config"
      })
    }), "\n", _jsx(_components.p, {
      children: "This function returns oAuth config. It can be implemented as mapping or custom javascript."
    }), "\n", _jsx(_components.p, {
      children: "Inputs:"
    }), "\n", _jsx("table", {
      className: 'stripe w-full',
      children: _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "connectorParameters"
            })
          }), "\n", _jsxs("td", {
            children: ["Dictionary with parameters matching ", _jsx(_components.code, {
              children: "parametersSchema"
            }), " of the connector. Usually clientId and clientSecret go here."]
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "connectionParameters"
            })
          }), "\n", _jsxs("td", {
            children: ["Values provided by the end-user in the connection UI. Matches ", _jsx(_components.code, {
              children: "ui.schema"
            })]
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "redirectUri"
            })
          }), "\n", _jsx("td", {
            children: "Redirect URI that should be used to send user to complete the authentication flow."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "state"
            })
          }), "\n", _jsx("td", {
            children: "oAuth state - it needs to be present in the callback URI for integration.app to identify the user performing authentication."
          })]
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: "Output:"
    }), "\n", _jsx("table", {
      className: 'stripe w-full',
      children: _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "clientId"
            })
          }), "\n", _jsx("td", {
            children: "Client Id that will be used in the oAuth flow."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "clientSecret"
            })
          }), "\n", _jsx("td", {
            children: "Client Secret that will be used in the oAuth flow."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "authorizeUri"
            })
          }), "\n", _jsx("td", {
            children: "URI user will be redirected to when initializing the authentication."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "tokenUri"
            })
          }), "\n", _jsx("td", {
            children: "URI that will be used to get access_token data when creating a connection or refreshing it."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "scopes"
            })
          }), "\n", _jsxs("td", {
            children: ["List of scopes that will be requested from the app by adding them to authorizeUri. Should return an array of strings. They will be sent as ", _jsx(_components.code, {
              children: "scope"
            }), " parameter separated by space according to the oAuth specification."]
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "clientAuthLocation"
            })
          }), "\n", _jsx("td", {
            children: "Whether to send clientId/clientSecret in request headers or in request body. Will be sent in both by default. You may want to choose a value here if default behavior breaks authentication."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "noRefreshToken"
            })
          }), "\n", _jsxs("td", {
            children: ["Set to ", _jsx(_components.code, {
              children: "true"
            }), " if refresh token is not supported by this app. If not set, authentication process will expect ", _jsx(_components.code, {
              children: "refresh_token"
            }), " to be returned from the ", _jsx(_components.code, {
              children: "tokenUri"
            }), " when creating a connection."]
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "skipPkce"
            })
          }), "\n", _jsxs("td", {
            children: ["Do not add ", _jsx("a", {
              href: "https://oauth.net/2/pkce/",
              target: "_blank",
              children: "PKCE"
            }), " parameters to the auth request. Some apps require them, and some apps don't allow to authenticate if they are provided. By default PKCE parameters are added."]
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "extra"
            })
          }), "\n", _jsxs("td", {
            children: ["Dictionary of additional parameters that will be added to ", _jsx(_components.code, {
              children: "authorizeUri"
            }), " when creating a connection."]
          })]
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Custom token request"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["By default, connector will implement the ", _jsx("a", {
        href: "https://www.oauth.com/oauth2-servers/access-tokens/authorization-code-request/",
        target: "_blank",
        children: "OAuth Code Flow"
      }), " to get access token and refresh token."]
    }), "\n", _jsxs(_components.p, {
      children: ["If you need to implement a different flow, you can override the ", _jsx(_components.code, {
        children: "getTokenData"
      }), " method. It receives the following input:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "connectorParameters"
        }), " - parameters configured for the connector in your workspace."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "connectionParameters"
        }), " - input provided by the user in the connection UI."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "codeVerifier"
        }), " - required for PKCE flow."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "queryParameters"
        }), " - any parameters returned in the query of the callback URI."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "redirectUri"
        }), " - callback URI used for authentication."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Extract credentials from token response"
      })
    }), "\n", _jsx(_components.p, {
      children: "By default, everything that application returned from the token response is added to the connection credentials.\nIf you want to change it or make additional requests to get more credentials, you can implement this function."
    }), "\n", _jsx(_components.p, {
      children: "It receives the following input parameters:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "connectorParameters"
        }), " - parameters configured for the connector in your workspace."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "connectionParameters"
        }), " - input provided by the user in the connection UI."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "queryParameters"
        }), " - any parameters returned in the query of the callback URI."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "tokenResponse"
        }), " - response received from the token URI or from your custom implementation of \"Get Token Data\""]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Response from this function will be saved as the connection credentials, additionally to the token response (the objects will be merged)."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Custom token refresh"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["By default, the standard exchange of the ", _jsx(_components.code, {
        children: "refresh_token"
      }), " received in the token response for a new access token will be performed."]
    }), "\n", _jsx(_components.p, {
      children: "If you want to customize this process, configure this function. It receives the following input parameters:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "credentials"
        }), " - current credentials of the connection."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Response of this function will be merged with the current credentials and saved as the new connection credentials."
    }), "\n", _jsx(_components.h2, {
      id: "api-client",
      children: "API Client"
    }), "\n", _jsx(_components.p, {
      children: "Configure how API will be accessed through the connector by providing settings or custom code for the API client."
    }), "\n", _jsxs(_components.p, {
      children: ["Full article: ", _jsx(DocLink, {
        path: "connector-builder/authentication/api-client"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "testing",
      children: "Testing"
    }), "\n", _jsx(_components.p, {
      children: "After connection is established, it is a good idea to test it. Provide mapping or code that makes a test API requests that verifies\nthat credentials are correct and connection is functioning."
    }), "\n", _jsx(_components.p, {
      children: "Test function should return a truthy value for test to be considered successful."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

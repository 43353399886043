import { generateExampleFromSchema } from '@integration-app/sdk'
import Editor from '@monaco-editor/react'
import { JsonViewer } from 'components/JsonViewer'
import { SchemaBuilder } from '@integration-app/ui'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'

export const DEFAULT_CODE = `module.exports = async function({
  input,
  externalApiClient,
}) {
  return 'result'
}`

export function RunJavascript() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()

  const variablesExample = generateExampleFromSchema(variablesSchema)

  const code = config.code

  return (
    <div>
      <h2>Javascript Code</h2>
      <div className='h-96 border py-4'>
        <Editor
          width='100%'
          height='100%'
          language='javascript'
          value={code}
          onChange={(newCode: string) => patchConfig({ code: newCode })}
          options={{
            minimap: {
              enabled: false,
            },
          }}
        />
      </div>

      <h3>Variables Example</h3>
      <JsonViewer json={variablesExample} />

      <h3>Output Schema</h3>
      <SchemaBuilder
        schema={config.outputSchema ?? {}}
        isObject={false}
        onChange={(outputSchema) => patchConfig({ outputSchema })}
      />
    </div>
  )
}

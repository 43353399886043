const BASE_URL = process.env.https://api.integration.app

type RequestOptions = {
  headers?: Record<string, string>
  method?: string
  body?: string
}

export function httpCodeExamples(path: string, options?: RequestOptions) {
  return {
    curl: curl(path, options),
    go: go(path, options),
    python: python(path, options),
    java: java(path, options),
    php: php(path, options),
  }
}

function handleOptions(path: string, options?: RequestOptions) {
  const method = options?.method ?? 'GET'

  const headers: Record<string, string> = {
    Authorization: `Bearer {CUSTOMER_TOKEN}`,
    ...(options?.headers ?? {}),
  }

  if (options?.body && !headers['Content-Type'] && method !== 'GET') {
    headers['Content-Type'] = 'application/json'
  }

  return {
    method,
    headers,
    url: `${BASE_URL}${path}`,
  }
}

function curl(path: string, options?: RequestOptions): string {
  const { method, headers, url } = handleOptions(path, options)

  const lines: string[] = []

  lines.push(`curl --request ${method}`)

  lines.push(`--url ${url}`)

  Object.entries(headers).forEach(([key, value]) => {
    lines.push(`--header "${key}: ${value}"`)
  })

  if (options?.body) {
    lines.push(`--data '${options.body}'`)
  }

  return lines.join(' \\\n\t')
}

function go(path: string, options?: RequestOptions) {
  const { method, headers, url } = handleOptions(path, options)

  const lines: string[] = []

  lines.push(`  url := "${url}"`)

  if (options?.body) {
    lines.push(`  payload := strings.NewReader("${options.body}")`)
    lines.push(`  req, _ := http.NewRequest("${method}", url, payload)`)
  } else {
    lines.push(`  req, _ := http.NewRequest("${method}", url, nil)`)
  }

  Object.entries(headers).forEach(([key, value]) => {
    lines.push(`  req.Header.Add("${key}", "${value}")`)
  })

  return `package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {
${lines.join('\n')}

  res, _ := http.DefaultClient.Do(req)

  defer res.Body.Close()
  body, _ := ioutil.ReadAll(res.Body)

  fmt.Println(res)
  fmt.Println(string(body))
}
`
}

function python(path: string, options?: RequestOptions) {
  const { method, headers, url } = handleOptions(path, options)

  // TODO: replace null with None
  const payloadString = options?.body
    ? `\npayload = ${JSON.stringify(options.body, null, 2)}\n`
    : ''

  const payloadParam = payloadString ? ', json=payload' : ''

  return `import requests

url = "${url}"

headers = ${JSON.stringify(headers, null, 2)}
${payloadString}
response = requests.request("${method}", url, headers=headers${payloadParam})

print(response.text)
`
}

function java(path: string, options?: RequestOptions) {
  const { method, headers, url } = handleOptions(path, options)

  const lines = [
    `HttpResponse<String> response = Unirest.${method.toLowerCase()}("${url}")`,
  ]

  Object.keys(headers).forEach((key) =>
    lines.push(`\t.header("${key}", "${headers[key]}")`),
  )

  if (options?.body) {
    lines.push(`\t.body("${options.body}")`)
  }

  lines.push(`\t.asString();`)

  return lines.join('\n')
}

function php(path: string, options?: RequestOptions) {
  const { method, headers, url } = handleOptions(path, options)

  const lines: string[] = []

  lines.push(`curl_setopt_array($curl, [`)

  lines.push(`  CURLOPT_URL => "${url}",`)
  lines.push(`  CURLOPT_RETURNTRANSFER => true,`)
  lines.push(`  CURLOPT_ENCODING => "",`)
  lines.push(`  CURLOPT_MAXREDIRS => 10,`)
  lines.push(`  CURLOPT_TIMEOUT => 30,`)
  lines.push(`  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,`)
  lines.push(`  CURLOPT_CUSTOMREQUEST => "${method}",`)

  if (options?.body) {
    lines.push(`  CURLOPT_POSTFIELDS => "${options.body}",`)
  }

  lines.push(`  CURLOPT_HTTPHEADER => [`)
  Object.entries(headers).forEach(([key, value]) => {
    lines.push(`    "${key}" => "${value}",`)
  })
  lines.push(`  ],`)

  lines.push(`]);`)

  return `<?php

$curl = curl_init();

${lines.join('\n')}

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #:" . $err;
} else {
  echo $response;
}`
}

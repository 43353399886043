import { UNIFIED_DATA_MODELS } from '@integration-app/sdk'
import {
  CommonItemComboboxSelect,
  ItemComboBoxGenericValue,
  ItemComboBoxGenericValuePlaceholder,
  ItemComboboxSelectProps,
} from './CommonItemComboboxSelect'

export function UdmComboboxSelect({
  value,
  onChange,
  editable = true,
  hasRightSibling = false,
}: ItemComboboxSelectProps<string>) {
  const udms = Object.keys(UNIFIED_DATA_MODELS)
  const options = udms?.map((udm) => ({
    value: udm,
    label: UNIFIED_DATA_MODELS[udm].pluralName,
  }))

  const selectedOption = options?.find((o) => o.value === value)

  return (
    <CommonItemComboboxSelect<string>
      value={value}
      onChange={onChange}
      options={options}
      hasRightSibling={hasRightSibling}
      editable={editable}
      valueComponent={
        <ItemComboBoxGenericValue
          title={'Data Model:'}
          value={selectedOption?.label}
        />
      }
      valuePlaceholderComponent={
        <ItemComboBoxGenericValuePlaceholder title={'UDM:'} />
      }
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Configure Flow Instance Parameters",
  "attributes": {},
  "children": []
}];
export const TITLE = 'Configure Flow Instance Parameters';
export const DESCRIPTION = 'Let your users configure the integration logic for a given flow instance.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code"
  }, _provideComponents(), props.components), {CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.PatchFlowInstanceParameters) _missingMdxReference("CodeExample.PatchFlowInstanceParameters", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "configure-flow-instance-parameters",
      children: "Configure Flow Instance Parameters"
    }), "\n", _jsxs(_components.p, {
      children: ["To set flow parameters, patch the ", _jsx(_components.code, {
        children: "parameters"
      }), " property of the Flow Instance:"]
    }), "\n", _jsx(CodeExample.PatchFlowInstanceParameters, {}), "\n", _jsx(_components.p, {
      children: "The parameters must match the Parameters Schema you configured for the flow."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "For Each",
  "attributes": {},
  "children": []
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "for-each",
      children: "For Each"
    }), "\n", _jsx(_components.p, {
      children: "For Each node executes a subset of flow nodes for each item in a list, then aggregates their outputs."
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "forEach:\n    name: For Each\n    type: for-each\n    version: 2\n    config:\n        items:\n            - item1\n            - item2\n        rootNodeKey: nodeInsideForEach\nnodeInsideForEach:\n    name: Inside For Each\n    type: transform-data\n    config:\n        output:\n            forEachItem:\n                $var: input.forEach.item\n            customItem: customValue\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["In this example, the forEach node will execute nodeInsideForEach twice (for ", _jsx(_components.code, {
        children: "item1"
      }), " and ", _jsx(_components.code, {
        children: "item2"
      }), ").\nThe current item is available inside the for-each loop as ", _jsx(_components.code, {
        children: "item"
      }), " property of the ", _jsx(_components.code, {
        children: "input.forEach"
      }), " object (assuming the key of for-each node is ", _jsx(_components.code, {
        children: "forEach"
      }), ")"]
    }), "\n", _jsx(_components.p, {
      children: "The output of the forEach node is an object with keys matching nodes inside the for-each loop and values being lists of outputs of those nodes."
    }), "\n", _jsx(_components.p, {
      children: "In the example above, the output of the forEach node will be:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "nodeInsideForEach:\n    - forEachItem: item1\n      customItem: customValue\n    - forEachItem: item2\n      customItem: customValue\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["This output can be used in the subsequent nodes as ", _jsx(_components.code, {
        children: "input.forEach"
      }), " variable (assuming the key of for-each node is ", _jsx(_components.code, {
        children: "forEach"
      }), ")."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;

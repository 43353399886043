/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Data Link Table Instances",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Data Links",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Creating Data Link",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Finding Data Link",
      "attributes": {},
      "children": []
    }]
  }]
}];
import {DATA_LINK_TABLE_INSTANCES_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    code: "code",
    h3: "h3",
    blockquote: "blockquote"
  }, _provideComponents(), props.components), {DocLink, Link} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "data-link-table-instances",
      children: "Data Link Table Instances"
    }), "\n", _jsxs(_components.p, {
      children: ["Each pair of Data Link Table and ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connection"
      }), " will have a separate Data Link Table Instance."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can find all the Data Link Table Instances on the ", _jsx(Link, {
        path: DATA_LINK_TABLE_INSTANCES_ROUTE,
        children: "Instances"
      }), " page.\nIf you click on a given Data Link Instance, you can see and modify the list of Data Links in that instance."]
    }), "\n", _jsx(_components.p, {
      children: "Whenever you create a link from one record to another, it is recorded in a corresponding Data Link Table Instance."
    }), "\n", _jsx(_components.h2, {
      id: "data-links",
      children: "Data Links"
    }), "\n", _jsx(_components.p, {
      children: "Data Link is a record within Data Link Table Instance that has:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "externalRecordId - id of a record on the external app's side."
      }), "\n", _jsx(_components.li, {
        children: "appRecordId - id of a record on your app's side."
      }), "\n", _jsxs(_components.li, {
        children: ["direction - one of:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "export"
            }), " - link from your app to an external app."]
          }), "\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "import"
            }), " - link from external app to your app."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "creating-data-link",
      children: "Creating Data Link"
    }), "\n", _jsx(_components.p, {
      children: "Data Link is created with the following data:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "dataLinkTableInstanceId - id of Data Link Table Instance associated with the record"
      }), "\n", _jsx(_components.li, {
        children: "externalRecordId"
      }), "\n", _jsx(_components.li, {
        children: "appRecordId"
      }), "\n", _jsxs(_components.li, {
        children: ["direction - ", _jsx(_components.code, {
          children: "export"
        }), ", ", _jsx(_components.code, {
          children: "import"
        }), ", or ", _jsx(_components.code, {
          children: "both"
        }), "."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["If direction ", _jsx(_components.code, {
        children: "both"
      }), " is selected then both ", _jsx(_components.code, {
        children: "export"
      }), " and ", _jsx(_components.code, {
        children: "import"
      }), " link will be created."]
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsxs(_components.p, {
        children: ["Only one link with the same ", _jsx(_components.code, {
          children: "externalRecordId"
        }), ", ", _jsx(_components.code, {
          children: "appRecordId"
        }), ", and ", _jsx(_components.code, {
          children: "direction"
        }), " can exist within a single Data Link Table Instance. If another one is created, it will overwrite the previously existing one."]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "finding-data-link",
      children: "Finding Data Link"
    }), "\n", _jsx(_components.p, {
      children: "When finding a data link with a Flow Node or a Formula, you need to provide:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "dataLinkTableInstanceId"
      }), "\n", _jsx(_components.li, {
        children: "direction"
      }), "\n", _jsx(_components.li, {
        children: "recordId"
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Find External Record Id and Find App Record Id formulas provide ", _jsx(_components.code, {
        children: "direction"
      }), " automatically."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

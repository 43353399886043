// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '98b5f4ffb79819cd0540870b549ad691';
const css =`._tabList_vnnql_1{align-items:stretch;border-bottom-width:1px;border-color:rgb(221 221 221/var(--tw-border-opacity));flex-direction:row;flex-wrap:nowrap;margin-left:calc(var(--padding-x, 2.18rem)*-1);margin-right:calc(var(--padding-x, 2.18rem)*-1);padding-left:calc(var(--padding-x, 2.18rem));padding-right:calc(var(--padding-x, 2.18rem))}._tab_vnnql_1,._tabList_vnnql_1{--tw-border-opacity:1;display:flex}._tab_vnnql_1{--tw-bg-opacity:1;background-color:rgb(248 248 248/var(--tw-bg-opacity));border-color:rgb(221 221 221/var(--tw-border-opacity));border-width:1px 0 1px 1px;flex-direction:column;padding:.75rem 1.25rem}._tab_vnnql_1:focus{outline:2px solid transparent;outline-offset:2px}._tab_vnnql_1{margin-bottom:-1px;max-width:24rem;text-align:left}._tab_vnnql_1:first-child{border-top-left-radius:.25rem}._tab_vnnql_1:last-child{border-right-width:1px;border-top-right-radius:.25rem}._tab_vnnql_1 p{margin:0;padding:0}._tab_vnnql_1 p a{--tw-text-opacity:1;color:rgb(13 121 194/var(--tw-text-opacity))}._tab_vnnql_1 p a:hover{text-decoration-line:underline}._tab_vnnql_1 p+p{margin-top:.25rem}._tab_vnnql_1[aria-selected=false]:not([disabled]):hover,._tab_vnnql_1[aria-selected=true]{--tw-bg-opacity:1;background-color:rgb(255 255 255/var(--tw-bg-opacity))}._tab_vnnql_1[aria-selected=true]{border-bottom-color:#fff}._tab_vnnql_1[disabled]{--tw-text-opacity:1;color:rgb(170 170 170/var(--tw-text-opacity));cursor:default}._tabPanels_vnnql_1{margin-top:2rem}._tabPanel_vnnql_1,._tabPanels_vnnql_1{display:block}._tabPanel_vnnql_1:focus-within{outline:2px solid transparent;outline-offset:2px}._tabPanel_vnnql_1:focus{outline:2px solid transparent;outline-offset:2px}`;
const styles = {
    get ['tabList']() { injectStyles(key, css, options);  return '_tabList_vnnql_1'; },
    get ['tab']() { injectStyles(key, css, options);  return '_tab_vnnql_1'; },
    get ['tabPanels']() { injectStyles(key, css, options);  return '_tabPanels_vnnql_1'; },
    get ['tabPanel']() { injectStyles(key, css, options);  return '_tabPanel_vnnql_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Add Javascript SDK to your Front-end",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Dynamic Token",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "SDK Reference",
    "attributes": {},
    "children": []
  }]
}];
import {DocGrid} from "components/Docs/DocLinksGrid";
import DocLink from "components/Docs/DocLink";
import {SETTINGS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
import {CodeExample} from '../../../../../../components/CodeExamples';
export const TITLE = 'Javascript SDK';
export const DESCRIPTION = "Use integration.app in your app's front-end.";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    blockquote: "blockquote",
    p: "p",
    ul: "ul",
    li: "li",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components), {Link} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.InitSdk) _missingMdxReference("CodeExample.InitSdk", true);
  if (!CodeExample.InstallSdk) _missingMdxReference("CodeExample.InstallSdk", true);
  if (!CodeExample.OpenUi) _missingMdxReference("CodeExample.OpenUi", true);
  if (!CodeExample.SdkFetchToken) _missingMdxReference("CodeExample.SdkFetchToken", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "add-javascript-sdk-to-your-front-end",
      children: "Add Javascript SDK to your Front-end"
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsx(_components.p, {
        children: "If you are using one of the popular JS frameworks, check out a framework-specific article:"
      }), "\n", _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: ["\n", _jsx(DocLink, {
            path: "overview/getting-started/front-end/react"
          }), "\n"]
        }), "\n", _jsxs(_components.li, {
          children: ["\n", _jsx(DocLink, {
            path: "overview/getting-started/front-end/vue"
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["First, install ", _jsx(_components.code, {
        children: "@integration-app/sdk"
      }), " NPM package:"]
    }), "\n", _jsx(CodeExample.InstallSdk, {}), "\n", _jsx(_components.p, {
      children: "Then, initialize the SDK with an Authentication Token:"
    }), "\n", _jsx(CodeExample.InitSdk, {}), "\n", _jsxs(_components.p, {
      children: ["In this example we use the test token you can find on the ", _jsx(Link, {
        path: SETTINGS_ROUTE,
        children: "Settings"
      }), " page of your workspace.\nYou will need to replace it with a real authentication token later (see ", _jsx(DocLink, {
        path: "overview/getting-started/authentication"
      }), ")."]
    }), "\n", _jsx(_components.p, {
      children: "To verify that everything works, let's open our drop-in integration UI:"
    }), "\n", _jsx(CodeExample.OpenUi, {}), "\n", _jsx(_components.h2, {
      id: "dynamic-token",
      children: "Dynamic Token"
    }), "\n", _jsxs(_components.p, {
      children: ["If it is more convenient for you to fetch token dynamically instead of providing static value, you can use ", _jsx(_components.code, {
        children: "fetchToken"
      }), " instead:"]
    }), "\n", _jsx(CodeExample.SdkFetchToken, {}), "\n", _jsx(_components.p, {
      children: "This option also automatically handles token expiration. If SDK was initialized long ago and token had time to expire, it will be automatically re-fetched before making new API requests."
    }), "\n", _jsx(_components.h2, {
      id: "sdk-reference",
      children: "SDK Reference"
    }), "\n", _jsxs(_components.p, {
      children: ["To see the full list of SDK methods check out the ", _jsx("a", {
        href: "/ref/sdk/index.html",
        target: "_blank",
        children: "Javascript API Reference"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components), {DocLink, CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.CreateFlowInstance) _missingMdxReference("CodeExample.CreateFlowInstance", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "To start running this flow, you need to enable it for a specific customer and connection."
    }), "\n", _jsx(_components.p, {
      children: "You can do it in two ways:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsx(_components.li, {
        children: "Enable \"Auto-create Instances\" in the flow settings."
      }), "\n", _jsxs(_components.li, {
        children: ["Create a ", _jsx(DocLink, {
          path: "engine/deployments/flow-instances",
          children: "Customer Instance"
        }), " of this flow."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "To create a Customer Instance for a given connection, do the following:"
    }), "\n", _jsx(CodeExample.CreateFlowInstance, {})]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

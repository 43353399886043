import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'

import {
  MenuItemType,
  isMenuItemLinkType,
  MenuItemLinkType,
  MenuItemHeaderType,
} from 'routes/Docs/types'
import { getDocMenu } from 'routes/Docs/components/DocsMenu/helpers'

import classes from './Menu.module.css'
import { useRouter } from 'next/router'

export function DocsMenu() {
  const { pathname } = useLocation()
  const { route } = useRouter()

  const isDocInWorkspace = route.startsWith('/w/[workspaceId]')

  const clearPathname = pathname
    .split('/')
    .slice(isDocInWorkspace ? 2 : 1) // Remove leading slash or `/docs/`
    .join('/')

  const docMenu = getDocMenu(clearPathname)

  // This is a pointer to menu item
  const engineMenuPointer = docMenu.find(
    (item) => (item as any).path === 'engine',
  )
  const engineReferencesMenuPointer = engineMenuPointer?.links?.find(
    (item) => (item as any).title === 'References',
  )

  engineReferencesMenuPointer?.links?.push(
    ...[
      {
        to: '/ref/sdk/index.html',
        title: 'SDK',
        isExternalLink: true,
      },
      {
        to: '/ref/react/index.html',
        title: 'React Library',
        isExternalLink: true,
      },
      {
        to: 'https://api-reference.integration.app',
        title: 'REST API',
        isExternalLink: true,
      },
    ],
  )

  return <Menu className={classes.menu} items={docMenu} />
}

function Menu({
  items,
  className,
}: {
  items?: MenuItemType[]
  className?: string
}) {
  if (!items?.length) {
    return null
  }
  return (
    <ul className={className}>
      {items.map((item, i) => (
        <MenuItem item={item} key={i} />
      ))}
    </ul>
  )
}

function MenuItem({ item }: { item: MenuItemType }) {
  const [isExpand, setIsExpand] = useState(item.isExpand ?? false)

  useEffect(() => {
    setIsExpand(item.isExpand ?? false)
  }, [item.isExpand])

  const isExpandable = (item.links?.length || 0) > 0

  const toggleExpand = () => {
    if (!isExpandable) return
    setIsExpand(!isExpand)
  }

  return (
    <li>
      <span className={classes.menuItem}>
        {isMenuItemLinkType(item) ? (
          <MenuItemLink item={item} />
        ) : (
          <MenuItemHeader onToggleClick={toggleExpand} item={item} />
        )}
        {isExpandable && <Chevron isExpand={isExpand} onClick={toggleExpand} />}
      </span>
      {isExpandable && isExpand && <Menu items={item.links} />}
    </li>
  )
}

function MenuItemLink({ item }: { item: MenuItemLinkType }) {
  if (item.isExternalLink) {
    return (
      <a
        className={clsx(
          classes.menuItem_title,
          classes.menuItem_title__external,
        )}
        href={item.to}
        rel='noreferrer'
      >
        {item.title} <SvgIcon type={SvgIconType.ExternalLink} />
      </a>
    )
  }
  return (
    <NavLink
      className={clsx(
        classes.menuItem_title,
        item.isActive && classes.menuItem_title__active,
        item.links?.length && classes.menuItem_title__expandable,
      )}
      to={item.to}
    >
      {item.title}
    </NavLink>
  )
}

function MenuItemHeader({
  item,
  onToggleClick,
}: {
  item: MenuItemHeaderType
  onToggleClick: () => void
}) {
  const navigate = useNavigate()
  const isExpandable = item.links?.length

  function handleClick() {
    if (!isExpandable) {
      return
    }

    // If has active child, toggle expand section
    if (itemHasActiveChild(item)) {
      return onToggleClick()
    }

    // If has no active child, navigate to first child
    const firstChildLink = item?.links?.find((item) =>
      isMenuItemLinkType(item),
    ) as MenuItemLinkType
    !!firstChildLink && void navigate(firstChildLink.to)
  }

  return (
    <span
      className={clsx(
        classes.menuItem_title,
        isExpandable && classes.menuItem_title__expandable,
      )}
      onClick={handleClick}
    >
      {item.title}
    </span>
  )
}

function Chevron({
  isExpand,
  onClick,
}: {
  isExpand?: boolean
  onClick: () => void
}) {
  return (
    <button onClick={onClick} className={classes.chevronButton}>
      <SvgIcon
        type={isExpand ? SvgIconType.ChevronDown : SvgIconType.ChevronRight}
        className={classes.chevronButton_icon}
      />
    </button>
  )
}

function itemHasActiveChild(item: MenuItemType) {
  return item.links?.some((item) => item.isActive || itemHasActiveChild(item))
}

import { UNIFIED_DATA_MODELS } from '@integration-app/sdk'
import ComboBox from '@integration-app/ui/ComboBox'
import { ConstantOptionFactory } from '@integration-app/ui/ComboBox/options-factories/constant'
import { ValueType } from '@integration-app/ui/ComboBox/value-spec'
import { ComboBoxTag } from '@integration-app/ui/ComboBoxElements/ComboBoxTag'
import { Cell, Row } from '@integration-app/ui/DataBuilderUI'
import TagPlaceholder from '@integration-app/ui/Tag/TagPlaceholder'
import { capitalize } from '@integration-app/ui/helpers/capitalize'

const valueSpec = {
  type: ValueType.CUSTOM,
  allowCustom: false,
}

const optionFactories = [
  new ConstantOptionFactory(
    Object.keys(UNIFIED_DATA_MODELS).map((u) => ({
      label: capitalize(u),
      value: u,
    })),
  ),
]

export default function UdmSelect({
  value,
  onChange,
}: {
  value: string | undefined
  onChange: (value: string | null) => void
}) {
  return (
    <Row>
      <Cell.Name>Data Model</Cell.Name>
      <Cell.Combobox grow>
        <ComboBox
          value={value}
          onChange={onChange}
          valueSpec={valueSpec}
          optionFactories={optionFactories}
          valueComponent={<UdmSelectValueComponent value={value} />}
        />
      </Cell.Combobox>
    </Row>
  )
}

function UdmSelectValueComponent({ value }) {
  return value ? (
    <ComboBoxTag>{capitalize(value)}</ComboBoxTag>
  ) : (
    <TagPlaceholder>Select Universal Data Model</TagPlaceholder>
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Actions",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Create a Universal Action",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Apply Universal Action to Relevant Integrations",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Customize integration-specific Actions",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Test Actions",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Let your customers configure Actions",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Execute Actions",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Use Actions in Flows",
    "attributes": {},
    "children": []
  }]
}];
import {ACTIONS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const DESCRIPTION = 'Executing individual actions in external applications.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ol: "ol",
    li: "li",
    h2: "h2",
    img: "img",
    ul: "ul"
  }, _provideComponents(), props.components), {Link, DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "actions",
      children: "Actions"
    }), "\n", _jsx(_components.p, {
      children: "Action represents a single request or query your application wants to make in an external application. For example, \"Create a task\", \"Get list of users\", or \"Send a message\"."
    }), "\n", _jsx(_components.p, {
      children: "Typically you use actions in the following way:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsx(_components.li, {
        children: "Create a Universal Action that works across multiple external apps."
      }), "\n", _jsx(_components.li, {
        children: "Apply it to relevant integrations."
      }), "\n", _jsx(_components.li, {
        children: "(optional) Adjust integration-specific actions if default implementation doesn't work for you."
      }), "\n", _jsx(_components.li, {
        children: "(optional) Build UI to let your customers configure their Actions."
      }), "\n", _jsx(_components.li, {
        children: "Run actions via front-end SDK or Rest API."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "create-a-universal-action",
      children: "Create a Universal Action"
    }), "\n", _jsx(_components.p, {
      children: "Universal Actions let you use the same code and UI to run similar actions across multiple external applications.\nThey provide a standard interface as well as default implementation for each integration you apply them to."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/universal-actions.png",
      alt: "Universal Actions"
    }), "\n", _jsxs(_components.p, {
      children: ["To create a Universal Action, go to the ", _jsx(Link, {
        path: ACTIONS_ROUTE,
        children: "Actions"
      }), " page."]
    }), "\n", _jsx(_components.p, {
      children: "An action is defined by:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Input schema - ", _jsx(DocLink, {
          path: "engine/references/data-schemas",
          children: "Data Schema"
        }), " of any data you want to provide to the action as an input."]
      }), "\n", _jsx(_components.li, {
        children: "Action Type - type of action you want to create. It will be used to automatically generate integration-specific actions whenever possible. You can skip this step and configure the action for each integration separately."
      }), "\n", _jsx(_components.li, {
        children: "Configuration depending on the action type."
      }), "\n", _jsx(_components.li, {
        children: "Output mapping if you want to get the response in a different format than provided by default."
      }), "\n", _jsx(_components.li, {
        children: "Output schema - a data schema of the response you expect from the action. It is automatically determined from the implementation, but you can change it if you want."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Input and output schema of the universal action will act as a guide for integration-specific actions."
    }), "\n", _jsx(_components.h2, {
      id: "apply-universal-action-to-relevant-integrations",
      children: "Apply Universal Action to Relevant Integrations"
    }), "\n", _jsx(_components.p, {
      children: "After you created a Universal Action, you can apply it to every application you want to use it with."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/apply-universal-action.png",
      alt: "Apply Universal Action"
    }), "\n", _jsx(_components.p, {
      children: "In many cases, this will produce a working implementation for each application by default. In some cases you will need to manually adjust or create an integration-specific action.\nYou will know when you need to do it when you see a \"missing configuration\" indicator near integration-specific action."
    }), "\n", _jsx(_components.p, {
      children: "You should always keep input and output of integration-specific actions match input and output of the universal action.\nThis will let you use the same code and UI for working with the action regardless of which application they are executed against."
    }), "\n", _jsx(_components.h2, {
      id: "customize-integration-specific-actions",
      children: "Customize integration-specific Actions"
    }), "\n", _jsx(_components.p, {
      children: "If you want to change the default behavior of the action for a given external app, you can do it by editing the integration-specific action."
    }), "\n", _jsx(_components.p, {
      children: "You can edit any aspect of integration-specific action except its input schema - it needs to be consistent across all implementations of the universal action.\nWe also highly recommend to have output schema consistent between all implementations of the universal action, but it is not enforced."
    }), "\n", _jsx(_components.h2, {
      id: "test-actions",
      children: "Test Actions"
    }), "\n", _jsx(_components.p, {
      children: "You can test an action you created against any integration by creating a test connection and running the action against it."
    }), "\n", _jsx(_components.p, {
      children: "You can do it on the \"Test\" tab of the Action page."
    }), "\n", _jsx(_components.h2, {
      id: "let-your-customers-configure-actions",
      children: "Let your customers configure Actions"
    }), "\n", _jsx(_components.p, {
      children: "To let your customers configure actions for the use case, you can use user-customizeable integration elements:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/blueprints/data-sources"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/blueprints/field-mappings"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/blueprints/app-data-schemas"
        }), "\n"]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Depending on the type of the action, you can enable them by turning on \"Use Field Mapping\" or \"Use Data Source\" toggle in the action configuration:"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/use-shared-data-source.png",
      alt: "Use Shared Data Source"
    }), "\n", _jsxs(_components.p, {
      children: ["After adding these elements to the Action configuration, see ", _jsx(DocLink, {
        path: "ux",
        children: "UI"
      }), " section of the docs to learn how to use or build customization UI inside your app."]
    }), "\n", _jsx(_components.h2, {
      id: "execute-actions",
      children: "Execute Actions"
    }), "\n", _jsx(_components.p, {
      children: "To run actions, you can use our front-end SDK or Rest API. s"
    }), "\n", _jsxs(_components.p, {
      children: ["See more here: ", _jsx(DocLink, {
        path: "engine/deployments/action-instances"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "use-actions-in-flows",
      children: "Use Actions in Flows"
    }), "\n", _jsxs(_components.p, {
      children: ["After you created an action, you can re-use it in ", _jsx(DocLink, {
        path: "engine/blueprints/flows",
        children: "Flows"
      }), " with the help of the \"Run Action\" node."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

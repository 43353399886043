import { getArticle } from 'routes/Docs/helpers/articleList'
import { NavLink } from 'react-router-dom'
import { PropsWithChildren } from 'react'
import { getDocRelativeLink } from 'components/Docs/DocLinksGrid/helpers'
import { DocTagBadge } from './DocTag'

export default function DocLink({
  path,
  showTag,
  children,
  className,
}: PropsWithChildren<{
  path: string
  showTag?: boolean
  className?: string
}>) {
  const to = getDocRelativeLink(path)

  return (
    <NavLink to={to} className={className}>
      {children || getArticle(path)?.title}
      {showTag && <DocTagBadge />}
    </NavLink>
  )
}

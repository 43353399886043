import { CustomizeImplementation } from './CustomizeImplementation'
import { DataCollectionMethodParametersConfig } from './DataCollectionMethodParametersConfig'
import { DataSourceUnitConfig } from './DataSourceUnitConfig'
import { ImportFieldMappingUnitConfig } from './ImportFieldMappingUnitConfig'
import { LookupQueryConfig } from './LookupQueryConfig'
import { useGenericConfig } from './contexts/generic-config-context'

export function MatchDataRecordUnitConfig() {
  const { dataCollectionSpec } = useGenericConfig()

  const apiRequests = dataCollectionSpec?.match?.apiRequests
  return (
    <>
      <DataSourceUnitConfig />

      <DataCollectionMethodParametersConfig methodKey='match' />

      <LookupQueryConfig />

      <ImportFieldMappingUnitConfig />

      <CustomizeImplementation apiRequests={apiRequests} />
    </>
  )
}

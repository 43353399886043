/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Connector Data",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "List Data Collections",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Get Data Collection Details",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Parameters",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Methods",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "find-by-id",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "list",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "search",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "match",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "create",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "update",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "delete",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Events",
    "attributes": {},
    "children": []
  }]
}];
import {CodeExample} from '../../../../../../../components/CodeExamples';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    h3: "h3",
    code: "code",
    pre: "pre",
    blockquote: "blockquote"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.DataCollectionCreate) _missingMdxReference("CodeExample.DataCollectionCreate", true);
  if (!CodeExample.DataCollectionDelete) _missingMdxReference("CodeExample.DataCollectionDelete", true);
  if (!CodeExample.DataCollectionFindById) _missingMdxReference("CodeExample.DataCollectionFindById", true);
  if (!CodeExample.DataCollectionList) _missingMdxReference("CodeExample.DataCollectionList", true);
  if (!CodeExample.DataCollectionMatch) _missingMdxReference("CodeExample.DataCollectionMatch", true);
  if (!CodeExample.DataCollectionSearch) _missingMdxReference("CodeExample.DataCollectionSearch", true);
  if (!CodeExample.DataCollectionUpdate) _missingMdxReference("CodeExample.DataCollectionUpdate", true);
  if (!CodeExample.GetConnectionDataCollection) _missingMdxReference("CodeExample.GetConnectionDataCollection", true);
  if (!CodeExample.GetIntegrationDataCollection) _missingMdxReference("CodeExample.GetIntegrationDataCollection", true);
  if (!CodeExample.ListIntegrationDataCollections) _missingMdxReference("CodeExample.ListIntegrationDataCollections", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "connector-data",
      children: "Connector Data"
    }), "\n", _jsxs(_components.p, {
      children: ["Data is represented as a set of Data Collections that contain ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/data/data-records",
        children: "Data Records"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Data collections provide useful abstraction on top of the underlying API. They let you do the following:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Get schema of data record fields, including custom fields configured for a particular connection."
      }), "\n", _jsx(_components.li, {
        children: "Perform CRUD operations on data records using a consistent API."
      }), "\n", _jsx(_components.li, {
        children: "Understand which operations are possible and not possible for a given collection."
      }), "\n", _jsx(_components.li, {
        children: "Subscribe to data change events even if webhooks are not supported by the underlying API."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "list-data-collections",
      children: "List Data Collections"
    }), "\n", _jsx(_components.p, {
      children: "To get a list of data collections provided by the connector, do the following:"
    }), "\n", _jsx(CodeExample.ListIntegrationDataCollections, {}), "\n", _jsx(_components.p, {
      children: "The result is a list of records with the following fields:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "key - unique identifier of the data location."
      }), "\n", _jsx(_components.li, {
        children: "name - human-readable name of the data location."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "get-data-collection-details",
      children: "Get Data Collection Details"
    }), "\n", _jsx(_components.p, {
      children: "To get detailed information of a specific collection, do the following:"
    }), "\n", _jsx(CodeExample.GetIntegrationDataCollection, {}), "\n", _jsx(_components.p, {
      children: "To get collection for a specific connection (with all the custom fields and other connection-specific information), do the following:"
    }), "\n", _jsx(CodeExample.GetConnectionDataCollection, {}), "\n", _jsx(_components.h3, {
      id: "parameters",
      children: "Parameters"
    }), "\n", _jsxs(_components.p, {
      children: ["Some Data Collections can be parametrized (if ", _jsx(_components.code, {
        children: "parametersSchema"
      }), " is provided in the specification)."]
    }), "\n", _jsx(_components.p, {
      children: "Parameters are needed to navigate complex data collections, for example:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Salesforce has ", _jsx(_components.code, {
          children: "objects"
        }), " collection, and you need to specify ", _jsx(_components.code, {
          children: "objectType"
        }), " parameter to work with it in a meaningful way."]
      }), "\n", _jsxs(_components.li, {
        children: ["GitHub has ", _jsx(_components.code, {
          children: "issues"
        }), " collection, but you need to select a repository before doing anything with it (including getting a structure of the issue fields)."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Adding parameters to a collection may change:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["List of records in the collection (for example, adding a parameter like ", _jsx(_components.code, {
          children: "projectId"
        }), " for a collection containing tasks)."]
      }), "\n", _jsxs(_components.li, {
        children: ["Fields schema for Data Records (for example, if ", _jsx(_components.code, {
          children: "projectId"
        }), " is added as a collection parameter, you can't add ", _jsx(_components.code, {
          children: "projectId"
        }), " field when creating a data record)."]
      }), "\n", _jsx(_components.li, {
        children: "Available methods (for example, an application an search for all tasks, but not for tasks in a specific project)."
      }), "\n", _jsx(_components.li, {
        children: "Method of retrieving events (for example, webhooks may be available only for a task in a specific project, but not for all tasks in the application)."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "In integration.app API, parameters are specified as query parameters to any request to a Data Collection like this:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "https://api.integration.app/connections/github/data/issues?repo=octocat/Hello-World\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "or this:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "https://api.integration.app/connections/github/data/issues/create?repo=octocat/Hello-World\n"
      })
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsx(_components.p, {
        children: "For most purposes, you can treat two collection with the same key but different parameters as two different collections."
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["To conveniently work with parametrized collections, we recommend using ", _jsx(DocLink, {
        path: "engine/blueprints/data-sources",
        children: "Data Sources"
      }), ".\nThey store collection parameters as a part of their configuration and automatically apply them to every request to a collection (among other convenient things)."]
    }), "\n", _jsx(_components.h2, {
      id: "methods",
      children: "Methods"
    }), "\n", _jsx(_components.p, {
      children: "Collections may provide any number of the methods listed below to perform actions on its data records."
    }), "\n", _jsx(_components.h3, {
      id: "find-by-id",
      children: "find-by-id"
    }), "\n", _jsx(_components.p, {
      children: "Returns one data record by its id."
    }), "\n", _jsx(CodeExample.DataCollectionFindById, {}), "\n", _jsx(_components.p, {
      children: "Input:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "id"
        }), " - id of the record to return."]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "list",
      children: "list"
    }), "\n", _jsxs(_components.p, {
      children: ["Get a list of data records. For large collections, the results are paginated. In such cases, ", _jsx(_components.code, {
        children: "cursor"
      }), " is returned in the response to retrieve the next page."]
    }), "\n", _jsx(CodeExample.DataCollectionList, {}), "\n", _jsx(_components.p, {
      children: "Input:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "cursor"
        }), " - cursor returned from the previous page of the results. If not provided, the first page is returned."]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "search",
      children: "search"
    }), "\n", _jsx(_components.p, {
      children: "Search records by a substring. Supports type-ahead whenever underlying API supports it."
    }), "\n", _jsx(CodeExample.DataCollectionSearch, {}), "\n", _jsx(_components.p, {
      children: "Input:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "query"
        }), " - a string to search by."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "cursor"
        }), " - cursor returned from the previous page of the results. If not provided, the first page is returned."]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "match",
      children: "match"
    }), "\n", _jsx(_components.p, {
      children: "Returns a single record that matches provided fields. Uses whichever matching algorithm is supported by the underlying API."
    }), "\n", _jsx(CodeExample.DataCollectionMatch, {}), "\n", _jsx(_components.p, {
      children: "Input:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "fields"
        }), " - fields to match by. You can find the list of fields that can be used for matching in the collection specification."]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "create",
      children: "create"
    }), "\n", _jsx(_components.p, {
      children: "Creates a record in the collection and returns its id."
    }), "\n", _jsx(CodeExample.DataCollectionCreate, {}), "\n", _jsx(_components.p, {
      children: "Input:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "fields"
        }), " - fields of the record to create. Must be present in the ", _jsx(_components.code, {
          children: "fieldsSchema"
        }), " of the collection. You can find the list of fields that can be used to create a record in the collection specification."]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "update",
      children: "update"
    }), "\n", _jsx(_components.p, {
      children: "Updates one record by its id."
    }), "\n", _jsx(CodeExample.DataCollectionUpdate, {}), "\n", _jsx(_components.p, {
      children: "Input:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "id"
        }), " - id of the record to update"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "fields"
        }), " - fields of the record to update. Must be present in the ", _jsx(_components.code, {
          children: "fieldsSchema"
        }), " of the collection. You can find the list of fields that can be used to create a record in the collection specification."]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "delete",
      children: "delete"
    }), "\n", _jsx(_components.p, {
      children: "Deletes one record by its id."
    }), "\n", _jsx(CodeExample.DataCollectionDelete, {}), "\n", _jsx(_components.p, {
      children: "Input:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "id"
        }), " - id of the record to delete."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "events",
      children: "Events"
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsxs(_components.p, {
        children: ["We recommend using ", _jsx(DocLink, {
          path: "engine/blueprints/data-sources",
          children: "Data Sources"
        }), " to work with data collection events.\nThey provide much simpler and consistent interface than working with data collections directly."]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import SchemaBuilder from '@integration-app/ui/SchemaBuilder'
import { useGenericConfig } from './contexts/generic-config-context'

export function HttpResponseConfig() {
  const { config, patchConfig } = useGenericConfig()

  return (
    <div>
      <h3>Response Schema</h3>

      <p>What to expect from this request</p>

      <SchemaBuilder
        schema={config.responseSchema}
        isObject={false}
        onChange={(responseSchema) => patchConfig({ responseSchema })}
      />
    </div>
  )
}

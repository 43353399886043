import classes from './CallOut.module.css'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { ReactNode } from 'react'

interface CallOutProps {
  children: ReactNode
  type?: 'info' | 'warning' | 'error'
}

export default function CallOut({ children, type = 'info' }: CallOutProps) {
  return (
    <blockquote className={classes.wrapper}>
      <SvgIcon className={classes.icon} type={getIconByType(type)} />
      <div className={classes.content}>{children}</div>
    </blockquote>
  )
}

function getIconByType(type: CallOutProps['type']) {
  switch (type) {
    case 'info':
      return SvgIconType.InfoCircle
    case 'warning':
      return SvgIconType.AlertCircle
    case 'error':
      return SvgIconType.AlertTriangle
    default:
      return SvgIconType.InfoCircle
  }
}

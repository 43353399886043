import { NodeBodyContainer } from './NodeBody/NodeBodyContainer'
import { SourceHandle } from './Handle/Handle'

export function RootNode() {
  return (
    <NodeBodyContainer>
      <div />
      <SourceHandle />
    </NodeBodyContainer>
  )
}

import { UNIFIED_DATA_MODELS, schemaWithTitle } from '@integration-app/sdk'
import { CollapsibleSection } from '../../Section'
import { useGenericConfig } from '../contexts/generic-config-context'

import { SimpleRadio } from '@integration-app/ui'
import { toHeaderCase } from 'js-convert-case'
import { IncludeRawFieldsBlock } from './IncludeRawFieldsBlock'
import { SharedImportFieldMapping } from './SharedImportFieldMapping'
import { CustomImportFieldMapping } from './CustomImportFieldMapping'

enum ImportFieldMappingUnitConfigOption {
  None = 'none',
  Udm = 'udm',
  Custom = 'custom',
  Shared = 'shared',
}

export function UniversalImportFieldMappingUnitConfig() {
  const { patchConfig, patchFieldMappingConfig, fieldMappingConfig, udm } =
    useGenericConfig()

  async function handleSelectOption(
    option: ImportFieldMappingUnitConfigOption,
  ) {
    switch (option) {
      case ImportFieldMappingUnitConfigOption.None:
        return patchConfig({
          fieldMapping: undefined,
        })
      case ImportFieldMappingUnitConfigOption.Udm:
        return patchConfig({
          fieldMapping: {
            defaultUnifiedValue: {
              $var: '$',
            },
          },
        })
      case ImportFieldMappingUnitConfigOption.Custom:
        let newValue =
          fieldMappingConfig.defaultUnifiedValue ??
          fieldMappingConfig.default ??
          {}
        if (newValue.$var === '$') {
          newValue = {}
        }
        return patchConfig({
          fieldMapping: {
            defaultUnifiedValue: newValue,
          },
        })
      case ImportFieldMappingUnitConfigOption.Shared:
        return patchConfig({
          fieldMapping: {
            key: null,
          },
        })
    }
  }

  const externalFieldsSchema = udm
    ? schemaWithTitle(
        UNIFIED_DATA_MODELS[udm]?.fieldsSchema,
        'Universal Fields',
      )
    : undefined

  const fields =
    fieldMappingConfig?.defaultUnifiedValue ?? fieldMappingConfig?.default // fallback to `default` for backward compatibility

  const fieldsSchema = {
    type: 'object',
    additionalProperties: true,
  }

  let selectedOption = ImportFieldMappingUnitConfigOption.None

  if (fields !== undefined) {
    if (fields.$var === '$') {
      selectedOption = ImportFieldMappingUnitConfigOption.Udm
    } else {
      selectedOption = ImportFieldMappingUnitConfigOption.Custom
    }
  }

  if (fieldMappingConfig?.key !== undefined) {
    selectedOption = ImportFieldMappingUnitConfigOption.Shared
  }

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Field Mapping</CollapsibleSection.Header>

      <p className='mb-4'>
        Configure the contents of the <code>fields</code> property of data
        records returned by this action.
      </p>

      {udm && UNIFIED_DATA_MODELS[udm] && (
        <SimpleRadio
          label={
            <>
              Map to a standard fields schema for{' '}
              {toHeaderCase(UNIFIED_DATA_MODELS[udm]?.pluralName)}
              <a
                className='link ml-2'
                href={`/docs/engine/references/udm#${udm}`}
                target='_blank'
              >
                See schema ↗
              </a>
            </>
          }
          checked={selectedOption === ImportFieldMappingUnitConfigOption.Udm}
          onCheck={() =>
            handleSelectOption(ImportFieldMappingUnitConfigOption.Udm)
          }
        />
      )}
      <SimpleRadio
        label='Map to a custom schema'
        checked={selectedOption === ImportFieldMappingUnitConfigOption.Custom}
        onCheck={() =>
          handleSelectOption(ImportFieldMappingUnitConfigOption.Custom)
        }
      />
      <SimpleRadio
        label='Use shared field mapping config'
        checked={selectedOption === ImportFieldMappingUnitConfigOption.Shared}
        onCheck={() =>
          handleSelectOption(ImportFieldMappingUnitConfigOption.Shared)
        }
      />
      <SimpleRadio
        label='Keep original fields returned from a connector'
        checked={selectedOption === ImportFieldMappingUnitConfigOption.None}
        onCheck={() =>
          handleSelectOption(ImportFieldMappingUnitConfigOption.None)
        }
      />

      {selectedOption === ImportFieldMappingUnitConfigOption.Shared && (
        <SharedImportFieldMapping />
      )}

      {selectedOption === ImportFieldMappingUnitConfigOption.Custom && (
        <CustomImportFieldMapping
          value={
            fieldMappingConfig?.defaultUnifiedValue ??
            fieldMappingConfig?.default
          }
          onChange={(defaultUnifiedValue) =>
            patchFieldMappingConfig({ defaultUnifiedValue })
          }
          internalFieldsSchema={fieldsSchema}
          externalFieldsSchema={externalFieldsSchema}
        />
      )}

      {selectedOption !== ImportFieldMappingUnitConfigOption.None && (
        <IncludeRawFieldsBlock />
      )}
    </CollapsibleSection>
  )
}

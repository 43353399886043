/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Customer-level Integrations",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Customers",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Connections",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Instances",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Configuring Deployments",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Customer-level Integrations';
export const DESCRIPTION = 'Applying integration logic to customers connections to external apps.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "customer-level-integrations",
      children: "Customer-level Integrations"
    }), "\n", _jsx(_components.p, {
      children: "Before integrations can run, you need to connect your customers' accounts in external apps and deploy integration logic to them."
    }), "\n", _jsx(_components.h2, {
      id: "customers",
      children: "Customers"
    }), "\n", _jsx(_components.p, {
      children: "Customers represent people or groups of people who use your application."
    }), "\n", _jsxs(_components.p, {
      children: ["Integration Engine adds customers automatically as soon as a new Customer Id is seen in an\n", _jsx(DocLink, {
        path: "overview/getting-started/authentication",
        children: "Access Token"
      }), " used to access its API."]
    }), "\n", _jsxs(_components.p, {
      children: ["See ", _jsx(DocLink, {
        path: "engine/deployments/customers"
      }), " for more details."]
    }), "\n", _jsx(_components.p, {
      children: "Each customer can have multiple connections to external applications."
    }), "\n", _jsx(_components.h2, {
      id: "connections",
      children: "Connections"
    }), "\n", _jsx(_components.p, {
      children: "Connections provide access to your customers' accounts in external applications. Integration Engine stores and refreshes the customer's credentials in a Connection object."
    }), "\n", _jsxs(_components.p, {
      children: ["To create a connection from inside your app, see ", _jsx(DocLink, {
        path: "ux/connection"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["For more information on connections, see ", _jsx(DocLink, {
        path: "engine/deployments/connections"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "instances",
      children: "Instances"
    }), "\n", _jsx(_components.p, {
      children: "Before using integrations (running actions or flows, configuring field mappings, etc), you need to deploy their instances to a given connection."
    }), "\n", _jsx(_components.p, {
      children: "During the deployment, integration engine performs necessary steps to make the integrations work in the context of the connection:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Subscribes to webhooks."
      }), "\n", _jsx(_components.li, {
        children: "Saves initial states of elements."
      }), "\n", _jsx(_components.li, {
        children: "Fetches connection-specific field schemas."
      }), "\n", _jsx(_components.li, {
        children: "..."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "configuring-deployments",
      children: "Configuring Deployments"
    }), "\n", _jsxs(_components.p, {
      children: ["To add UI for configuring or running integrations into your app, check out the ", _jsx(DocLink, {
        path: "ux",
        children: "UX"
      }), " section of the docs."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "API Client",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Handling Responses",
    "attributes": {},
    "children": []
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "api-client",
      children: "API Client"
    }), "\n", _jsx(_components.p, {
      children: "You can configure the following settings:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "baseUri - it will be added to all relative requests to the API (requests that don't contain https:// at the start)."
      }), "\n", _jsx(_components.li, {
        children: "headers - headers that will be added to every request to the API."
      }), "\n", _jsx(_components.li, {
        children: "query - additional query parameters to be added to all API requests."
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Alternatively, you can provide a custom Javascript function that receives ", _jsx(_components.code, {
        children: "credentials"
      }), " in the input parameters and\nreturns an API client that have ", _jsx(_components.code, {
        children: "get"
      }), ", ", _jsx(_components.code, {
        children: "post"
      }), ", ", _jsx(_components.code, {
        children: "put"
      }), ", ", _jsx(_components.code, {
        children: "patch"
      }), ", and ", _jsx(_components.code, {
        children: "delete"
      }), " methods. To see an example of the custom client,\nselect \"Javascript\" implementation type and example code will be auto-populated."]
    }), "\n", _jsx(_components.h2, {
      id: "handling-responses",
      children: "Handling Responses"
    }), "\n", _jsx(_components.p, {
      children: "By default, API client will handle responses using the following logic:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "status >= 200 && status < 300 - return response."
      }), "\n", _jsx(_components.li, {
        children: "status == 401 - throw ACCESS_TOKEN_EXPIRED error that will trigger an attempt to refresh credentials (if possible)."
      }), "\n", _jsx(_components.li, {
        children: "status == 429 - throw RATE_LIMIT_EXCEEDED error that will lead to retrying the request with exponential backoff until timeout is reached."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "You can add or override this logic by providing a list of response handlers. Each handler consists of:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "A filter that checks response status, headers, or data and returns true if the handler should be applied."
      }), "\n", _jsx(_components.li, {
        children: "A flag that indicates whether it is a successful request or an error should be thrown."
      }), "\n", _jsx(_components.li, {
        children: "Data that will be returned if request is successful (by default, the response body will be returned)."
      }), "\n", _jsx(_components.li, {
        children: "Error that will be thrown if the request is not successful."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;

import { Edge, Node } from 'reactflow'

export const NODE_WIDTH = 265
export const NODE_HEIGHT = 52
export const NODE_STACK_ITEM_HEIGHT = 25

export const EDGE_HANDLE_OFFSET = 5

export const GRAPH_NODE_STYLES = {
  width: NODE_WIDTH,
  height: NODE_HEIGHT + 2, // -2 to account for the border
}

export type GNode = Node
export type GEdge = Edge

import { NodeProps } from 'reactflow'
import { RegularNodeData } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Build/Graph/elements/RegularNode/RegularNode'
import {
  SourceHandle,
  TargetHandle,
} from 'components/FlowBuilder/Graph/elements/Handle/Handle'
import { NodeBodyContainer } from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBodyContainer'
import { TriggerNodeData } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Build/Graph/elements/TriggerNode/TriggerNode'
import {
  getNodeBodyTypeFromGraphElementType,
  NodeBody,
} from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBody'
import { calculateNodeStackItems } from '../../../../FlowBuilder/Graph/elements/NodeBody/NodeStackItems/calculate'

export function RegularOrTriggerNode(
  props: NodeProps<RegularNodeData | TriggerNodeData>,
) {
  const { flowNode, flowNodeKey } = props.data

  const type = getNodeBodyTypeFromGraphElementType(props.type)

  const stackItems = calculateNodeStackItems(flowNode)

  return (
    <NodeBodyContainer>
      <TargetHandle />

      <NodeBody
        flowNodeKey={flowNodeKey}
        flowNode={flowNode}
        type={type}
        onClick={() => {}}
        isSelected={false}
        isHovered={false}
        onNodeHover={() => {}}
        stackItems={stackItems}
      />

      <SourceHandle />
    </NodeBodyContainer>
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "AI-Ready Metadata",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Connector Specification",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Operations",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Data Model",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "List Data Collections",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Data Collection Specification",
      "attributes": {},
      "children": []
    }]
  }]
}];
import {CodeExample} from '../../../../../../components/CodeExamples';
export const TITLE = 'AI-Ready Metadata';
export const DESCRIPTION = 'Understanding what is possible in a given integration in a consistent way.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.GetConnectionDataCollection) _missingMdxReference("CodeExample.GetConnectionDataCollection", true);
  if (!CodeExample.GetConnectorSpec) _missingMdxReference("CodeExample.GetConnectorSpec", true);
  if (!CodeExample.GetIntegrationDataCollection) _missingMdxReference("CodeExample.GetIntegrationDataCollection", true);
  if (!CodeExample.GetOperation) _missingMdxReference("CodeExample.GetOperation", true);
  if (!CodeExample.GetOperationsList) _missingMdxReference("CodeExample.GetOperationsList", true);
  if (!CodeExample.ListIntegrationDataCollections) _missingMdxReference("CodeExample.ListIntegrationDataCollections", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "ai-ready-metadata",
      children: "AI-Ready Metadata"
    }), "\n", _jsx(_components.p, {
      children: "You can use metadata lets you understand external applications in a consistent way and adjust your integrations to each of them automatically."
    }), "\n", _jsx(_components.p, {
      children: "Metadata includes things like:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "How to authenticate with the application."
      }), "\n", _jsx(_components.li, {
        children: "What operations does this integration support."
      }), "\n", _jsx(_components.li, {
        children: "What data collections does it have."
      }), "\n", _jsx(_components.li, {
        children: "What are the fields, their types and possible values in a given data collection."
      }), "\n", _jsx(_components.li, {
        children: "What operations are supported by a given data collection."
      }), "\n", _jsx(_components.li, {
        children: "etc"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "You can get metadata on two levels: integration and connection. Connection-level metadata will be more detailed and include information about the specific customer setup: custom fields, custom objects, etc."
    }), "\n", _jsx(_components.h2, {
      id: "connector-specification",
      children: "Connector Specification"
    }), "\n", _jsx(_components.p, {
      children: "To understand basic information about a given integration, you can fetch its connector specification:"
    }), "\n", _jsx(CodeExample.GetConnectorSpec, {}), "\n", _jsxs(_components.p, {
      children: ["Read more about data collections here: ", _jsx(DocLink, {
        path: "engine/external-apps/connectors",
        children: "Connectors"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "operations",
      children: "Operations"
    }), "\n", _jsx(_components.p, {
      children: "To understand what operations are supported by a given integration, do the following:"
    }), "\n", _jsx(CodeExample.GetOperationsList, {}), "\n", _jsx(_components.p, {
      children: "To get information about a single operation, do this:"
    }), "\n", _jsx(CodeExample.GetOperation, {}), "\n", _jsxs(_components.p, {
      children: ["Read more about data collections here: ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/operations",
        children: "Connector Operations"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "data-model",
      children: "Data Model"
    }), "\n", _jsx(_components.p, {
      children: "To understand a data model for a given integration or connection, you can do the following."
    }), "\n", _jsx(_components.h3, {
      id: "list-data-collections",
      children: "List Data Collections"
    }), "\n", _jsx(_components.p, {
      children: "To list all data collections available in a given integration or connection, do the following:"
    }), "\n", _jsx(CodeExample.ListIntegrationDataCollections, {}), "\n", _jsx(_components.h3, {
      id: "data-collection-specification",
      children: "Data Collection Specification"
    }), "\n", _jsx(_components.p, {
      children: "To understand a data collection in a given integration (default for all connections), do the following:"
    }), "\n", _jsx(CodeExample.GetIntegrationDataCollection, {}), "\n", _jsx(_components.p, {
      children: "To get a connection-specific collection metadata, do the following:"
    }), "\n", _jsx(CodeExample.GetConnectionDataCollection, {}), "\n", _jsxs(_components.p, {
      children: ["Read more about data collections here: ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/data",
        children: "Connector Data"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

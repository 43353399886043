/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Data Sources",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Universal and Integration-specific Data Sources",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Customer Instances",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Navigating Data Source",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Data Collection Specification",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Reading and Writing Data",
    "attributes": {},
    "children": []
  }]
}];
import {DocGrid} from "components/Docs/DocLinksGrid";
export const DESCRIPTION = 'Working with data in external apps.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2",
    img: "img",
    code: "code"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "data-sources",
      children: "Data Sources"
    }), "\n", _jsxs(_components.p, {
      children: ["Data Sources work on top of ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/data",
        children: "Data Collections"
      }), " and provide advanced functionality, such as:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Ability for your customers to choose which data collection to use and configure its parameters."
      }), "\n", _jsx(_components.li, {
        children: "Share these settings between different actions and flows: they will be updated in all places simultaneously."
      }), "\n", _jsx(_components.li, {
        children: "Provide dynamic schemas for Field Mappings."
      }), "\n", _jsx(_components.li, {
        children: "Subscribe to data change events even if webhooks are not available."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "universal-and-integration-specific-data-sources",
      children: "Universal and Integration-specific Data Sources"
    }), "\n", _jsx(_components.p, {
      children: "When you want to work with similar data in multiple applications, create a universal data source."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/universal-data-sources.png",
      alt: "Universal Data Sources"
    }), "\n", _jsxs(_components.p, {
      children: ["They let you use the same code and logic for any app you integrate with and\ncan be used in universal ", _jsx(DocLink, {
        path: "engine/blueprints/flows",
        children: "Flows"
      }), " and ", _jsx(DocLink, {
        path: "engine/blueprints/field-mappings",
        children: "Field Mappings"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["For example, \"Users\" or \"Tasks\" will most often be universal data sources. In most cases, you would select\na ", _jsx(DocLink, {
        path: "engine/references/udm",
        children: "Universal Data Model"
      }), " for a Data Source to make it automatically\nmap to the right data in each external application."]
    }), "\n", _jsx(_components.p, {
      children: "If you want to work with data that is specific to a particular application, create an integration-specific data source.\nFor example, \"Slack Canvases\" or \"JIRA Workflows\" may be integration-specific data sources."
    }), "\n", _jsx(_components.p, {
      children: "You can customize universal data sources for each individual application after you create them.\nIt lets you work with data that is similar, but not exactly the same in each app."
    }), "\n", _jsx(_components.h2, {
      id: "customer-instances",
      children: "Customer Instances"
    }), "\n", _jsxs(_components.p, {
      children: ["To actually interact with data, you need to create a Data Source Instance for a specific ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connection"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Through a Data Source Instance, you can navigate data, get information about the collection, read and write data, and react to events."
    }), "\n", _jsxs(_components.p, {
      children: ["See more detail in ", _jsx(DocLink, {
        path: "engine/deployments/data-source-instances",
        children: "Data Source Instances"
      }), " article."]
    }), "\n", _jsx(_components.h2, {
      id: "navigating-data-source",
      children: "Navigating Data Source"
    }), "\n", _jsx(_components.p, {
      children: "Depending on an application, there may be different ways of getting data, for example:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Application may have multiple similar objects (Task and Subtasks, or Leads and Contacts)."
      }), "\n", _jsx(_components.li, {
        children: "There could be multiple lists of objects user may want to select."
      }), "\n", _jsx(_components.li, {
        children: "There could be custom objects user may use for a given integration."
      }), "\n"]
    }), "\n", _jsx("img", {
      src: "/images/docs/data-source-data-collections.jpg",
      style: {
        maxWidth: 600
      },
      title: "Data Source Data Collections"
    }), "\n", _jsxs(_components.p, {
      children: ["To deal with this, you can request a list of available data locations from a Data Source Instance and\nthen set its ", _jsx(_components.code, {
        children: "path"
      }), " field to point to a specific collection."]
    }), "\n", _jsxs(_components.p, {
      children: ["See ", _jsx(DocLink, {
        path: "engine/deployments/data-source-instances",
        children: "Navigating Data Source Instance"
      }), " article for details."]
    }), "\n", _jsx(_components.h2, {
      id: "data-collection-specification",
      children: "Data Collection Specification"
    }), "\n", _jsxs(_components.p, {
      children: ["When a Data Source Instance is pointing to a collection, you can use its ", _jsx(_components.code, {
        children: "collectionSpec"
      }), " property to get information about the collection:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "How do records look like (their fields schema with names, types, descriptions, and possible values)."
      }), "\n", _jsx(_components.li, {
        children: "What operations are supported (create, update, delete, etc)."
      }), "\n", _jsx(_components.li, {
        children: "What methods of receiving events are supported (real-time push, polling, etc.)."
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["For more detail, see ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/data",
        children: "Data Collection"
      }), " article."]
    }), "\n", _jsx(_components.h2, {
      id: "reading-and-writing-data",
      children: "Reading and Writing Data"
    }), "\n", _jsxs(_components.p, {
      children: ["See the ", _jsx(DocLink, {
        path: "engine/deployments/data-source-instances",
        children: "API"
      }), " docs for more detail."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

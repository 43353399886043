import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { SchemaBuilder } from '@integration-app/ui'

export function ApiTrigger() {
  const { config, patchConfig } = useGenericConfig()

  return (
    <div>
      <h3>Input Parameters</h3>

      <p>Data you will be launching the flow with when using API</p>

      <SchemaBuilder
        schema={config.inputSchema || { type: 'object' }}
        onChange={(inputSchema) => patchConfig({ inputSchema })}
      />
    </div>
  )
}

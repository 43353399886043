import { UNIFIED_DATA_MODELS } from '@integration-app/sdk'
import { Cell, Row, Table, TableWrapper } from '@integration-app/ui/Table'
import { capitalize } from '@integration-app/ui/helpers/capitalize'
import { SearchInput, useSearch } from 'components/SearchInput'
import { CreateDataSourceHeader } from 'routes/Workspaces/Workspace/Blueprints/DataSources/components/PopupCreateDataSourceForm/CreateDataSourceHeader'
import { Popup } from 'routes/components/Popup'
import classes from './PopupCreateDataSourceForm.module.css'

const UdmModelsOptions = Object.keys(UNIFIED_DATA_MODELS).map((u) => ({
  label: capitalize(u),
  value: u,
}))
const UdmOptions = [
  { label: 'Any Data', value: undefined },
  ...UdmModelsOptions,
]

export function StepSelectUdm({
  title,
  onBack,
  onSelect,
  integrationId,
  selectedUdm,
}: {
  title?: string
  onBack(): void
  onSelect(udm: string): void
  integrationId?: string
  selectedUdm?: string
}) {
  const { search, setSearch, filtered } = useSearch(UdmOptions, [
    'value',
    'label',
  ])

  return (
    <>
      <Popup.Header>
        <CreateDataSourceHeader
          title={title}
          onBack={onBack}
          integrationId={integrationId}
        />
        <p>Select a data model to initialize this data source with</p>
        <div className={classes.searchInputWrapper}>
          <SearchInput
            onChange={setSearch}
            value={search}
            placeholder={'Type to search UDM'}
          />
        </div>
      </Popup.Header>

      <TableWrapper>
        <Table>
          {filtered.map(({ label, value }) => (
            <UdmRow
              selectedUdm={selectedUdm}
              label={label}
              value={value}
              onClick={onSelect}
            />
          ))}
        </Table>
      </TableWrapper>
    </>
  )
}

function UdmRow({ selectedUdm, label, value, onClick }) {
  return (
    <Row highlighted={selectedUdm === value} onClick={() => onClick(value)}>
      <Cell className={'cursor-pointer'}>{label}</Cell>
    </Row>
  )
}

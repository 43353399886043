import {
  CommonItemComboboxSelect,
  ItemComboBoxGenericValue,
  ItemComboBoxGenericValuePlaceholder,
  ItemComboboxSelectProps,
} from './CommonItemComboboxSelect'
import { Flow } from '@integration-app/sdk'
import useIntegrationElement from '../hooks/useIntegrationElement'

export function DataSourceKeyComboboxSelect({
  value,
  onChange,
  editable = true,
  hasRightSibling = false,
}: ItemComboboxSelectProps<string>) {
  const { items } = useIntegrationElement<Flow>({
    route: 'data-sources',
  })

  const options = items?.map((ds) => ({
    value: ds.key,
    label: ds.name,
  }))

  const selectedOption = options?.find((o) => o.value === value)

  return (
    <CommonItemComboboxSelect<string>
      value={value}
      onChange={onChange}
      options={options}
      hasRightSibling={hasRightSibling}
      editable={editable}
      valueComponent={
        <ItemComboBoxGenericValue
          title={'Data Source:'}
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          value={selectedOption?.label}
        />
      }
      valuePlaceholderComponent={
        <ItemComboBoxGenericValuePlaceholder title={'Data Source:'} />
      }
    />
  )
}

import { SvgIconType } from '@integration-app/ui/SvgIcon'

export enum NodeStackItemType {
  DataSource = 'data-source',
  AppEventType = 'app-event-type',
  FieldMapping = 'field-mapping',
  DataLinkTable = 'data-link-table',
}

export interface INodeStackItem {
  id: string

  type: NodeStackItemType

  text: string

  icon: SvgIconType

  hasError?: boolean
}

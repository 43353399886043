/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Enabling and Disabling Flow Instances",
  "attributes": {},
  "children": []
}];
export const TITLE = 'Enable or Disable Flow Instance';
export const DESCRIPTION = 'Let your users decide whether to run this integration scenario or not.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code"
  }, _provideComponents(), props.components), {DocLink, CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.ToggleFlowInstanceEnabled) _missingMdxReference("CodeExample.ToggleFlowInstanceEnabled", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "enabling-and-disabling-flow-instances",
      children: "Enabling and Disabling Flow Instances"
    }), "\n", _jsxs(_components.p, {
      children: ["To enable or disable a specific ", _jsx(DocLink, {
        path: "engine/deployments/flow-instances",
        children: "Flow Instance"
      }), ", patch its ", _jsx(_components.code, {
        children: "enabled"
      }), " property:"]
    }), "\n", _jsx(CodeExample.ToggleFlowInstanceEnabled, {}), "\n", _jsx(_components.p, {
      children: "Flow Instances are created enabled by default."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

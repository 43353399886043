import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'
import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .operation('{OPERATION_KEY}')
  .run()`

const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/operations/{OPERATION_KEY}/run',
)

export function RunOperationCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        OPERATION_KEY: CodeParamType.String,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .operation(parameters.OPERATION_KEY)
          .run()
      }}
    />
  )
}

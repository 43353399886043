/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Formulas",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Variables",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "List of Formulas",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "$tpl",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$lookup",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$eval",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$and",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$or",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$case",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$map",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$iterate",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$firstName",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$lastName",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$copy",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$findExternalRecordId",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$findAppRecordId",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$jsonata",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$plain",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "$formula",
      "attributes": {},
      "children": []
    }]
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li",
    blockquote: "blockquote",
    a: "a"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "formulas",
      children: "Formulas"
    }), "\n", _jsxs(_components.p, {
      children: ["You can use formulas to dynamically generate values when building ", _jsx(DocLink, {
        path: "engine/blueprints/flows",
        children: "Flows"
      }), ",\nconfiguring ", _jsx(DocLink, {
        path: "engine/blueprints/field-mappings",
        children: "Field Mappings"
      }), ",\nand in many other places that require data based on variables."]
    }), "\n", _jsx(_components.p, {
      children: "Formulas look like this:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "fieldFromVariable:\n  $var: $.someVariable\nmappedField:\n  $map:\n    value: $.someVariable\n    mappings:\n      - from: From Value 1\n        to: To Value 2\n      - from: From Value 2\n        to: To Value 2\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "variables",
      children: "Variables"
    }), "\n", _jsx(_components.p, {
      children: "All formulas use variables to calculate. The simplest formula just references the variable:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "fieldFromVariable:\n  $var: $.someVariable\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.code, {
        children: "$.someVariable"
      }), " here is called a Locator. Locators reference a value within the variables structure."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can use ", _jsx(_components.code, {
        children: "."
      }), " to address a field of an object and ", _jsx(_components.code, {
        children: "[<idx>]"
      }), " to address an index in an array."]
    }), "\n", _jsxs(_components.p, {
      children: ["If fields of an object have ", _jsx(_components.code, {
        children: "."
      }), " or ", _jsx(_components.code, {
        children: "["
      }), " inside them, you can escape them with ", _jsx(_components.code, {
        children: "\\"
      }), ".\nFor example, ", _jsx(_components.code, {
        children: "$.dot\\.field.bracket\\[field[0]"
      }), " will point to the first element in the field ", _jsx(_components.code, {
        children: "bracket[field"
      }), " array inside the ", _jsx(_components.code, {
        children: "dot.field"
      }), " variable."]
    }), "\n", _jsx(_components.h2, {
      id: "list-of-formulas",
      children: "List of Formulas"
    }), "\n", _jsx(_components.h3, {
      id: "tpl",
      children: "$tpl"
    }), "\n", _jsx(_components.p, {
      children: "Generates a string with interpolated variables."
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "subject:\n  $tpl:\n    template: 'Hello, {firstName} {lastName}!'\n    values:\n      firstName:\n        $var: input.firstName\n      lastName:\n        $var: input.lastName\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "lookup",
      children: "$lookup"
    }), "\n", _jsx(_components.p, {
      children: "Lets you find a record in a related data collection or create one if it does not exist yet."
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "contactId:\n  $lookup:\n    query:\n      email: john.doe@example.com\n    createIfNotFound: true\n    create:\n      fields:\n        name: John Doe\n        email: john.doe@example.com\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "It has the following parameters:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "query"
        }), " - one or more fields used to find a matching related record."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "createIfNotFound"
        }), " - if set to ", _jsx(_components.code, {
          children: "true"
        }), ", a new record will be created if no matching record is found."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "create"
        }), "\n", _jsxs(_components.ul, {
          children: ["\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "fields"
            }), " - a set of fields to use when creating a new record."]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "eval",
      children: "$eval"
    }), "\n", _jsxs(_components.p, {
      children: ["This formula evaluates a value using one of supported operators and returns ", _jsx(_components.code, {
        children: "true"
      }), " or ", _jsx(_components.code, {
        children: "false"
      }), " depending on the result."]
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "condition:\n  $eval:\n    $var: status\n  is: Open\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "All $eval formulas have the same form:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "$eval: <value>\n<operator>: <operand>\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["This $eval formula will return ", _jsx(_components.code, {
        children: "true"
      }), " if the ", _jsx(_components.code, {
        children: "status"
      }), " variable has value ", _jsx(_components.code, {
        children: "Open"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Supported operators:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "is"
        }), " - value is equal to the operand."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "isNot"
        }), " - value is not equal to the operand"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "isEmpty"
        }), " - value is empty (null, undefined, empty string, empty array, object without keys). Operand is ignored."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "isNotEmpty"
        }), " - value is not empty. Operand is ignored."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "contains"
        }), " - value contains the operand. For strings, it checks if the string contains the provided substring. For arrays, it checks if the array contains the provided value."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "doesNotContain"
        }), " - value does not contain the operand."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "oneOf"
        }), " - value is one of the values in the operand array."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "noneOf"
        }), " - value is none of the values in the operand array."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "gt"
        }), " - value is greater than the operand."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "gte"
        }), " - value is greater than or equal to the operand."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "lt"
        }), " - value is less than operand."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "lte"
        }), " - value is less than or equal to the operand."]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "and",
      children: "$and"
    }), "\n", _jsx(_components.p, {
      children: "Returns true if all of its arguments are true"
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "condition:\n  $and:\n    - $eval:\n        $var: status\n      is: Open\n    - $var: isActive\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["It will resolve to ", _jsx(_components.code, {
        children: "true"
      }), " if ", _jsx(_components.code, {
        children: "status"
      }), " variable is set to ", _jsx(_components.code, {
        children: "Open"
      }), " and ", _jsx(_components.code, {
        children: "isActive"
      }), " variable is ", _jsx(_components.code, {
        children: "true"
      }), "."]
    }), "\n", _jsx(_components.h3, {
      id: "or",
      children: "$or"
    }), "\n", _jsxs(_components.p, {
      children: ["Returns ", _jsx(_components.code, {
        children: "true"
      }), " if at least one of its arguments is ", _jsx(_components.code, {
        children: "true"
      }), ", otherwise returns ", _jsx(_components.code, {
        children: "false"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "condition:\n  $or:\n    - $eval:\n        $var: status\n      is: Open\n    - $var: isActive\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["This will return ", _jsx(_components.code, {
        children: "true"
      }), " if ", _jsx(_components.code, {
        children: "status"
      }), " variable is set to ", _jsx(_components.code, {
        children: "Open"
      }), " or ", _jsx(_components.code, {
        children: "isActive"
      }), " variable is ", _jsx(_components.code, {
        children: "true"
      }), "."]
    }), "\n", _jsx(_components.h3, {
      id: "case",
      children: "$case"
    }), "\n", _jsx(_components.p, {
      children: "Case formula selects a value based on a list of conditions. It has the following structure:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "conditionalValue:\n  $case:\n    cases:\n      # A list of cases,\n      # each containing optional `filter` and `value`\n      - filter:\n          # Each filter is a list of conditions\n          - field: status\n            operator: is\n            value: Won\n        # If all the conditions are met, this value will be used.\n        value: 'Status is Won'\n      # Value without `filter` acts as \"else\". It will always be used\n      # if none of the previous filters matched.\n      - value: 'Status is not Won'\n    # If no cases match - this value is used\n    default: 'Default Value'\n"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "If filters of multiple cases match, the first one will be used"
      }), "\n", _jsx(_components.li, {
        children: "A case without a filter always matches."
      }), "\n", _jsx(_components.li, {
        children: "Filter with zero conditions always matches."
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "map",
      children: "$map"
    }), "\n", _jsx(_components.p, {
      children: "Map formula transforms a set of input values into corresponding output values."
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "mappedValue:\n  $map:\n    # Value that will be checked against the list of mappings below\n    value:\n      $var: $.status\n    mapping:\n      # Each mapping item has `from` and `to`.\n      # If `value` above matches `from`,\n      # then `to` will be used as the output of the formula.\n      - from: ToDo\n        to: Open\n      - from: In Progress\n        to: Open\n      - from: Done\n        to: Closed\n    # If none of the mappings matched, then default value will be used.\n    # If `default` is not specified and no mapping matched,\n    # the output will be empty.\n    default: 'Unknown Status'\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "iterate",
      children: "$iterate"
    }), "\n", _jsx(_components.p, {
      children: "Iterates over an array and returns an array of values calculated for each item."
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "transformedContacts:\n  $iterate:\n    source:\n      $var: $.contacts\n    item:\n      name:\n        $var: $.item.name\n      tags:\n        $var: $.parent.tags\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "The formula accepts two arguments:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "source: the source list to iterate over. Could be a value or any formula that returns an array."
      }), "\n", _jsxs(_components.li, {
        children: ["item: value for each item in the new list. When item value is calculated, two variables are available for it:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "item - the current item in the source list."
          }), "\n", _jsx(_components.li, {
            children: "parent - all the variables of the parent scope (i.e. variables available to the $iterate formula itself)."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["The resulting list will contain one ", _jsx(_components.code, {
        children: "item"
      }), " for each item of the source list."]
    }), "\n", _jsx(_components.h3, {
      id: "firstname",
      children: "$firstName"
    }), "\n", _jsx(_components.p, {
      children: "Extract first name from the name."
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "firstName:\n  $firstName:\n    $var: fullName\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "lastname",
      children: "$lastName"
    }), "\n", _jsx(_components.p, {
      children: "Extract last name from the name."
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "lastName:\n  $lastName:\n    $var: fullName\n"
      })
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsx(_components.p, {
        children: "Note: we will treat single-word names as Last Name because some applications require Last Name, but not First Name. This logic leads to fewer validation errors."
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "copy",
      children: "$copy"
    }), "\n", _jsx(_components.p, {
      children: "Copies another field from your value. Useful when you need the same value in two places and don't want to define it twice."
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "# Value\nfirstName:\n   $extractFirstName:\n      $var: fullName\npreferredName:\n    $copy: firstName\n\n# Variables\nfullName: John Snow\n\n# Result\nfirstName: John\npreferredName: John\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "findexternalrecordid",
      children: "$findExternalRecordId"
    }), "\n", _jsxs(_components.p, {
      children: ["Finds id of a record in an external application matching record in your application using a provided ", _jsx(_components.a, {
        href: "../main-concepts/data-links.md",
        children: "Data Link Table"
      }), "."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "# Will find a link in \"person-to-lead\" data link table\n# with appRecordId equal 'leadId'.\n# Will return externalRecordId from the link.\nexternalPersonId:\n  $findExternalRecordId:\n    dataLinkTable:\n      key: person-to-lead\n    recordId: 'leadId'\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "findapprecordid",
      children: "$findAppRecordId"
    }), "\n", _jsxs(_components.p, {
      children: ["Finds id of a record in you app that matches an external record id using a provided ", _jsx(_components.a, {
        href: "../main-concepts/data-links.md",
        children: "Data Link Table"
      }), "."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "# Will find a link in \"person-to-lead\" data link table\n# with externalRecordId equal 'personId'.\n# Will return appRecordId from the link.\nappLeadId:\n  $findAppRecordId:\n    dataLinkTable:\n      key: person-to-lead\n    recordId: 'personId'\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "jsonata",
      children: "$jsonata"
    }), "\n", _jsxs(_components.p, {
      children: ["If you want to apply complex data transformations, we support ", _jsx("a", {
        href: "https://jsonata.org/",
        target: "_blank",
        children: "Jsonata"
      }), " expressions."]
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "sumOfPrices:\n  $jsonata: $sum($.products.price)\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "plain",
      children: "$plain"
    }), "\n", _jsx(_components.p, {
      children: "This formula preserves everything inside it as is. It is useful when you need to skip evaluating formulas in a part of the expression."
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "# Formula\n$plain:\n  $firstName:\n    $var: fullName\n\n# Result\n$firstName:\n  $var: fullName\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "formula",
      children: "$formula"
    }), "\n", _jsx(_components.p, {
      children: "This formula allows you to use a formula as a value, but it still evaluates $var inside it. It is useful when you want to produce a formula dynamically, parametrized by variables."
    }), "\n", _jsx(_components.p, {
      children: "This is, for example, used in connectors when mapping fields to/from unified fields when you want the result to be a formula."
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "# When mapping unified fields to application-specific fields\n# we want the result to be a $firstNotEmpty formula,\n# but we want $.contactId and $.companyId to be replaced with actual values of unified fields.\nunifiedFieldsToNative:\n  parent:\n    $formula:\n      $firstNotEmpty:\n        - $var: $.contactId\n        - $var: $.companyId\n\n# Variables\ncontactId:\n  $var: $.input.contactId\ncompanyId:\n  $var: $.input.companyId\n\n# Result\nunifiedFieldsToNative:\n  parent:\n    $firstNotEmpty:\n      - $var: $.input.contactId\n      - $var: $.input.companyId\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

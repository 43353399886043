import { useConnections } from '@integration-app/react'
import {
  CommonItemComboboxSelect,
  ItemComboBoxGenericValue,
  ItemComboBoxGenericValuePlaceholder,
  ItemComboboxSelectProps,
} from './CommonItemComboboxSelect'

export function ConnectionComboboxSelect({
  userId = undefined,
  value,
  onChange,
  editable = true,
  hasRightSibling = false,
}: ItemComboboxSelectProps<string> & { userId?: string }) {
  const { items } = useConnections({ userId })

  const options = items?.map((conn) => ({
    value: conn.id,
    label: conn.name,
  }))

  const selectedItem = items?.find((o) => o.id === value)

  return (
    <CommonItemComboboxSelect<string>
      value={value}
      onChange={onChange}
      options={options}
      hasRightSibling={hasRightSibling}
      editable={editable}
      valueComponent={
        <ItemComboBoxGenericValue
          title={'Connection:'}
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          value={selectedItem?.name}
          imageSrc={selectedItem?.integration?.logoUri}
          imageAlt={selectedItem?.name}
        />
      }
      valuePlaceholderComponent={
        <ItemComboBoxGenericValuePlaceholder title={'Connection:'} />
      }
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Deploying Actions",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Running an Action",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Customizing an Action",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Adding Actions to Your App';
export const DESCRIPTION = "Call an action from your app's front-end or backend";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink, CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.RunActionInstance) _missingMdxReference("CodeExample.RunActionInstance", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "deploying-actions",
      children: "Deploying Actions"
    }), "\n", _jsx(_components.p, {
      children: "To deploy an action, run it from inside your application's front-end or backend."
    }), "\n", _jsx(_components.p, {
      children: "When running an action, you will need to specify:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "This action's key (can be found in the action's settings or in the code example below)."
      }), "\n", _jsx(_components.li, {
        children: "Integration key, integration id, or connection id this action should be run against."
      }), "\n", _jsx(_components.li, {
        children: "Input data for the action if it has inputs."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "running-an-action",
      children: "Running an Action"
    }), "\n", _jsx(_components.p, {
      children: "To run this action, do the following:"
    }), "\n", _jsx(CodeExample.RunActionInstance, {}), "\n", _jsx(_components.p, {
      children: "This code:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Creates ", _jsx(DocLink, {
          path: "engine/deployments/action-instances",
          children: "Customer Instance"
        }), " of this action if it didn't exist yet."]
      }), "\n", _jsx(_components.li, {
        children: "Executes action with the input provided."
      }), "\n", _jsx(_components.li, {
        children: "Returns the action output as well as execution logs."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "customizing-an-action",
      children: "Customizing an Action"
    }), "\n", _jsx(_components.p, {
      children: "To customize the behavior of this action for each individual customer, you can do one of the following:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Update the Customer Instance of the action with the new configuration. See ", _jsx(DocLink, {
          path: "engine/deployments/action-instances",
          children: "Actions API"
        }), " for details."]
      }), "\n", _jsxs(_components.li, {
        children: ["Use ", _jsx(DocLink, {
          path: "engine/blueprints/data-sources",
          children: "Data Source"
        }), " or ", _jsx(DocLink, {
          path: "engine/blueprints/field-mappings",
          children: "Field Mapping"
        }), " in the action configuration and then work with corresponding customer instances."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["If you want to build UI for your customers to configure this action, check out the ", _jsx(DocLink, {
        path: "ux",
        children: "User Interface"
      }), " documentation to see available options."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import ExampleCodeBlock from '../../Docs/ExampleCodeBlock'

export function ReactSdkFetchTokenCodeExample() {
  const jsx = `export function MyApp() {
  async fetchToken() {
    return '{TOKEN}';
  }

  return (
    <IntegrationAppProvider fetchToken={fetchToken}>
      <MyComponent />
    </IntegrationAppProvider>
  )
}`

  return <ExampleCodeBlock customCodes={{ jsx }} />
}

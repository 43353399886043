/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Flow Instance Setup Failed",
  "attributes": {},
  "children": []
}];
import {FLOW_INSTANCES_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ol: "ol",
    li: "li",
    ul: "ul"
  }, _provideComponents(), props.components), {DocLink, Link} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "flow-instance-setup-failed",
      children: "Flow Instance Setup Failed"
    }), "\n", _jsxs(_components.p, {
      children: ["When you see this error, it means something went wrong when creating or configuring a ", _jsx(DocLink, {
        path: "engine/deployments/flow-instances",
        children: "Flow Instance"
      })]
    }), "\n", _jsx(_components.p, {
      children: "You can troubleshoot this error by:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Going to the ", _jsx(Link, {
          path: FLOW_INSTANCES_ROUTE,
          children: "Flow Instances"
        }), " page."]
      }), "\n", _jsx(_components.li, {
        children: "Finding the Flow Instance by ID you see in the error."
      }), "\n", _jsx(_components.li, {
        children: "Looking at the error message you see on its page."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Typical reasons for this error are:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Connection does not work properly (for example, user does not have API access)."
      }), "\n", _jsx(_components.li, {
        children: "Connection does not have a default location for the Universal Data Model you requested - your user needs to select one manually."
      }), "\n", _jsx(_components.li, {
        children: "Some required fields are not filled in one of the Flow Nodes."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

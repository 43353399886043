/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Data Record Created Trigger",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Multiple Events",
    "attributes": {},
    "children": []
  }]
}];
export const DESCRIPTION = 'Run flow when new data is created in an external app';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "data-record-created-trigger",
      children: "Data Record Created Trigger"
    }), "\n", _jsx(_components.p, {
      children: "This trigger launches a flow when a data record is created in a selected data source."
    }), "\n", _jsx(_components.p, {
      children: "To configure this node you can provide:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/blueprints/data-sources",
          children: "Data Sources"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/blueprints/field-mappings",
          children: "Field Mapping"
        }), " from external app to your app."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "multiple-events",
      children: "Multiple Events"
    }), "\n", _jsx(_components.p, {
      children: "If multiple events are detected at the same time, the flow will be started with multiple inputs, one for each event."
    }), "\n", _jsx(_components.p, {
      children: "To avoid launching flows that will run for too long, if there are too many inputs, events could be split in multiple groups, each starting their own flow run."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

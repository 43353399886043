import { Fragment } from 'react'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import classes from 'routes/Docs/components/DocsBreadcrumbs/Breadcrumbs.module.css'
import DocLink from 'components/Docs/DocLink'

export type DocBreadcrumbType = {
  path?: string
  title: string | JSX.Element
}

export function DocBreadcrumbs({
  breadcrumbs,
}: {
  breadcrumbs?: DocBreadcrumbType[]
}) {
  if (!breadcrumbs?.length || breadcrumbs?.length < 2) {
    return null
  }
  return (
    <nav className={classes.breadcrumbs}>
      {breadcrumbs?.map((breadcrumb, i) => (
        <Fragment key={i}>
          {breadcrumb?.path ? (
            <DocLink className={classes.breadcrumb} path={breadcrumb.path}>
              {breadcrumb.title}
            </DocLink>
          ) : (
            <span className={classes.breadcrumb}>{breadcrumb.title}</span>
          )}

          <SvgIcon
            type={SvgIconType.BreadcrumbStep}
            className={classes.breadcrumbIcon}
          />
        </Fragment>
      ))}
    </nav>
  )
}

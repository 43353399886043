/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Security and Privacy",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Certifications and Compliance",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Encrypted credentials",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "No permanent user data storage",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "No third parties",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Running on your own infrastructure",
    "attributes": {},
    "children": []
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "security-and-privacy",
      children: "Security and Privacy"
    }), "\n", _jsx(_components.p, {
      children: "Integration.app engine is designed to minimize security and privacy risks. We apply a number of measures to achieve that."
    }), "\n", _jsx(_components.h2, {
      id: "certifications-and-compliance",
      children: "Certifications and Compliance"
    }), "\n", _jsx(_components.p, {
      children: "Integration.app is SOC 2 Type 2 certified."
    }), "\n", _jsx(_components.p, {
      children: "Our servers and data are fully located in European Union and we are GDPR-compliant."
    }), "\n", _jsx(_components.h2, {
      id: "encrypted-credentials",
      children: "Encrypted credentials"
    }), "\n", _jsx(_components.p, {
      children: "All customer credentials are stored in encrypted form in the database.\nThey are only decrypted before making API requests."
    }), "\n", _jsx(_components.p, {
      children: "Decrypted version is not stored anywhere outside the system memory."
    }), "\n", _jsx(_components.p, {
      children: "We take measures to exclude credentials from any logs or error reports."
    }), "\n", _jsx(_components.h2, {
      id: "no-permanent-user-data-storage",
      children: "No permanent user data storage"
    }), "\n", _jsx(_components.p, {
      children: "Any user data flowing through the system is stored for 14 days on AWS S3 for logging and debugging purposes."
    }), "\n", _jsx(_components.p, {
      children: "It is then automatically and permanently erased by AWS S3 lifecycle rules."
    }), "\n", _jsx(_components.p, {
      children: "This means no user data is stored beyond the last 14 days, which excludes you from the need to handle GDPR and similar requests for data erasure."
    }), "\n", _jsx(_components.h2, {
      id: "no-third-parties",
      children: "No third parties"
    }), "\n", _jsx(_components.p, {
      children: "We do not pass user data to any third parties except the AWS S3 service and Cloudflare."
    }), "\n", _jsx(_components.h2, {
      id: "running-on-your-own-infrastructure",
      children: "Running on your own infrastructure"
    }), "\n", _jsx(_components.p, {
      children: "If the measures above are not sufficient for your security and privacy requirements, you can run Integration.app on your own infrastructure and take full control of the data."
    }), "\n", _jsxs(_components.p, {
      children: ["Please contact ", _jsx("a", {
        href: "mailto:support@integration.app",
        children: "support@integration.app"
      }), " to explore this option."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Universal Data Models",
  "attributes": {},
  "children": []
}];
import {UNIFIED_DATA_MODELS} from '@integration-app/sdk';
import {toHeaderCase, toKebabCase} from 'js-convert-case';
import {SchemaBuilder} from '@integration-app/ui';
export const TITLE = 'Universal Data Models';
export const DESCRIPTION = 'Working with external data in a unified way';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [(toc[0].children = Object.values(UNIFIED_DATA_MODELS).map(udm => ({
      depth: 2,
      value: toHeaderCase(udm.pluralName),
      slug: toKebabCase(udm.pluralName),
      children: []
    })), null), "\n", _jsx(_components.h1, {
      id: "universal-data-models",
      children: "Universal Data Models"
    }), "\n", _jsx(_components.p, {
      children: "Universal Data Models let you work with data from third-party applications in a standardized way - without knowing anything about the underlying data structure."
    }), "\n", _jsx(_components.p, {
      children: "Connectors provide mappings from and to UDM for relevant data models."
    }), "\n", _jsx(_components.p, {
      children: "You can find currently supported Universal Data Models below."
    }), "\n", Object.keys(UNIFIED_DATA_MODELS).map(udm => _jsxs(_Fragment, {
      children: [_jsx(_components.h2, {
        children: toHeaderCase(UNIFIED_DATA_MODELS[udm].pluralName)
      }), _jsx(_components.strong, {
        children: "Fields Schema"
      }), _jsx(SchemaBuilder, {
        schema: UNIFIED_DATA_MODELS[udm].fieldsSchema,
        readOnly: true
      })]
    }))]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;

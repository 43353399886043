/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Data Links",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Data Link Tables",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Using Data Links",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Deployments",
    "attributes": {},
    "children": []
  }]
}];
import DocLink from "components/Docs/DocLink";
import {DATA_LINK_TABLES_ROUTE, DATA_LINK_TABLE_INSTANCES_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const DESCRIPTION = 'Managing relations between data records in your app and external apps';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Link} = _components;
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "data-links",
      children: "Data Links"
    }), "\n", _jsx(_components.p, {
      children: "Data Links represent relations between data records in your application and data records in external applications."
    }), "\n", _jsx(_components.p, {
      children: "For example, you may want to link a User in your app with a corresponding User in a third-party app, Data Links are what you need."
    }), "\n", _jsx(_components.h2, {
      id: "data-link-tables",
      children: "Data Link Tables"
    }), "\n", _jsxs(_components.p, {
      children: ["Data Links are organized in Data Link Tables. You can manage the list of tables on the ", _jsx(Link, {
        path: DATA_LINK_TABLES_ROUTE,
        children: "Data Links"
      }), " page."]
    }), "\n", _jsx(_components.p, {
      children: "You should create a Data Link Table for each type of relation you want to manage, like \"Users to Users\" or \"Meetings to Deals\"."
    }), "\n", _jsx(_components.h2, {
      id: "using-data-links",
      children: "Using Data Links"
    }), "\n", _jsx(_components.p, {
      children: "You can use Data Links in:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/blueprints/flows/nodes/create-data-link"
        }), " and ", _jsx(DocLink, {
          path: "engine/blueprints/flows/nodes/find-data-link"
        }), " flow nodes."]
      }), "\n", _jsxs(_components.li, {
        children: ["Find Data Link ", _jsx(DocLink, {
          path: "engine/references/formulas",
          children: "Formula"
        })]
      }), "\n", _jsx(_components.li, {
        children: "Via API."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "deployments",
      children: "Deployments"
    }), "\n", _jsxs(_components.p, {
      children: ["See ", _jsx(DocLink, {
        path: "engine/deployments/data-link-table-instances"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Integrations",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Connector Versions",
    "attributes": {},
    "children": []
  }]
}];
export const DESCRIPTION = 'Managing external apps and their connectors within your workspace.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "integrations",
      children: "Integrations"
    }), "\n", _jsxs(_components.p, {
      children: ["Integration represents configured ", _jsx(DocLink, {
        path: "engine/external-apps/connectors",
        children: "connector"
      }), " to an external app in your workspace.\nIt is used to build ", _jsx(DocLink, {
        path: "engine/blueprints",
        children: "integration blueprints"
      }), " and create ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "customer connections"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "While you usually have just one integration for each external app, you can create multiple integrations with different connector versions or parameters\nif you want to have multiple ways to integrate with a given app."
    }), "\n", _jsx(_components.h2, {
      id: "connector-versions",
      children: "Connector Versions"
    }), "\n", _jsx(_components.p, {
      children: "Integration uses a specific version of a connector, which prevents integration logic from breaking when the connector is updated."
    }), "\n", _jsx(_components.p, {
      children: "You can decide when and how to update the connector version. Connectors typically follow semantic versioning scheme, which means:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "If the last digit of the version is changed, it is a patch update. It usually includes bug fixes or minor improvements. It should be safe to update."
      }), "\n", _jsx(_components.li, {
        children: "If the middle digit is changed, it is a minor update. It usually includes new features or changes that are backward-compatible. It may include breaking changes, so you should read the changelog to see if it's safe to update."
      }), "\n", _jsx(_components.li, {
        children: "If the first digit is changed, it is a major update. It usually includes breaking changes. You should review the changes and test the integration before updating, or consider creating a new integration with the new connector version and deprecatin the current one."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import { useDeepMemo } from '@integration-app/ui/hooks/useDeepEffect'
import {
  HoveringNodeState,
  useNodesHoveringState,
} from 'components/FlowBuilder/Graph/elements/NodeBody/useNodesHoveringState'
import {
  SelectingNodeState,
  useSelectingNodeState,
} from 'components/FlowBuilder/Graph/elements/NodeBody/useSelectingNodeState'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react'
import { useConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors'
import { NodeConfigurationErrorData } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { useGenericFlow } from './useGenericFlow'

export const FlowBuilderProvider = ({ children }: PropsWithChildren) => {
  const { flow, flowRoute } = useGenericFlow()

  const hoveringState = useNodesHoveringState()
  const selectingNodeState = useSelectingNodeState(flow?.nodes, flowRoute)

  const [deletingNodeKey, setDeletingNodeKey] = useState<string>('')

  const [linking, setLinking] = useState<
    | {
        parent: string
        index: number
      }
    | undefined
  >(undefined)

  const clear = useCallback(() => {
    setDeletingNodeKey('')
    setLinking(undefined)
  }, [setDeletingNodeKey, setLinking])

  const toggleDeleteNode = useCallback(
    async (nodeKey = '') => {
      setDeletingNodeKey(nodeKey)
    },
    [setDeletingNodeKey],
  )

  function startLinking(parent: string, index: number) {
    clear()
    selectingNodeState.selectNode('')

    setLinking({ parent, index })
  }

  const selectNode = useCallback(
    (nodeKey: string) => {
      clear() // clear before selecting node
      selectingNodeState.selectNode(nodeKey)
    },
    [clear, selectingNodeState],
  )

  // PERFORMANCE NOTE: do not call this hook inside each node because it performs a lot of un-optimized computations. Instead, call it hare and pass getNodeErrors to use inside each node.
  const { getNodeErrors } = useConfigurationErrors()

  const context = useDeepMemo(
    () => ({
      ...selectingNodeState,
      ...hoveringState,
      selectNode,

      deletingNodeKey,
      toggleDeleteNode,

      linking,
      startLinking,

      routeBeforeNodes: flowRoute,

      getNodeErrors,
    }),
    [
      selectingNodeState,
      hoveringState,
      selectNode,

      deletingNodeKey,
      toggleDeleteNode,

      startLinking,

      flowRoute,

      getNodeErrors,
    ],
  )

  return (
    <FlowBuilderContext.Provider value={context}>
      {children}
    </FlowBuilderContext.Provider>
  )
}

interface FlowBuilderInterface {
  toggleDeleteNode(nodeKey: string): void
  deletingNodeKey: string

  startLinking(parent: string, index: number): void
  linking:
    | {
        parent: string
        index: number
      }
    | undefined

  routeBeforeNodes: string

  getNodeErrors(nodeKey: string): NodeConfigurationErrorData[]
}

const FlowBuilderContext = createContext<
  FlowBuilderInterface & HoveringNodeState & SelectingNodeState
>({} as any)

export function useFlowBuilder() {
  return useContext(FlowBuilderContext)
}

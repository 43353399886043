import {
  DataSchema,
  IntegrationAppClient,
  Workspace,
} from '@integration-app/sdk'
import { JsonViewer } from 'components/JsonViewer'
import useAuth from 'contexts/auth'
import { useEffect, useState } from 'react'
import useWorkspace from '../Workspaces/workspace-context'
import {
  CodeBlockRunButton,
  ExampleBlock,
  LoginToRun,
  Tab,
} from 'components/Docs/CodeBlockUI'
import { CodeParams } from 'components/Docs/CodeBlockComponents'

import useDocs from '../../routes/Docs/components/docs-context'
import { CodeHighlighterLanguages } from '../CodeHighlighter'
import { BottomErrors } from '../BottomErrors'
import {
  CodeBlockExtension,
  CodeBlockLanguageTitle,
  CodeExampleType,
  CodeParamType,
  CodeBlockType,
} from './codeBlock-types'
import { Link } from 'react-router-dom'
import DocLink from './DocLink'

const CodeBlockExtensions = new Map<
  CodeBlockExtension,
  {
    title: CodeBlockLanguageTitle
    language: CodeHighlighterLanguages
  }
>()
CodeBlockExtensions.set('js', {
  title: CodeBlockLanguageTitle.Javascript,
  language: CodeHighlighterLanguages.javascript,
})
CodeBlockExtensions.set('ts', {
  title: CodeBlockLanguageTitle.Javascript,
  language: CodeHighlighterLanguages.typescript,
})
CodeBlockExtensions.set('tsx', {
  title: CodeBlockLanguageTitle.React,
  language: CodeHighlighterLanguages.tsx,
})
CodeBlockExtensions.set('jsx', {
  title: CodeBlockLanguageTitle.React,
  language: CodeHighlighterLanguages.jsx,
})
CodeBlockExtensions.set('vue', {
  title: CodeBlockLanguageTitle.Vue,
  language: CodeHighlighterLanguages.jsx,
})
CodeBlockExtensions.set('http', {
  title: CodeBlockLanguageTitle.API,
  language: CodeHighlighterLanguages.http,
})
CodeBlockExtensions.set('curl', {
  title: CodeBlockLanguageTitle.curl,
  language: CodeHighlighterLanguages.bash,
})
CodeBlockExtensions.set('sh', {
  title: CodeBlockLanguageTitle.Bash,
  language: CodeHighlighterLanguages.bash,
})
CodeBlockExtensions.set('npm', {
  title: CodeBlockLanguageTitle.npm,
  language: CodeHighlighterLanguages.bash,
})
CodeBlockExtensions.set('yarn', {
  title: CodeBlockLanguageTitle.yarn,
  language: CodeHighlighterLanguages.bash,
})
CodeBlockExtensions.set('go', {
  title: CodeBlockLanguageTitle.Go,
  language: CodeHighlighterLanguages.go,
})
CodeBlockExtensions.set('python', {
  title: CodeBlockLanguageTitle.Python,
  language: CodeHighlighterLanguages.python,
})
CodeBlockExtensions.set('java', {
  title: CodeBlockLanguageTitle.Java,
  language: CodeHighlighterLanguages.java,
})
CodeBlockExtensions.set('php', {
  title: CodeBlockLanguageTitle.PHP,
  language: CodeHighlighterLanguages.php,
})
CodeBlockExtensions.set('ruby', {
  title: CodeBlockLanguageTitle.Ruby,
  language: CodeHighlighterLanguages.ruby,
})

export interface RunArgs {
  integrationApp: IntegrationAppClient
  parameters: Record<string, any>
  workspace: Workspace
}

export interface ParameterSpec {
  type: CodeParamType
  title?: string
  schema?: DataSchema
  default?: any
  readOnly?: boolean
}

export type ParametersSpec = Record<string, CodeParamType | ParameterSpec>

const LANGUAGE_HELP_ARTICLES = {
  javascript: 'overview/getting-started/front-end/javascript',
  jsx: 'overview/getting-started/front-end/react',
  curl: 'overview/getting-started/backend/rest-api',
}

export default function ExampleCodeBlock({
  variables = {},
  variablesSpec = {},
  customCodes,
  parameters: parametersSpec = {},
  showOutput = true,
  parametersExpandedByDefault = false,
  run,
  key,
}: {
  variables?: Record<string, string>
  variablesSpec?: ParametersSpec
  customCodes: CodeBlockType
  parameters?: ParametersSpec
  showOutput?: boolean
  key?: string
  parametersExpandedByDefault?: boolean
  run?: (args: RunArgs) => Promise<any>
}) {
  const { self, loginLink } = useAuth()
  const { workspace } = useWorkspace()
  const { parameters } = useDocs()

  const codeExamples = codeBlocksToCodeExamples(customCodes)

  const { testCustomerClient } = useWorkspace()
  const [output, setOutput] = useState(null)
  const [error, setError] = useState(null)
  const [running, setRunning] = useState(false)

  async function doRun() {
    if (!run) {
      return
    }

    setRunning(true)
    try {
      const result = await run({
        integrationApp: testCustomerClient,
        workspace,
        parameters,
      })
      setOutput(result ?? 'No output')
      setError(null)
    } catch (e) {
      setError(e)
    } finally {
      setRunning(false)
    }
  }

  function replaceParamsAndVariables(code: string) {
    return renderVariables(
      renderVariables(code, parameters, parametersSpec),
      variables,
      variablesSpec,
    )
  }

  useEffect(() => {
    setOutput(null)
    setError(null)
  }, [key])

  const [currentLanguage, setCurrentLanguage] = useState(
    codeExamples[0].language,
  )

  return (
    <Tab.Group
      onChange={(index: number) =>
        setCurrentLanguage(codeExamples[index].language)
      }
    >
      <CodeParams
        expandedByDefault={parametersExpandedByDefault}
        disabled={!self?.user?.id}
        parametersSpec={parametersSpec}
      />
      <Tab.List>
        {codeExamples.map((codeExample) => (
          <Tab key={codeExample.title}>{codeExample.title}</Tab>
        ))}
      </Tab.List>
      {LANGUAGE_HELP_ARTICLES[currentLanguage] && (
        <div className='text-sm p-0.5 px-4 bg-neutral02 text-right'>
          <span className='text-secondary'>Run in your app: </span>
          <DocLink path={LANGUAGE_HELP_ARTICLES[currentLanguage]} />
        </div>
      )}
      <Tab.Panels>
        {codeExamples.map((codeExample) => (
          <Tab.Panel key={codeExample.title}>
            <ExampleBlock.CodeBlock
              code={replaceParamsAndVariables(codeExample.code).trim()}
              language={codeExample.language}
              copyToClipboard
            />
          </Tab.Panel>
        ))}
      </Tab.Panels>
      {run && (
        <ExampleBlock.Toolbar>
          {self?.user?.id ? (
            <div className='flex gap-4 items-center'>
              <CodeBlockRunButton
                onClick={doRun}
                disabled={running}
                loading={running}
              >
                {running ? 'Running...' : 'Run Now'}
              </CodeBlockRunButton>
              <div>
                This code will run on behalf of your{' '}
                <Link to='/settings/testing'>test customer</Link>.
              </div>
            </div>
          ) : (
            <LoginToRun>
              <a href={loginLink(window.location.href)}>log in</a> to run this
              example
            </LoginToRun>
          )}
        </ExampleBlock.Toolbar>
      )}
      {error && (
        <div className='w-full'>
          <BottomErrors
            title='Run has 1 error'
            // FIXME: strictNullCheck temporary fix
            // @ts-expect-error TS(2339): Property 'data' does not exist on type 'never'.
            errors={[error.data ?? error]}
            reverse
            isInSidebar={false}
          />
        </div>
      )}
      {!error && output && showOutput && (
        <ExampleBlock.Output>
          {typeof output === 'object' ? (
            <JsonViewer json={output} />
          ) : (
            // FIXME: strictNullCheck temporary fix
            // @ts-expect-error TS(2339): Property 'toString' does not exist on type 'never'... Remove this comment to see the full error message
            output.toString()
          )}
        </ExampleBlock.Output>
      )}
    </Tab.Group>
  )
}

export function codeBlocksToCodeExamples(
  codeBlocks: CodeBlockType,
): CodeExampleType[] {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type '{ language: CodeHighlighterLanguages; title:... Remove this comment to see the full error message
  return (Object.keys(codeBlocks) as CodeBlockExtension[]).map((ext) => ({
    language: mapExtensionToLanguage(ext),
    title: mapExtensionToLanguageTitle(ext),
    code: codeBlocks[ext],
  }))
}

function mapExtensionToLanguageTitle(ext: CodeBlockExtension) {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  return getCodeBlockExtension(ext).title
}
function mapExtensionToLanguage(ext: CodeBlockExtension) {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  return getCodeBlockExtension(ext).language
}
function getCodeBlockExtension(ext: CodeBlockExtension) {
  return CodeBlockExtensions.get(ext) || CodeBlockExtensions.get('js')
}

export function renderVariables(
  code: string,
  variables: Record<string, any>,
  parametersSpec?: ParametersSpec,
) {
  let result = code

  const defaultValues = {}
  for (const [key, value] of Object.entries(parametersSpec ?? {})) {
    if (typeof value === 'object' && value?.default) {
      defaultValues[key] = value.default
    }
  }

  const values = {
    ...defaultValues,
    ...(variables ?? {}),
  }

  for (const key of Object.keys(parametersSpec ?? {})) {
    let value = values?.[key]
    const parameterSpec = parametersSpec?.[key]
    const type =
      typeof parameterSpec === 'object' ? parameterSpec?.type : parameterSpec
    const defaultValue =
      typeof parameterSpec === 'object' ? parameterSpec?.default : undefined

    // For objects and booleans, we remove the outer quotes
    // that we add to make placeholders valid JSON (like '{OBJECT}' or '{BOOLEAN}')
    // For other types, we keep the quotes.
    const isStringType =
      type &&
      ![
        CodeParamType.Object,
        CodeParamType.Boolean,
        CodeParamType.DataSchema,
      ].includes(type)

    // Some keys can have format `baseKey/subKey` to store different values for different objects
    // for example, `INPUT/<objectId1>` and `INPUT/<objectId2>`.
    const baseKey = key.split('/')[0]

    const regexp = isStringType
      ? new RegExp(`{${baseKey}}`, 'g')
      : new RegExp(`['"]?{${baseKey}}['"]?`, 'g')

    if (value === undefined) {
      value = defaultValue
    }

    let stringValue = value === undefined ? '' : JSON.stringify(value, null, 2)

    if (isStringType) {
      stringValue = stringValue.replace(/"/g, '')
    }

    if (stringValue) {
      result = result.replace(regexp, stringValue)
    }
  }

  return result
}

ExampleCodeBlock.ParamType = CodeParamType

export { CodeParamType }

import { forwardRef, useRef } from 'react'
import { SvgIconType } from '@integration-app/ui/SvgIcon'
import Dropdown from '@integration-app/ui/Dropdown'
import { useCopyToClipboard, CopyToClipboardPopper } from './CopyToClipboard'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import { ITagId, TagId, TagIdCopyToClipboard } from './Tag'
import { useRouter } from 'next/router'

interface ITableCellTagRoot extends ITagId {
  children: string
  copyToClipboard?: boolean
}

const TableCellTagRoot = forwardRef<HTMLSpanElement, ITableCellTagRoot>(
  ({ children, title, copyToClipboard = true, ...props }, ref) => {
    const Component = copyToClipboard ? TagIdCopyToClipboard : TagId
    return (
      <Component title={title || (children as string)} {...props} ref={ref}>
        {children}
      </Component>
    )
  },
)
TableCellTagRoot.displayName = 'TableCellTag'

interface ITagDropdown extends ITagId {
  open?: boolean
}

const TagDropdown = forwardRef<HTMLSpanElement, ITagDropdown>(
  ({ children, open = false, title, onClick, ...props }, ref) => {
    return (
      <TagId
        icon={open ? SvgIconType.ChevronUp : SvgIconType.ChevronDown}
        iconAlways={open}
        onClick={onClick}
        title={title || (children as string)}
        {...props}
        ref={ref}
      >
        {children}
      </TagId>
    )
  },
)
TagDropdown.displayName = 'TagDropdown'

function NameWithDropdown<Item extends { id: string; name?: string }>({
  item,
  truncate = false,
  filterByValue,
  filterByField,
  onDetailsClick,
}: {
  item: Item
  truncate?: boolean
  filterByField?: string
  filterByValue?: string
  onDetailsClick?: (e?: Event) => void
}) {
  const { id, name } = item
  const refElement = useRef(null)

  return (
    <div ref={refElement}>
      <Dropdown>
        <Dropdown.Button>
          <TagDropdown truncate={truncate}>{name || id}</TagDropdown>
        </Dropdown.Button>

        <DropdownOptions
          filterByField={filterByField}
          filterByValue={filterByValue ?? id}
          onDetailsClick={onDetailsClick}
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
          copyValue={id}
          copyTitle={'Copy'}
          refElement={refElement}
        />
      </Dropdown>
    </div>
  )
}

function DropdownOptions({
  refElement,
  copyValue = undefined,
  copyTitle = 'Copy Id',
  filterByValue,
  filterByField,

  onDetailsClick,
}) {
  const router = useRouter()
  const { copied, copyToClipboard } = useCopyToClipboard()

  async function copyOnClick() {
    await copyToClipboard(copyValue)
  }

  async function filterByOnClick() {
    await router.push({
      query: { ...router.query, [filterByField]: filterByValue },
    })
  }

  return (
    <>
      <CopyToClipboardPopper
        refElement={refElement}
        isOpen={copied}
        offset={[0, -20]}
      />
      <Dropdown.Options>
        {copyValue && (
          <Dropdown.Option onClick={useEventStopPropagation(copyOnClick)}>
            {copyTitle}
          </Dropdown.Option>
        )}

        {onDetailsClick && (
          <Dropdown.Option onClick={useEventStopPropagation(onDetailsClick)}>
            Details
          </Dropdown.Option>
        )}

        {filterByField && (
          <Dropdown.Option onClick={useEventStopPropagation(filterByOnClick)}>
            Filter By
          </Dropdown.Option>
        )}
      </Dropdown.Options>
    </>
  )
}

const TableCellTag = Object.assign(TableCellTagRoot, {
  NameWithDropdown,
})
export default TableCellTag

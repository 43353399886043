import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import DataBuilderFilter from '@integration-app/ui/DataBuilder/Filter'
import {
  DataInputSelect,
  useDataInput,
} from '../../../../../../../../../../components/FlowBuilder/NodeSidebar/data-input'
import SidePanel from '../../../../../../../../../../components/Layout/SidePanel'
import { Checkbox } from '@integration-app/ui'
import { Row, Cell } from '@integration-app/ui/DataBuilderUI'
import { NodeConfigurationErrorsGetter } from '../configuration-errors/types'
import { getBrokenVariablesConfigurationErrors } from '../configuration-errors/geBrokenVariablesConfigurationErrors'
import { CollapsibleSection } from '../../../../../../../components/Section'

export function Filter() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()

  const userFilterInput = config.userFilterInput ?? { $var: 'input' }

  const { inputSchema } = useDataInput(userFilterInput)

  return (
    <div>
      <CollapsibleSection open>
        <CollapsibleSection.Header>Filter</CollapsibleSection.Header>
        <p>
          Only input matching conditions below will pass to the downstream
          nodes.
        </p>

        <DataBuilderFilter
          variablesSchema={variablesSchema}
          value={config.filter}
          onChange={(filter: any) => patchConfig({ filter })}
        />
      </CollapsibleSection>

      <CollapsibleSection open={false}>
        <CollapsibleSection.Header>Customer Filter</CollapsibleSection.Header>

        <SidePanel.Subheader>
          You can let customers specify their own filter, additionally to the
          one you configured above. Only inputs that match both your and
          customer's conditions will pass to the downstream nodes.
        </SidePanel.Subheader>

        <SidePanel.Content>
          <Row>
            <Cell.Name>Allow Customer Configure Filter</Cell.Name>
            <Cell>
              <Checkbox
                checked={config.userFilterEnabled}
                onChange={() =>
                  patchConfig({
                    userFilterEnabled: !config.userFilterEnabled,
                  })
                }
              />
            </Cell>
          </Row>
        </SidePanel.Content>

        <h3>Filter Input</h3>

        <SidePanel.Content>
          <DataInputSelect
            value={userFilterInput}
            onChange={(userFilterInput: any) =>
              patchConfig({ userFilterInput })
            }
          />
        </SidePanel.Content>

        <h3>Default Customer Filter</h3>

        <SidePanel.Content>
          <DataBuilderFilter
            variablesSchema={inputSchema}
            value={config.defaultUserFilter}
            onChange={(defaultUserFilter) => patchConfig({ defaultUserFilter })}
          />
        </SidePanel.Content>
      </CollapsibleSection>
    </div>
  )
}

export const getFilterConfigErrors: NodeConfigurationErrorsGetter = ({
  config,
  runTimeVariablesSchema,
}) => {
  const errors = []

  errors.push(
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type 'NodeConfigurationErrorData' is n... Remove this comment to see the full error message
    ...getBrokenVariablesConfigurationErrors(config.filter, [
      runTimeVariablesSchema,
    ]),
  )

  return errors
}

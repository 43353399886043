import ComboBox from '@integration-app/ui/ComboBox'
import { ComboBoxOptionType } from '@integration-app/ui/ComboBox/types'
import { ValueType } from '@integration-app/ui/ComboBox/value-spec'
import { Cell, Row, Title } from '@integration-app/ui/DataBuilderUI'
import { FieldDescription } from '@integration-app/ui'

export type SimpleSelectOptionType = ComboBoxOptionType

export default function SimpleSelect({
  name,
  description,
  value,
  options,
  onChange,
  disabled,
}: {
  name: string
  description?: string
  value: string
  options: ComboBoxOptionType[]
  onChange: (value: string) => void
  disabled?: boolean
}) {
  return (
    <Row>
      <Cell.Name>
        <Title rightSlot={<FieldDescription text={description} />}>
          {name}
        </Title>
      </Cell.Name>
      <Cell.Combobox grow>
        <ComboBox
          value={value}
          valueSpec={{ type: ValueType.STRING, allowCustom: false }}
          options={options}
          onChange={onChange}
          disabled={disabled}
        />
      </Cell.Combobox>
    </Row>
  )
}

import { useGenericConfig } from './contexts/generic-config-context'
import { CollapsibleSection } from '../Section'
import AdminDataBuilderForm from '../../../../../components/AdminDataBuilderForm'
import { makeDataField } from '@integration-app/ui/DataBuilder'

export function PaginationConfig() {
  const { variablesSchema, config, patchConfig } = useGenericConfig()

  return (
    <div>
      <CollapsibleSection open>
        <CollapsibleSection.Header>Pagination</CollapsibleSection.Header>

        <AdminDataBuilderForm
          onChange={(cursor) => patchConfig({ cursor })}
          field={makeDataField({
            value: config?.cursor,
            schema: {
              type: 'string',
              title: 'Cursor',
              description:
                'Cursor for getting the next page of results when iterating over pages.',
            },
            variablesSchema,
          })}
        />
      </CollapsibleSection>
    </div>
  )
}

import { Fragment, HTMLAttributes, ReactNode, useRef } from 'react'
import { Portal } from 'react-portal'
import clsx from 'clsx'
import classes from './SidePanel.module.css'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { RoundButton } from 'components/RoundButton'
import { useClickOutsideElements } from '@integration-app/ui/hooks/useWindowEvent'
import { Dialog } from '@integration-app/ui/Dialog'
import Button from '@integration-app/ui/Button'

interface ISidePanel {
  width?: string
  relative?: boolean
  lightBg?: boolean
  onCollapseButtonClick?: (...args) => void
  onClickOutside?: (...args) => void
  children: ReactNode
  animated?: boolean
}

function SidePanelRoot({
  relative,
  lightBg,
  onCollapseButtonClick,
  onClickOutside,
  children,
  animated = true,
}: ISidePanel) {
  const ref = useRef()

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
  useClickOutsideElements([ref], onClickOutside)

  const Wrapper = relative ? Fragment : Portal

  return (
    <Wrapper>
      <div
        className={clsx(
          classes.wrapper,
          animated && classes.animated,
          !relative && classes.wrapperAbsolute,
          lightBg && classes.wrapperLightBg,
        )}
        // FIXME: strictNullCheck temporary fix
        // @ts-expect-error TS(2322): Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={ref}
      >
        <div className={classes.relWrapper}>
          {onCollapseButtonClick && (
            <RoundButton
              onClick={onCollapseButtonClick}
              className={classes.collapseButton}
            >
              <SvgIcon type={SvgIconType.Cancel} />
            </RoundButton>
          )}
          <div className={classes.panel}>{children}</div>
        </div>
      </div>
    </Wrapper>
  )
}

interface ISidePanelTitle extends HTMLAttributes<HTMLHeadingElement> {
  level?: 1 | 2 | 3
}

function SidePanelHeader({
  children,
  className,
  level = 1,
  ...props
}: ISidePanelTitle) {
  const Tag = getValueByLevel(level, 'h1', 'h2', 'h3')
  const levelClassName = getValueByLevel(
    level,
    classes.header,
    classes.subLevelHeader,
    classes.subSubLevelHeader,
  )
  return (
    <Tag className={clsx(levelClassName, className)} {...props}>
      {children}
    </Tag>
  )
}

function getValueByLevel<T>(
  level: ISidePanelTitle['level'],
  val1: T,
  val2: T,
  val3: T,
) {
  switch (level) {
    case 1:
      return val1
    case 2:
      return val2
    case 3:
      return val3
    default:
      return val1
  }
}

function SidePanelSubheader({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className={clsx(className, classes.subheader)} {...props}>
      {children}
    </p>
  )
}

function SidePanelHeaderBlock({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx(classes.headerBlock, className)} {...props}>
      {children}
    </div>
  )
}

interface ISidePanelContent extends HTMLAttributes<HTMLDivElement> {
  grow?: boolean
}
function SidePanelContent({
  children,
  className,
  grow,
  ...props
}: ISidePanelContent) {
  return (
    <div
      className={clsx(classes.content, grow && classes.contentGrow, className)}
      {...props}
    >
      {children}
    </div>
  )
}

function SidePanelCloseConfirmationModal({
  title = 'Want to close?',
  description = 'All unsaved data will be lost',

  onCloseConfirm,
  onCloseCancel,
}: {
  title?: string
  description?: string

  onCloseConfirm()
  onCloseCancel()
}) {
  return (
    <Dialog open={true} onClickOutside={onCloseCancel}>
      <Dialog.Title>{title}</Dialog.Title>

      <Dialog.Description>
        <p>{description}</p>
      </Dialog.Description>

      <Dialog.ActionBar>
        <Button variant={'text'} onClick={onCloseCancel}>
          No, keep open
        </Button>

        <Button onClick={onCloseConfirm}>Yes, close</Button>
      </Dialog.ActionBar>
    </Dialog>
  )
}

function SidePanelResetConfirmationModal({
  title = 'Want to reset?',
  description = 'This will erase user configuration for this element and replace it with default values. Are you sure?',

  onResetConfirm,
  onResetCancel,
}: {
  title?: string
  description?: string

  onResetConfirm()
  onResetCancel()
}) {
  return (
    <Dialog open={true} onClickOutside={onResetCancel}>
      <Dialog.Title>{title}</Dialog.Title>

      <Dialog.Description>
        <p>{description}</p>
      </Dialog.Description>

      <Dialog.ActionBar>
        <Button variant={'text'} onClick={onResetCancel}>
          No
        </Button>

        <Button onClick={onResetConfirm}>Yes</Button>
      </Dialog.ActionBar>
    </Dialog>
  )
}

const SidePanel = Object.assign(SidePanelRoot, {
  Header: SidePanelHeader,
  Subheader: SidePanelSubheader,
  HeaderBlock: SidePanelHeaderBlock,
  Content: SidePanelContent,
  CloseConfirmationModal: SidePanelCloseConfirmationModal,
  ResetConfirmationModal: SidePanelResetConfirmationModal,
})
export default SidePanel

import { PropsWithChildren, useState } from 'react'
import clsx from 'clsx'
import { makeDataField, SimpleInput } from '@integration-app/ui'
import { GenericButton } from '@integration-app/ui/Button'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import useDocs from 'routes/Docs/components/docs-context'
import { DataSourceKeyComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/DataSourceKeyComboboxSelect'
import { ActionComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/ActionComboboxSelect'
import { BooleanComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/BooleanComboboxSelect'
import { ConnectionComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/ConnectionComboboxSelect'
import { FieldMappingComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/FieldMappingComboboxSelect'
import { FlowComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/FlowComboboxSelect'
import { IntegrationComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationComboboxSelect'
import { IntegrationWithConnectionComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationWithConnectionComboboxSelect'
import { UdmComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/UdmComboboxSelect'
import {
  CodeParamType,
  ParameterSpec,
  ParametersSpec,
} from 'components/Docs/ExampleCodeBlock'
import { ExampleBlock } from 'components/Docs/CodeBlockUI'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { schemaWithTitle } from '@integration-app/sdk'
import { toHeaderCase } from 'js-convert-case'
import { FlowInstanceComboboxSelect } from '../../IntegrationElements/ComboboxSelects/FlowInstanceComboboxSelect'

export function CodeParams({
  disabled,
  expandedByDefault = false,
  parametersSpec,
}: {
  disabled?: boolean
  expandedByDefault?: boolean
  parametersSpec: ParametersSpec
}) {
  const { isVariableParameter } = useDocs()
  const [toggle, setToggle] = useState(expandedByDefault)

  const editableParameters = Object.keys(parametersSpec ?? {})
    .filter((key) => isVariableParameter(key))
    .filter((key) => !(parametersSpec?.[key] as ParameterSpec)?.readOnly)

  const amountOfParameters = editableParameters.length

  const toggleParameters = () => {
    setToggle(!toggle)
  }

  if (!amountOfParameters) {
    return null
  }

  if (disabled) {
    return null
  }

  return (
    <ExampleBlock.Parameters>
      {amountOfParameters > 1 && !expandedByDefault && (
        <ToggleParametersButton onClick={toggleParameters} active={toggle}>
          {toggle ? 'Hide Parameters' : 'Edit Parameters'}
        </ToggleParametersButton>
      )}
      {(amountOfParameters <= 1 || toggle) && (
        <ParamsList parametersSpec={parametersSpec} />
      )}
    </ExampleBlock.Parameters>
  )
}

function ToggleParametersButton({
  children,
  active,
  className,
  onClick,
}: PropsWithChildren<{
  active?: boolean
  className?: string
  onClick: () => void
}>) {
  return (
    <GenericButton
      className={clsx(
        'pl-3.5 pr-3 py-1.5 flex flex-row items-center gap-1.5',
        !active &&
          'rounded bg-neutral02 text-neutral05 hover:bg-neutral03 hover:text-neutral06',
        active &&
          'rounded-b bg-neutral03 text-neutral06 hover:bg-neutral02 hover:text-neutral05',
        className,
      )}
      onClick={onClick}
    >
      {children}
      <SvgIcon type={SvgIconType.Settings} className={'w-5 h-5'}></SvgIcon>
    </GenericButton>
  )
}

function ParamsList({ parametersSpec }: { parametersSpec: ParametersSpec }) {
  const { parameters, patchParameters, isVariableParameter } = useDocs()

  return (
    <div className={'flex flex-col items-start gap-2 w-full'}>
      {Object.entries(parametersSpec ?? {}).map(([name, parameterSpec]) =>
        isVariableParameter(name) ? (
          <RunParam
            key={name}
            name={name}
            spec={parameterSpec as ParameterSpec}
            type={
              typeof parameterSpec === 'object'
                ? parameterSpec?.type
                : parameterSpec
            }
            value={parameters?.[name]}
            onChange={(value) => patchParameters({ [name]: value })}
          />
        ) : null,
      )}
    </div>
  )
}

function RunParam({
  name,
  type,
  value: providedValue,
  spec,
  onChange,
}: {
  name: string
  type: CodeParamType
  value: any
  spec: ParameterSpec
  onChange: (value: any) => void
}) {
  let value = providedValue
  if (value === undefined) {
    value = spec?.default
  }

  switch (type) {
    case CodeParamType.String:
      return (
        <DataBuilderForm
          field={makeDataField({
            schema: schemaWithTitle(
              {
                type: 'string',
              },
              toHeaderCase(name),
            ),
            value,
          })}
          onChange={onChange}
        />
      )
    case CodeParamType.Boolean:
      return (
        <BooleanComboboxSelect name={name} value={value} onChange={onChange} />
      )
    case CodeParamType.integrationKeyWithoutConnection:
      return <IntegrationComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.IntegrationKey:
      return (
        <IntegrationWithConnectionComboboxSelect
          integrationKey={value}
          onIntegrationSelect={onChange}
        />
      )
    case CodeParamType.ConnectionId:
      return <ConnectionComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.FieldMappingKey:
      return <FieldMappingComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.FlowKey:
      return <FlowComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.FlowInstanceId:
      return <FlowInstanceComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.ActionKey:
      return <ActionComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.DataSourceKey:
      return <DataSourceKeyComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.UDM:
      return <UdmComboboxSelect value={value} onChange={onChange} />
    case CodeParamType.Object:
      // Objects can only be set from code, not by user (for now)
      return null
    case CodeParamType.DataSchema:
      return spec?.schema ? (
        <div className='w-full'>
          <DataBuilderForm
            field={makeDataField({
              schema: schemaWithTitle(
                spec?.schema,
                spec?.title ?? toHeaderCase(name),
              ),
              value,
            })}
            onChange={onChange}
          />
        </div>
      ) : null
    default:
      return <SimpleInput label={name} value={value} onChange={onChange} />
  }
}

import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'
import useDocs from '../../../routes/Docs/components/docs-context'
import { useActionContext } from '../../../routes/Workspaces/Workspace/Blueprints/Actions/Action/contexts/action-context'
import { useAction } from '@integration-app/react'
import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .action('{ACTION_KEY}')
  .run('{INPUT}')
`

const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/actions/{ACTION_KEY}/run',
  {
    method: 'POST',
    body: '{INPUT}',
  },
)

export function RunActionInstanceCodeExample({
  parametersExpandedByDefault = false,
}) {
  const { parameters } = useDocs()

  const integrationKey = parameters.INTEGRATION_KEY
  const key = parameters.ACTION_KEY

  const { action: actionFromContext } = useActionContext()
  const { action: actionFromHook } = useAction(
    key && integrationKey
      ? {
          key,
          integrationKey,
        }
      : undefined,
  )

  const action = actionFromContext ?? actionFromHook

  const inputParamName = `INPUT/${action?.id}`

  return (
    <ExampleCodeBlock
      parametersExpandedByDefault={parametersExpandedByDefault}
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        ACTION_KEY: CodeParamType.ActionKey,
        [inputParamName]: {
          title: 'Input',
          type: CodeParamType.DataSchema,
          schema: action?.inputSchema,
        },
      }}
      run={async ({ integrationApp, parameters }) => {
        const input = parameters[inputParamName]

        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .action(parameters.ACTION_KEY)
          .run(input)
      }}
    />
  )
}

import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .flow('{FLOW_KEY}')
  .enable()
`

export function EnableFlowInstanceExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FLOW_KEY: CodeParamType.FlowKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .flow(parameters.FLOW_KEY)
          .enable()
      }}
    />
  )
}

import useAuth from 'contexts/auth'
import Button from '@integration-app/ui/Button'
import classes from './AnonymousLoginAndTryWrapper.module.css'

export function AnonymousLoginAndTryWrapper({ children }) {
  const { self } = useAuth()
  const isLoggedIn = !!self?.user?.id

  if (isLoggedIn) {
    return children
  }
  return (
    <>
      {children}
      <LoginAndTryFooter />
    </>
  )
}

function LoginAndTryFooter() {
  const { login } = useAuth()
  return (
    <div className={classes.loginAndTryFooter}>
      <p>
        Integration.app is free to try. It takes just one click to get started.
      </p>
      <Button variant={'secondary'} size={'large'} onClick={() => login()}>
        Login and Try it Yourself
      </Button>
    </div>
  )
}

import {
  Action,
  ActionInstance,
  AppDataSchema,
  AppEventSubscription,
  AppEventType,
  Connection,
  DataLinkTable,
  DataSource,
  DataSourceInstance,
  ExternalEventSubscription,
  FieldMapping,
  Flow,
  FlowInstance,
  Integration,
  IntegrationElementInstance,
  IntegrationElementType,
  User,
} from '@integration-app/sdk'
import { IntegrationIcon } from '@integration-app/ui'

import { Cell, Row } from '@integration-app/ui/DataBuilderUI'
import clsx from 'clsx'
import DateFormat from 'components/DateFormat'

import TableCellTag from 'components/TableCellTag'
import { toHeaderCase } from 'js-convert-case'
import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import { ActionInstanceStateLabel } from '../routes/Workspaces/Workspace/Deployments/ActionInstances/components/ActionInstanceStateLabel'
import { routeTo } from '../routes/Workspaces/Workspace/routes-constants'

function ContentRowsRoot({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={clsx('flex flex-col gap-2', className)}>{children}</div>
  )
}

function UserRow({ user }: { user?: User }) {
  if (!user) return null

  return (
    <CommonElementRow
      name='Customer'
      label={user.name ?? ''}
      to={routeTo.customer(user.id)}
    />
  )
}

function ConnectionRow({ connection }: { connection?: Connection }) {
  if (!connection) return null

  return (
    <CommonElementRow
      name='Connection'
      label={connection.id}
      to={routeTo.connection(connection.id)}
    />
  )
}

function IntegrationRow({ integration }: { integration?: Integration }) {
  if (!integration) return null

  return (
    <Row>
      <Cell.Name>Integration</Cell.Name>

      <Cell.Value>
        <NavLink to={routeTo.app(integration.id)}>
          <TableCellTag copyToClipboard={false}>
            <div className='flex items-center gap-2'>
              <span>{integration?.name}</span>
              <IntegrationIcon integration={integration} />
            </div>
          </TableCellTag>
        </NavLink>
      </Cell.Value>
    </Row>
  )
}

function ActionInstanceState({
  actionInstance,
}: {
  actionInstance: ActionInstance
}) {
  return (
    <Row>
      <Cell.Name>State</Cell.Name>

      <Cell className={'!py-1.5'}>
        <ActionInstanceStateLabel state={actionInstance.state} />
      </Cell>
    </Row>
  )
}

function FlowRow({ flow }: { flow?: Flow }) {
  if (!flow) return null

  return (
    <CommonElementRow
      name='Flow'
      label={flow.name}
      to={routeTo.flow(flow.id)}
    />
  )
}

function IntegrationFromConnectionRow({
  connection,
}: {
  connection: Connection
}) {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'Integration | { id: string; name: string; }'... Remove this comment to see the full error message
  const integration: Integration = connection?.integration ?? {
    id: connection.integrationId,
    name: connection.name, // name of the integration can be changed, while name of the connection remains the same forever
  }

  return <IntegrationRow integration={integration} />
}

function AppEventTypeRow({ appEventType }: { appEventType?: AppEventType }) {
  if (!appEventType) return null

  return (
    <CommonElementRow
      name='App Event Type'
      label={appEventType.name}
      to={routeTo.appEventType(appEventType.id)}
    />
  )
}

function AppEventSubscriptionRow({
  appEventSubscription,
}: {
  appEventSubscription?: AppEventSubscription
}) {
  if (!appEventSubscription) return null

  return (
    <CommonElementRow
      name='App Event Subscription'
      label={appEventSubscription.id}
      to={routeTo.appEventSubscription(appEventSubscription.id)}
    />
  )
}

function ExternalEventSubscriptionRow({
  externalEventSubscription,
}: {
  externalEventSubscription?: ExternalEventSubscription
}) {
  if (!externalEventSubscription) return null

  return (
    <CommonElementRow
      name='External Event Subscription'
      label={externalEventSubscription.id}
      to={routeTo.externalEventSubscription(externalEventSubscription.id)}
    />
  )
}

function FieldMappingRow({ fieldMapping }: { fieldMapping?: FieldMapping }) {
  if (!fieldMapping) {
    return null
  }

  return (
    <CommonElementRow
      name='Field Mapping'
      label={fieldMapping.name}
      to={routeTo.fieldMapping(fieldMapping.id)}
    />
  )
}

function DataLinkTableRow({
  dataLinkTable,
}: {
  dataLinkTable?: DataLinkTable
}) {
  if (!dataLinkTable) return null

  return (
    <CommonElementRow
      name='Data Link Table'
      label={dataLinkTable.name}
      to={routeTo.dataLinkTable(dataLinkTable.id)}
    />
  )
}

function DataSourceTableRow({ dataSource }: { dataSource?: DataSource }) {
  if (!dataSource) {
    return null
  }

  return (
    <CommonElementRow
      name='Data Source'
      label={dataSource.name}
      to={routeTo.dataSource(dataSource.id)}
    />
  )
}

function ActionRow({ action }: { action?: Action }) {
  if (!action) {
    return null
  }

  return (
    <CommonElementRow
      name='Action'
      label={action.name}
      to={routeTo.action(action)}
    />
  )
}

function DataSourceInstanceRow({
  dataSourceInstance,
}: {
  dataSourceInstance?: DataSourceInstance
}) {
  if (!dataSourceInstance) {
    return null
  }

  return (
    <CommonElementRow
      name='Data Source Instance'
      label={dataSourceInstance.id}
      to={routeTo.dataSourceInstance(dataSourceInstance.id)}
    />
  )
}

function ExternalEventRow({ id }: { id: string }) {
  return (
    <CommonElementRow
      name='External Event'
      label={id}
      to={routeTo.externalEventLogRecord(id)}
    />
  )
}

function AppEventRow({ id }: { id: string }) {
  return (
    <CommonElementRow name='App Event' label={id} to={routeTo.appEvent(id)} />
  )
}

function FlowInstanceRow({ flowInstance }: { flowInstance?: FlowInstance }) {
  if (!flowInstance) return null

  return (
    <CommonElementRow
      name='Flow Instance'
      label={flowInstance.id}
      to={routeTo.flowInstance(flowInstance.id)}
    />
  )
}

function AppDataSchemaRow({
  appDataSchema,
}: {
  appDataSchema?: AppDataSchema
}) {
  if (!appDataSchema) {
    return null
  }

  return (
    <CommonElementRow
      name='App Data Schema'
      label={appDataSchema.name}
      to={routeTo.appDataSchema(appDataSchema.id)}
    />
  )
}

function BooleanRow({
  name,
  value,
  trueLabel = 'Yes',
  falseLabel = 'No',
}: {
  trueLabel?: string
  falseLabel?: string
  name: string
  value?: boolean
}) {
  return (
    <Row>
      <Cell.Name>{name}</Cell.Name>
      <Cell>{value ? trueLabel : falseLabel}</Cell>
    </Row>
  )
}

function InstanceKeyRow({ instanceKey }: { instanceKey?: string }) {
  return (
    <Row>
      <Cell.Name>Instance Key</Cell.Name>

      {instanceKey ? (
        <Cell.Value>
          <TableCellTag>{instanceKey}</TableCellTag>
        </Cell.Value>
      ) : (
        <Cell>default</Cell>
      )}
    </Row>
  )
}

function IdRow({ id }: { id: string }) {
  return (
    <Row>
      <Cell.Name>Id</Cell.Name>
      <Cell.Value>
        <TableCellTag>{id}</TableCellTag>
      </Cell.Value>
    </Row>
  )
}

function DatetimeRow({ name, value }: { name: string; value: string }) {
  return (
    <Row>
      <Cell.Name>{name}</Cell.Name>
      <Cell>
        <DateFormat>{value}</DateFormat>
      </Cell>
    </Row>
  )
}
function InstanceDependencies({
  dependencies,
}: {
  dependencies?: IntegrationElementInstance[]
}) {
  if (!dependencies?.length) {
    return null
  }

  return (
    <>
      <h3>Dependencies</h3>

      <div>
        {(dependencies ?? []).map((dependency) => {
          const id = dependency?.instanceId

          if (!id) return null

          function getNavigateToPath(
            dependencyType: IntegrationElementType,
            id: string,
          ) {
            switch (dependencyType) {
              case IntegrationElementType.DATA_SOURCE:
                return routeTo.dataSourceInstance(id)
              case IntegrationElementType.FIELD_MAPPING:
                return routeTo.fieldMappingInstance(id)
              case IntegrationElementType.ACTION:
                return routeTo.actionInstance(id)
              case IntegrationElementType.EXTERNAL_EVENT:
                return routeTo.externalEventSubscription(id)
              default:
                return ''
            }
          }

          const to = getNavigateToPath(dependency.type, id)

          return (
            <Row className='my-2' key={id}>
              <Cell.Name>{toHeaderCase(dependency.type)}</Cell.Name>

              <Cell.Value>
                <NavLink to={to}>
                  <TableCellTag copyToClipboard={false}>{id}</TableCellTag>
                </NavLink>
              </Cell.Value>
            </Row>
          )
        })}
      </div>
    </>
  )
}

type CommonElementRowToProps = {
  name: string
  label: string
  copyToClipboard?: boolean
  to?: never
}
type CommonElementRowCopyProps = {
  name: string
  label: string
  to?: string
  copyToClipboard?: never
}

type CommonElementRowProps = CommonElementRowToProps | CommonElementRowCopyProps

function CommonElementRow({
  name,
  label,
  to,
  copyToClipboard = false,
}: CommonElementRowProps) {
  const CellValue = () => (
    <TableCellTag copyToClipboard={copyToClipboard}>{label}</TableCellTag>
  )

  return (
    <Row>
      <Cell.Name>{name}</Cell.Name>
      <Cell.Value>
        {to ? (
          <NavLink to={to}>
            <CellValue />
          </NavLink>
        ) : (
          <CellValue />
        )}
      </Cell.Value>
    </Row>
  )
}

function ParentRow({
  name,
  value,
  to,
}: {
  name: string
  value: string
  to: string
}) {
  return (
    <Row>
      <Cell.Name>{name}</Cell.Name>

      <Cell.Value>
        <NavLink to={to}>
          <TableCellTag copyToClipboard>{value}</TableCellTag>
        </NavLink>
      </Cell.Value>
    </Row>
  )
}

// Commonly used components in side panels
export const ContentRows = Object.assign(ContentRowsRoot, {
  Id: IdRow,
  Boolean: BooleanRow,
  Datetime: DatetimeRow,

  Dependencies: InstanceDependencies,

  User: UserRow,
  FlowInstance: FlowInstanceRow,
  AppEventType: AppEventTypeRow,
  FieldMapping: FieldMappingRow,
  Connection: ConnectionRow,
  Integration: IntegrationRow,
  IntegrationFromConnection: IntegrationFromConnectionRow,
  AppDataSchema: AppDataSchemaRow,
  AppEvent: AppEventRow,
  AppEventSubscription: AppEventSubscriptionRow,
  ExternalEventSubscription: ExternalEventSubscriptionRow,
  ExternalEvent: ExternalEventRow,
  DataSourceInstance: DataSourceInstanceRow,
  DataLinkTable: DataLinkTableRow,
  DataSource: DataSourceTableRow,
  Action: ActionRow,

  ActionInstanceState: ActionInstanceState,

  InstanceKey: InstanceKeyRow,

  Parent: ParentRow,

  Flow: FlowRow,
})

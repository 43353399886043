/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Working with Binary Data",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Files Endpoint",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Custom Code Example",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Working with Binary Data';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    code: "code",
    ul: "ul",
    li: "li",
    pre: "pre"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "working-with-binary-data",
      children: "Working with Binary Data"
    }), "\n", _jsx(_components.p, {
      children: "Engine API works with data in JSON format."
    }), "\n", _jsx(_components.p, {
      children: "When you need to work with binary data (uploading or downloading files), the data is stored in a separate storage and URI to access it is provided in JSON."
    }), "\n", _jsx(_components.h2, {
      id: "files-endpoint",
      children: "Files Endpoint"
    }), "\n", _jsxs(_components.p, {
      children: ["For convenience, integration engine provides a special ", _jsx(_components.code, {
        children: "/files"
      }), " endpoint that accepts binary files and returns a pre-signed URL to access them."]
    }), "\n", _jsx(_components.p, {
      children: "You can use it to:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["To download files from external apps: you can use ", _jsx(_components.code, {
          children: "/files"
        }), " endpoint in connector code or your custom code, then return URL to access them in the output."]
      }), "\n", _jsxs(_components.li, {
        children: ["To upload files to external apps: you can use ", _jsx(_components.code, {
          children: "/files"
        }), " endpoint in your code and pass the URL to the input of the code running in the engine."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "custom-code-example",
      children: "Custom Code Example"
    }), "\n", _jsx(_components.p, {
      children: "Here is an example of a custom code that can run in the engine and download a large file from external app:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-javascript",
        children: "import axios from 'axios'\n\nexport default async function myCustomFunction({externalApiClient, engineApiClient}) {\n    const fileResponse = await externalApiClient.makeApiRequest({\n        path,\n        method: 'get',\n        responseType: 'stream', // We recommend using streams to work with large files to avoid memory issues\n        returnFullResponse: true, // Return full response to get headers and pass them on to the next request\n    })\n\n    const response = await engineApiClient.post('/files',\n        fileResponse.data,\n        {\n            headers: {\n                'Content-Length': fileResponse.headers['content-length'],\n                'Content-Type': fileResponse.headers['content-type'],\n            },\n            // Without this, we load the whole file into memory (which can be several GB).\n            // https://github.com/axios/axios/issues/1045\n            maxRedirects: 0,\n        }\n    )\n\n    return {\n        fileUri: response.downloadUri,\n    }\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;

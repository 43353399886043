/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Field Mapping Instances",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "UI",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "API",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Create",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Get",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Update",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Set up / Refresh",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Reset",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Archive",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Multiple Field Mapping Instances per Connection",
    "attributes": {},
    "children": []
  }]
}];
import {CodeExample} from '../../../../../components/CodeExamples';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    code: "code",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.ArchiveFieldMappingInstance) _missingMdxReference("CodeExample.ArchiveFieldMappingInstance", true);
  if (!CodeExample.CreateFieldMappingInstance) _missingMdxReference("CodeExample.CreateFieldMappingInstance", true);
  if (!CodeExample.GetFieldMappingInstance) _missingMdxReference("CodeExample.GetFieldMappingInstance", true);
  if (!CodeExample.GetFieldMappingInstanceWithInstanceKey) _missingMdxReference("CodeExample.GetFieldMappingInstanceWithInstanceKey", true);
  if (!CodeExample.OpenFieldMappingInstanceConfiguration) _missingMdxReference("CodeExample.OpenFieldMappingInstanceConfiguration", true);
  if (!CodeExample.PatchFieldMappingInstance) _missingMdxReference("CodeExample.PatchFieldMappingInstance", true);
  if (!CodeExample.ResetFieldMappingInstance) _missingMdxReference("CodeExample.ResetFieldMappingInstance", true);
  if (!CodeExample.SetupFieldMappingInstance) _missingMdxReference("CodeExample.SetupFieldMappingInstance", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "field-mapping-instances",
      children: "Field Mapping Instances"
    }), "\n", _jsxs(_components.p, {
      children: ["To use a Field Mapping, you need to create a Field Mapping Instance for a specific ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connection"
      })]
    }), "\n", _jsx(_components.p, {
      children: "Mapping Value is represented by two properties of the Field Mapping Instance:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "importValue"
        }), " - mapping from external app schema to your app schema"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "exportValue"
        }), " - mapping from your app schema to external app schema"]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["These are calculated values based on ", _jsx(_components.code, {
        children: "defaultImportValue"
      }), "/", _jsx(_components.code, {
        children: "defaultExportValue"
      }), " that come from Field Mapping\nand ", _jsx(_components.code, {
        children: "config.importValue"
      }), " / ", _jsx(_components.code, {
        children: "config.exportValue"
      }), " that come from your user."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can update the ", _jsx(_components.code, {
        children: "config"
      }), " part using the ", _jsx(_components.code, {
        children: "patch"
      }), " method:"]
    }), "\n", _jsx(_components.h2, {
      id: "ui",
      children: "UI"
    }), "\n", _jsx(_components.p, {
      children: "The simplest way to configure a Field Mapping is to use configuration UI:"
    }), "\n", _jsx(CodeExample.OpenFieldMappingInstanceConfiguration, {}), "\n", _jsxs(_components.p, {
      children: ["See more: ", _jsx(DocLink, {
        path: "ux/field-mappings",
        children: "Field Mapping UI"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "api",
      children: "API"
    }), "\n", _jsx(_components.p, {
      children: "Here is how to use Field Mapping Instances via API or SDK."
    }), "\n", _jsx(_components.h3, {
      id: "create",
      children: "Create"
    }), "\n", _jsx(CodeExample.CreateFieldMappingInstance, {}), "\n", _jsx(_components.h3, {
      id: "get",
      children: "Get"
    }), "\n", _jsx(CodeExample.GetFieldMappingInstance, {}), "\n", _jsx(_components.h3, {
      id: "update",
      children: "Update"
    }), "\n", _jsx(CodeExample.PatchFieldMappingInstance, {}), "\n", _jsx(_components.h3, {
      id: "set-up--refresh",
      children: "Set up / Refresh"
    }), "\n", _jsx(CodeExample.SetupFieldMappingInstance, {}), "\n", _jsx(_components.h3, {
      id: "reset",
      children: "Reset"
    }), "\n", _jsx(_components.p, {
      children: "This method resets field mapping instance to its default state, erasing all the customer configuration."
    }), "\n", _jsx(CodeExample.ResetFieldMappingInstance, {}), "\n", _jsx(_components.h3, {
      id: "archive",
      children: "Archive"
    }), "\n", _jsx(CodeExample.ArchiveFieldMappingInstance, {}), "\n", _jsx(_components.h2, {
      id: "multiple-field-mapping-instances-per-connection",
      children: "Multiple Field Mapping Instances per Connection"
    }), "\n", _jsx(_components.p, {
      children: "By default, every pair of Field Mapping and Connection will use the same Field Mapping Instance, which means that mapping values will be shared between them."
    }), "\n", _jsxs(_components.p, {
      children: ["If you want to use multiple different mapping values for the same pair of Field Mapping + Connection, you should use ", _jsx(_components.code, {
        children: "instanceKey"
      }), "."]
    }), "\n", _jsx(CodeExample.GetFieldMappingInstanceWithInstanceKey, {}), "\n", _jsxs(_components.p, {
      children: ["When you apply ", _jsx(_components.code, {
        children: "instanceKey"
      }), " to a Field Mapping Instance, it will be automatically applied to the elements Field Mapping works with: Data Source, App Data Collection, etc."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import { httpCodeExamples } from 'components/CodeExamples/http-code-examples'
import { stringifyWithIndent } from 'components/CodeExamples/stringify-with-indent'
import ExampleCodeBlock from 'components/Docs/ExampleCodeBlock'

export function DataSourceApiDeleteRecordCodeExample({
  id,
  input,
}: {
  id: string
  input: any
}) {
  const js = `await integrationApp
  .dataSourceInstance('${id}')
  .deleteRecord(${stringifyWithIndent(input)})
`
  const { curl } = httpCodeExamples(
    `/data-source-instances/${id}/collection/delete`,
    { method: 'POST', body: JSON.stringify(input) },
  )

  return (
    <ExampleCodeBlock
      customCodes={{ js, curl }}
      run={async ({ integrationApp }) =>
        await integrationApp.dataSourceInstance(id).deleteRecord(input)
      }
    />
  )
}

import { useState } from 'react'
import ExampleUIBlock from '../../Docs/ExampleUIBlock'

export function ConnectionParametersUiCodeExample() {
  return <ExampleUIBlock customCodes={{ jsx }} Component={Component} />
}

const jsx = `function ConnectionForm() {
  const [parameters, setParameters] = useState({})

  const schema = {
    type: 'object',
    properties: {
      email: { type: 'string' },
      apiKey: { type: 'string' },
    },
  }

  const fields = Object.keys(schema.properties)

  return (
    <>
      {fields.map((field) => (
        <div key={field}>
          <label>{field}</label>
          <input
            type='text'
            className='p-1 border rounded'
            value={parameters[field] || ''}
            onChange={(e) =>
              setParameters({ ...parameters, [field]: e.target.value })
            }
          />
        </div>
      ))}
    </>
  )
}
`

function Component() {
  const [connectionParameters, setConnectionParameters] = useState({})
  const schema = {
    type: 'object',
    properties: {
      email: { type: 'string' },
      apiKey: { type: 'string' },
    },
  }

  const fields = Object.keys(schema.properties)

  return (
    <>
      {fields.map((field) => (
        <div key={field}>
          <label>{field}</label>
          <input
            type='text'
            className='p-1 border rounded'
            value={connectionParameters[field] || ''}
            onChange={(e) =>
              setConnectionParameters({
                ...connectionParameters,
                [field]: e.target.value,
              })
            }
          />
        </div>
      ))}
    </>
  )
}

import classes from './DocTag.module.css'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { getArticle } from 'routes/Docs/helpers/articleList'

export default function DocTag({
  path,
  title = 'Learn more',
  className,
}: {
  path: string
  title?: string
  className?: string
}) {
  const article = getArticle(path)

  if (process.env.NODE_ENV === 'development' && !article) {
    return (
      <p className='bg-error text-white p-1 rounded nowrap font-bold'>
        {`NOT FOUND: <DocTag path='${path}' />`}
      </p>
    )
  }

  return (
    <NavLink
      to={
        article ? `/docs/${path}` : `/docs/overview` // fallback to docs root
      }
      title={title}
      className={clsx(classes.link, className)}
    >
      docs
    </NavLink>
  )
}

export function DocTagBadge({ className }: { className?: string }) {
  return <span className={clsx(classes.link, className)}>docs</span>
}

import ExampleCodeBlock from '../../Docs/ExampleCodeBlock'

const js = `const { items: connections } = await integrationApp.connections.find()`

export function ListConnectionsCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      run={async ({ integrationApp }) => {
        const { items: connections } = await integrationApp.connections.find()

        return { connections }
      }}
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Data Source UI",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Getting a Data Source",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Get information about the current Collection",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Get list of available Data Collections",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Selecting a Collection",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "See More",
    "attributes": {},
    "children": []
  }]
}];
import {DocGrid} from "components/Docs/DocLinksGrid";
import {CodeExample} from '../../../../../components/CodeExamples';
export const TITLE = 'Custom Data Source UI';
export const DESCRIPTION = 'Customizing data sources from inside your app.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    code: "code"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.GetConnectionDataCollection) _missingMdxReference("CodeExample.GetConnectionDataCollection", true);
  if (!CodeExample.GetDataSourceInstance) _missingMdxReference("CodeExample.GetDataSourceInstance", true);
  if (!CodeExample.ListIntegrationDataCollections) _missingMdxReference("CodeExample.ListIntegrationDataCollections", true);
  if (!CodeExample.PatchDataSourceInstanceCollection) _missingMdxReference("CodeExample.PatchDataSourceInstanceCollection", true);
  if (!DocGrid) _missingMdxReference("DocGrid", false);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "data-source-ui",
      children: "Data Source UI"
    }), "\n", _jsxs(_components.p, {
      children: ["When a ", _jsx(DocLink, {
        path: "engine/blueprints/data-sources",
        children: "Data Source"
      }), "\nhas multiple possible data locations, you may want to give your customers the ability to choose between them."]
    }), "\n", _jsx(_components.h2, {
      id: "getting-a-data-source",
      children: "Getting a Data Source"
    }), "\n", _jsxs(_components.p, {
      children: ["First, access a customer data source for the ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connection"
      }), " and ", _jsx(DocLink, {
        path: "engine/blueprints/data-sources",
        children: "Data Source"
      }), " you want to work with:"]
    }), "\n", _jsx(CodeExample.GetDataSourceInstance, {}), "\n", _jsx(_components.h2, {
      id: "get-information-about-the-current-collection",
      children: "Get information about the current Collection"
    }), "\n", _jsx(_components.p, {
      children: "To get name and other information about the currently selected Data Collection, use this code:"
    }), "\n", _jsx(CodeExample.GetConnectionDataCollection, {}), "\n", _jsxs(_components.p, {
      children: ["If there is no collection selected by default - ", _jsx(_components.code, {
        children: "null"
      }), " will be returned as the result."]
    }), "\n", _jsxs(_components.p, {
      children: ["When collection is selected you can display the name of the collection to the customer.\nIn some collections (say, ", _jsx(_components.code, {
        children: "Sheets"
      }), " in Google Sheets or ", _jsx(_components.code, {
        children: "Contacts in List"
      }), " in Hubspot) you will see ", _jsx(_components.code, {
        children: "parametersSchema"
      }), " present in the collection object - display the form that will allow Customer to set them (Some of them may be required, you will also see this from schema)."]
    }), "\n", _jsx(_components.h2, {
      id: "get-list-of-available-data-collections",
      children: "Get list of available Data Collections"
    }), "\n", _jsx(_components.p, {
      children: "When customer what to change selected collection you need to display a list of the available data locations. To do it use this code:"
    }), "\n", _jsx(CodeExample.ListIntegrationDataCollections, {}), "\n", _jsx(_components.p, {
      children: "If data source does not have any locations to navigate, this will return an empty list."
    }), "\n", _jsx(_components.h2, {
      id: "selecting-a-collection",
      children: "Selecting a Collection"
    }), "\n", _jsxs(_components.p, {
      children: ["When your customer selected a new collection for a data source, you can save it using ", _jsx(_components.code, {
        children: "patch"
      }), " method:"]
    }), "\n", _jsx(CodeExample.PatchDataSourceInstanceCollection, {}), "\n", _jsxs(_components.p, {
      children: ["After, check if collection has ", _jsx(_components.code, {
        children: "parametersSchema"
      }), " and ask customer to fill them as well, if needed."]
    }), "\n", _jsx(_components.h2, {
      id: "see-more",
      children: "See More"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/external-apps/connectors/data"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/data-sources"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Data Schemas",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Additional Properties",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Reference Records (Enums or Picklists):",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Reference Collection",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Rich Text",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Dynamic Data Schema",
    "attributes": {},
    "children": []
  }]
}];
export const DESCRIPTION = 'Representing data structures used in integrations.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "data-schemas",
      children: "Data Schemas"
    }), "\n", _jsx(_components.p, {
      children: "Data Schema is used whenever you need to describe the data structure without providing the actual value."
    }), "\n", _jsxs(_components.p, {
      children: ["Data Schema we use is based on ", _jsx(_components.a, {
        href: "https://json-schema.org/",
        children: "JSONSchema"
      }), " with additional features."]
    }), "\n", _jsx(_components.h2, {
      id: "additional-properties",
      children: "Additional Properties"
    }), "\n", _jsx(_components.h3, {
      id: "reference-records-enums-or-picklists",
      children: "Reference Records (Enums or Picklists):"
    }), "\n", _jsx(_components.p, {
      children: "To specify reference records that can be used for a field (for example, to create field with limited option for selection) use schema like this:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "status:\n  type: number // Type of `id` keys below\n  referenceRecords:\n    - name: Open // Title of that option displayed in the UI\n      id: 1 // Actual value that will be used after selecting an option\n    - name: Won\n      id: 2\n    - name: Lost\n      id: 3\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "reference-collection",
      children: "Reference Collection"
    }), "\n", _jsxs(_components.p, {
      children: ["When working in a context of a ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connection"
      }), ",\nyou can point to a specific ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/data",
        children: "Data Collection"
      }), " to use its records as possible values for a field."]
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "ownerId:\n  type: string\n  referenceCollection:\n    key: users\n    parameters:\n      isActive: true\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "rich-text",
      children: "Rich Text"
    }), "\n", _jsxs(_components.p, {
      children: ["There is an additional property ", _jsx(_components.code, {
        children: "isRichText"
      }), " (boolean) that can be used to specify that the field of type ", _jsx(_components.code, {
        children: "string"
      }), " is a rich text field."]
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "note:\n  type: string\n  isRichText: true\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "dynamic-data-schema",
      children: "Dynamic Data Schema"
    }), "\n", _jsxs(_components.p, {
      children: ["You can use ", _jsx(DocLink, {
        path: "engine/references/formulas",
        children: "Formulas"
      }), " in data schemas to make them dynamic. For example:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "parent:\n  type: object\n  properties:\n    type:\n      type: string\n      enum: ['tasks', 'projects']\n    id:\n      type: string\n      referenceCollection:\n        key:\n          $var: parent.type\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["In this, ", _jsx(_components.code, {
        children: "parent.id"
      }), " property will reference ", _jsx(_components.code, {
        children: "tasks"
      }), " collection if ", _jsx(_components.code, {
        children: "parent.type"
      }), " is ", _jsx(_components.code, {
        children: "tasks"
      }), " and ", _jsx(_components.code, {
        children: "projects"
      }), " collection if ", _jsx(_components.code, {
        children: "parent.type"
      }), " is ", _jsx(_components.code, {
        children: "projects"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Schema will be evaluated in the context of the current value the schema is applied to."
    }), "\n", _jsxs(_components.p, {
      children: ["It works in a special way for ", _jsx(_components.code, {
        children: "array"
      }), " schemas: when evaluating dynamic schemas, all arrays leading to the current value will be replaced with the current item that is being evaluated."]
    }), "\n", _jsx(_components.p, {
      children: "For example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "parents:\n  type: array\n  items:\n    type: object\n    properties:\n      type:\n        type: string\n        enum: ['tasks', 'projects']\n      id:\n        type: string\n        referenceCollection:\n          key:\n            $var: parents.type\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Notice the locator in the ", _jsx(_components.code, {
        children: "id"
      }), " field: ", _jsx(_components.code, {
        children: "parents.type"
      }), ". It treats ", _jsx(_components.code, {
        children: "parents"
      }), " as if it was an object rather than array. When evaluated, it will use the current item of the ", _jsx(_components.code, {
        children: "parents"
      }), " array."]
    }), "\n", _jsx(_components.p, {
      children: "For example, if the schema is applied to the following object:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "parents:\n  - type: tasks\n    id: 1\n  - type: projects\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["The schema for the first item of the ", _jsx(_components.code, {
        children: "parents"
      }), " list will be the following:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "type: object\nproperties:\n  type:\n    type: string\n    enum: ['tasks', 'projects']\n  id:\n    type: string\n    referenceCollection:\n      key: tasks\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "But for the second item, it will be:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "type: object\nproperties:\n  type:\n    type: string\n    enum: ['tasks', 'projects']\n  id:\n    type: string\n    referenceCollection:\n      key: projects\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

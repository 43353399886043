/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Building Native Field Mapping UI",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Create a Field Mapping",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Get a Field Mapping Instance",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Using DataForm",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Advanced Functionality",
    "attributes": {},
    "children": []
  }]
}];
import {FIELD_MAPPINGS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
import {DocGrid} from "components/Docs/DocLinksGrid";
import {CodeExample} from '../../../../../components/CodeExamples';
export const TITLE = 'Custom Field Mapping UI';
export const DESCRIPTION = 'Letting your users map fields between your app and external apps.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components), {Link, DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.FieldMappingInstanceUiWithDataForm) _missingMdxReference("CodeExample.FieldMappingInstanceUiWithDataForm", true);
  if (!CodeExample.GetFieldMappingInstance) _missingMdxReference("CodeExample.GetFieldMappingInstance", true);
  if (!CodeExample.GetFieldMappingInstanceDataForm) _missingMdxReference("CodeExample.GetFieldMappingInstanceDataForm", true);
  if (!DocGrid) _missingMdxReference("DocGrid", false);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "building-native-field-mapping-ui",
      children: "Building Native Field Mapping UI"
    }), "\n", _jsx(_components.h2, {
      id: "create-a-field-mapping",
      children: "Create a Field Mapping"
    }), "\n", _jsxs(_components.p, {
      children: ["Before building a field mapping UI, you need to create a ", _jsx(Link, {
        path: FIELD_MAPPINGS_ROUTE,
        children: "Field Mapping"
      }), ".\nSelect what you want to map your fields from and to, and the mapping direction. Then continue."]
    }), "\n", _jsx(_components.h2, {
      id: "get-a-field-mapping-instance",
      children: "Get a Field Mapping Instance"
    }), "\n", _jsx(_components.p, {
      children: "To apply field mapping to a specific Connection, you need to get a Field Mapping Instance:"
    }), "\n", _jsx(CodeExample.GetFieldMappingInstance, {}), "\n", _jsx(_components.p, {
      children: "Field Mapping Instance has the following properties:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "externalSchema"
        }), " - ", _jsx(DocLink, {
          path: "engine/references/data-schemas",
          children: "Data Schema"
        }), " on the external app side."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "appSchema"
        }), " - Data Schema on your app's side."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "importValue"
        }), " - mapping from external app to your app."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "exportValue"
        }), " - mapping from your app to external app."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "direction"
        }), " - mapping direction. Can be ", _jsx(_components.code, {
          children: "import"
        }), ", ", _jsx(_components.code, {
          children: "export"
        }), ", or ", _jsx(_components.code, {
          children: "both"
        }), "."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "using-dataform",
      children: "Using DataForm"
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsx(_components.p, {
        children: "This API is experimental and is likely to change significantly in the future.\nExpect needing to migrate this code when new versions of our SDK are released."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "To simplify working with schemas and values, we provide a DataForm class. It works like this:"
    }), "\n", _jsx(CodeExample.GetFieldMappingInstanceDataForm, {}), "\n", _jsx(_components.p, {
      children: "To put it all together, here is an example of a simple field mapping editing UI:"
    }), "\n", _jsx(CodeExample.FieldMappingInstanceUiWithDataForm, {}), "\n", _jsx(_components.h2, {
      id: "advanced-functionality",
      children: "Advanced Functionality"
    }), "\n", _jsx(_components.p, {
      children: "This example does not handle complex fields (like arrays or objects) and does not support formulas."
    }), "\n", _jsx(_components.p, {
      children: "Here are articles you should check out to build a more advanced field mapping UI:"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/field-mappings"
      }), _jsx(DocGrid.Link, {
        path: "engine/references/data-schemas"
      }), _jsx(DocGrid.Link, {
        path: "engine/references/formulas"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Customer-level Integrations",
  "attributes": {},
  "children": []
}];
import {CUSTOMERS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const TITLE = 'Customer-level Integrations';
export const DESCRIPTION = 'Building custom integration workflows for specific customers';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {DocLink, Link} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "customer-level-integrations",
      children: "Customer-level Integrations"
    }), "\n", _jsx(_components.p, {
      children: "Customer-level integrations are unique to a single customer."
    }), "\n", _jsxs(_components.p, {
      children: ["In most cases you will build customer-level integrations as ", _jsx(DocLink, {
        path: "engine/blueprints/flows"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "To build a customer-level flow:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Go to the ", _jsx(Link, {
          path: CUSTOMERS_ROUTE,
          children: "Customers"
        }), " page"]
      }), "\n", _jsx(_components.li, {
        children: "Select the customer you need (you can create one if it doesn't exist yet)."
      }), "\n", _jsx(_components.li, {
        children: "Go to the \"Flows\" page inside the customer's page."
      }), "\n", _jsx(_components.li, {
        children: "Create and configure a new flow."
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Instead of creating a fully custom flow from scratch, you can build a ", _jsx(DocLink, {
        path: "overview/building-integrations/universal",
        children: "Universal"
      }), "\nor ", _jsx(DocLink, {
        path: "overview/building-integrations/app-level",
        children: "App-level"
      }), " integration and then customize it for a specific customer."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

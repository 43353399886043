import { DataCollectionMethodParametersConfig } from './DataCollectionMethodParametersConfig'
import { DataSourceUnitConfig } from './DataSourceUnitConfig'
import { RecordIdFieldConfig } from './RecordIdFieldConfig'

export function DeleteDataRecordUnitConfig() {
  return (
    <>
      <DataSourceUnitConfig />

      <DataCollectionMethodParametersConfig methodKey='delete' />

      <RecordIdFieldConfig />
    </>
  )
}

import classes from '../NodeBody/NodeBody.module.css'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import clsx from 'clsx'
import { forwardRef } from 'react'

export const CommonRoundedButton = forwardRef<
  HTMLButtonElement,
  {
    onClick()
    iconType: SvgIconType
    className?: string
    squared?: boolean
  }
>(({ onClick, iconType, className, squared = false, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={clsx(
        className,
        classes.commonRoundedButton,
        squared && classes.commonRoundedButtonSquared,
      )}
      onClick={useEventStopPropagation(onClick)}
      {...props}
    >
      <SvgIcon type={iconType} />
    </button>
  )
})

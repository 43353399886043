import { useGenericConfig } from './contexts/generic-config-context'
import { DataSourceUnitConfig } from './DataSourceUnitConfig'
import { PaginationConfig } from './PaginationConfig'
import { SearchQueryConfig } from './SearchQueryConfig'
import { CustomizeImplementation } from './CustomizeImplementation'
import { ImportFieldMappingUnitConfig } from './ImportFieldMappingUnitConfig'
import { DataCollectionMethodParametersConfig } from './DataCollectionMethodParametersConfig'

export function SearchDataRecordsUnitConfig() {
  const { dataCollectionSpec } = useGenericConfig()

  const apiRequests = dataCollectionSpec?.search?.apiRequests

  return (
    <>
      <DataSourceUnitConfig />

      <DataCollectionMethodParametersConfig methodKey='search' />

      <PaginationConfig />

      <SearchQueryConfig />

      <CustomizeImplementation apiRequests={apiRequests} />

      <ImportFieldMappingUnitConfig />
    </>
  )
}

import { Link, To } from 'react-router-dom'

export function GoToElementLink({
  to,
  children = 'Go To Element',
}: {
  to: To
  children?: string
}) {
  return (
    <Link to={to} className='text-primaryColor hover:underline cursor-pointer'>
      {children} -&gt;
    </Link>
  )
}

import { PropsWithChildren } from 'react'
import clsx from 'clsx'
import classes from './styles.module.css'

export function DocFullPageHeader({ children }: PropsWithChildren) {
  return (
    <div className={classes.siteHeader}>
      <div className={clsx(classes.container, classes.siteHeader_content)}>
        {children}
      </div>
    </div>
  )
}

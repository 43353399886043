import { StaticEdge } from 'components/FlowBuilder/Graph/elements/Edge/StaticEdge'
import { Graph } from 'components/FlowBuilder/Graph'

import useFlowPreviewContext from '../flow-preview-context'
import { RegularOrTriggerNode } from './elements/RegularOrTriggerNode'
import { PortalNode } from './elements/PortalNode'
import { RootNode } from 'components/FlowBuilder/Graph/elements/RootNode'
import {
  GraphEdgeType,
  GraphNodeType,
} from '../../../FlowBuilder/Graph/elements'

const NODE_TYPES = {
  [GraphNodeType.Node]: RegularOrTriggerNode,
  [GraphNodeType.Trigger]: RegularOrTriggerNode,
  [GraphNodeType.Portal]: PortalNode,
  [GraphNodeType.Root]: RootNode,
}

const EDGE_TYPES = {
  [GraphEdgeType.Edge]: StaticEdge,
  [GraphEdgeType.EdgeToPortal]: StaticEdge,
}

export function FlowPreviewGraph() {
  const { flowNodes } = useFlowPreviewContext()

  return (
    <Graph
      flowNodes={flowNodes}
      nodeTypes={NODE_TYPES}
      edgeTypes={EDGE_TYPES}
      fitOnNodesChange
    />
  )
}

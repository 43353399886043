import Radiobutton from '@integration-app/ui/Radio'
import classes from './RadiobuttonBlockSelector.module.css'
import {
  createContext,
  ImgHTMLAttributes,
  PropsWithChildren,
  useContext,
} from 'react'
import clsx from 'clsx'

type RadiobuttonBlockSelectorRootProps = {
  selected?: boolean
  onChange?: () => void
}

const RadiobuttonBlockSelectorContext =
  createContext<RadiobuttonBlockSelectorRootProps>({
    selected: false,
    onChange: () => {},
  })

export const useRadiobuttonBlockSelectorContext = () =>
  useContext(RadiobuttonBlockSelectorContext)

function RadiobuttonBlockSelectorRoot({
  children,
  selected,
  onChange,
}: PropsWithChildren<RadiobuttonBlockSelectorRootProps>) {
  const values = { selected, onChange }

  return (
    <RadiobuttonBlockSelectorContext.Provider value={values}>
      <div
        className={clsx(
          classes.section,
          selected && classes.section__active,
          !selected && classes.section__inactive,
        )}
        onClick={onChange}
      >
        {children}
      </div>
    </RadiobuttonBlockSelectorContext.Provider>
  )
}

function RadiobuttonBlockSelectorHeader({ children }) {
  const { selected, onChange } = useRadiobuttonBlockSelectorContext()
  return (
    <h2 className={classes.section_header}>
      <Radiobutton checked={selected} onChange={onChange} />
      {children}
    </h2>
  )
}

function RadiobuttonBlockSelectorDescription({ children }) {
  return <div className={classes.section_description}>{children}</div>
}

function RadiobuttonBlockSelectorImage({
  ...props
}: ImgHTMLAttributes<HTMLImageElement>) {
  const { selected } = useRadiobuttonBlockSelectorContext()
  return (
    <div
      className={clsx(
        classes.section_image,
        selected && classes.section_image__selected,
      )}
    >
      <img {...props} />
    </div>
  )
}

function RadiobuttonBlockSelectorBody({ children }) {
  const { selected } = useRadiobuttonBlockSelectorContext()
  return (
    <div
      className={clsx(
        classes.section_body,
        !selected && classes.section_body__inactive,
      )}
    >
      {children}
    </div>
  )
}

function RadiobuttonBlockSelectorWrapper({ children }) {
  return <div className={classes.wrapper}>{children}</div>
}

export const RadiobuttonBlockSelector = Object.assign(
  RadiobuttonBlockSelectorRoot,
  {
    Wrapper: RadiobuttonBlockSelectorWrapper,
    Header: RadiobuttonBlockSelectorHeader,
    Description: RadiobuttonBlockSelectorDescription,
    Image: RadiobuttonBlockSelectorImage,
    Body: RadiobuttonBlockSelectorBody,
  },
)

import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'
import { DataForm } from '@integration-app/sdk'

const js = `import { DataForm } from '@integration-app/sdk'

const fieldMappingInstance = await integrationApp
  .connection('{INTEGRATION_KEY}')
  .fieldMapping('{FIELD_MAPPING_KEY}')
  .get({ autoCreate: true })

const importDataForm = new DataForm({
  schema: fieldMappingInstance.appSchema,
  value: fieldMappingInstance.importValue,
  variablesSchema: fieldMappingInstance.externalSchema,
})

const fields = importDataForm.getFields()
const fieldOptions = importDataForm.getFieldValueOptions(fields[0])
const newImportValue = importDataForm.setFieldValue(fields[0], fieldOptions[0])

// fieldMappingInstance.patch({ importValue: newImportValue })
`

export function GetFieldMappingInstanceDataFormCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FIELD_MAPPING_KEY: CodeParamType.FieldMappingKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        const fieldMappingInstance = await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .fieldMapping(parameters.FIELD_MAPPING_KEY)
          .get({ autoCreate: true })

        const importDataForm = new DataForm({
          schema: fieldMappingInstance.appSchema ?? {},
          value: fieldMappingInstance.importValue,
          variablesSchema: fieldMappingInstance.externalSchema,
        })

        const fields = importDataForm.getFields()
        const fieldOptions = importDataForm.getFieldValueOptions(fields[0])
        const newImportValue = importDataForm.setFieldValue(
          fields[0],
          fieldOptions[0],
        )

        return {
          fields,
          fieldOptions,
          newImportValue,
        }
      }}
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Connecting Your App API",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Authentication",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Integration.app Token (Default)",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Client Credentials",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "OAuth2",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Credentials",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Credentials Schema",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Initializing Credentials",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Refreshing Credentials",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "API Client",
    "attributes": {},
    "children": []
  }]
}];
import {YOUR_APP_API_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
import {CodeExample} from '../../../../../../components/CodeExamples';
export const TITLE = 'Connecting Your App API';
export const DESCRIPTION = 'Allowing integration.app to access your API when running integrations.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    h3: "h3",
    code: "code",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Link, DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.InitSdkWithCredentials) _missingMdxReference("CodeExample.InitSdkWithCredentials", true);
  if (!CodeExample.InitSdkWithFetchCredentials) _missingMdxReference("CodeExample.InitSdkWithFetchCredentials", true);
  if (!CodeExample.UpdateSdkUserCredentials) _missingMdxReference("CodeExample.UpdateSdkUserCredentials", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "connecting-your-app-api",
      children: "Connecting Your App API"
    }), "\n", _jsxs(_components.p, {
      children: ["When integration.app makes requests to your API, it will use configuration from the ", _jsx(Link, {
        path: YOUR_APP_API_ROUTE,
        children: "Your App / API"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "authentication",
      children: "Authentication"
    }), "\n", _jsx(_components.p, {
      children: "You can configure how integration.app will authenticate with your API by choosing an authentication method and providing the necessary configuration."
    }), "\n", _jsx(_components.h3, {
      id: "integrationapp-token-default",
      children: "Integration.app Token (Default)"
    }), "\n", _jsxs(_components.p, {
      children: ["With this authentication method, integration.app will add ", _jsx(_components.code, {
        children: "X-Integration-App-Token"
      }), " header to all requests to your API. It will contain a ", _jsx("a", {
        href: "https://jwt.io",
        children: "JSON Web Token"
      }), "\nsigned by the Workspace Secret. The token will contain the following claims:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "iss"
        }), " - Issuer, will be the key of the workspace integration runs in."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "sub"
        }), " - Subject, will be the ID of the user who owns the integration being run."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "fields"
        }), " - Fields of the user who owns the integration being run."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "You can decode and verify the token to authenticate the request."
    }), "\n", _jsx(_components.h3, {
      id: "client-credentials",
      children: "Client Credentials"
    }), "\n", _jsx(_components.p, {
      children: "With this authentication method, you can use credentials associated with the user to authenticate requests to your API."
    }), "\n", _jsx(_components.p, {
      children: "Configure the credentials schema, initialize credentials, and verify them in your API backend."
    }), "\n", _jsx(_components.p, {
      children: "See \"credentials\" section below for more details."
    }), "\n", _jsx(_components.h3, {
      id: "oauth2",
      children: "OAuth2"
    }), "\n", _jsx(_components.p, {
      children: "If your app uses OAuth2 authentication, choose this option. You will need to provide oAuth2 configuration and the initial accessToken and (optionally) refreshToken.\nIntegration.app will add accessToken to requests and use refreshToken to renew accessToken when it expires."
    }), "\n", _jsx(_components.p, {
      children: "You have to provide the following parameters for OAuth2 Configuration:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Client Id - OAuth client id integration.app should use to refresh the token."
      }), "\n", _jsx(_components.li, {
        children: "Client Secret - OAuth client secret integration.app should use to refresh the token."
      }), "\n", _jsx(_components.li, {
        children: "Token Uri - uri to send refresh token requests to."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "credentials",
      children: "Credentials"
    }), "\n", _jsx(_components.p, {
      children: "When you use any authentication method except Integration.app Token, you need to configure Credentials Schema and initialize user's credentials using one of the methods describe below."
    }), "\n", _jsx(_components.h3, {
      id: "credentials-schema",
      children: "Credentials Schema"
    }), "\n", _jsx(_components.p, {
      children: "Credentials Schema is a JSON Schema that describes the structure of credentials you need to authenticate requests to your API."
    }), "\n", _jsx(_components.h3, {
      id: "initializing-credentials",
      children: "Initializing Credentials"
    }), "\n", _jsx(_components.p, {
      children: "There are a few ways of initializing the user's credentials."
    }), "\n", _jsx(_components.p, {
      children: "Providing credentials when initializing Integration.app Javascript SDK:"
    }), "\n", _jsx(CodeExample.InitSdkWithCredentials, {}), "\n", _jsx(_components.p, {
      children: "Alternatively, you can provide fetchCredentials method that returns credentials asynchronously:"
    }), "\n", _jsx(CodeExample.InitSdkWithFetchCredentials, {}), "\n", _jsx(_components.p, {
      children: "Whenever you provide credentials or fetchCredentials, the SDK will associate them with the current user and use to authenticate requests going forward."
    }), "\n", _jsx(_components.p, {
      children: "You can also update credentials for the current user at any time via SDK or API:"
    }), "\n", _jsx(CodeExample.UpdateSdkUserCredentials, {}), "\n", _jsx(_components.h3, {
      id: "refreshing-credentials",
      children: "Refreshing Credentials"
    }), "\n", _jsx(_components.p, {
      children: "If you use OAuth2 authentication type, integration.app will automatically refresh credentials when HTTP 401 response code is received."
    }), "\n", _jsxs(_components.p, {
      children: ["It will follow the standard OAuth2 token refresh flow: ", _jsx("a", {
        href: "https://www.oauth.com/oauth2-servers/access-tokens/refreshing-access-tokens/",
        children: "Refreshing Access Token"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "api-client",
      children: "API Client"
    }), "\n", _jsx(_components.p, {
      children: "Configuration for your app API client follows the same structure as api client for custom connectors."
    }), "\n", _jsxs(_components.p, {
      children: ["Read the corresponding article for details: ", _jsx(DocLink, {
        path: "connector-builder/authentication",
        children: "API Client for Custom Connectors"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

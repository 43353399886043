/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Activity Log",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Monitoring",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Flow Runs",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "External Events",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Internal Events",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Webhook Notifications",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Troubleshooting",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Errors",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Integrations not doing the right thing",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Integration Not Running",
      "attributes": {},
      "children": []
    }]
  }]
}];
export const DESCRIPTION = 'Understanding what is happening in your integrations.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    h3: "h3",
    img: "img",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {DocLink, Link} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "activity-log",
      children: "Activity Log"
    }), "\n", _jsx(_components.p, {
      children: "After you implemented the integrations, you need to make sure they are working as expected. When they don't, you need to understand what is happening and why."
    }), "\n", _jsx(_components.h2, {
      id: "monitoring",
      children: "Monitoring"
    }), "\n", _jsx(_components.p, {
      children: "Integration.app keeps and provides you with detailed logs of what is happening in your integrations."
    }), "\n", _jsx(_components.h3, {
      id: "flow-runs",
      children: "Flow Runs"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/flow-runs.png",
      alt: "Flow Runs"
    }), "\n", _jsxs(_components.p, {
      children: ["Whenever a ", _jsx(DocLink, {
        path: "engine/blueprints/flows",
        children: "Flow"
      }), " runs, we record the following:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "What launched the flow (an event, schedule, or an API call)."
      }), "\n", _jsx(_components.li, {
        children: "Flow inputs."
      }), "\n", _jsx(_components.li, {
        children: "Runs of every node in the flow."
      }), "\n", _jsxs(_components.li, {
        children: ["Logs of every run:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "Data transformations."
          }), "\n", _jsx(_components.li, {
            children: "API requests."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["You can see the list of runs on the ", _jsx(Link, {
        path: "/activity-log/flow-runs",
        children: "Flow Runs"
      }), " page."]
    }), "\n", _jsxs(_components.p, {
      children: ["Learn more about flow runs here: ", _jsx(DocLink, {
        path: "engine/activity-log/flow-runs"
      })]
    }), "\n", _jsx(_components.h3, {
      id: "external-events",
      children: "External Events"
    }), "\n", _jsx(_components.p, {
      children: "Whenever an event one of your integrations is subscribed to happens in an external app, we record the event and any flows it triggered."
    }), "\n", _jsxs(_components.p, {
      children: ["You can explore the collected events on the ", _jsx(Link, {
        path: "/activity-log/external-events",
        children: "Data Source Events"
      }), " page."]
    }), "\n", _jsx(_components.h3, {
      id: "internal-events",
      children: "Internal Events"
    }), "\n", _jsx(_components.p, {
      children: "When your app sends an event to the integration engine, we record the event and any flows it triggered."
    }), "\n", _jsxs(_components.p, {
      children: ["You can explore the collected events on the ", _jsx(Link, {
        path: "/activity-log/app-events",
        children: "App Events"
      }), " page."]
    }), "\n", _jsxs(_components.p, {
      children: ["Learn more about internal events here: ", _jsx(DocLink, {
        path: "engine/blueprints/app-events",
        children: "App Events Documentation"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "webhook-notifications",
      children: "Webhook Notifications"
    }), "\n", _jsxs(_components.p, {
      children: ["If you want to receive notifications about events that happen in your integrations, you can add a webhook to your app and select which events you want to receive\non the ", _jsx(Link, {
        path: "/settings/webhook-nofications",
        children: "Webhook Notifications"
      }), " page."]
    }), "\n", _jsx(_components.h2, {
      id: "troubleshooting",
      children: "Troubleshooting"
    }), "\n", _jsx(_components.p, {
      children: "There are usually three types of problems with integrations:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Integration ran but resulted in an error."
      }), "\n", _jsx(_components.li, {
        children: "Integrate ran but didn't do what you expected it to do."
      }), "\n", _jsx(_components.li, {
        children: "Integration did not run when you expected it to."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Let's look at how to troubleshoot each of these problems."
    }), "\n", _jsx(_components.h3, {
      id: "errors",
      children: "Errors"
    }), "\n", _jsx(_components.p, {
      children: "When integration errored, follow red dots to find the reason:"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/follow-red-dots.png",
      alt: "Follow Red Dots"
    }), "\n", _jsx(_components.p, {
      children: "You can always find the error logs on the \"Error\" tab of the failed run. To understand what led to the error, check out the \"Logs\" tab."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/troubleshooting-logs.png",
      alt: "Logs"
    }), "\n", _jsx(_components.h3, {
      id: "integrations-not-doing-the-right-thing",
      children: "Integrations not doing the right thing"
    }), "\n", _jsx(_components.p, {
      children: "When integration ran, but didn't do what you expected it to do, go to the Flow Run page and trace its execution step by step."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/flow-run-graph.png",
      alt: "Flow Run Graph"
    }), "\n", _jsx(_components.p, {
      children: "Each node run contains detailed logs of what it did and why. You can see the logs by clicking on the node run and selecting the \"Logs\" tab."
    }), "\n", _jsx(_components.h3, {
      id: "integration-not-running",
      children: "Integration Not Running"
    }), "\n", _jsx(_components.p, {
      children: "When you expected an integration to run, but it did not, check the following:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Check event logs to see if the events you expected did happen:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsxs(_components.li, {
            children: [_jsx(Link, {
              path: "activity-log/data-source-events",
              children: "Data Source Events"
            }), " for external events."]
          }), "\n", _jsxs(_components.li, {
            children: [_jsx(Link, {
              path: "activity-log/app-events",
              children: "App Events"
            }), " for internal events."]
          }), "\n"]
        }), "\n"]
      }), "\n", _jsx(_components.li, {
        children: "If events did happen, check the event details to see if it launched the integration you expected. If not - check the trigger configuration."
      }), "\n", _jsxs(_components.li, {
        children: ["If events did not happen, check the following:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsxs(_components.li, {
            children: ["For external events: find the ", _jsx(DocLink, {
              path: "engine/deployments/data-source-instances",
              children: "Customer Data Source"
            }), " you expected to trigger the event and go to the \"Subscriptions and Events\" tab for troubleshooting."]
          }), "\n", _jsxs(_components.li, {
            children: ["For internal events:", "\n", _jsxs(_components.ul, {
              children: ["\n", _jsxs(_components.li, {
                children: ["If you have per-customer subscriptions, go to the ", _jsx(Link, {
                  path: "integrations/ux/app-event-types/subscriptions",
                  children: "Customer Subscriptions"
                }), " page, find the subscription you expected to generate the event, and see its details."]
              }), "\n", _jsx(_components.li, {
                children: "If you have global webhooks, try making an API call to the global webhook and check the response."
              }), "\n"]
            }), "\n"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import Slugger, { slug } from 'github-slugger'

/**
 * Returns a slug for the given value
 */
export function getSlug(value: string) {
  return slug(value)
}

/**
 * Returns a slug key for the given value
 * Slug key is trimmed and in lower case
 */
export function getSlugKey(value: string) {
  return getSlug(value.trim().toLowerCase())
}

/**
 * Returns a new slugger that keeps track of used slugs and adds a numerical
 */
export function getSlugger() {
  return new Slugger()
}

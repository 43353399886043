import { DataSchema } from '@integration-app/sdk'
import { Button } from '@integration-app/ui'
import { useState } from 'react'
import { BsMagic } from 'react-icons/bs'
import useWorkspace from '../../../../../../../components/Workspaces/workspace-context'

export function GenerateFieldMappingButton({
  fromSchema,
  toSchema,
  extraPrompt,
  onGenerate,
}: {
  fromSchema: DataSchema
  toSchema: DataSchema
  extraPrompt?: string
  onGenerate: (value: any) => void
}) {
  const { engineAdminClient } = useWorkspace()
  const [generating, setGenerating] = useState(false)

  async function handleGenerate() {
    setGenerating(true)
    try {
      const result = await engineAdminClient.post('/copilot/map-data-schemas', {
        fromSchema,
        toSchema,
        extraPrompt,
      })
      onGenerate(result)
    } finally {
      setGenerating(false)
    }
  }

  return (
    <Button
      variant='outline'
      size='small'
      onClick={handleGenerate}
      disabled={generating}
    >
      <BsMagic /> {generating ? 'Generating...' : 'Generate'}
    </Button>
  )
}

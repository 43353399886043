import { useConnectorSpec } from '@integration-app/react'
import { ApiRequestSpec } from '@integration-app/sdk'
import { Button, makeDataField } from '@integration-app/ui'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import useSWR from 'swr'
import useWorkspace from '../../../../../components/Workspaces/workspace-context'
import { CollapsibleSection } from '../Section'
import { useGenericConfig } from './contexts/generic-config-context'
import { ActionBarToggleOnOff } from '../Section/CollapsibleSection'

export function CustomizeImplementation({
  apiRequests,
}: {
  apiRequests?: ApiRequestSpec[]
}) {
  const { config, integrationId, patchConfig } = useGenericConfig()
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const { data: connectorSpec, loading } = useConnectorSpec(integrationId)

  if (!integrationId) {
    // Customization is only supported for integration-specific units
    return null
  }

  const isSupported =
    !connectorSpec?.api?.type || connectorSpec?.api?.type == 'openapi'
  const enabled = config?.override !== undefined

  const initialOverridValue: any = {}

  if (apiRequests) {
    initialOverridValue.apiRequests = []
    for (const apiRequest of apiRequests) {
      initialOverridValue.apiRequests.push({
        match: apiRequest,
      })
    }
  }

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>
        Customize API Requests
      </CollapsibleSection.Header>
      <CollapsibleSection.ActionBar>
        <ActionBarToggleOnOff
          checked={enabled}
          onChange={(value) => {
            void patchConfig({
              override: value ? initialOverridValue : undefined,
            })
          }}
        />
      </CollapsibleSection.ActionBar>
      {enabled ? (
        <>
          {loading ? (
            <div>Loading...</div>
          ) : isSupported ? (
            <OpenApiCustomization />
          ) : (
            <div>Customization is not available for this connector.</div>
          )}
        </>
      ) : (
        <p className='italic'>
          Turn this on to customize API requests made by this action.
        </p>
      )}
    </CollapsibleSection>
  )
}

function OpenApiCustomization() {
  const { config, patchConfig } = useGenericConfig()

  const apiRequests = config.override?.apiRequests ?? [{}]

  async function handleAddRequest() {
    return patchConfig({
      override: {
        apiRequests: [...apiRequests, {}],
      },
    })
  }

  async function handleRequestChange(index, request) {
    const newRequests = [...apiRequests]
    newRequests[index] = request
    return patchConfig({ override: { apiRequests: newRequests } })
  }

  async function handleRequestDelete(index) {
    const newRequests = [...apiRequests]
    newRequests.splice(index, 1)
    return patchConfig({ override: { apiRequests: newRequests } })
  }

  return (
    <div>
      <p>
        Modify API requests made by this action. Parameters you specify will be
        merged with the default request. In case of overlap, your parameters
        will take precedence.
      </p>
      {apiRequests.map((request, index) => (
        <OpenApiRequestCustomization
          key={index}
          value={request}
          onChange={(request) => handleRequestChange(index, request)}
          onDelete={() => handleRequestDelete(index)}
        />
      ))}
      <Button variant='outline' onClick={handleAddRequest}>
        Add request to customize
      </Button>
    </div>
  )
}

function OpenApiRequestCustomization({ value, onChange, onDelete }) {
  const { engineAdminFetcher } = useWorkspace()
  const { integrationId, variablesSchema } = useGenericConfig()

  const path = value?.match?.path
  const method = value?.match?.method
  const patch = value.patch

  const { data: paths } = useSWR(
    integrationId ? `/integrations/${integrationId}/openapi/paths` : undefined,
    engineAdminFetcher,
  )
  const { data: methods } = useSWR(
    integrationId && path
      ? `/integrations/${integrationId}/openapi/path-methods?path=${path}`
      : undefined,
    engineAdminFetcher,
  )
  const { data: requestSchema } = useSWR(
    integrationId && path && method
      ? `/integrations/${integrationId}/openapi/request-schema?path=${path}&method=${method}`
      : undefined,
    engineAdminFetcher,
  )

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>
        {path ?? 'New Request'}
      </CollapsibleSection.Header>
      <CollapsibleSection.ActionBar>
        <Button size='small' variant='outline' onClick={onDelete}>
          Delete
        </Button>
      </CollapsibleSection.ActionBar>
      When this request is made:
      <DataBuilderForm
        field={makeDataField({
          schema: {
            title: 'Path',
            type: 'string',
            enum: paths,
            allowCustom: true,
            description:
              'Partial path of the request to override. Use * to match any part of the path (i.e. "/users/*/create").',
          },
          value: path,
        })}
        onChange={(path) => onChange({ ...value, match: { path, method } })}
      />
      <DataBuilderForm
        field={makeDataField({
          schema: {
            title: 'Method',
            type: 'string',
            enum: methods,
            allowCustom: true,
            description: 'HTTP method of the request to override.',
          },
          value: method,
        })}
        frozenFieldsLocators={path ? [] : ['$']}
        onChange={(method) => onChange({ ...value, match: { path, method } })}
      />
      <div className='mt-2'>...add or replace these parameters:</div>
      {path && method ? (
        <>
          <DataBuilderForm
            field={makeDataField({
              schema: requestSchema || DEFAULT_REQUEST_SCHEMA,
              value: patch,
              variablesSchema,
            })}
            onChange={(patch) =>
              onChange({
                ...value,
                patch,
              })
            }
          />
        </>
      ) : (
        <p className='italic'>Select a path and method to customize.</p>
      )}
    </CollapsibleSection>
  )
}

const DEFAULT_REQUEST_SCHEMA = {
  type: 'object',
  properties: {
    headers: {
      additionalProperties: true,
      properties: {},
      type: 'object',
    },
    query: {
      additionalProperties: true,
      properties: {},
      type: 'object',
    },
    data: {
      additionalProperties: true,
      properties: {},
      type: 'object',
    },
  },
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "List",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Specification",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Input",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Output",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Drilldowns",
    "attributes": {},
    "children": []
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    code: "code",
    a: "a",
    pre: "pre"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "list",
      children: "List"
    }), "\n", _jsx(_components.p, {
      children: "Lists records in the data collection page by page."
    }), "\n", _jsx(_components.h2, {
      id: "specification",
      children: "Specification"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "parametersSchema"
        }), " - schema for parameters that can be passed to the operation."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "filterFields"
        }), " - list of fields from the ", _jsx(_components.code, {
          children: "fieldsSchema"
        }), " that can be used to filter the records."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "input",
      children: "Input"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "parameters"
        }), " - parameters matching ", _jsx(_components.code, {
          children: "parametersSchema"
        }), "."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "filter"
        }), " - list of fields from ", _jsx(_components.code, {
          children: "filterFileds"
        }), " and their values to filter the records by."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "cursor"
        }), " - cursor returned from the previous page of the results. If not provided, the first page is returned."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "output",
      children: "Output"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "records"
        }), " - list of records that match the filter."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "cursor"
        }), " - cursor for the next page of the results. If not provided, there are no more pages."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "drilldowns"
        }), " - list of additional requests that should be made to this operation to get all the records. See ", _jsx(_components.a, {
          href: "#drilldowns",
          children: "Drilldowns"
        }), " for more information."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "drilldowns",
      children: "Drilldowns"
    }), "\n", _jsx(_components.p, {
      children: "If it is not possible to return all records at once and additional requests need to be made, you can return a list of drilldowns."
    }), "\n", _jsx(_components.p, {
      children: "Each drilldown can have the following properties:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "parameters"
        }), " - parameters for the drilldown operation."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "filter"
        }), " - filter for the drilldown operation."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Example:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-json",
        children: "{\n    \"drilldowns\": [\n        {\n            \"parameters\": {\n                \"recursive\": true\n            },\n            \"filter\": {\n                \"type\": \"file\",\n                \"parentFolderId\": \"123\"\n            }\n        },\n        {\n            \"parameters\": {\n                \"recursive\": true\n            },\n            \"filter\": {\n                \"type\": \"file\",\n                \"parentFolderId\": \"124\"\n            }\n        }\n    ]\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Examples when drilldown can be used:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Requesting a list of files, but the external application only supports retruning files from one folder at a time. You can return drilldowns for each folder."
      }), "\n", _jsx(_components.li, {
        children: "Requesting a list of tasks, but the API only supports returning tasks from a given project. You can return a list of drilldowns, one for for each project."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Drilldowns can be recursive, i.e. when returning list of files from a sub-folder, you can return drilldowns for each sub-sub-folder, and so on."
    }), "\n", _jsxs(_components.p, {
      children: ["If you cannot get all the drilldowns with one request, use ", _jsx(_components.code, {
        children: "cursor"
      }), ". A response can return no records but only drilldowns - the engine will iterate over pages of drilldowns and collect them all."]
    }), "\n", _jsx(_components.p, {
      children: "Drilldowns are executed in order they were returned, using the following logic:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "When making the original request, all the records are returned right away, and drilldowns saved in the cursor."
      }), "\n", _jsxs(_components.li, {
        children: ["When no more ", _jsx(_components.code, {
          children: "cursor"
        }), " is returned, the engine will start making requests for drilldowns in order they were returned."]
      }), "\n", _jsx(_components.li, {
        children: "If drilldowns return more drilldowns, they are appended to the list of drilldowns to execute."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;

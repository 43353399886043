/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "HTTP Request",
  "attributes": {},
  "children": [{
    "depth": 3,
    "value": "Batch Mode",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'HTTP Request';
export const DESCRIPTION = 'Send or receive data to/from API via HTTP(S)';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h3: "h3",
    code: "code"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "http-request",
      children: "HTTP Request"
    }), "\n", _jsx(_components.p, {
      children: "This node sends an HTTP request to the specified URL with specified content."
    }), "\n", _jsx(_components.h3, {
      id: "batch-mode",
      children: "Batch Mode"
    }), "\n", _jsxs(_components.p, {
      children: ["In some cases - for example when using ", _jsx(DocLink, {
        path: "engine/blueprints/flows/nodes/find-data-record-by-id"
      }), " node, you want to avoid sending dozens or hundreds of HTTP requests.\nYou can do this Batch Mode."]
    }), "\n", _jsxs(_components.p, {
      children: ["When using Batch Mode, the node's input becomes a list of up to ", _jsx(_components.code, {
        children: "batchSize"
      }), " items."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import {
  Flow,
  FlowInstance,
  FlowNode,
  FlowInstanceNode,
} from '@integration-app/sdk'
import { useParams, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'

export function useSelectingNodeState(
  nodes: Flow['nodes'] | FlowInstance['nodes'],
  routeBeforeNodes: string,
) {
  const navigate = useNavigate()

  const params = useParams<{
    nodeKey?: string
  }>()

  const [selectedNodeKey, setSelectedNodeKey] = useState<string>('')
  const [selectedNode, setSelectedNode] = useState<
    FlowNode | FlowInstanceNode
  >()

  useEffect(() => {
    if (selectedNodeKey !== params.nodeKey) {
      setSelectedNodeKey(params.nodeKey ?? '')
    }
  }, [params.nodeKey])

  useEffect(() => {
    setSelectedNode(nodes?.[selectedNodeKey])
  }, [selectedNodeKey])

  const selectNode = useCallback(
    (nodeKey: string) => {
      if (nodeKey) {
        navigate(`${routeBeforeNodes}/nodes/${nodeKey}`)
        setSelectedNodeKey(nodeKey)
      } else {
        navigate(`${routeBeforeNodes}`)
        setSelectedNodeKey('')
      }
    },
    [navigate, routeBeforeNodes],
  )

  const getNode = useCallback(
    (nodeKey: string) => {
      return nodes?.[nodeKey]
    },
    [JSON.stringify(nodes)],
  )

  return useMemo(
    () => ({
      selectedNodeKey,
      selectedNode,
      selectNode,
      getNode,
    }),
    [selectedNodeKey, selectedNode, selectNode, getNode],
  )
}

export interface SelectingNodeState<FlowNodeType = FlowNode> {
  selectedNodeKey: string
  selectedNode: FlowNodeType | undefined
  selectNode: (nodeKey: string) => void
  getNode: (nodeKey: string) => FlowNodeType | undefined
}

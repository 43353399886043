import { SchemaBuilder, SimpleRadio } from '@integration-app/ui'

import { CollapsibleSection } from '../../../../components/Section'
import AppDataSchemaSelect from '../../../../../../../components/AppDataSchemas/AppDataSchemaSelect'

enum InternalSchemaOption {
  Auto = 'auto',
  Shared = 'shared',
  Custom = 'custom',
}

export function FieldMappingInternalSchema({ schema, onChange }) {
  let selectedOption = InternalSchemaOption.Auto

  if (schema) {
    selectedOption = InternalSchemaOption.Custom
  }

  if (schema?.$ref !== undefined) {
    selectedOption = InternalSchemaOption.Shared
  }

  function handleSelectOption(option: InternalSchemaOption) {
    switch (option) {
      case InternalSchemaOption.Auto:
        return onChange(undefined)
      case InternalSchemaOption.Shared:
        return onChange({
          $ref: null,
        })
      case InternalSchemaOption.Custom:
        return onChange({
          type: 'object',
        })
    }
  }

  return (
    <div className='flex flex-col'>
      <CollapsibleSection open>
        <CollapsibleSection.Header>Internal Fields</CollapsibleSection.Header>

        <p>Data schema in your app to map fields to/from.</p>

        <div className='my-4 flex flex-col gap-1'>
          <SimpleRadio
            label='Auto-detect from mapping'
            checked={selectedOption === InternalSchemaOption.Auto}
            onCheck={() => handleSelectOption(InternalSchemaOption.Auto)}
          />

          <SimpleRadio
            label='Custom schema'
            checked={selectedOption === InternalSchemaOption.Custom}
            onCheck={() => handleSelectOption(InternalSchemaOption.Custom)}
          />

          <SimpleRadio
            label='Shared App Data Schema'
            checked={selectedOption === InternalSchemaOption.Shared}
            onCheck={() => handleSelectOption(InternalSchemaOption.Shared)}
          />
        </div>

        {selectedOption === InternalSchemaOption.Custom && (
          <SchemaBuilder schema={schema} onChange={onChange} />
        )}

        {selectedOption === InternalSchemaOption.Shared && (
          <AppDataSchemaSelect
            value={schema?.$ref}
            onChange={(key) =>
              onChange({
                $ref: key,
              })
            }
          />
        )}
      </CollapsibleSection>
    </div>
  )
}

import { HTMLAttributes } from 'react'
import clsx from 'clsx'
import UiTab from '@integration-app/ui/Tab'
import CodeHighlighter, { ICodeHighlighter } from 'components/CodeHighlighter'
import classes from './ExampleBlock.module.css'
import useMeasure from 'react-use-measure'

function ExampleBlockWrapper({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return <GenericExampleBlock {...props}>{children}</GenericExampleBlock>
}

function GenericExampleBlock({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx(classes.exampleBlock, className)} {...props}>
      {children}
    </div>
  )
}

function ExampleBlockParameters({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx(classes.exampleBlockParameters, className)} {...props}>
      {children}
    </div>
  )
}

function ExampleBlockHeader({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx(classes.exampleBlockHeader, className)} {...props}>
      {children}
    </div>
  )
}

function ExampleBlockSection({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx(classes.exampleBlockSection, className)} {...props}>
      {children}
    </div>
  )
}

function ExampleBlockToolbar({ children, ...props }) {
  return (
    <ExampleBlockSection className={classes.exampleBlockToolbar} {...props}>
      {children}
    </ExampleBlockSection>
  )
}

interface ExampleBlockOutputProps extends HTMLAttributes<HTMLDivElement> {
  error?: boolean
}

function ExampleBlockOutput({
  children,
  className,
  error,
  ...props
}: ExampleBlockOutputProps) {
  const [ref, { height }] = useMeasure()

  const shouldFade = height > 280 // 288 is about a height of output block limited in .exampleBlockOutput class

  return (
    <div
      ref={ref}
      className={clsx(
        classes.exampleBlockOutput,
        error && classes.exampleBlockOutput_error,
        shouldFade && classes.fadeBottomContent,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

function ExampleBlockCodeBlock(props: Omit<ICodeHighlighter, 'className'>) {
  return <CodeHighlighter {...props} />
}

export const ExampleBlock = Object.assign(ExampleBlockWrapper, {
  GenericBlock: GenericExampleBlock,
  Parameters: ExampleBlockParameters,
  Header: ExampleBlockHeader,
  Section: ExampleBlockSection,
  Toolbar: ExampleBlockToolbar,
  Output: ExampleBlockOutput,
  CodeBlock: ExampleBlockCodeBlock,
})

const Group = ({ children, ...props }) => (
  <UiTab.Group as={ExampleBlock} {...props}>
    {children}
  </UiTab.Group>
)

const List = ({ children, ...props }) => (
  <UiTab.List as={ExampleBlock.Header} className={classes.tabList} {...props}>
    {children}
  </UiTab.List>
)

const TabItem = ({ children, ...props }) => (
  <UiTab {...props} className={classes.tab}>
    {children}
  </UiTab>
)

const Panels = ({ children, ...props }) => (
  <UiTab.Panels
    as={ExampleBlock.Section}
    className={classes.tabPanelWrapper}
    {...props}
  >
    {children}
  </UiTab.Panels>
)

type TabType = typeof TabItem & {
  Group: typeof Group
  List: typeof List
  Panels: typeof Panels
  Panel: typeof UiTab.Panel
}

export const Tab: TabType = Object.assign(TabItem, {
  Group: Group,
  List: List,
  Panels: Panels,
  Panel: UiTab.Panel,
})

import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'
import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .flowRun('{FLOW_RUN_ID}')
  .getOutput({
    nodeKey: '{NODE_KEY}',
  })
`

const rest = httpCodeExamples(
  '/flow-runs/{FLOW_RUN_ID}/output?nodeKey={NODE_KEY}',
  {
    method: 'GET',
  },
)

export function GetFlowRunOutputWithNodeKeyCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{
        js,
        ...rest,
      }}
      parameters={{
        FLOW_RUN_ID: CodeParamType.String,
        NODE_KEY: CodeParamType.String,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp.flowRun(parameters.FLOW_RUN_ID).getOutput()
      }}
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "UI for Managing Connections",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "List Integrations",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "List Connections",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Connect",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Disconnect",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Next Steps",
    "attributes": {},
    "children": []
  }]
}];
import {DocGrid} from "components/Docs/DocLinksGrid";
import SubHeader from "components/Docs/SubHeader";
import {INTEGRATIONS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const TITLE = 'Custom List of Integrations';
export const DESCRIPTION = 'Show available integrations, connect and disconnect external apps.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    code: "code"
  }, _provideComponents(), props.components), {Link, DocLink, CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.ArchiveConnection) _missingMdxReference("CodeExample.ArchiveConnection", true);
  if (!CodeExample.ListConnections) _missingMdxReference("CodeExample.ListConnections", true);
  if (!CodeExample.ListIntegrations) _missingMdxReference("CodeExample.ListIntegrations", true);
  if (!CodeExample.OpenNewConnection) _missingMdxReference("CodeExample.OpenNewConnection", true);
  if (!DocGrid) _missingMdxReference("DocGrid", false);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "ui-for-managing-connections",
      children: "UI for Managing Connections"
    }), "\n", _jsx(SubHeader, {
      children: "Display the list of integrations, connect and disconnect external apps, configure connections."
    }), "\n", _jsx(_components.h2, {
      id: "list-integrations",
      children: "List Integrations"
    }), "\n", _jsx(_components.p, {
      children: "Your integration experience likely starts with a list of integrations your user can enable."
    }), "\n", _jsx(_components.p, {
      children: "To get this list, use the following code:"
    }), "\n", _jsx(CodeExample.ListIntegrations, {}), "\n", _jsxs(_components.p, {
      children: ["Integrations that already have a Connection created will have a ", _jsx(_components.code, {
        children: "connection"
      }), " property.\nYou can use it to indicate that the integration is already connected."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can manage the list of available integrations on the ", _jsx(Link, {
        path: INTEGRATIONS_ROUTE,
        children: "Integrations"
      }), " page."]
    }), "\n", _jsx(_components.h2, {
      id: "list-connections",
      children: "List Connections"
    }), "\n", _jsx(_components.p, {
      children: "If you want to list all the connections user has created without listing all the available integrations, do this:"
    }), "\n", _jsx(CodeExample.ListConnections, {}), "\n", _jsx(_components.h2, {
      id: "connect",
      children: "Connect"
    }), "\n", _jsx(_components.p, {
      children: "To connect a user's account in a specific third-party app, call this method:"
    }), "\n", _jsx(CodeExample.OpenNewConnection, {}), "\n", _jsxs(_components.p, {
      children: ["Or if you want to create your own authentication UI, check out this guide: ", _jsx(DocLink, {
        path: "ux/connection/custom"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "disconnect",
      children: "Disconnect"
    }), "\n", _jsx(_components.p, {
      children: "To disconnect a specific connection, call this method:"
    }), "\n", _jsx(CodeExample.ArchiveConnection, {}), "\n", _jsx(_components.h2, {
      id: "next-steps",
      children: "Next Steps"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "ux/connection/custom"
      }), _jsx(DocGrid.Link, {
        path: "ux/flows/custom"
      }), _jsx(DocGrid.Link, {
        path: "ux/data-sources/custom"
      }), _jsx(DocGrid.Link, {
        path: "ux/field-mappings/custom"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

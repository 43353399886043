/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Operations",
  "attributes": {},
  "children": []
}];
export const TITLE = 'Operations';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "operations",
      children: "Operations"
    }), "\n", _jsx(_components.p, {
      children: "Operation are used to extend the API of external application with functionality that is not supported by it natively, but could be re-used across integrations."
    }), "\n", _jsx(_components.p, {
      children: "Example of situations when operations could be useful:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Getting information about the current connection (i.e. account connection is created for)."
      }), "\n", _jsx(_components.li, {
        children: "Complex transformations of data received from / sent to API (i.e. parsing of raw email body)."
      }), "\n", _jsx(_components.li, {
        children: "Combining multiple API calls in a way that is commonly used (i.e. query list of ids of object and then query their details in a separate request)."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;

import {
  DataSchema,
  FieldMappingDirection,
  buildDataSchema,
  getLocatorsFromSchema,
  getSchemaByLocator,
  locatorToField,
  schemaWithTitle,
} from '@integration-app/sdk'
import { FieldMappingSelect } from '../FieldMappingSelect'
import { useGenericConfig } from '../contexts/generic-config-context'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { makeDataField } from '@integration-app/ui'
import CallOut from '../../../../../../components/Docs/CallOut'
import useWorkspace from '../../../../../../components/Workspaces/workspace-context'
import useSWR from 'swr'

export function SharedExportFieldMapping() {
  const { engineAdminFetcher } = useWorkspace()
  const {
    patchFieldMappingConfig,
    fieldMappingConfig,
    fieldMapping,
    integrationId,
    variablesSchema,
    editableVariablesSchemaLocators,
    handleAddVariable,
  } = useGenericConfig()

  const { data: appSchema } = useSWR(
    fieldMapping?.id
      ? `/field-mappings/${fieldMapping.id}/app-schema`
      : undefined,
    engineAdminFetcher,
  )

  return (
    <>
      <div className='my-4'>
        <p>
          This field mapping will be synchronized between all actions and flows
          that use it.
        </p>
        <FieldMappingSelect
          fieldMappingKey={fieldMappingConfig.key}
          integrationId={integrationId}
          direction={FieldMappingDirection.EXPORT}
          onChange={(key) => patchFieldMappingConfig({ key })}
        />
      </div>

      {fieldMapping && (
        <>
          <p>
            This field mapping requires input in a specific format. Please
            provide it below.
          </p>

          <DataBuilderForm
            field={makeDataField({
              value: fieldMappingConfig.input,
              schema: schemaWithTitle(appSchema, 'Field Mapping Input'),
              variablesSchema,
            })}
            onChange={(input: any) => patchFieldMappingConfig({ input })}
            editableVariablesSchemaLocators={editableVariablesSchemaLocators}
            onAddVariable={handleAddVariable}
            hideReadOnlyFields
          />

          <div className='mt-4'>
            <SchemaMismatchWarning
              expectedSchema={fieldMapping?.appSchema}
              actualSchema={buildDataSchema(
                fieldMappingConfig.input,
                variablesSchema,
              )}
            />
          </div>
        </>
      )}
    </>
  )
}

function SchemaMismatchWarning({
  expectedSchema,
  actualSchema,
}: {
  expectedSchema: DataSchema | undefined
  actualSchema: DataSchema
}) {
  const expectedSchemaFields = getLocatorsFromSchema(expectedSchema)
  const actualSchemaFields = getLocatorsFromSchema(actualSchema)

  const mismatchedFields: Record<string, { expected: string; actual: string }> =
    {}
  actualSchemaFields.forEach((field) => {
    const expectedFieldSchema = getSchemaByLocator(expectedSchema, field)
    const actualFieldSchema = getSchemaByLocator(actualSchema, field)
    if (
      expectedFieldSchema?.type &&
      actualFieldSchema?.type &&
      actualFieldSchema?.type !== expectedFieldSchema.type
    ) {
      mismatchedFields[field] = {
        expected: expectedFieldSchema.type,
        actual: actualFieldSchema?.type,
      }
    }
  })

  const unexpectedFieldsPresent = actualSchemaFields
    .filter((field) => !expectedSchemaFields.includes(field))
    .map(locatorToField)

  if (expectedSchemaFields.length) {
    if (!actualSchemaFields.length) {
      return (
        <CallOut type='warning'>
          An input value is required for field mapping to function.
        </CallOut>
      )
    } else {
      if (unexpectedFieldsPresent.length > 0) {
        return (
          <CallOut type='warning'>
            The input value contains unexpected fields:
            <ul className='list-disc'>
              {unexpectedFieldsPresent.slice(0, 5).map((field) => (
                <li>{field}</li>
              ))}
            </ul>
            Please check if the input value is correct.
          </CallOut>
        )
      } else if (Object.keys(mismatchedFields).length > 0) {
        return (
          <CallOut type='warning'>
            The input value contains fields with mismatched types:{' '}
            {Object.entries(mismatchedFields)
              .map(([field, { expected, actual }]) => {
                return `${field} (expected ${expected}, got ${actual})`
              })
              .join(', ')}
          </CallOut>
        )
      }
    }
  }

  return null
}

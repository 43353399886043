import Toggle from '@integration-app/ui/Toggle'
import { useGenericConfig } from '../contexts/generic-config-context'

export function IncludeRawFieldsBlock() {
  const { patchFieldMappingConfig, fieldMappingConfig } = useGenericConfig()

  return (
    <div className={'flex flex-row gap-2 items-baseline justify-start mt-4'}>
      <Toggle
        id={'toggleRawFields'}
        checked={fieldMappingConfig.includeRawFields}
        onChange={(includeRawFields) =>
          patchFieldMappingConfig({ includeRawFields })
        }
      />

      <label htmlFor={'toggleRawFields'} className={'cursor-pointer'}>
        Include Raw Fields
        <br />
        <span className={'text-neutral04'}>
          If enabled - adds <code>rawFields</code> property to the returned
          records. It contains the original fields returned by the external
          application.
        </span>
      </label>
    </div>
  )
}

import Button from '@integration-app/ui/Button'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import classes from 'components/Docs/CodeBlockUI/CodeBlockComponents.module.css'

export function CodeBlockRunButton(props) {
  return (
    <Button
      variant={'secondary'}
      className={classes.codeExampleRunButton}
      {...props}
    />
  )
}

export function CodeBlockNotification({ children }) {
  return (
    <div className={classes.codeExampleNotification}>
      <SvgIcon type={SvgIconType.InfoCircle} />
      <div>{children}</div>
    </div>
  )
}

import { DataSchema, schemaWithTitle } from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { CollapsibleSection } from '../Section'
import { useGenericConfig } from './contexts/generic-config-context'

export function DataCollectionMethodParametersConfig({
  methodKey,
}: {
  methodKey: string
}) {
  const { dataCollectionSpec, patchConfig, config, variablesSchema } =
    useGenericConfig()

  const parametersSchema: DataSchema =
    dataCollectionSpec?.[methodKey]?.parametersSchema

  if (!parametersSchema) return null

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Parameters</CollapsibleSection.Header>

      <DataBuilderForm
        field={makeDataField({
          schema: schemaWithTitle(parametersSchema, 'Parameters'),
          value: config.parameters,
          variablesSchema,
        })}
        onChange={(parameters) => patchConfig({ parameters })}
      />
    </CollapsibleSection>
  )
}

import { NavLink } from 'react-router-dom'
import { HttpRequestConfig } from '../../../../../../../components/common-configs/HttpRequestConfig'
import { HttpResponseConfig } from '../../../../../../../components/common-configs/HttpResponseConfig'
import { YOUR_APP_API_ROUTE } from '../../../../../../../routes-constants'

export function ApiRequestToYourApp() {
  return (
    <>
      <p>
        You can configure authentication and default parameters of these
        requests here:{' '}
        <NavLink className='link' to={YOUR_APP_API_ROUTE}>
          My App API configuration
        </NavLink>
        .
      </p>

      <HttpRequestConfig />

      <HttpResponseConfig />
    </>
  )
}

import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .flow('{FLOW_KEY}')
  .patch({ enabled: {ENABLED} })
`

export function ToggleFlowEnabledCodeExample({
  parametersExpandedByDefault,
  afterRun,
}: {
  parametersExpandedByDefault?: boolean
  afterRun?: () => void
}) {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parametersExpandedByDefault={parametersExpandedByDefault}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FLOW_KEY: CodeParamType.FlowKey,
        ENABLED: {
          type: CodeParamType.Boolean,
          default: true,
        },
      }}
      run={async ({ integrationApp, parameters }) => {
        const response = await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .flow(parameters.FLOW_KEY)
          .patch({
            enabled: parameters.ENABLED ?? true,
          })

        afterRun?.()

        return response
      }}
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "App Events",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "App Event Types",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Global Webhook",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Per-customer Subscriptions",
    "attributes": {},
    "children": []
  }]
}];
import {APP_EVENT_TYPES_ROUTE, APP_EVENT_SUBSCRIPTIONS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
import {DocGrid} from "components/Docs/DocLinksGrid";
export const DESCRIPTION = 'Reacting to events in your app';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    img: "img",
    code: "code"
  }, _provideComponents(), props.components), {DocLink, Link} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "app-events",
      children: "App Events"
    }), "\n", _jsxs(_components.p, {
      children: ["App Events let integration.app know when something happens in your app. They can be used to trigger Flows using ", _jsx(DocLink, {
        path: "engine/blueprints/flows/nodes/app-event-trigger",
        children: "App Event Trigger"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "app-event-types",
      children: "App Event Types"
    }), "\n", _jsxs(_components.p, {
      children: ["To use App Events, you need to define one or more App Event Types. You can do it on the ", _jsx(Link, {
        path: APP_EVENT_TYPES_ROUTE,
        children: "App Event Types"
      }), " page."]
    }), "\n", _jsx(_components.p, {
      children: "Each App Event Type has:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Schema - a ", _jsx(DocLink, {
          path: "engine/references/data-schemas"
        }), " describing the event's contents."]
      }), "\n", _jsx(_components.li, {
        children: "Example - an example of the event's contents matching the Schema. You can use Example to generate schema."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "global-webhook",
      children: "Global Webhook"
    }), "\n", _jsx(_components.p, {
      children: "Use Global Webhook to send app events to integration.app in a centralized (not per-user) way.\nSimply make an HTTP request of any type to the webhook URI and the event will be captured and processed."
    }), "\n", _jsx(_components.h2, {
      id: "per-customer-subscriptions",
      children: "Per-customer Subscriptions"
    }), "\n", _jsx(_components.p, {
      children: "If instead of a Global Webhook, you want to use per-user webhooks or if you already have API that allows per-user event subscriptions,\nyou can configure subscriptions for your App Event."
    }), "\n", _jsx(_components.p, {
      children: "To do this, configure the Subscription Request:"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/app-event-type-subscription.png",
      alt: ""
    }), "\n", _jsxs(_components.p, {
      children: ["You can use ", _jsx(DocLink, {
        path: "engine/deployments/customers",
        children: "Customer Fields"
      }), " and ", _jsx(_components.code, {
        children: "webhookUri"
      }), " variables to configure the request."]
    }), "\n", _jsxs(_components.p, {
      children: ["The request will be called the first time this App Event Type is used for a given User.\nYou can find the subscription request and response, as well as other information about subscriptions on the ", _jsx(Link, {
        path: APP_EVENT_SUBSCRIPTIONS_ROUTE,
        children: "Subscriptions"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

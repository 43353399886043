import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { getDocRelativeLink, useArticleData } from './helpers'
import classes from './DocGrid.module.css'

function DocGridRoot({
  children,
  className,
  cols = 3,
}: PropsWithChildren<{
  className?: string
  cols?: GridProps['cols']
}>) {
  return (
    <Grid cols={cols} className={className}>
      {children}
    </Grid>
  )
}

function DocLink({
  path,
  title: customTitle,
  highlight = false,
  target,
}: {
  path: string
  title?: string
  highlight?: boolean
  target?: string
}) {
  const { title, description } = useArticleData(path)

  const to = getDocRelativeLink(path)

  if (!title) {
    return null
  }

  return (
    <div className={clsx(classes.item, highlight && classes.item__highlight)}>
      <h4 className={classes.item_title}>
        <NavLink to={to} target={target} className={classes.item_titleLink}>
          {customTitle || title}
        </NavLink>
      </h4>
      {description && <p className={classes.item_description}>{description}</p>}
    </div>
  )
}

function ExternalLink({
  href,
  title,
  className,
  highlight,
  description,
}: {
  href: string
  title: string
  className?: string
  description?: string
  highlight?: boolean
}) {
  return (
    <div
      className={clsx(
        classes.item,
        highlight && classes.item__highlight,
        className,
      )}
    >
      <h4 className={classes.item_title}>
        <a href={href} target='_blank' className={classes.item_titleLink}>
          {title}
        </a>
      </h4>
      {description && <p className={classes.item_description}>{description}</p>}
    </div>
  )
}

export const DocGrid = Object.assign(DocGridRoot, {
  Link: DocLink,
  ExternalLink: ExternalLink,
})

type GridProps = {
  className?: string
  cols?: 2 | 3 | 4
}

function Grid({ children, className, cols }: PropsWithChildren<GridProps>) {
  return (
    <div
      className={clsx(
        classes.gridBlock,
        cols === 2 && classes.gridBlock2,
        cols === 3 && classes.gridBlock3,
        cols === 4 && classes.gridBlock4,
        className,
      )}
    >
      {children}
    </div>
  )
}

import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'
import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .dataCollection('{DATA_COLLECTION_KEY}')
  .delete('{INPUT}')`

const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/data/{DATA_COLLECTION_KEY}/delete',
  {
    method: 'POST',
  },
)

export function DataCollectionDeleteCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        DATA_COLLECTION_KEY: CodeParamType.String,
        INPUT: CodeParamType.Object,
      }}
      run={async ({ integrationApp, parameters }) => {
        return integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .dataCollection(parameters.DATA_COLLECTION_KEY)
          .delete(parameters.INPUT)
      }}
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Authentication Token Errors",
  "attributes": {},
  "children": []
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "authentication-token-errors",
      children: "Authentication Token Errors"
    }), "\n", _jsxs(_components.p, {
      children: ["All requests to integration.app API require an HTTP header that looks like this: ", _jsx(_components.code, {
        children: "Authorization: Bearer <token>"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can get more information on how to generate and verify a correct token here: ", _jsx(DocLink, {
        path: "overview/getting-started/authentication"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

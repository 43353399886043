import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `const integration = await integrationApp.integration('{INTEGRATION_KEY}').get()`

export function GetIntegrationCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{
        js,
      }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .integration(parameters.INTEGRATION_KEY)
          .get()
      }}
    />
  )
}

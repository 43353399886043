import { useIntegrationApp } from '@integration-app/react'
import useSWR from 'swr'

export function useExportFieldsAsCode(
  elementKey: string,
  id: string | undefined,
  put: (data: any) => Promise<any>,
) {
  const client = useIntegrationApp()

  const { data: code, mutate: mutateCode } = useSWR(
    id ? `/${elementKey}/${id}/export` : undefined,
    (url) => client.get(url),
  )

  async function onCodeChange(newCode: any) {
    await Promise.all([
      mutateCode(newCode, { revalidate: false }),
      put({ ...newCode }),
    ])
  }

  return { code, onCodeChange, mutateCode }
}

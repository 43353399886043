import { FieldMappingDirection } from '@integration-app/sdk'
import { FieldMappingSelect } from '../FieldMappingSelect'
import { useGenericConfig } from '../contexts/generic-config-context'

export function SharedImportFieldMapping() {
  const { patchFieldMappingConfig, fieldMappingConfig, integrationId } =
    useGenericConfig()

  return (
    <div className='my-4'>
      <p>
        This field mapping will be synchronized between all actions and flows
        that use it.
      </p>
      <FieldMappingSelect
        fieldMappingKey={fieldMappingConfig.key}
        integrationId={integrationId}
        direction={FieldMappingDirection.IMPORT}
        onChange={(key) => patchFieldMappingConfig({ key })}
      />
    </div>
  )
}

import { FlowNode } from '@integration-app/sdk'
import { FLOW_NODE_SPECS } from '@integration-app/sdk'

export function isTriggerNode(node: FlowNode | string): boolean {
  if (!node) return false

  const t = typeof node === 'string' ? node : node.type

  return FLOW_NODE_SPECS[t]?.isTrigger
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Connectors",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Creating Development / Test Accounts",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Connector Parameters",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Updating connectors",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Connectors';
export const DESCRIPTION = 'Connecting your app to everything else your customers use';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    img: "img",
    ul: "ul",
    li: "li",
    h2: "h2",
    code: "code"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "connectors",
      children: "Connectors"
    }), "\n", _jsx(_components.p, {
      children: "Connectors let you interact with any external app in a consistent way."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/connectors.png",
      alt: "Connectors"
    }), "\n", _jsx(_components.p, {
      children: "They let you do the following things without having to write unique code for each unique API:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/external-apps/connectors/authentication",
          children: "Authentication"
        }), " using the same logic and UI regardless of the app you authenticate against."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/external-apps/connectors/operations",
          children: "Exploring and performing Operations"
        }), " with a consistent way to work with their inputs, outputs, and errors."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/external-apps/connectors/data",
          children: "Working with Data"
        }), " using the same code to read data, write it, and react to changes for every app."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/external-apps/connectors/proxy-api",
          children: "Making custom API calls"
        }), " if you need to use the raw underlying API."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Integration.app comes with a library of pre-built connectors, and you can build your own.\nEach connector is just a set of YAML and JavaScript files transforming the underlying API into a consistent format."
    }), "\n", _jsxs(_components.p, {
      children: ["You can see a full list of connectors in the integration.app library here: ", _jsx("a", {
        href: "https://integration.app/connectors",
        children: "integration.app/connectors"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "creating-development--test-accounts",
      children: "Creating Development / Test Accounts"
    }), "\n", _jsx(_components.p, {
      children: "To test a connector (and sometimes to configure it) you need to create a development and/or test accounts in an application being connected."
    }), "\n", _jsx(_components.p, {
      children: "For most connectors, you can find instructions on how to do it on the connector's page."
    }), "\n", _jsxs(_components.p, {
      children: ["When configuring oAuth applications, always use ", _jsx(_components.code, {
        children: "https://api.integration.app/oauth-callback"
      }), " as the callback URL."]
    }), "\n", _jsx(_components.h2, {
      id: "connector-parameters",
      children: "Connector Parameters"
    }), "\n", _jsx(_components.p, {
      children: "Some connectors (for example, all connectors that use oAuth authentication) can be configured with parameters.\nMost of them have default parameters provided by integration.app that let connector work out of the box."
    }), "\n", _jsx(_components.p, {
      children: "You can set your own parameters on the connector page.\nFor security reasons, connector parameters are write-only: you can set them, but you cannot read them back."
    }), "\n", _jsx(_components.h2, {
      id: "updating-connectors",
      children: "Updating connectors"
    }), "\n", _jsx(_components.p, {
      children: "When a new version of a connector is released, you can update your connector to the latest version using the \"Update\" button on the connector page."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Using Rest API",
  "attributes": {},
  "children": []
}];
export const TITLE = 'Using Rest API';
export const DESCRIPTION = 'Configuring and running your integrations via an API';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "using-rest-api",
      children: "Using Rest API"
    }), "\n", _jsxs(_components.p, {
      children: ["To authenticate in our Rest API, you need to use an ", _jsx(DocLink, {
        path: "overview/getting-started/authentication",
        children: "Access Token"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Add the token into the Authorization header like this:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "Authorization Bearer <ACCESS_TOKEN>\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["To learn which API requests to make, see code examples throuhout the product or check out our ", _jsx("a", {
        target: "_blank",
        href: "https://api-reference.integration.app",
        children: "Rest API Reference"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Create Data Link",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "See Also",
    "attributes": {},
    "children": []
  }]
}];
export const DESCRIPTION = 'Link object in your app with corresponding object in an external app.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink, DocGrid} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "create-data-link",
      children: "Create Data Link"
    }), "\n", _jsxs(_components.p, {
      children: ["This node creates a ", _jsx(DocLink, {
        path: "engine/blueprints/data-links",
        children: "Data Link"
      }), " between an object in your app and an object in an external app."]
    }), "\n", _jsx(_components.p, {
      children: "For this, you need to provide:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Data Link Table (you need to create one first)."
      }), "\n", _jsx(_components.li, {
        children: "Id of an object in your app."
      }), "\n", _jsx(_components.li, {
        children: "Id of an object in an external app."
      }), "\n", _jsx(_components.li, {
        children: "Direction: whether to link object from external app to your app, the other way around or both."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "If a link already exists, it will be updated."
    }), "\n", _jsx(_components.h2, {
      id: "see-also",
      children: "See Also"
    }), "\n", _jsx(DocGrid, {
      children: _jsx(DocGrid.Link, {
        path: "engine/blueprints/data-links"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

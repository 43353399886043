import { makeDataField } from '@integration-app/ui/DataBuilder'
import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { useNodeConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/geBrokenVariablesConfigurationErrors'
import {
  ConfigurationErrorType,
  NodeConfigurationErrorData,
} from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { useGenericConfig } from './contexts/generic-config-context'

export function HttpRequestConfig() {
  const { patchConfig, config, variablesSchema } = useGenericConfig()

  const { errorFieldsLocators } = useNodeConfigurationErrors()

  return (
    <div>
      <h3>Request Parameters</h3>

      <AdminDataBuilderForm
        // FIXME: strictNullCheck temporary fix
        // @ts-expect-error TS(2322): Type '(string | undefined)[]' is not assignable to... Remove this comment to see the full error message
        errorFieldsLocators={errorFieldsLocators}
        onChange={(request) => patchConfig({ request })}
        field={makeDataField({
          value: config.request,
          schema: HTTP_REQUEST_INPUT_SCHEMA,
          variablesSchema,
        })}
      />
    </div>
  )
}

const HTTP_REQUEST_INPUT_SCHEMA = {
  type: 'object',
  properties: {
    uri: { type: 'string' },
    method: {
      type: 'string',
      enum: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
    },
    body: {
      title: 'Body',
    },
    query: {
      type: 'object',
      section: true,
      additionalProperties: true,
    },
    headers: {
      type: 'object',
      section: true,
      additionalProperties: true,
    },
  },
}

export function getHttpRequestConfigErrors({ config, runTimeVariablesSchema }) {
  const errors: NodeConfigurationErrorData[] = []

  const uri = config?.request?.uri
  if (!uri) {
    errors.push({
      type: ConfigurationErrorType.MissingFieldValue,
      message: 'URI is required.',
      valueLocator: '$.uri',
    })
  }

  errors.push(
    ...getBrokenVariablesConfigurationErrors(config?.request, [
      runTimeVariablesSchema,
    ]),
  )

  return errors
}

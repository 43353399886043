import { CustomizeImplementation } from '../../../../../../../components/common-configs/CustomizeImplementation'
import { DataSourceUnitConfig } from '../../../../../../../components/common-configs/DataSourceUnitConfig'
import { ExportFieldMappingUnitConfig } from '../../../../../../../components/common-configs/ExportFieldMappingUnitConfig'
import { FindOrCreateDataRecordDataLinkConfig } from '../../../../../../../components/common-configs/FindOrCreateDataRecordDataLinkConfig'
import { LookupQueryConfig } from '../../../../../../../components/common-configs/LookupQueryConfig'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'

export function FindOrCreateDataRecord() {
  const { dataCollectionSpec } = useGenericConfig()

  const apiRequests = [
    ...(dataCollectionSpec?.match?.apiRequests ?? []),
    ...(dataCollectionSpec?.create?.apiRequests ?? []),
  ]

  return (
    <>
      <DataSourceUnitConfig />

      <LookupQueryConfig />

      <FindOrCreateDataRecordDataLinkConfig />

      <ExportFieldMappingUnitConfig />

      <CustomizeImplementation apiRequests={apiRequests} />
    </>
  )
}

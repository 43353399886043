/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "App-level Integrations",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Flows",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Proxy API",
    "attributes": {},
    "children": []
  }]
}];
import {INTEGRATIONS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const TITLE = 'App-level Integrations';
export const DESCRIPTION = 'Deep native integrations with one app at a time.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink, Link} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "app-level-integrations",
      children: "App-level Integrations"
    }), "\n", _jsx(_components.p, {
      children: "App-level integrations are unique to a single application.\nIf you want to build a deep native integration that leverages unique features of a given application, this option is for you."
    }), "\n", _jsx(_components.p, {
      children: "You can use two main approaches to building app-level integrations:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Middleware such as ", _jsx(DocLink, {
          path: "engine/blueprints/flows"
        }), ", ", _jsx(DocLink, {
          path: "engine/blueprints/actions"
        }), ", ", _jsx(DocLink, {
          path: "engine/blueprints/field-mappings"
        })]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/external-apps/connectors/proxy-api"
        }), "\n"]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Middleware is best when you want to keep the integration logic or end-user customizations on the Integration Engine side.\nProxy API is best when you want to keep as much logic as possible on your app's side. You can combine both approaches to use the best of both worlds."
    }), "\n", _jsx(_components.h2, {
      id: "flows",
      children: "Flows"
    }), "\n", _jsxs(_components.p, {
      children: ["In most cases, you will start with ", _jsx(DocLink, {
        path: "engine/blueprints/flows"
      }), " when building app-level inegrations. You may want to use other types of middleware to support your flows:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/blueprints/actions"
        }), " to configure simple re-usable pieces of logic."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/blueprints/field-mappings"
        }), " to re-use field mappings across multiple actions and flows or let your customers configure them in the UI."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/blueprints/data-sources"
        }), " to keep data source configuration in sync between multiple flows."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["To create flows, go to your ", _jsx(Link, {
        path: INTEGRATIONS_ROUTE,
        children: "integration page"
      }), " and select \"Flows\" in the left-side menu."]
    }), "\n", _jsx(_components.h2, {
      id: "proxy-api",
      children: "Proxy API"
    }), "\n", _jsxs(_components.p, {
      children: ["Using ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/proxy-api"
      }), " or Actions of type \"API Request to External App\", you can make API requests of any complexity with authentication applied automatically."]
    }), "\n", _jsx(_components.p, {
      children: "This is the best option when you want to write very custom API requests without having to work through integration middleware."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import { createContext, PropsWithChildren, useContext } from 'react'
import {
  DataCollectionSpec,
  DataSchema,
  DataSource,
  FieldMapping,
} from '@integration-app/sdk'
import {
  useDataSource,
  useFieldMapping,
  useDataCollectionSpec,
} from '@integration-app/react'
import useSWR from 'swr'
import { EmptyPageLoader } from '../../../../../../../components/Loader'
import { useExportFieldsAsCode } from '../../../components/useExportFieldsAsCode'

export function FieldMappingContextProvider({
  children,
  fieldMappingId,
}: PropsWithChildren<{
  fieldMappingId: string | undefined
}>) {
  const { fieldMapping, accessor, put, refresh, apply, reset, archive } =
    useFieldMapping(fieldMappingId)

  const { fieldMapping: parent, refresh: refreshParent } = useFieldMapping(
    fieldMapping?.universalFieldMappingId,
  )

  const { dataSource } = useDataSource(fieldMapping?.dataSourceId)

  const { data: appSchema, mutate: mutateAppSchema } = useSWR(
    `/field-mappings/${fieldMappingId}/app-schema`,
    () => accessor?.getAppSchema(),
  )

  const { code, mutateCode, onCodeChange } = useExportFieldsAsCode(
    'field-mappings',
    fieldMappingId,
    put,
  )

  const dataCollectionSpec = useDataCollectionSpec({
    path: dataSource?.defaultPath,
    integrationId: fieldMapping?.integrationId ?? '',
  })

  async function onChange(data: Partial<FieldMapping>) {
    await put({
      ...fieldMapping,
      ...(data ?? {}),
    })
    void mutateAppSchema()
    void mutateCode()
    void refreshParent()
  }

  async function onArchive() {
    await archive()
    void refreshParent()
  }
  async function onReset() {
    await reset()
    void refreshParent()
  }

  if (!fieldMappingId || !fieldMapping) {
    return <EmptyPageLoader />
  }

  return (
    <FieldMappingContext.Provider
      value={{
        fieldMapping,
        isUniversal: !fieldMapping.integrationId,
        parent,

        dataSource,

        appSchema: appSchema || { type: 'object' },
        mutateAppSchema,
        dataCollectionSpec,

        onChange,

        apply,
        reset: onReset,
        archive: onArchive,

        refresh,

        code,
        onCodeChange,
      }}
    >
      {children}
    </FieldMappingContext.Provider>
  )
}

const FieldMappingContext = createContext<{
  fieldMapping: FieldMapping
  isUniversal: boolean
  parent: FieldMapping | undefined

  dataSource?: DataSource

  appSchema: DataSchema | undefined
  mutateAppSchema: (appSchema?: DataSchema, revalidate?: boolean) => void
  dataCollectionSpec: DataCollectionSpec | undefined

  onChange(data: Partial<FieldMapping>): Promise<void>

  apply(integrationKeys: string[]): Promise<FieldMapping[]>
  reset(): Promise<void>
  archive(): Promise<void>

  refresh(): Promise<FieldMapping>

  code: any
  onCodeChange(newCode: any): Promise<void>
}>({} as any)

export function useFieldMappingContext() {
  return useContext(FieldMappingContext)
}

// function withAfterEffect<T extends (...args: any[]) => Promise<unknown>>(
//   fn: T,
//   aftereffect: () => Promise<unknown>,
// ): T {
//   // @ts-ignore
//   return async (...args: any[]) => {
//     const result = await fn(...args)
//     await aftereffect()
//     return result
//   }
// }

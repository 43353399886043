/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "React SDK",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Dynamic Token",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "SDK Reference",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Next Steps",
    "attributes": {},
    "children": []
  }]
}];
import {DocGrid} from "components/Docs/DocLinksGrid";
import {CodeExample} from '../../../../../../components/CodeExamples';
export const TITLE = 'React SDK';
export const DESCRIPTION = 'Add our React SDK to start building your integration UI';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.InstallReactSdk) _missingMdxReference("CodeExample.InstallReactSdk", true);
  if (!CodeExample.IntegrationAppProvider) _missingMdxReference("CodeExample.IntegrationAppProvider", true);
  if (!CodeExample.OpenUiWithReactSdk) _missingMdxReference("CodeExample.OpenUiWithReactSdk", true);
  if (!CodeExample.ReactSdkFetchToken) _missingMdxReference("CodeExample.ReactSdkFetchToken", true);
  if (!DocGrid) _missingMdxReference("DocGrid", false);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "react-sdk",
      children: "React SDK"
    }), "\n", _jsxs(_components.p, {
      children: ["First, install ", _jsx(_components.code, {
        children: "@integration-app/react"
      }), " NPM package:"]
    }), "\n", _jsx(CodeExample.InstallReactSdk, {}), "\n", _jsxs(_components.p, {
      children: ["Then, wrap your components that will use integration.app with ", _jsx(_components.code, {
        children: "IntegrationAppProvider"
      }), ":"]
    }), "\n", _jsx(CodeExample.IntegrationAppProvider, {}), "\n", _jsxs(_components.p, {
      children: ["This example uses your test token. For production, you should generate a token on your backend and pass it to your React app: ", _jsx(DocLink, {
        path: "overview/getting-started/authentication"
      })]
    }), "\n", _jsx(_components.p, {
      children: "To check that SDK is working correctly, let's open the integration UI:"
    }), "\n", _jsx(CodeExample.OpenUiWithReactSdk, {}), "\n", _jsx(_components.h2, {
      id: "dynamic-token",
      children: "Dynamic Token"
    }), "\n", _jsxs(_components.p, {
      children: ["If it is more convenient for you to fetch token dynamically instead of providing static value, you can use ", _jsx(_components.code, {
        children: "fetchToken"
      }), " instead:"]
    }), "\n", _jsx(CodeExample.ReactSdkFetchToken, {}), "\n", _jsx(_components.p, {
      children: "This option also automatically handles token expiration. If SDK was initialized long ago and token had time to expire, it will be automatically re-fetched before making new API requests."
    }), "\n", _jsx(_components.h2, {
      id: "sdk-reference",
      children: "SDK Reference"
    }), "\n", _jsxs(_components.p, {
      children: ["To see the full list of SDK methods check out the ", _jsx("a", {
        href: "/ref/react/index.html",
        target: "_blank",
        children: "Javascript API Reference"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "next-steps",
      children: "Next Steps"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "overview/getting-started/authentication"
      }), _jsx(DocGrid.Link, {
        path: "ux/connection"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Building Integrations",
  "attributes": {},
  "children": []
}];
export const DESCRIPTION = 'Choose the right approach for building the exact integration you need';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {DocGrid} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "building-integrations",
      children: "Building Integrations"
    }), "\n", _jsx(_components.p, {
      children: "Integration.app offers dozens of building blocks for your integrations."
    }), "\n", _jsx(_components.p, {
      children: "Navigating them can be overwhelming at first, so we've created this step-by-step guide to help you choose the right approach for your use case."
    }), "\n", _jsx(_components.p, {
      children: "As the first step, choose a level of customization you need:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Universal Integrations"
        }), " work across many external applications and customers. If your integration should work in a similar way across multiple apps, this option is for you."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "App-level Integrations"
        }), " are unique to a single application. If you want to build a deep native integration that leverages unique features of a given application, this option is for you."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Customer-level Integrations"
        }), " are unique to a single customer. If you want to build a custom integration workflow for a specific customer, this option is for you."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "If not sure, start from Universal Integrations - you can always customize them for a given app and customer later."
    }), "\n", _jsx(_components.p, {
      children: "Choose your option:"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "overview/building-integrations/universal"
      }), _jsx(DocGrid.Link, {
        path: "overview/building-integrations/app-level"
      }), _jsx(DocGrid.Link, {
        path: "overview/building-integrations/customer-level"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import { CollapsibleSection } from '../../Section'
import { useGenericConfig } from '../contexts/generic-config-context'
import SidePanel from '../../../../../../components/Layout/SidePanel'
import { SharedDataSource } from './SharedDataSource'
import {
  DataCollectionSelect,
  DataSourceConfigureEvents,
  SimpleRadio,
} from '@integration-app/ui'

enum DataSourceConfigOption {
  Shared = 'shared',
  Specific = 'specific',
}

export function IntegrationLevelDataSourceUnitConfig({
  configureEvents,
}: {
  configureEvents: boolean
}) {
  const { dataSourceConfig, patchConfig, variablesSchema } = useGenericConfig()

  async function handleSelectOption(option: DataSourceConfigOption) {
    switch (option) {
      case DataSourceConfigOption.Shared:
        return patchConfig({
          dataSource: {
            key: null,
          },
        })
      case DataSourceConfigOption.Specific:
        return patchConfig({
          dataSource: {
            collectionKey: null,
          },
        })
    }
  }

  let selectedOption = DataSourceConfigOption.Specific

  if (dataSourceConfig?.key !== undefined) {
    selectedOption = DataSourceConfigOption.Shared
  }

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Data Source</CollapsibleSection.Header>
      <p>
        Select which data in the external application do you want to use in this
        integration.
      </p>

      <SimpleRadio
        label='Select a data collection'
        checked={selectedOption === DataSourceConfigOption.Specific}
        onCheck={() => handleSelectOption(DataSourceConfigOption.Specific)}
      />

      <SimpleRadio
        label='Use shared data source configuration'
        checked={selectedOption === DataSourceConfigOption.Shared}
        onCheck={() => handleSelectOption(DataSourceConfigOption.Shared)}
      />

      <SidePanel.Content>
        {selectedOption === DataSourceConfigOption.Shared && (
          <SharedDataSource />
        )}
        {selectedOption === DataSourceConfigOption.Specific && (
          <>
            <DataCollectionSelect
              value={dataSourceConfig}
              onChange={(dataSource) => patchConfig({ dataSource })}
              variablesSchema={variablesSchema}
            />
            {configureEvents && (
              <DataSourceConfigureEvents
                value={dataSourceConfig}
                onChange={(dataSource) => patchConfig({ dataSource })}
              />
            )}
          </>
        )}
      </SidePanel.Content>
    </CollapsibleSection>
  )
}

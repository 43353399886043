import React, { createContext, PropsWithChildren, useContext } from 'react'
import {
  DataCollectionSpec,
  DataSource,
  FieldMapping,
  FlowNode,
} from '@integration-app/sdk'
import { useGenericFlow } from '../../routes/Workspaces/Workspace/components/FlowBuilder/useGenericFlow'
import {
  useDataSource,
  useFieldMapping,
  useDataCollectionSpec,
} from '@integration-app/react'
import useSWR from 'swr'
const FlowNodeContext = createContext<{
  nodeKey: string
  node: FlowNode

  patchNode(data: any): Promise<void>
  putNode(data: any): Promise<void>
  patchNodeConfig(data: any): Promise<void>
  patchNodeUi(data: any): Promise<void>

  // data node specific
  patchFieldMappingConfig(data: any): Promise<void>
  fieldMapping?: FieldMapping
  fieldMappingConfig: any
  appSchema?: any

  patchDataSourceConfig(data: any): Promise<void>
  dataSourceConfig: any
  dataSource?: DataSource
  udm?: string
  defaultPath?: string
  dataCollectionSpec?: DataCollectionSpec
}>({
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  nodeKey: null,
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'FlowNode'.
  node: null,

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type '(data: any)... Remove this comment to see the full error message
  patchNode: null,
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type '(data: any)... Remove this comment to see the full error message
  putNode: null,
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type '(data: any)... Remove this comment to see the full error message
  patchNodeConfig: null,
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type '(data: any)... Remove this comment to see the full error message
  patchNodeUi: null,

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type '(data: any)... Remove this comment to see the full error message
  patchFieldMappingConfig: null,
  fieldMappingConfig: null,
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'FieldMappin... Remove this comment to see the full error message
  fieldMapping: null,
  appSchema: null,

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type '(data: any)... Remove this comment to see the full error message
  patchDataSourceConfig: null,
  dataSourceConfig: null,
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'DataSource ... Remove this comment to see the full error message
  dataSource: null,
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
  udm: null,
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
  defaultPath: null,
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'DataCollect... Remove this comment to see the full error message
  dataCollectionSpec: null,
})

export default function useFlowNode() {
  return useContext(FlowNodeContext)
}

export const FlowNodeProvider = ({
  nodeKey,
  children,
}: PropsWithChildren<{ nodeKey: string }>) => {
  const { patchFlow, flow } = useGenericFlow()

  const { nodes, integrationId } = flow

  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const node = nodes[nodeKey]

  if (!node) {
    console.error(`Node ${nodeKey} does not exist`)
    return <p>Node {nodeKey} does not exist</p>
  }

  async function putNode(data: any) {
    await patchFlow({
      nodes: {
        ...flow.nodes,
        [nodeKey]: data,
      },
    })
  }

  async function patchNode(data: any) {
    return patchFlow({
      ...flow,
      nodes: {
        ...flow.nodes,
        [nodeKey]: {
          ...node,
          ...(data ?? {}),
        },
      },
    })
  }

  async function patchNodeConfig(data: any) {
    return patchNode({
      ...node,
      config: {
        ...(node.config ?? {}),
        ...(data ?? {}),
      },
    })
  }

  async function patchNodeUi(data: any) {
    return patchNode({
      ...node,
      ui: {
        ...(node.ui ?? {}),
        ...(data ?? {}),
      },
    })
  }

  const dataSourceConfig = node.config?.dataSource ?? {}
  const fieldMappingConfig = node.config?.fieldMapping ?? {}

  async function patchFieldMappingConfig(data: any) {
    // NOTE: filtering out nulls in object because combobox return null when value is deleted from input (but should return undefined or no value at all)
    if (data.defaultValue) {
      data.defaultValue = undefinedIfObjectContainsOnlyEmptyFields(
        data.defaultValue,
      )
    }
    if (data.defaultUnifiedValue) {
      data.defaultUnifiedValue = undefinedIfObjectContainsOnlyEmptyFields(
        data.defaultUnifiedValue,
      )
    }
    if (data.default) {
      data.default = undefinedIfObjectContainsOnlyEmptyFields(data.default)
    }

    return patchNodeConfig({
      fieldMapping: {
        ...fieldMappingConfig,
        ...(data ?? {}),
      },
    })
  }

  async function patchDataSourceConfig(data: any) {
    return patchNodeConfig({
      dataSource: {
        ...dataSourceConfig,
        ...(data ?? {}),
      },
    })
  }

  function getSelectorForDependency(key: string) {
    if (!key) {
      return null
    }

    return integrationId
      ? {
          integrationId,
          key,
        }
      : {
          key,
        }
  }

  const { dataSource } = useDataSource(
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type '{ integrationId: string; key: st... Remove this comment to see the full error message
    getSelectorForDependency(dataSourceConfig.key),
  )

  const { fieldMapping, accessor: fieldMappingAccessor } = useFieldMapping(
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type '{ integrationId: string; key: st... Remove this comment to see the full error message
    getSelectorForDependency(fieldMappingConfig.key),
  )

  const { data: fieldMappingAppSchema } = useSWR(
    fieldMapping ? `/field-mappings/${fieldMapping.id}/app-schema` : null,
    () => fieldMappingAccessor?.getAppSchema(),
  )

  const appSchema = fieldMappingAppSchema ?? fieldMappingConfig?.appSchema

  const udm = dataSource?.udm ?? dataSourceConfig.udm
  const defaultPath = dataSource?.defaultPath ?? dataSourceConfig.defaultPath

  const dataCollectionSpec = useDataCollectionSpec({
    path: defaultPath,
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    integrationId: flow.integrationId,
  })

  return (
    <FlowNodeContext.Provider
      value={{
        nodeKey,
        node,
        patchNode,
        putNode,
        patchNodeUi,
        patchNodeConfig,

        patchFieldMappingConfig,
        fieldMappingConfig,
        fieldMapping,
        appSchema,

        patchDataSourceConfig,
        dataSourceConfig,
        dataSource,
        udm,
        defaultPath,

        dataCollectionSpec,
      }}
    >
      {children}
    </FlowNodeContext.Provider>
  )
}

const undefinedIfObjectContainsOnlyEmptyFields = (value: any) =>
  Object.entries(value).every(
    ([_, value]) => value === null || value === undefined || value === '',
  )
    ? undefined
    : value

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Data Source has No Collection Selected",
  "attributes": {},
  "children": []
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code"
  }, _provideComponents(), props.components), {DocLink, DocGrid} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "data-source-has-no-collection-selected",
      children: "Data Source has No Collection Selected"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(DocLink, {
        path: "engine/blueprints/data-sources",
        children: "Data Sources"
      }), " have ", _jsx(_components.code, {
        children: "path"
      }), " field that points to a Data Collection."]
    }), "\n", _jsxs(_components.p, {
      children: ["If Data Source does not have ", _jsx(_components.code, {
        children: "path"
      }), ", it means that:"]
    }), "\n", _jsxs(_components.p, {
      children: ["a) It was created for a ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connection"
      }), " and ", _jsx(DocLink, {
        path: "engine/references/udm",
        children: "Universal Data Model"
      }), " that does not have a default collection. User must select the collection manually."]
    }), "\n", _jsx(_components.p, {
      children: "b) Path was unset via an API call (unlikely)."
    }), "\n", _jsx(_components.p, {
      children: "You need to make sure a Data Source is configured with a collection before using it:"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/data-sources"
      }), _jsx(DocGrid.Link, {
        path: "ux/data-sources"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

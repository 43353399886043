import { AppDataSchema } from '@integration-app/sdk'
import { useState } from 'react'
import { v4 as uuid4 } from 'uuid'
import { GoToElementLink } from '../GoToElemenLink'
import { routeTo } from '../../routes/Workspaces/Workspace/routes-constants'
import { Cell, Row } from '@integration-app/ui/DataBuilderUI'
import ComboBox from '@integration-app/ui/ComboBox'
import { ValueType } from '@integration-app/ui/ComboBox/value-spec'
import { ComboBoxOptionType } from '@integration-app/ui/ComboBox/types'
import { useAppDataSchemas } from '@integration-app/react'
import { CreateAppDataSchemaPopup } from 'routes/Workspaces/Workspace/YourApp/AppDataSchemas/AppDataSchema/components/CreateAppDataSchemaPopup'

const CREATE_OPTION_ID = uuid4()

export default function AppDataSchemaSelect({
  value,
  onChange,
  disabled = false,
  withCreateOption = true,
}: {
  value?: string
  onChange: (value: string | undefined) => void
  disabled?: boolean
  withCreateOption?: boolean
}) {
  const [createPopupOpen, setCreatePopupOpen] = useState(false)

  const { appDataSchemas } = useAppDataSchemas()

  const options: ComboBoxOptionType[] = appDataSchemas.map(({ name, key }) => ({
    label: name,
    value: key,
  }))

  if (withCreateOption) {
    options.push({
      label: '+ Create New Data Schema',
      value: CREATE_OPTION_ID,
    })
  }

  async function handleChange(value: string | undefined) {
    if (value === CREATE_OPTION_ID) {
      setCreatePopupOpen(true)
      return
    }

    onChange(value)
  }

  const selected = appDataSchemas.find(({ key }) => key === value)

  return (
    <div className='flex items-center gap-4'>
      <Row>
        <Cell.Name shrink>App Data Schema</Cell.Name>
        <Cell.Combobox grow>
          <ComboBox
            value={value}
            valueSpec={{ type: ValueType.STRING, allowCustom: false }}
            options={options}
            onChange={handleChange}
            disabled={disabled}
            placeholder='Select Data Schema'
          />
        </Cell.Combobox>
      </Row>

      {selected && (
        <GoToElementLink to={routeTo.appDataSchema(selected.id)}>
          View/Edit Schema
        </GoToElementLink>
      )}

      <CreateAppDataSchemaPopup
        open={createPopupOpen}
        onClose={() => setCreatePopupOpen(false)}
        onCreate={(appDataSchema: AppDataSchema) =>
          handleChange(appDataSchema.key)
        }
      />
    </div>
  )
}

import CommonContextsWrapper from 'components/Wrappers/CommonContextsWrapper'
import { RequiredAuthWrapper } from 'components/RequiredAuthWrapper'
import useWorkspace, {
  WorkspaceProvider,
} from 'components/Workspaces/workspace-context'
import { IntegrationAppProvider } from '@integration-app/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export function WorkspaceContextsWrapper({ children }) {
  const { workspaceId } = useRouter().query as { workspaceId: string }

  return (
    <CommonContextsWrapper>
      <RequiredAuthWrapper>
        <WorkspaceProvider wsId={workspaceId}>
          <IntegrationAppProviderReactSDK>
            {children}
          </IntegrationAppProviderReactSDK>
        </WorkspaceProvider>
      </RequiredAuthWrapper>
    </CommonContextsWrapper>
  )
}

export function IntegrationAppProviderReactSDK({ children }) {
  const { workspace } = useWorkspace()

  // Keeping token in state to avoid re-rendering of the whole tree
  const [token, setToken] = useState(workspace.engineAccessToken)
  // Only changing the token when workspace changes
  useEffect(() => {
    setToken(workspace.engineAccessToken)
  }, [workspace.id])

  return (
    <IntegrationAppProvider
      token={token}
      apiUri={process.env.https://api.integration.app}
      uiUri={process.env.https://ui.integration.app}
    >
      {children}
    </IntegrationAppProvider>
  )
}

import { Flow } from '@integration-app/sdk'
import { CreateFlowRequest } from '@integration-app/sdk'
import Button from '@integration-app/ui/Button'
import { useState } from 'react'
import useSWR from 'swr'
import { ExampleBlock } from 'components/Docs/CodeBlockUI'
import TableCellTag from '../TableCellTag'
import useWorkspace from 'components/Workspaces/workspace-context'
import deepEqual from 'fast-deep-equal'
import useAuth from 'contexts/auth'
import { FlowPreview } from './FlowPreview'
import classes from './ExampleFlowBlock.module.css'
import { LoginToRun } from 'components/Docs/CodeBlockUI'
import Link from 'next/link'

export default function ExampleFlowBlock({
  flow: flowData,
}: {
  flow: CreateFlowRequest
}) {
  const { self, loginLink } = useAuth()
  const { engineAdminClient, engineAdminFetcher } = useWorkspace()

  const [resettingFlow, setResettingFlow] = useState(false)
  const [creatingFlow, setCreatingFlow] = useState(false)

  const flowPath = `/flows/${flowData.key}`

  const {
    data: flow,
    mutate: mutateFlow,
    error,
  } = useSWR<Flow>(flowPath, engineAdminFetcher)

  const flowExists = !!flow && !error // TODO: check if flow archived

  const flowWasModified = flowExists && !isFlowEqual(flow, flowData)

  async function createFlow() {
    setCreatingFlow(true)
    try {
      if (flowExists) {
        await engineAdminClient.flow(flow.key).archive()
      }

      await engineAdminClient.flows.create(flowData)
      await mutateFlow()

      // sleep for better ux
      await new Promise((resolve) => setTimeout(resolve, 1000))
    } finally {
      setCreatingFlow(false)
    }
  }

  async function resetFlow() {
    setResettingFlow(true)
    try {
      await engineAdminClient.flow(flowData.key).put(flowData)
      await mutateFlow()

      // sleep for better ux
      await new Promise((resolve) => setTimeout(resolve, 1000))
    } finally {
      setResettingFlow(false)
    }
  }

  if (!self?.user) {
    return (
      <ExampleBlock>
        <ExampleBlockSectionFlowPreview flowNodes={flowData.nodes} />

        <ExampleBlock.Toolbar>
          <LoginToRun>
            Please <a href={loginLink(window.location.href)}>log in</a> to try
            out this flow.
          </LoginToRun>
        </ExampleBlock.Toolbar>
      </ExampleBlock>
    )
  }

  return (
    <>
      <ExampleBlock>
        {flowExists ? (
          <ExampleBlockSectionFlowPreview flowNodes={flow.nodes} />
        ) : (
          <ExampleBlockSectionFlowPreview flowNodes={flowData.nodes} />
        )}

        <ExampleBlock.Section className='flex flex-row justify-end'>
          <Button
            disabled={creatingFlow || resettingFlow}
            loading={creatingFlow && !resettingFlow}
            onClick={createFlow}
          >
            {flowExists ? 'Recreate Flow' : 'Create Flow'}
          </Button>
        </ExampleBlock.Section>

        {flowExists && !creatingFlow && (
          <ExampleBlock.Section className='flex flex-row items-center gap-2'>
            <TableCellTag copyToClipboard={false}>
              <span>See in Flow Builder: </span>
              {/* TODO */}
              <Link href='flows/[flowId]' as={`flows/${flow.id}`}>
                <a>{flow.name}</a>
              </Link>
            </TableCellTag>

            {flowWasModified && (
              <>
                <div className='flex-grow'></div>
                <div className='text-error'>Flow was modified</div>
                <Button
                  disabled={resettingFlow}
                  loading={resettingFlow}
                  variant='secondary'
                  onClick={resetFlow}
                >
                  Reset Flow to the Initial State
                </Button>
              </>
            )}
          </ExampleBlock.Section>
        )}
      </ExampleBlock>
    </>
  )
}

function ExampleBlockSectionFlowPreview({ flowNodes }) {
  return (
    <ExampleBlock.Section className={classes.flowPreviewExampleSectionBlock}>
      <div className={classes.flowPreviewGraphContainer}>
        <FlowPreview flowNodes={flowNodes as Flow['nodes']} />
      </div>
    </ExampleBlock.Section>
  )
}

function isFlowEqual(flow: Flow, original: CreateFlowRequest) {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2769): No overload matches this call.
  const flowKeys = Object.keys(flow.nodes)
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2769): No overload matches this call.
  const originalKeys = Object.keys(original.nodes)
  if (flowKeys.length !== originalKeys.length) {
    return false
  }
  if (flowKeys.some((key) => !originalKeys.includes(key))) {
    return false
  }
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2769): No overload matches this call.
  for (const key of Object.keys(flow.nodes)) {
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const flowNode = flow.nodes[key]
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const originalNode = original.nodes[key]
    if (!deepEqual(flowNode.config, originalNode.config)) {
      return false
    }
  }
  return true
}

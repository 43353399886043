import { FlowNode } from '@integration-app/sdk'
import { NodeProps } from 'reactflow'
import { TargetHandle } from 'components/FlowBuilder/Graph/elements/Handle/Handle'
import {
  NodeBody,
  NodeBodyType,
} from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBody'
import { UnlinkNodeButton } from './UnlinkNodeButton'
import { useFlowBuilder } from 'routes/Workspaces/Workspace/components/FlowBuilder/flow-builder-context'
import { NodeBodyContainer } from 'components/FlowBuilder/Graph/elements/NodeBody/NodeBodyContainer'

export type PortalNodeData = {
  portalFlowNodeKey: string
  portalFlowNode: FlowNode
  parentFlowNodeKey: string
  parentFlowNode: FlowNode
}

export function PortalNode(props: NodeProps<PortalNodeData>) {
  const { portalFlowNode, portalFlowNodeKey, parentFlowNodeKey } = props.data

  const {
    hoveringPortalNodeKey,
    selectNode,
    selectedNodeKey,
    hoverPortalNode,
  } = useFlowBuilder()

  function handleSelectPortalNode() {
    selectNode(portalFlowNodeKey)
  }

  const isSourceNodeSelected = selectedNodeKey === portalFlowNodeKey
  const isHoveredFromAnotherPortal = hoveringPortalNodeKey === portalFlowNodeKey
  const isHovered = isSourceNodeSelected || isHoveredFromAnotherPortal

  return (
    <NodeBodyContainer>
      <TargetHandle />

      <NodeBody
        flowNodeKey={portalFlowNodeKey}
        flowNode={portalFlowNode}
        type={NodeBodyType.Portal}
        onClick={handleSelectPortalNode}
        customIcon={PORTAL_NODE_ICON}
        isHovered={isHovered}
        onNodeHover={hoverPortalNode}
      />

      <UnlinkNodeButton
        portalFlowNodeKey={portalFlowNodeKey}
        parentFlowNodeKey={parentFlowNodeKey}
      />
    </NodeBodyContainer>
  )
}

export const PORTAL_NODE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke="#696969" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path d="M12 3v12m4-4-4 4m-4-4 4 4"/>
  <circle cx="12" cy="20" r="1"/>
</svg>`

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDebouncedCallback, useThrottledCallback } from 'use-debounce'
import { useScroll } from 'hooks/useScroll'

type HeaderDataType = {
  id: string
  offsetTop: number
}

type ActiveHeaderIdType = string | null

export type ActiveHeaderProps = {
  articleContainerId: string
  scrollableContainerSelector?: string
}

const ArticleTocContext = createContext<ActiveHeaderIdType>(null)

export const useArticleActiveHeaderId = () => useContext(ArticleTocContext)

export function ArticleActiveHeaderIdProvider({
  children,
  articleContainerId,
  scrollableContainerSelector,
}: PropsWithChildren<ActiveHeaderProps>) {
  const [activeHeaderId, setActiveHeaderId] = useState<ActiveHeaderIdType>(null)

  return (
    <ArticleTocContext.Provider value={activeHeaderId}>
      <DetectActiveHeaderOnPage
        onChange={setActiveHeaderId}
        scrollableContainerSelector={scrollableContainerSelector}
        articleContainerId={articleContainerId}
      >
        {children}
      </DetectActiveHeaderOnPage>
    </ArticleTocContext.Provider>
  )
}

function DetectActiveHeaderOnPage({
  children,
  onChange,
  articleContainerId,
  scrollableContainerSelector,
}: PropsWithChildren<
  {
    onChange: (id: ActiveHeaderIdType) => void
  } & ActiveHeaderProps
>) {
  const [containerElement, setContainerElement] = useState<HTMLElement | null>(
    null,
  )
  const [scrollableContainer, setScrollableContainer] = useState<
    Element | Window | null
  >(null)
  const [initialized, setInitialized] = useState(false)
  const headerDataMapRef = useRef<HeaderDataType[]>([])

  function handleActiveHeaderChange(id: ActiveHeaderIdType) {
    const hash = window.location.hash?.slice(1)
    onChange(!!hash ? hash : id)
  }

  useEffect(() => {
    const containerElement = getElementById(articleContainerId)
    const scrollableContainer =
      getElementBySelector(scrollableContainerSelector) || containerElement

    setContainerElement(containerElement)
    setScrollableContainer(scrollableContainer)
  }, [])

  const handleLayoutChange = useDebouncedCallback(() => {
    if (containerElement) {
      headerDataMapRef.current = getHeadersDataFromContainer(containerElement)
      setInitialized(true)
    }
  }, 300)

  const handleScroll = useThrottledCallback(({ scrollY }) => {
    // Do not run if headers are not initialized or container is not found
    if (!scrollableContainer || !initialized) return

    const containerHeight = getElementHeight(scrollableContainer)
    const containerScrollTop = scrollY

    const halfPageYOffset = containerScrollTop + containerHeight * 0.4

    const visibleHeaders: string[] = []

    let newActiveHeader: ActiveHeaderIdType = null

    headerDataMapRef?.current?.forEach((header) => {
      const headerOffsetTop = header.offsetTop

      if (headerOffsetTop <= halfPageYOffset) {
        newActiveHeader = header.id
      }

      if (
        containerScrollTop - 20 < headerOffsetTop &&
        headerOffsetTop < containerScrollTop + containerHeight + 20
      ) {
        visibleHeaders.push(header.id)
      }
    })

    handleActiveHeaderChange(newActiveHeader)

    // When header with hash is not visible, remove hash from url
    // to switch to default header detection by scroll position
    const hash = window.location.hash.slice(1)
    if (hash && !visibleHeaders.includes(hash)) {
      history.pushState(null, '', window.location.pathname)
    }
  }, 300)

  useOnLayoutChange(containerElement, handleLayoutChange)
  useScroll(scrollableContainer, handleScroll)

  return children as JSX.Element
}

function getElementById(id?: string): HTMLElement | null {
  if (!id) return null
  return document.getElementById(id) || null
}

function getElementBySelector(selector?: string): Element | Window | null {
  if (!selector) return null
  if (selector === 'window') return window
  return document.querySelector(selector) || null
}

function getElementHeight(element: Element | Window) {
  if (element === window) {
    return window.innerHeight
  }
  return (element as HTMLElement).clientHeight
}

function getHeadersDataFromContainer(containerElement): HeaderDataType[] {
  return Array.from(
    containerElement.querySelectorAll(
      'h2[id], h3[id], h4[id]',
    ) as NodeListOf<HTMLHeadingElement>,
  ).map((header) => ({ id: header.id, offsetTop: header.offsetTop }))
}

function useOnLayoutChange(element, callback) {
  useEffect(() => {
    if (!element) return () => {}

    const resizeObserver = new ResizeObserver(callback)
    const mutationObserver = new MutationObserver(callback)

    resizeObserver.observe(element)
    mutationObserver.observe(element, {
      attributes: true,
      childList: true,
      subtree: true,
    })

    return () => {
      resizeObserver.unobserve(element)
      mutationObserver.disconnect()
    }
  }, [element, callback])
}

import {
  useConnection,
  useDataCollectionSpec,
  useDataSource,
  useFieldMapping,
  useIntegration,
} from '@integration-app/react'
import {
  Connection,
  DataCollectionSpec,
  DataSchema,
  DataSource,
  FieldMapping,
  Integration,
  IntegrationElementLevel,
  UNIFIED_DATA_MODELS,
  UnifiedDataModel,
  parseDataLocationPath,
} from '@integration-app/sdk'
import { locatorToField, setSchemaAtLocator } from '@integration-app/sdk'
import { IntegrationElementProvider } from '@integration-app/ui'
import { PropsWithChildren, createContext, useContext } from 'react'
import useSWR from 'swr'

const GenericConfigContext = createContext<{
  level?: IntegrationElementLevel

  integrationId?: string
  integration?: Integration

  connectionId?: string
  connection?: Connection

  variablesSchema?: DataSchema
  configVariablesSchema?: DataSchema
  inputSchema: any

  config: any
  patchConfig(data: any): Promise<void>

  parentConfig?: any

  // data node specific
  patchFieldMappingConfig(data: any): Promise<void>
  fieldMapping?: FieldMapping
  fieldMappingConfig: any
  appSchema?: any

  patchDataSourceConfig(data: any): Promise<void>
  dataSourceConfig: any
  dataSource?: DataSource
  udm?: string
  udmSpec?: UnifiedDataModel
  defaultPath?: string
  dataCollectionSpec: DataCollectionSpec

  editableVariablesSchemaLocators?: string[]
  handleAddVariable?(locator: string, schema: any): void

  dataLinkConfig: any
  patchDataLinkConfig(data: any): Promise<void>
}>({} as any)

export function useGenericConfig() {
  return useContext(GenericConfigContext)
}

export const GenericConfigProvider = ({
  children,
  inputSchema,
  variablesSchema,
  configVariablesSchema,
  integrationId,
  connectionId,
  config: anyConfig,
  parentConfig,
  editableVariablesSchemaLocators = [],
  onChange,
}: PropsWithChildren<{
  inputSchema: any
  /**
   * Variables schema to be used in config time
   */
  configVariablesSchema?: DataSchema | undefined
  /**
   * Variables schema to be used in runtime
   */
  variablesSchema: DataSchema | undefined
  config: any
  parentConfig?: any
  integrationId?: string
  connectionId?: string
  editableVariablesSchemaLocators?: string[]
  onChange(data: any): Promise<void>
}>) => {
  const { integration } = useIntegration(integrationId)
  const { connection } = useConnection(connectionId)

  const config = anyConfig ?? {}

  const dataSourceConfig = config.dataSource ?? {}
  const fieldMappingConfig = config.fieldMapping ?? {}
  const dataLinkConfig = config.dataLink ?? {}

  async function patchConfig(data: any) {
    return await onChange({
      config: {
        ...config,
        ...(data ?? {}),
      },
    })
  }

  async function patchDataLinkConfig(data: any) {
    return await patchConfig({
      dataLink: {
        ...dataLinkConfig,
        ...(data ?? {}),
      },
    })
  }

  async function patchFieldMappingConfig(data: any) {
    return await patchConfig({
      fieldMapping: {
        ...fieldMappingConfig,
        ...(data ?? {}),
      },
    })
  }

  async function patchDataSourceConfig(data: any) {
    return await patchConfig({
      dataSource: {
        ...dataSourceConfig,
        ...(data ?? {}),
      },
    })
  }

  function handleAddVariable(locator: string, schema: any) {
    const field = locatorToField(locator)
    if (field.startsWith('input')) {
      const inputField = field.replace('input.', '')
      const newInputSchema = setSchemaAtLocator(inputSchema, inputField, schema)
      void onChange({
        inputSchema: newInputSchema,
      })
    }
  }

  function getSelectorForDependency(key: string) {
    if (!key) {
      return null
    }

    return integrationId
      ? {
          integrationId,
          key,
        }
      : {
          key,
        }
  }

  const { dataSource } = useDataSource(
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type '{ integrationId: string; key: st... Remove this comment to see the full error message
    getSelectorForDependency(dataSourceConfig.key),
  )

  const { fieldMapping, accessor: fieldMappingAccessor } = useFieldMapping(
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2345): Argument of type '{ integrationId: string; key: st... Remove this comment to see the full error message
    getSelectorForDependency(fieldMappingConfig.key),
  )

  const { data: fieldMappingAppSchema } = useSWR(
    fieldMapping ? `/field-mappings/${fieldMapping.id}/app-schema` : null,
    () => fieldMappingAccessor?.getAppSchema(),
  )

  const appSchema = fieldMappingAppSchema ?? fieldMappingConfig?.appSchema

  const udm = dataSource?.udm ?? dataSourceConfig.udm
  const udmSpec = udm ? UNIFIED_DATA_MODELS[udm] : null

  const defaultPath = dataSource?.defaultPath ?? dataSourceConfig.defaultPath

  let dataCollectionKey

  if (dataSource?.collectionKey) {
    dataCollectionKey = dataSource.collectionKey
  } else if (dataSourceConfig?.collectionKey) {
    dataCollectionKey = dataSourceConfig.collectionKey
  } else if (dataSourceConfig?.defaultPath) {
    dataCollectionKey = parseDataLocationPath(dataSourceConfig.defaultPath)?.key
  }

  const dataCollectionSpec = useDataCollectionSpec({
    key: dataCollectionKey,
    // FIXME: strictNullCheck temporary fix
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    integrationId,
  })

  return (
    <GenericConfigContext.Provider
      value={{
        level: connectionId
          ? IntegrationElementLevel.CONNECTION
          : integrationId
          ? IntegrationElementLevel.CONNECTOR
          : IntegrationElementLevel.UNIVERSAL,

        integrationId,
        integration,
        connectionId,
        connection,

        inputSchema,
        configVariablesSchema,
        variablesSchema,

        config,
        patchConfig,

        parentConfig,

        patchFieldMappingConfig,
        fieldMappingConfig,
        fieldMapping,
        appSchema,

        patchDataSourceConfig,
        dataSourceConfig,
        dataSource,
        udm,
        udmSpec,
        defaultPath,

        editableVariablesSchemaLocators,
        handleAddVariable,

        // FIXME: strictNullCheck temporary fix
        // @ts-expect-error
        dataCollectionSpec,

        dataLinkConfig,
        patchDataLinkConfig,
      }}
    >
      <IntegrationElementProvider integrationId={integrationId}>
        {children}
      </IntegrationElementProvider>
    </GenericConfigContext.Provider>
  )
}

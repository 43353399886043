/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Create Data Record",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "See also",
    "attributes": {},
    "children": []
  }]
}];
export const DESCRIPTION = 'Create a new record in an external app';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink, DocGrid} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "create-data-record",
      children: "Create Data Record"
    }), "\n", _jsxs(_components.p, {
      children: ["This node creates a record in a ", _jsx(DocLink, {
        path: "engine/blueprints/data-sources",
        children: "Data Source"
      })]
    }), "\n", _jsx(_components.p, {
      children: "To configure this node you can provide:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/references/udm",
          children: "Universal Data Model"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/blueprints/field-mappings",
          children: "Field Mapping"
        }), " and its input (whole node input is used by default)."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "see-also",
      children: "See also"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/data-sources"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/field-mappings"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

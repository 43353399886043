import { getArticle } from 'routes/Docs/helpers/articleList'
import { isInsideWorkspace } from 'hooks/isInsideWorkspace'

export function getDocRelativeLink(path: string): string {
  return isInsideWorkspace() ? `/docs/${path}` : path
}

export function useArticleData(path: string): {
  title: string
  description?: string
} {
  const article = getArticle(path)

  if (!article?.title) {
    return {
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      title: undefined,
    }
  }

  let description = article.description ?? ''

  if (description && !description.endsWith('.')) {
    description += '.'
  }

  return {
    title: article.title,
    description,
  }
}

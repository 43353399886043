/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "External Events",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Event Types",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Data Events",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Connector Events",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Custom Webhook",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "External Event Subscriptions",
    "attributes": {},
    "children": []
  }]
}];
export const DESCRIPTION = 'Reacting to events in external applications.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong",
    h2: "h2",
    h3: "h3",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components), {DocLink, Link} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "external-events",
      children: "External Events"
    }), "\n", _jsx(_components.p, {
      children: "Subscribing to events lets your application react to things that happen in external applications."
    }), "\n", _jsxs(_components.p, {
      children: ["To learn how to work with events that happen in ", _jsx(_components.strong, {
        children: "your application"
      }), " - see ", _jsx(DocLink, {
        path: "engine/blueprints/app-events"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Similarly to other integration elements, you can manage external events on three levels:"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Universal Events"
      }), " let you define events you want to work in a similar way across multiple external applications.\nImplementation could vary from application to application, but your application will receive these events in a consistent way and in a consistent format."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Integration-level Events"
      }), " let you define events that are specific to a particular external application."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Customer Event Instances"
      }), " produce events for a specific ", _jsx(DocLink, {
        path: "engine/deployments/customers",
        children: "Customer"
      }), " and ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connection"
      }), ". They can be customized on per-customer level."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can manage events on the ", _jsx(Link, {
        path: "/integrations/events",
        children: "External Events"
      }), " page."]
    }), "\n", _jsx(_components.h2, {
      id: "event-types",
      children: "Event Types"
    }), "\n", _jsx(_components.p, {
      children: "There are a few types of external events you can subscribe to:"
    }), "\n", _jsx(_components.h3, {
      id: "data-events",
      children: "Data Events"
    }), "\n", _jsxs(_components.p, {
      children: ["Data Events let you react to changes in a given ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/data",
        children: "Data Collection"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: ["There are three types of data events: ", _jsx(_components.code, {
        children: "data-record-created"
      }), ", ", _jsx(_components.code, {
        children: "data-record-updated"
      }), ", and ", _jsx(_components.code, {
        children: "data-record-deleted"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Data events have the following payload structure:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "type: <created|updated|deleted>\nrecord:\n    id: <string>\n    name: <string>\n    fields:\n        field1: <value>\n        ...\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "All data events are guaranteed to provide the type and record id, but the rest of the payload depends on the external API.\nTo be sure you receive all the fields for the modified record, you should retrieve the record from the external application using the provided id."
    }), "\n", _jsx(_components.h3, {
      id: "connector-events",
      children: "Connector Events"
    }), "\n", _jsx(_components.p, {
      children: "Each connector can provide its own unique set of events. They usually, but not always, match webhooks supported by the external application."
    }), "\n", _jsx(_components.h3, {
      id: "custom-webhook",
      children: "Custom Webhook"
    }), "\n", _jsx(_components.p, {
      children: "You can define fully custom webhooks with your own logic for subscribing, unsubscribing, and handling the webhooks. You can use this if connector does not provide the events you need."
    }), "\n", _jsx(_components.h2, {
      id: "external-event-subscriptions",
      children: "External Event Subscriptions"
    }), "\n", _jsx(_components.p, {
      children: "A subscription is created when you want to start receiving events of a given type."
    }), "\n", _jsx(_components.p, {
      children: "Subscriptions can be shared between multiple integration elements such as flows and event instances.\nFor example, if multiple flows are subscribed to updates of data records in the same collection, only one subscription will be created."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'
import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .flow('{FLOW_KEY}')
  .setup()
`
const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/flows/{FLOW_KEY}/setup',
  {
    method: 'POST',
  },
)

export function SetupFlowInstanceCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FLOW_KEY: CodeParamType.FlowKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .flow(parameters.FLOW_KEY)
          .setup()
      }}
    />
  )
}

// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = 'a39b6548cf0141ef20dbc2f28f87a9ce';
const css =`._backdrop_1sa3o_1{align-items:center;display:flex;justify-content:center;overflow-y:auto;z-index:500}._backdrop_1sa3o_1,._overlay_1sa3o_1{inset:0;position:fixed}._overlay_1sa3o_1{background-color:rgb(0 0 0/var(--tw-bg-opacity));opacity:.5}._dialog_1sa3o_1,._overlay_1sa3o_1{--tw-bg-opacity:1}._dialog_1sa3o_1{background-color:rgb(255 255 255/var(--tw-bg-opacity));border-radius:.125rem;flex-grow:0;margin:2rem auto;max-height:100vh;max-width:28rem;padding:1.25rem 2rem 1.5rem;position:relative;width:min(28rem,100%)}._title_1sa3o_1{font-size:20px;font-weight:700;line-height:28px;margin-bottom:0;margin-top:0}._actionBar_1sa3o_1{align-items:center;display:flex;flex-direction:row;gap:1rem;justify-content:flex-end;padding-top:1.75rem}`;
const styles = {
    get ['backdrop']() { injectStyles(key, css, options);  return '_backdrop_1sa3o_1'; },
    get ['overlay']() { injectStyles(key, css, options);  return '_overlay_1sa3o_1'; },
    get ['dialog']() { injectStyles(key, css, options);  return '_dialog_1sa3o_1'; },
    get ['title']() { injectStyles(key, css, options);  return '_title_1sa3o_1'; },
    get ['actionBar']() { injectStyles(key, css, options);  return '_actionBar_1sa3o_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;

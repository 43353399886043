import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { useActions } from '@integration-app/react'
import { CollapsibleSection } from '../../../../../../../components/Section'
import { GoToElementLink } from '../../../../../../../../../../components/GoToElemenLink'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { makeDataField } from '@integration-app/ui'
import { routeTo } from '../../../../../../../routes-constants'
import { ValueType } from '@integration-app/ui/ComboBox/value-spec'
import { Row, Cell } from '@integration-app/ui/DataBuilderUI'
import ComboBox from '@integration-app/ui/ComboBox'

export function RunAction() {
  const { config, patchConfig, integrationId, variablesSchema } =
    useGenericConfig()

  const { actions } = useActions({
    integrationId: integrationId ?? 'null',
  })

  const options = actions.map((action) => ({
    label: action.name,
    value: action.key,
  }))

  const selected = actions.find(({ key }) => key === config.action?.key)

  function onChange(key: string) {
    return patchConfig({
      ...config,
      action: { key },
    })
  }

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Action</CollapsibleSection.Header>

      <p>
        <span className='mr-2'>Select which action to run.</span>
      </p>

      <div className='my-4 flex items-center gap-4'>
        <Row>
          <Cell.Name shrink>Action</Cell.Name>
          <Cell.Combobox grow>
            <ComboBox
              placeholder='Select Action'
              value={config.action?.key}
              options={options}
              onChange={onChange}
              valueSpec={{ type: ValueType.STRING, allowCustom: false }}
            />
          </Cell.Combobox>
        </Row>

        {selected && <GoToElementLink to={routeTo.action(selected)} />}
      </div>

      {selected && selected.inputSchema && (
        <CollapsibleSection open>
          <CollapsibleSection.Header>Input</CollapsibleSection.Header>

          <DataBuilderForm
            field={makeDataField({
              schema: selected.inputSchema,
              value: config.input,
              variablesSchema,
            })}
            onChange={(input) => patchConfig({ input })}
          />
        </CollapsibleSection>
      )}
    </CollapsibleSection>
  )
}

import { useRouter } from 'next/router'
import useAuth from 'contexts/auth'
import { TbArrowBackUp } from 'react-icons/tb'
import classes from './BackToWorkspaceLink.module.css'
import useWorkspace from 'components/Workspaces/workspace-context'
import { UniversalLink } from 'routes/components/UniversalLink'

export function BackToWorkspaceLink() {
  const { self } = useAuth()
  const { route } = useRouter()
  const { workspace } = useWorkspace()

  const isDocInReactRouter = route.startsWith('/w/[workspaceId]')

  // If workspace is exist, link to the workspace
  const workspaceLink =
    workspace?.id && !isDocInReactRouter ? `/w/${workspace.id}` : '/'

  if (!self?.user?.id) return null

  return (
    <div className={classes.wrapper}>
      <UniversalLink
        className={classes.link}
        to={workspaceLink}
        isNextLink={!isDocInReactRouter}
      >
        <TbArrowBackUp /> Back to Workspace
      </UniversalLink>
    </div>
  )
}

import { createContext, PropsWithChildren, useContext } from 'react'
import { Action } from '@integration-app/sdk'
import { useAction } from '@integration-app/react'
import { EmptyPageLoader } from '../../../../../../../components/Loader'
import { useExportFieldsAsCode } from '../../../components/useExportFieldsAsCode'

export const ActionContextProvider = ({
  actionId,
  children,
}: PropsWithChildren<{
  actionId: string | undefined
}>) => {
  const { action, put, refresh, reset, apply, archive } = useAction(actionId)

  const parentId = action?.parentId ?? undefined

  const { action: parentAction, refresh: refreshParent } = useAction(parentId)

  async function onArchive() {
    await archive()

    if (parentAction) await refreshParent()
  }

  async function onReset() {
    await reset()

    if (parentAction) await refreshParent()
  }

  const { code, onCodeChange, mutateCode } = useExportFieldsAsCode(
    'actions',
    actionId,
    put,
  )

  async function onChange(data: Partial<Action>) {
    await put({
      ...action,
      ...(data ?? {}),
    })
    void mutateCode()
    void refreshParent()
  }

  if (!actionId || !action) {
    return <EmptyPageLoader />
  }

  return (
    <ActionContext.Provider
      value={{
        action,
        parentAction,

        onChange,

        apply,
        reset: onReset,

        refresh,

        archive: onArchive,

        code,
        onCodeChange,
      }}
    >
      {children}
    </ActionContext.Provider>
  )
}

const ActionContext = createContext<{
  action: Action
  parentAction: Action | undefined
  refresh(): Promise<Action>
  onChange(data: Partial<Action>): Promise<any>
  apply(integrationKeys: string[]): Promise<Action[] | undefined>
  archive(): Promise<void>
  reset(): Promise<void>

  code: any
  onCodeChange(newCode: any): Promise<void>
}>({} as any)

export function useActionContext() {
  return useContext(ActionContext)
}

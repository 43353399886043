import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'
import { httpCodeExamples } from '../http-code-examples'

const examples = httpCodeExamples('/{GLOBAL_WEBHOOK_URI}', {
  method: 'POST',
  body: '{INPUT}',
})

export function TriggerGlobalWebhookCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{
        ...examples,
      }}
      parameters={{
        INPUT: {
          type: CodeParamType.Object,
          default: {},
        },
      }}
    />
  )
}

import { GearHalfButton } from 'routes/Workspaces/Workspace/Blueprints/Flows/Flow/Test/Instances/components/GearHalfButton'
import Button from '@integration-app/ui/Button'
import { Connection } from '@integration-app/sdk'
import { IntegrationComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationComboboxSelect'
import { useEffect, useState } from 'react'
import useWorkspace from 'components/Workspaces/workspace-context'
import { useNavigate } from 'react-router-dom'
import { routeTo } from 'routes/Workspaces/Workspace/routes-constants'

export function IntegrationWithConnectionComboboxSelect({
  integrationKey,
  onIntegrationSelect,
}: {
  integrationKey: string
  onIntegrationSelect?(integrationKey: string | undefined)
}) {
  const navigate = useNavigate()
  const { testCustomerClient } = useWorkspace()
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [connection, setConnection] = useState<Connection>(null)
  const [loadingConnection, setLoadingConnection] = useState(false)

  function handleOpenConnectionSidePanel() {
    if (!connection) return

    return navigate(routeTo.connection(connection))
  }

  useEffect(() => {
    if (integrationKey && testCustomerClient) {
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setConnection(null)
      void loadConnection()
    }
  }, [integrationKey, testCustomerClient])

  async function handleConnect() {
    if (!integrationKey) return

    const newConnection = await testCustomerClient
      .integration(integrationKey)
      .openNewConnection()

    setConnection(newConnection)
  }

  async function loadConnection() {
    if (!integrationKey) return

    setLoadingConnection(true)

    const data = await testCustomerClient.get('connections', {
      integrationKey: integrationKey,
    })

    if (data?.items?.length) setConnection(data.items[0])

    setLoadingConnection(false)
  }

  return (
    <div className='flex'>
      <IntegrationComboboxSelect
        value={integrationKey}
        onChange={(i) => onIntegrationSelect?.(i)}
        editable
        hasRightSibling
      />

      {!connection && (
        <Button
          onClick={handleConnect}
          className='px-2'
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          disabled={loadingConnection || !integrationKey}
        >
          Connect
        </Button>
      )}

      {connection && <GearHalfButton onClick={handleOpenConnectionSidePanel} />}
    </div>
  )
}

import { useCallback, useState } from 'react'

export function useNodesHoveringState() {
  const [hoveringPortalNodeKey, hoverPortalNode] = useState('')
  const [hoveringNodeKey, hoverNode] = useState('')

  return {
    hoveringPortalNodeKey,
    hoverPortalNode,
    hoveringNodeKey,
    hoverNode,
  }
}

export function useNodeMouseEvents(
  nodeKey?: string,
  hoverNode?: (key: string) => void,
) {
  const handleMouseLeave = useCallback(() => hoverNode?.(''), [])
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const handleMouseEnter = useCallback(() => hoverNode?.(nodeKey), [])

  return {
    handleMouseLeave,
    handleMouseEnter,
  }
}

export interface HoveringNodeState {
  hoveringPortalNodeKey: string
  hoverPortalNode: (key: string) => void

  hoveringNodeKey: string
  hoverNode: (key: string) => void
}

import { HttpRequestConfig } from '../../../../../../../components/common-configs/HttpRequestConfig'
import { HttpResponseConfig } from '../../../../../../../components/common-configs/HttpResponseConfig'

export function CustomHttpRequest() {
  return (
    <>
      <HttpRequestConfig />

      <HttpResponseConfig />
    </>
  )
}

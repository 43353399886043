import CallOut from '../../../../../components/Docs/CallOut'
import React, { useState } from 'react'
import { Button } from '@integration-app/ui'

export function ResetCallOut({
  description,
  reset,
}: {
  description: string
  reset: () => Promise<any>
}) {
  const [resetting, setResetting] = useState(false)

  async function handleReset() {
    setResetting(true)
    await reset()
    setResetting(false)
  }

  return (
    <CallOut type='info'>
      <p>{description}</p>

      <Button
        onClick={handleReset}
        variant='secondary'
        loading={resetting}
        disabled={resetting}
      >
        Reset
      </Button>
    </CallOut>
  )
}

import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'
import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .dataSources.list()
`
const rest = httpCodeExamples('/connections/{INTEGRATION_KEY}/data-sources')

export function ListDataSourceInstancesCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .dataSources.list()
      }}
    />
  )
}

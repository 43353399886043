/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Data Records",
  "attributes": {},
  "children": []
}];
export const DESCRIPTION = 'Standardized representation of data used in integrations.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    pre: "pre",
    code: "code",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "data-records",
      children: "Data Records"
    }), "\n", _jsx(_components.p, {
      children: "Each record in Data Collections will have the following structure:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "id: <unique record id>\nname: <human-readable record name>\nurl: <url record can be accessed at>\nfields:\n     field: <field value>\n     ...\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Connectors are responsible for transforming data from application-specific format into Data Records and back. Each record will have the following top-level properties:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "id"
        }), " - record id, unique within a given data collection."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "name"
        }), " - human-readable name of the record. It will be a person's name in case of person, task title in case of task, etc."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "uri"
        }), " - link to the record if it has its own page."]
      }), "\n", _jsx(_components.li, {
        children: _jsx(_components.code, {
          children: "createdTime"
        })
      }), "\n", _jsx(_components.li, {
        children: _jsx(_components.code, {
          children: "updatedTime"
        })
      }), "\n", _jsx(_components.li, {
        children: _jsx(_components.code, {
          children: "deletedTime"
        })
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "createdById"
        }), " - id of an entity (usually a user) that created the record."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "updatedById"
        }), " - id of an entity that updated the record last."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "fields"
        }), " - data fields of the record that match ", _jsx(_components.code, {
          children: "fieldsSchema"
        }), " of the collection. Usually they match exactly the fields returned by the external application. They may differ if connector developer decided to simplify the schema for end-users."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Connector will try to do its best to populate as many of these properties as possible. At the very least, ", _jsx(_components.code, {
        children: "id"
      }), " is always populated."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;

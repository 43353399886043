import ExampleCodeBlock from '../../Docs/ExampleCodeBlock'

const jsx = `import { IntegrationAppProvider } from '@integration-app/react'

export function MyApp() {
  return (
    <IntegrationAppProvider token='{TOKEN}'>
      <MyComponent />
    </IntegrationAppProvider>
  )
}`

export function IntegrationAppProviderCodeExample() {
  return <ExampleCodeBlock customCodes={{ jsx }} />
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Authentication in Connectors",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Connector Auth Spec",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Authentication Flow",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Custom Callback URL",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Custom State",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Auth from outside of integration.app",
      "attributes": {},
      "children": []
    }]
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    code: "code",
    blockquote: "blockquote",
    ol: "ol",
    a: "a",
    img: "img",
    h3: "h3",
    pre: "pre"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "authentication-in-connectors",
      children: "Authentication in Connectors"
    }), "\n", _jsx(_components.p, {
      children: "To create a new connection or refresh credentials for an existing connection, follow the steps described below.\nThey work in the same way for every connector regardless of the app that is being connected."
    }), "\n", _jsx(_components.h2, {
      id: "connector-auth-spec",
      children: "Connector Auth Spec"
    }), "\n", _jsx(_components.p, {
      children: "You can get information about how authentication works in a given connector from its specification. Look for the following fields:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "auth.type"
        }), " - type of authentication supported by the connector. Possible values are:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "client-credentials"
            }), " - Authentication based on credentials user enters in the UI."]
          }), "\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "oauth1"
            }), " - OAuth1 authentication"]
          }), "\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "oauth2"
            }), " - OAuth2 authentication"]
          }), "\n"]
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "ui"
        }), " - information about what to display user in the UI when authenticating."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "authentication-flow",
      children: "Authentication Flow"
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsxs(_components.p, {
        children: ["We recommend using ", _jsx(DocLink, {
          path: "ux/connection",
          children: "Connection UI"
        }), " implemented with our drop-in components or Javascript SDK.\nUse the flow below only when you know what you are doing."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "To authenticate a user ou need to do the following:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Collect connection parameters from the user. They need to match ", _jsx(_components.code, {
          children: "ui.schema"
        }), "."]
      }), "\n", _jsxs(_components.li, {
        children: ["Redirect user to ", _jsx(_components.code, {
          children: "https://api.integration.app/connection-popup"
        }), " URL with the parameters described below. If ", _jsx(_components.code, {
          children: "auth.type"
        }), " is ", _jsx(_components.code, {
          children: "client-credentials"
        }), ", you can open this URL in a hidden iframe. If not - you need to open it in a new window visible to the user."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Add the following query parameters to the URL:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["token - your customer's ", _jsx(DocLink, {
          path: "engine/deployments/customers",
          children: "Access Token"
        })]
      }), "\n", _jsx(_components.li, {
        children: "requestId - a unique id of this authentication request. It will be used in the callback to separate callbacks from different requests. Note: providing this parameter will result in window being closed after connection is created. If you want it to behave differently (for example, redirect to another URL), you cannot use this parameter."
      }), "\n", _jsx(_components.li, {
        children: "integrationKey - key of the integration you want to authenticate against."
      }), "\n", _jsx(_components.li, {
        children: "connectionId (optional) - id of the connectio you want to refresh credentials for (if you want to do it for a specific connection)"
      }), "\n", _jsx(_components.li, {
        children: "connectionParameters (optional) - parameters provided by user in the connection UI, JSON-encoded."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "custom-callback-url",
      children: "Custom Callback URL"
    }), "\n", _jsxs(_components.p, {
      children: ["In some cases you need to use a custom Callback URL instead of standard ", _jsx(_components.code, {
        children: "https://api.integration.app/oauth-callback"
      }), ". These situations include:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "When you need to approve your app and callback URL needs to be on your domain (for example, Google requires that)."
      }), "\n", _jsxs(_components.li, {
        children: ["You want to create a custom installation logic for some application stores (for example, ", _jsx(_components.a, {
          href: "https://pipedrive.readme.io/docs/app-installation-flows",
          children: "Pipedrive app installation flow"
        }), ")."]
      }), "\n", _jsx(_components.li, {
        children: "When you want to do any other custom handling of oAuth callback."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "You can set custom callback URI in the integration settings. It will be used for all connections created for this integration."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/integration-oauth-callback-uri.png",
      alt: "Custom OAuth Callback URI"
    }), "\n", _jsxs(_components.p, {
      children: ["After handling the callback URI, you need to redirect user to ", _jsx(_components.code, {
        children: "https://api.integration.app/oauth-callback"
      }), " URL with the same query parameters you received in the callback URI."]
    }), "\n", _jsxs(_components.p, {
      children: ["For example, if you use callback url ", _jsx(_components.code, {
        children: "https://myapp.com/oauth-callback"
      }), " and a third-party application sends user to the following URL:"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.code, {
        children: "https://myapp.com/oauth-callback?code=abcd&state=1234"
      })
    }), "\n", _jsx(_components.p, {
      children: "when you are done with the user, you need to redirect them to:"
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.code, {
        children: "https://api.integration.app/oauth-callback?code=abcd&state=1234"
      })
    }), "\n", _jsx(_components.h3, {
      id: "custom-state",
      children: "Custom State"
    }), "\n", _jsxs(_components.p, {
      children: ["For OAuth2 connections, you can add a custom component to the ", _jsx(_components.code, {
        children: "state"
      }), " parameter used in the callback URI. This can be useful if you want to pass some information through the authentication process."]
    }), "\n", _jsxs(_components.p, {
      children: ["To do this, simply add ", _jsx(_components.code, {
        children: "customState"
      }), " query parameter when opening the ", _jsx(_components.code, {
        children: "https://api.integration.app/connection-popup"
      }), " URL like this:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "https://api.integration.app/connection-popup?customState=MY_CUSTOM_STATE\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["When the user is redirected to the callback URI, the ", _jsx(_components.code, {
        children: "state"
      }), " parameter will contain the value of ", _jsx(_components.code, {
        children: "customState"
      }), " parameter after ", _jsx(_components.code, {
        children: ":"
      }), " symbol like this:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "https://myapp.com/oauth-callback?code=abcd&state=1234:MY_CUSTOM_STATE\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "auth-from-outside-of-integrationapp",
      children: "Auth from outside of integration.app"
    }), "\n", _jsx(_components.p, {
      children: "When connection is initiated outside of integration.app (for example, from an app store of a third-party application),\nyou need to add the following additional query parameters when redirecting user to integration.app:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "token"
        }), " is ", _jsx(DocLink, {
          path: "overview/getting-started/authentication",
          children: "Integration.app Token"
        }), " you normally use to access our API or SDK."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "integration"
        }), " is id or key of the integration you want to authenticate against (i.e. ", _jsx(_components.code, {
          children: "hubspot"
        }), " or ", _jsx(_components.code, {
          children: "salesforce"
        }), ")."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "redirectUri"
        }), " is the URL you want to redirect user to after connection is created. This could be a page in your app you want the user to end up on."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["For example, if you use callback url ", _jsx(_components.code, {
        children: "https://myapp.com/oauth-callback"
      }), " and a third-party application sends user to the following URL:"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.code, {
        children: "https://myapp.com/oauth-callback?code=abcd"
      })
    }), "\n", _jsx(_components.p, {
      children: "when you are done with the user, you need to redirect them to:"
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.code, {
        children: "https://api.integration.app/oauth-callback?integration=pipedrive&code=abcd&token=AUTH_TOKEN&redirectUri=https://myapp.com/configure-integration"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["After user's connection is successfully created by integration.app, the user will be redirected to ", _jsx(_components.code, {
        children: "https://myapp.com/configure-integration"
      }), " URL.\nIf redirectUri is not provided - integration.app will simply close the window after connection is successfully created."]
    }), "\n", _jsxs(_components.p, {
      children: ["If connection creation fails, user will be redirected to your redirectUri with ", _jsx(_components.code, {
        children: "error"
      }), " parameter like this:"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.code, {
        children: "https://myapp.com/configure-integration?error=ERROR_MESSAGE"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

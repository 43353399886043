/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Flow Nodes",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Triggers",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Working with External Data",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Working with Data Links",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Working with APIs",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Utilities",
    "attributes": {},
    "children": []
  }]
}];
import {DocGrid} from "components/Docs/DocLinksGrid";
export const DESCRIPTION = 'Building blocks for integration flows';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2"
  }, _provideComponents(), props.components);
  if (!DocGrid) _missingMdxReference("DocGrid", false);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "flow-nodes",
      children: "Flow Nodes"
    }), "\n", _jsx(_components.h2, {
      id: "triggers",
      children: "Triggers"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/api-trigger"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/schedule-trigger"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/app-event-trigger"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/data-record-created-trigger"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/data-record-updated-trigger"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/data-record-deleted-trigger"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "working-with-external-data",
      children: "Working with External Data"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/list-data-records"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/find-data-record-by-id"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/create-data-record"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/update-data-record"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/find-data-record-by-id"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "working-with-data-links",
      children: "Working with Data Links"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/create-data-link"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/find-data-link"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "working-with-apis",
      children: "Working with APIs"
    }), "\n", _jsx(DocGrid, {
      children: _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/http-request"
      })
    }), "\n", _jsx(_components.h2, {
      id: "utilities",
      children: "Utilities"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/filter"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows/nodes/transform-data"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

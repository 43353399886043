import { ComponentType, useState } from 'react'
import { ExampleBlock, Tab } from 'components/Docs/CodeBlockUI'
import { CodeBlockRunButton, LoginToRun } from 'components/Docs/CodeBlockUI'
import useAuth from '../../contexts/auth'
import { CodeParams } from 'components/Docs/CodeBlockComponents'
import { CodeBlockType, CodeParamType } from 'components/Docs/codeBlock-types'
import {
  codeBlocksToCodeExamples,
  ParametersSpec,
  renderVariables,
} from './ExampleCodeBlock'
import { IntegrationAppProvider } from '@integration-app/react'
import useApp from '../Workspaces/workspace-context'
import useDocs from '../../routes/Docs/components/docs-context'

export interface RenderArgs {
  parameters: Record<string, any>
}

function ExampleUIBlock({
  variables = {},
  customCodes,
  parameters: parametersSpec = {},
  Component,
}: {
  variables?: Record<string, string>
  customCodes: CodeBlockType
  parameters?: ParametersSpec
  Component?: ComponentType<RenderArgs>
}) {
  const { self, loginLink } = useAuth()
  const { testCustomerClient } = useApp()
  const { parameters } = useDocs()
  const [rendered, setRendered] = useState(null)

  const codeExamples = codeBlocksToCodeExamples(customCodes)

  function replaceParamsAndVariables(code: string) {
    return renderVariables(
      renderVariables(code, parameters),
      variables,
      parametersSpec,
    )
  }

  return (
    <Tab.Group>
      <CodeParams disabled={!self?.user?.id} parametersSpec={parametersSpec} />
      <Tab.List>
        {codeExamples.map((codeExample) => (
          <Tab key={codeExample.title}>{codeExample.title}</Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {codeExamples.map((codeExample) => (
          <Tab.Panel key={codeExample.title}>
            <ExampleBlock.CodeBlock
              code={replaceParamsAndVariables(codeExample.code)}
              language={codeExample.language}
              copyToClipboard
            />
          </Tab.Panel>
        ))}
      </Tab.Panels>

      {Component && (
        <ExampleBlock.Toolbar>
          {self?.user?.id ? (
            <>
              {rendered ? (
                // FIXME: strictNullCheck temporary fix
                // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
                <CodeBlockRunButton onClick={() => setRendered(false)}>
                  Hide
                </CodeBlockRunButton>
              ) : (
                // FIXME: strictNullCheck temporary fix
                // @ts-expect-error TS(2345): Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
                <CodeBlockRunButton onClick={() => setRendered(true)}>
                  Render
                </CodeBlockRunButton>
              )}
            </>
          ) : (
            <LoginToRun>
              Please <a href={loginLink(window.location.href)}>log in</a> to run
              this example
            </LoginToRun>
          )}
        </ExampleBlock.Toolbar>
      )}

      {rendered && (
        <ExampleBlock.Output>
          <IntegrationAppProvider
            token={testCustomerClient.token}
            apiUri={testCustomerClient.apiUri}
            uiUri={testCustomerClient.uiUri}
          >
            {/* FIXME: strictNullCheck temporary fix */}
            {/* @ts-expect-error TS(2604): JSX element type 'Component' does not have any con... Remove this comment to see the full error message*/}
            <Component parameters={parameters} />
          </IntegrationAppProvider>
        </ExampleBlock.Output>
      )}
    </Tab.Group>
  )
}

ExampleUIBlock.ParamType = CodeParamType

export default ExampleUIBlock

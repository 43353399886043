import {
  CommonItemComboboxSelect,
  ItemComboBoxGenericValue,
  ItemComboBoxGenericValuePlaceholder,
  ItemComboboxSelectProps,
} from './CommonItemComboboxSelect'
import { Flow } from '@integration-app/sdk'
import useIntegrationElement from '../hooks/useIntegrationElement'

export function FlowComboboxSelect({
  value,
  onChange,
  editable = true,
  hasRightSibling = false,
}: ItemComboboxSelectProps<string>) {
  const { items } = useIntegrationElement<Flow>({
    route: 'flows',
  })
  const options = items?.map((ds) => ({
    value: ds.key,
    label: ds.name,
  }))

  const selectedOption = options?.find((o) => o.value === value)

  return (
    <CommonItemComboboxSelect<string>
      value={value}
      onChange={onChange}
      options={options}
      hasRightSibling={hasRightSibling}
      editable={editable}
      valueComponent={
        <ItemComboBoxGenericValue
          title={'Flow:'}
          value={selectedOption?.label ?? ''}
        />
      }
      valuePlaceholderComponent={
        <ItemComboBoxGenericValuePlaceholder title={'Flow:'} />
      }
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Data Source Instances",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Advanced Options",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "API",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Create",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Get",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Update",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Setup or Refresh",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Reset",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Archive",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Navigating Data Source Instances",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "List Data Collections",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Point Data Source to a Collection",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Data Collection Parameters",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Working with Data",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Multiple Data Source Instances per Connection",
    "attributes": {},
    "children": []
  }]
}];
import {CodeExample} from '../../../../../components/CodeExamples';
export const DESCRIPTION = 'Data Sources for a specific Customer Connection';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    code: "code",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3",
    img: "img",
    pre: "pre"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.ArchiveDataSourceInstance) _missingMdxReference("CodeExample.ArchiveDataSourceInstance", true);
  if (!CodeExample.CreateDataSourceInstance) _missingMdxReference("CodeExample.CreateDataSourceInstance", true);
  if (!CodeExample.GetDataSourceInstance) _missingMdxReference("CodeExample.GetDataSourceInstance", true);
  if (!CodeExample.GetDataSourceInstanceWithInstanceKey) _missingMdxReference("CodeExample.GetDataSourceInstanceWithInstanceKey", true);
  if (!CodeExample.ListIntegrationDataCollections) _missingMdxReference("CodeExample.ListIntegrationDataCollections", true);
  if (!CodeExample.PatchDataSourceInstance) _missingMdxReference("CodeExample.PatchDataSourceInstance", true);
  if (!CodeExample.PatchDataSourceInstanceCollection) _missingMdxReference("CodeExample.PatchDataSourceInstanceCollection", true);
  if (!CodeExample.ResetDataSourceInstance) _missingMdxReference("CodeExample.ResetDataSourceInstance", true);
  if (!CodeExample.SetupDataSourceInstance) _missingMdxReference("CodeExample.SetupDataSourceInstance", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "data-source-instances",
      children: "Data Source Instances"
    }), "\n", _jsxs(_components.p, {
      children: ["To interact with a Data Source, you need to get a Data Source Instance for a\nspecific ", _jsx(DocLink, {
        path: "engine/deployments/connections",
        children: "Connection"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "You can get an instance by Data Source Identifier (key or id) and Connection Identifier (integration key or connection id):"
    }), "\n", _jsx(CodeExample.GetDataSourceInstance, {}), "\n", _jsx(_components.p, {
      children: "Data Source Instance has the following properties:"
    }), "\n", _jsx("table", {
      className: 'stripe w-full',
      children: _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "id"
            })
          }), "\n", _jsx("td", {
            children: "Unique identifier of the instance."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "path"
            })
          }), "\n", _jsx("td", {
            children: "Path to the Data Collection the data source is pointing to."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "defaultPath"
            })
          }), "\n", _jsx("td", {
            children: "Path to the Data Collection the data source is pointing to by default."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "udm"
            })
          }), "\n", _jsx("td", {
            children: "a Universal Data Model records in this data source will be transformed to / from."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "pullUpdatesIntervalSeconds"
            })
          }), "\n", _jsx("td", {
            children: "how often will be data source pulled for new events."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "fullSyncIntervalSeconds"
            })
          }), "\n", _jsx("td", {
            children: "how often will the data source be fully synced (to get events that require a full sync)."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "subscriptions"
            })
          }), "\n", _jsx("td", {
            children: "information about active subscriptions to Data Source Events (see below for details)."
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "isCustomized"
            })
          }), "\n", _jsx("td", {
            children: "whether this data source was customized by your customer (path was changed, etc.)"
          })]
        })]
      })
    }), "\n", _jsx(_components.h2, {
      id: "advanced-options",
      children: "Advanced Options"
    }), "\n", _jsx(_components.p, {
      children: "Data Sources can be configured with the following advanced options:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Pull Updates Interval - number of seconds between each pull for updates such as new events (except events that require Full Sync). Setting it lower will increase the number of API requests and may result in hitting rate limits."
      }), "\n", _jsx(_components.li, {
        children: "Full Sync Interval - number of seconds between full syncs of the data source. Full Sync is a very heavy operation since it pull ALL the data from the data source. It generates events for subscriptions that require Full Sync."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "api",
      children: "API"
    }), "\n", _jsxs(_components.p, {
      children: ["Here is how to interact with data in external applications using ", _jsx(DocLink, {
        path: "engine/blueprints/data-sources",
        children: "Data Sources"
      }), "."]
    }), "\n", _jsx(_components.h3, {
      id: "create",
      children: "Create"
    }), "\n", _jsx(CodeExample.CreateDataSourceInstance, {}), "\n", _jsx(_components.h3, {
      id: "get",
      children: "Get"
    }), "\n", _jsx(CodeExample.GetDataSourceInstance, {}), "\n", _jsx(_components.h3, {
      id: "update",
      children: "Update"
    }), "\n", _jsx(CodeExample.PatchDataSourceInstance, {}), "\n", _jsx(_components.h3, {
      id: "setup-or-refresh",
      children: "Setup or Refresh"
    }), "\n", _jsx(CodeExample.SetupDataSourceInstance, {}), "\n", _jsx(_components.h3, {
      id: "reset",
      children: "Reset"
    }), "\n", _jsx(CodeExample.ResetDataSourceInstance, {}), "\n", _jsx(_components.h3, {
      id: "archive",
      children: "Archive"
    }), "\n", _jsx(CodeExample.ArchiveDataSourceInstance, {}), "\n", _jsx(_components.h2, {
      id: "navigating-data-source-instances",
      children: "Navigating Data Source Instances"
    }), "\n", _jsxs(_components.p, {
      children: ["Data Sources point to ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/data",
        children: "Data Collections"
      }), " in external apps.\nThey let you store and customize data collection and their parameters per customer."]
    }), "\n", _jsx(_components.p, {
      children: "This page describes how to navigate data sources and configure them for your customers."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/customer-data-source.png",
      alt: "Customer Data Sources"
    }), "\n", _jsx(_components.h3, {
      id: "list-data-collections",
      children: "List Data Collections"
    }), "\n", _jsx(_components.p, {
      children: "To get the list of available data collections in a given connector:"
    }), "\n", _jsx(CodeExample.ListIntegrationDataCollections, {}), "\n", _jsxs(_components.p, {
      children: ["The response will contain a ", _jsx(_components.code, {
        children: "locations"
      }), " property with a list of nested directories and collections.\nIf there are too many to return at once, the response will also contain ", _jsx(_components.code, {
        children: "cursor"
      }), " property.\nYou have to pass it back to get the next page of results."]
    }), "\n", _jsxs(_components.p, {
      children: ["To list locations inside a specific directory, pass its ", _jsx(_components.code, {
        children: "path"
      }), " to the ", _jsx(_components.code, {
        children: "getLocations"
      }), " method."]
    }), "\n", _jsx(_components.h3, {
      id: "point-data-source-to-a-collection",
      children: "Point Data Source to a Collection"
    }), "\n", _jsxs(_components.p, {
      children: ["When Data Source is created, it will point to a default collection (if one exists in the external app). You can change it by updating the ", _jsx(_components.code, {
        children: "collectionKey"
      }), " property of the Data Source:"]
    }), "\n", _jsx(CodeExample.PatchDataSourceInstanceCollection, {}), "\n", _jsxs(_components.p, {
      children: ["You can only update the ", _jsx(_components.code, {
        children: "collectionKey"
      }), " to point to a Collection. After you updated ", _jsx(_components.code, {
        children: "collectionKey"
      }), ", accessing data source's ", _jsx(_components.code, {
        children: "collection"
      }), " use the new path."]
    }), "\n", _jsx(_components.h3, {
      id: "data-collection-parameters",
      children: "Data Collection Parameters"
    }), "\n", _jsxs(_components.p, {
      children: ["Some collections have parameters. For example, a collection \"Tasks by Project\" may have ", _jsx(_components.code, {
        children: "projectId"
      }), " parameter."]
    }), "\n", _jsxs(_components.p, {
      children: ["If collection has parameters, it will have ", _jsx(_components.code, {
        children: "parametersSchema"
      }), " property.\nIt is a ", _jsx(DocLink, {
        path: "engine/references/data-schemas",
        children: "Data Schema"
      }), " for its parameters."]
    }), "\n", _jsxs(_components.p, {
      children: ["To apply parameters to a data collection, add them as query string to the ", _jsx(_components.code, {
        children: "path"
      }), " property of the Data Source Instance, like this:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "{\n  \"path\": \"/data/tasks?projectId=123\"\n}\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "working-with-data",
      children: "Working with Data"
    }), "\n", _jsxs(_components.p, {
      children: ["You can read and write data records using a Data Collection identified by the ", _jsx(_components.code, {
        children: "collectionKey"
      }), " property of the Data Source Instance."]
    }), "\n", _jsx(_components.h2, {
      id: "multiple-data-source-instances-per-connection",
      children: "Multiple Data Source Instances per Connection"
    }), "\n", _jsxs(_components.p, {
      children: ["By default, each pair of Connection and Data Source will have only one Data Source instance.\nIf you change it (for example, set a ", _jsx(_components.code, {
        children: "collectionKey"
      }), "), it will apply to every place you use a given Data Source with a given connection."]
    }), "\n", _jsx(_components.p, {
      children: "In some cases you may want to create multiple Data Source Instances for the same Connection and Data Source -\nfor example, if you have multiple locations you want to import the"
    }), "\n", _jsxs(_components.p, {
      children: ["This can be archived by providing ", _jsx(_components.code, {
        children: "instanceKey"
      }), " when accessing a Data Source. Each ", _jsx(_components.code, {
        children: "instanceKey"
      }), " will create a new Data Source Instance."]
    }), "\n", _jsx(CodeExample.GetDataSourceInstanceWithInstanceKey, {})]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

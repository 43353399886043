/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Deploying Flows",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Running Flows",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "API Trigger",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "App Event Trigger",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "External Event or Schedule",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Customizing Flows",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Adding Flows to Your App';
export const DESCRIPTION = "Run a flow depends on the trigger: API, App Event, External Event or Schedule.";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    h3: "h3",
    ol: "ol",
    li: "li",
    ul: "ul"
  }, _provideComponents(), props.components), {DocLink, CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.RunFlowInstance) _missingMdxReference("CodeExample.RunFlowInstance", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "deploying-flows",
      children: "Deploying Flows"
    }), "\n", _jsx(_components.p, {
      children: "The flow is considered deployed when it is first run for a non-test customer."
    }), "\n", _jsx(_components.h2, {
      id: "running-flows",
      children: "Running Flows"
    }), "\n", _jsx(_components.p, {
      children: "The way flows are launched depends on the trigger."
    }), "\n", _jsx(_components.h3, {
      id: "api-trigger",
      children: "API Trigger"
    }), "\n", _jsx(_components.p, {
      children: "To run a flow that uses an API trigger, do the following:"
    }), "\n", _jsx(CodeExample.RunFlowInstance, {}), "\n", _jsxs(_components.p, {
      children: ["The result will be a ", _jsx(DocLink, {
        path: "engine/activity-log/flow-runs",
        children: "Flow Run"
      }), ".\nIf you use REST API, you will get the flow run immediately, and it will be in \"Queued\" or \"Running\" state.\nIf you use a front-end SDK, it will return a promise that will resolve to a flow run when it is finished."]
    }), "\n", _jsx(_components.h3, {
      id: "app-event-trigger",
      children: "App Event Trigger"
    }), "\n", _jsx(_components.p, {
      children: "For flows triggered by an event in your app, you need to:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Make sure a ", _jsx(DocLink, {
          path: "engine/deployments/flow-instances",
          children: "Customer Instance"
        }), " of this flow is created."]
      }), "\n", _jsxs(_components.li, {
        children: ["Send App Event to integration.app. See more on how to do it here: ", _jsx(DocLink, {
          path: "engine/blueprints/app-events"
        })]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "external-event-or-schedule",
      children: "External Event or Schedule"
    }), "\n", _jsxs(_components.p, {
      children: ["For flows triggered by schedule or by an event in external app, all you need to do is create a ", _jsx(DocLink, {
        path: "engine/deployments/flow-instances",
        children: "customer instance"
      }), " of the flow and it will run automatically."]
    }), "\n", _jsx(_components.h2, {
      id: "customizing-flows",
      children: "Customizing Flows"
    }), "\n", _jsx(_components.p, {
      children: "To customize the behavior of the fow for each individual customer, you need to do one of the following:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Update ", _jsx(DocLink, {
          path: "engine/deployments/flow-instances",
          children: "customer instance"
        }), " of the flow."]
      }), "\n", _jsxs(_components.li, {
        children: ["Update customer instance of ", _jsx(DocLink, {
          path: "engine/blueprints/data-sources",
          children: "Data Source"
        }), ", ", _jsx(DocLink, {
          path: "engine/blueprints/field-mappings",
          children: "Field Mapping"
        }), " or another reusable element used in the flow."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["You can do it via corresponding API or via UI. To learn how to use our drop-in UI or build your own, see ", _jsx(DocLink, {
        path: "ux/flows"
      }), "."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .dataSource('{DATA_SOURCE_KEY}')
  .openConfiguration()`

export function OpenDataSourceInstanceConfigurationCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        DATA_SOURCE_KEY: CodeParamType.DataSourceKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .dataSource(parameters.DATA_SOURCE_KEY)
          .openConfiguration()
      }}
    />
  )
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Building and Testing Integration Blueprints",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Integration Layers",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Integration Logic",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Reusable Configurations",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Integration Metadata",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Integration Blueprints';
export const DESCRIPTION = 'Build your integration logic shared across multiple customers and external applications.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong",
    img: "img"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "building-and-testing-integration-blueprints",
      children: "Building and Testing Integration Blueprints"
    }), "\n", _jsx(_components.p, {
      children: "Integration blueprints are scenarios and interfaces defining how your app interacts with external apps. You should use them when building logic that spans across more than one customer or external app."
    }), "\n", _jsxs(_components.p, {
      children: ["The only time you would not use blueprints is if you want to work with ", _jsx(DocLink, {
        path: "engine/external-apps/connectors",
        children: "Connector API"
      }), " directly."]
    }), "\n", _jsx(_components.h2, {
      id: "integration-layers",
      children: "Integration Layers"
    }), "\n", _jsx(_components.p, {
      children: "There are three layers of abstraction when building customer-facing integrations:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Universal blueprints"
        }), " implement use cases that work in a similar way across multiple external apps. For example, \"Create a Task in a project management tool\" is a universal blueprint."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Application-level blueprints"
        }), " work for a specific external app. For example, \"create a task in JIRA\" in an application-level integration. They are generated from universal blueprints or created for use cases that are unique to a given external app."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Customer deployments"
        }), " describe integration logic for a specific customer and specific connection. They are typically created from application-level blueprints and can be further customized by you or your users."]
      }), "\n"]
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/integration-layers.png",
      alt: "Integration Layers"
    }), "\n", _jsx(_components.p, {
      children: "Integration.app lets you build integrations on all three layers and manage dependencies between them.\nThe higher level of abstraction you use, the less code you need to write in your application."
    }), "\n", _jsx(_components.p, {
      children: "We recommend start with universal integrations whenever possible."
    }), "\n", _jsx(_components.h2, {
      id: "integration-logic",
      children: "Integration Logic"
    }), "\n", _jsx(_components.p, {
      children: "To configure integration logic, use one of the following elements:"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(DocLink, {
        path: "engine/blueprints/actions",
        children: "Actions"
      }), " represent a single \"thing\" your app wants to do with an external app:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Get, create, update, or delete data records."
      }), "\n", _jsx(_components.li, {
        children: "Perform an operation supported by the external app."
      }), "\n", _jsx(_components.li, {
        children: "Make API calls"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "To run actions, you will be making an API call from your front-end or backend."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(DocLink, {
        path: "engine/blueprints/flows",
        children: "Flows"
      }), " let you configure complex multi-step integratoin logic that can be triggered by your app, external apps, or on schedule.\nThey consist of actions organized in potentially branching and looping sequences."]
    }), "\n", _jsx(_components.p, {
      children: "Flows could be triggered via an API call, on event (in your or an external app), or on schedule."
    }), "\n", _jsx(_components.h2, {
      id: "reusable-configurations",
      children: "Reusable Configurations"
    }), "\n", _jsx(_components.p, {
      children: "When you want to reuse a part of an integration in multiple places, you can use one of the following integration elements:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/blueprints/data-sources"
        }), " let you or your customers configure how to access data in external apps and keep this configuration consistent across multiple integrations."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/blueprints/field-mappings"
        }), " let you or your customers transform data structures between your app and extenral apps in a consistent way."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/blueprints/data-links"
        }), " store relations between data objects in your app and external apps."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "integration-metadata",
      children: "Integration Metadata"
    }), "\n", _jsx(_components.p, {
      children: "Getting metadata about external applications and integrations is often needed to build great UX."
    }), "\n", _jsx(_components.p, {
      children: "Integration.app provides differnt types of metadata in a consistent way. For example, you can get:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "List of data collections available in a given application."
      }), "\n", _jsx(_components.li, {
        children: "Fields scheam for a given data collection (for an application in general or a particular customer connection)."
      }), "\n", _jsx(_components.li, {
        children: "List of supported operations for a given application or data collection."
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Learn more here: ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/metadata"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Universal Integrations",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Scenario Templates",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Custom Scenarios",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Dynamic Scenarios (Advanced)",
    "attributes": {},
    "children": []
  }]
}];
import {SCENARIOS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const TITLE = 'Universal Integrations';
export const DESCRIPTION = 'Integrations that work in a similar way across multiple external apps.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    img: "img",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Link, DocLink, DocGrid} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "universal-integrations",
      children: "Universal Integrations"
    }), "\n", _jsx(_components.p, {
      children: "Universal integrations let you define a common logic that works across many external applications and customers."
    }), "\n", _jsx(_components.p, {
      children: "We recommend organizing universal integrations in Scenarios that contain all the building blocks in one place.\nScenarios help you conveniently manage multiple pieces of integration logic across multiple applications."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/scenario-grid.png",
      alt: "Scenario Grid"
    }), "\n", _jsxs(_components.p, {
      children: ["To start building a universal integration, go to the ", _jsx(Link, {
        path: SCENARIOS_ROUTE,
        children: "Scenarios"
      }), " page and create a new one."]
    }), "\n", _jsx(_components.h2, {
      id: "scenario-templates",
      children: "Scenario Templates"
    }), "\n", _jsx(_components.p, {
      children: "For common use cases, we provide pre-built and well-tested scenario templates. You can use them as a starting point for your integration and then customize."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/create-a-scenario.png",
      alt: "Create a Scenario"
    }), "\n", _jsx(_components.p, {
      children: "After selecting scenario and integrations to apply it to, you can proceed to adding it to your app or customize it using the section below."
    }), "\n", _jsx(_components.h2, {
      id: "custom-scenarios",
      children: "Custom Scenarios"
    }), "\n", _jsxs(_components.p, {
      children: ["The main building blocks of a scenario are ", _jsx(DocLink, {
        path: "engine/blueprints/actions",
        children: "Actions"
      }), " and ", _jsx(DocLink, {
        path: "engine/blueprints/flows",
        children: "Flows"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "To choose whether to use Action or Flow, use this comparison table:"
    }), "\n", _jsx("table", {
      className: 'stripe w-full',
      children: _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("th", {}), _jsx("th", {
            children: "Action"
          }), "\n", _jsx("th", {
            children: "Flow"
          })]
        }), _jsxs("tr", {
          children: [_jsx("th", {
            children: "Execution mode"
          }), "\n", _jsx("td", {
            children: "Synchronous"
          }), "\n", _jsx("td", {
            children: "Asynchronous"
          })]
        }), _jsxs("tr", {
          children: [_jsx("th", {
            children: "Max duration"
          }), "\n", _jsx("td", {
            children: "60 seconds"
          }), "\n", _jsx("td", {
            children: "50 minutes"
          })]
        }), _jsxs("tr", {
          children: [_jsx("th", {
            children: "Triggered by"
          }), "\n", _jsx("td", {
            children: "API call"
          }), "\n", _jsx("td", {
            children: "API Call, Event, Schedule"
          })]
        }), _jsxs("tr", {
          children: [_jsx("th", {
            children: "Logic complexity"
          }), "\n", _jsx("td", {
            children: "Simple (usually one API call with transformations)"
          }), "\n", _jsx("td", {
            children: "Complex (many calls, branches, cycles)"
          })]
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: "If an Action works for you, you should use it. If you need to make it more complex later - you can re-use it in a flow."
    }), "\n", _jsx(_components.p, {
      children: "After you've picked the main building block, see corresponding documentation for more details:"
    }), "\n", _jsxs(DocGrid, {
      cols: 2,
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/actions"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "dynamic-scenarios-advanced",
      children: "Dynamic Scenarios (Advanced)"
    }), "\n", _jsx(_components.p, {
      children: "When you do not know the exact logic of your integration ahead of time, you will need to use integration metadata to create it dynamically."
    }), "\n", _jsx(_components.p, {
      children: "Typical use cases when this approach is needed:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "AI agent that picks actions dynamically based on the context."
      }), "\n", _jsx(_components.li, {
        children: "Complex integration logic that you want to keep on your application's side."
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Integration Engine provides rich metadata about integrations, resources available through it, and any middleware you've created.\nYou can explore the available entities using ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/metadata"
      }), " and use corresponding ", _jsx(DocLink, {
        path: "engine",
        children: "Integration Engine"
      }), " APIs to interact with them."]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

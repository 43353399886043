import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

interface CollapsibleSectionContextInterface {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const CollapsibleSectionContext =
  createContext<CollapsibleSectionContextInterface>({
    isOpen: false,
    setIsOpen: () => {},
  })
CollapsibleSectionContext.displayName = 'CollapsibleSectionContext'

export function CollapsibleSectionProvider({
  children,
  open,
}: PropsWithChildren<{ open?: boolean }>) {
  // FIXME: strictNullCheck temporary fix
  // @ts-expect-error TS(2345): Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
  const [isOpen, setIsOpen] = useState<boolean>(open)

  const value = {
    isOpen,
    setIsOpen,
  }

  useEffect(() => {
    setIsOpen(!!open)
  }, [open])

  return (
    <CollapsibleSectionContext.Provider value={value}>
      {children}
    </CollapsibleSectionContext.Provider>
  )
}

export function useCollapsibleSection() {
  return useContext(CollapsibleSectionContext)
}

/*
 * Works in all modern browsers
 *
 * https://caniuse.com/?search=datetimeformat
 * https://caniuse.com/?search=PluralRules
 * */

import { createDateFormatter } from 'intl-dateformat'

const LOCALE = 'en-us'

const ordinal = (number) => {
  const ordinalRules = new Intl.PluralRules('en', {
    type: 'ordinal',
  })
  const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  }
  const suffix = suffixes[ordinalRules.select(number)]
  return number + suffix
}

const dateFormatter = createDateFormatter({
  // Day with suffix
  D: ({ day }) => ordinal(day),
  // numeric hour
  h: ({ hour }) => (hour[0] === '0' ? hour[1] : hour),
})

function formatDate(
  dateUtcString = new Date().toUTCString(),
  format = 'MMM D YY, h:mm:ss a',
) {
  return dateFormatter(new Date(dateUtcString), format, {
    locale: LOCALE,
  })
}

function formatDaysAgo(value, format = 'MMM D YY, h:mm:ss a') {
  const date = new Date(value)
  const deltaSeconds = (date.getTime() - Date.now()) / 1000
  const deltaMinutes = deltaSeconds / 60
  const deltaHours = deltaMinutes / 60
  const deltaDays = deltaHours / 24
  const deltaMonths = deltaDays / 31
  const deltaYears = deltaDays / 365

  if (Math.abs(deltaSeconds) < 10) {
    return 'A few seconds ago'
  }
  if (deltaYears > 0.9) {
    return formatDate(value, format)
  }

  let number: number
  let unit:
    | 'years'
    | 'months'
    | 'weeks'
    | 'days'
    | 'hours'
    | 'minutes'
    | 'seconds'

  switch (true) {
    case Math.abs(deltaSeconds) <= 90:
      unit = 'seconds'
      number = deltaSeconds
      break
    case Math.abs(deltaMinutes) <= 90:
      unit = 'minutes'
      number = deltaMinutes
      break
    case Math.abs(deltaHours) <= 21:
      unit = 'hours'
      number = deltaHours
      break
    case Math.abs(deltaDays) <= 25:
      unit = 'days'
      number = deltaDays
      break
    case Math.abs(deltaMonths) <= 11:
      unit = 'months'
      number = deltaMonths
      break
    default:
      unit = 'years'
      number = deltaYears
  }

  const formatter = new Intl.RelativeTimeFormat(LOCALE)
  return formatter.format(Math.round(number), unit)
}

export default function DateFormat({
  children = new Date().toUTCString(),
  format = 'MMM D YY, h:mm:ss a',
}: {
  children: string
  format?: string
}) {
  return <>{formatDate(children, format)}</>
}

export function DaysAgoDateFormat({
  children = new Date().toUTCString(),
  format = 'MMM D YY, h:mm:ss a',
}: {
  children: string
  format?: string
}) {
  return <>{formatDaysAgo(children, format)}</>
}

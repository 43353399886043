import { DataSchema, schemaWithTitle } from '@integration-app/sdk'
import AdminDataBuilderForm from '../../../../../../components/AdminDataBuilderForm'
import { makeDataField } from '@integration-app/ui'

export function CustomImportFieldMapping({
  value,
  onChange,
  internalFieldsSchema,
  externalFieldsSchema,
}: {
  value: any
  onChange: (value) => void
  internalFieldsSchema: DataSchema
  externalFieldsSchema: DataSchema | undefined
}) {
  return (
    <>
      {externalFieldsSchema ? (
        <div className='mb-6'>
          <AdminDataBuilderForm
            onChange={onChange}
            field={makeDataField({
              schema: schemaWithTitle(internalFieldsSchema, 'Fields'),
              variablesSchema: externalFieldsSchema,
              value,
            })}
            collapsed={true}
            fieldsToShow={5}
          />
        </div>
      ) : (
        <p className='italic mb-4'>
          Custom field mapping configuration is unavailable because data source
          does not provide fields schema. Please configure the data source
          first.
        </p>
      )}
    </>
  )
}

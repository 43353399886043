/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Custom Integration UI",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Get Integration",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Re-connect and Disconnect",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Manage Customer-level Elements",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Custom Integration UI';
export const DESCRIPTION = 'Building integration configuration page inside your app';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    code: "code"
  }, _provideComponents(), props.components), {DocLink, CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.ArchiveConnection) _missingMdxReference("CodeExample.ArchiveConnection", true);
  if (!CodeExample.GetIntegration) _missingMdxReference("CodeExample.GetIntegration", true);
  if (!CodeExample.ListInstancesByIntegration) _missingMdxReference("CodeExample.ListInstancesByIntegration", true);
  if (!CodeExample.OpenNewConnection) _missingMdxReference("CodeExample.OpenNewConnection", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "custom-integration-ui",
      children: "Custom Integration UI"
    }), "\n", _jsx(_components.p, {
      children: "This article describes how to build UI for configuring a single integration after your customer created a Connection."
    }), "\n", _jsxs(_components.p, {
      children: ["If you are looking for how to create a Connection in first place, check out ", _jsx(DocLink, {
        path: "ux/connection/custom"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "get-integration",
      children: "Get Integration"
    }), "\n", _jsx(_components.p, {
      children: "To get information about the integration and its connection, do the following:"
    }), "\n", _jsx(CodeExample.GetIntegration, {}), "\n", _jsx(_components.p, {
      children: "To understand the state of the connection for a given integration, look at the"
    }), "\n", _jsx(_components.p, {
      children: "You may be interested in the following fields to display in the UI:"
    }), "\n", _jsx("table", {
      className: 'stripe w-full',
      children: _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "connection"
            })
          }), "\n", _jsx("td", {
            children: "If this integration has a connection established for the current user, this field will be non-empty"
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: _jsx(_components.code, {
              children: "connection.disconnected"
            })
          }), "\n", _jsx("td", {
            children: "If a connection is disconnected and requires user to re-connect, this flag will be set to true."
          })]
        })]
      })
    }), "\n", _jsx(_components.h2, {
      id: "re-connect-and-disconnect",
      children: "Re-connect and Disconnect"
    }), "\n", _jsx(_components.p, {
      children: "You can use the following code to re-connect an integration if it was disconnected or if user wants to re-connect for any other reason:"
    }), "\n", _jsx(CodeExample.OpenNewConnection, {}), "\n", _jsxs(_components.p, {
      children: ["Or a custom version of this UI: ", _jsx(DocLink, {
        path: "ux/connection/custom"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "You can disconnect the integration using the following code:"
    }), "\n", _jsx(CodeExample.ArchiveConnection, {}), "\n", _jsx(_components.h2, {
      id: "manage-customer-level-elements",
      children: "Manage Customer-level Elements"
    }), "\n", _jsxs(_components.p, {
      children: ["To know which ", _jsx(DocLink, {
        path: "engine/blueprints/flows",
        children: "Flows"
      }), ", ", _jsx(DocLink, {
        path: "engine/blueprints/actions",
        children: "Actions"
      }), ",\n", _jsx(DocLink, {
        path: "engine/blueprints/data-sources",
        children: "Data Sources"
      }), ", or ", _jsx(DocLink, {
        path: "engine/blueprints/field-mappings",
        children: "Field Mappings"
      }), "\nare configured for the current integration, you can get their list this way:"]
    }), "\n", _jsx(CodeExample.ListInstancesByIntegration, {}), "\n", _jsx(_components.p, {
      children: "Then you can use the corresponding elements API and UI to manage them:"
    }), "\n", _jsxs("table", {
      className: 'stripe w-full',
      children: [_jsx("thead", {
        children: _jsxs("tr", {
          children: [_jsx("td", {
            children: "Element"
          }), "\n", _jsx("td", {
            children: "API"
          }), "\n", _jsx("td", {
            children: "UI"
          })]
        })
      }), _jsxs("tbody", {
        children: [_jsxs("tr", {
          children: [_jsx("td", {
            children: "Flow"
          }), "\n", _jsx("td", {
            children: _jsx(DocLink, {
              path: "engine/deployments/flow-instances",
              children: "Flows API"
            })
          }), "\n", _jsx("td", {
            children: _jsx(DocLink, {
              path: "ux/flows",
              children: "Flows UI"
            })
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Data Source"
          }), "\n", _jsx("td", {
            children: _jsx(DocLink, {
              path: "engine/deployments/data-source-instances",
              children: "Data Sources API"
            })
          }), "\n", _jsx("td", {
            children: _jsx(DocLink, {
              path: "ux/data-sources",
              children: "Data Sources UI"
            })
          })]
        }), _jsxs("tr", {
          children: [_jsx("td", {
            children: "Field Mapping"
          }), "\n", _jsx("td", {
            children: _jsx(DocLink, {
              path: "engine/deployments/field-mapping-instances",
              children: "Field Mappings API"
            })
          }), "\n", _jsx("td", {
            children: _jsx(DocLink, {
              path: "ux/field-mappings",
              children: "Field Mappings UI"
            })
          })]
        })]
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

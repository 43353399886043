/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Customers",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Customer Fields",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Test Customer",
    "attributes": {},
    "children": []
  }]
}];
import {DocGrid} from "components/Docs/DocLinksGrid";
import {CUSTOMERS_ROUTE, SETTINGS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const DESCRIPTION = 'People or groups using integrations in your app.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    img: "img",
    code: "code",
    h2: "h2",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components), {Link, DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "customers",
      children: "Customers"
    }), "\n", _jsx(_components.p, {
      children: "Every customer in your app has their own set of integrations: credentials for external apps, settings, etc.\nDepending on how your app is organized, a \"customer\" in integration.app could be a single user, a team, or an organization."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/customers.png",
      alt: "Customers and Customer Instances"
    }), "\n", _jsx(_components.p, {
      children: "Integration.app keeps track of your customers who use integrations and let you navigate and manage their data."
    }), "\n", _jsxs(_components.p, {
      children: ["Each customer has ", _jsx(_components.code, {
        children: "id"
      }), ", optional ", _jsx(_components.code, {
        children: "name"
      }), ", and ", _jsx(_components.code, {
        children: "fields"
      }), " that contain any information you need to use in your integration logic.\nFor example, you can store a user's API Token in ", _jsx(_components.code, {
        children: "fields.apiToken"
      }), " and use it to make requests to your API from within integrations."]
    }), "\n", _jsx(_components.h2, {
      id: "customer-fields",
      children: "Customer Fields"
    }), "\n", _jsxs(_components.p, {
      children: ["You can configure the list of fields on the ", _jsx(Link, {
        path: SETTINGS_ROUTE,
        children: "Settings"
      }), " page."]
    }), "\n", _jsx(_components.p, {
      children: "You can set user fields in two ways:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: ["By adding ", _jsx(_components.code, {
          children: "fields"
        }), " claim to the ", _jsx(DocLink, {
          path: "overview/getting-started/authentication",
          children: "Authentication Token"
        }), ". Whenever integration.app sees a new set of fields in the token, it saves them to be used going forward."]
      }), "\n", _jsxs(_components.li, {
        children: ["By updating users using PATCH /users or PUT /users methods of the ", _jsx("a", {
          target: "_blank",
          href: "https://api-reference.integration.app",
          children: "REST API"
        })]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["You can see the current state of user fields by finding the user on the ", _jsx(Link, {
        path: CUSTOMERS_ROUTE,
        children: "Users"
      }), " page and clicking on it."]
    }), "\n", _jsx(_components.h2, {
      id: "test-customer",
      children: "Test Customer"
    }), "\n", _jsxs(_components.p, {
      children: ["When you configure or run integrations in Console, your Test User is used.\nYou can configure the test user's ID, Name, and Fields on the Testing tab of the ", _jsx(Link, {
        path: SETTINGS_ROUTE,
        children: "Settings"
      }), " page."]
    }), "\n", _jsx(_components.p, {
      children: "Access token generated for the test user is available at the bottom of the page - copy it to use in your test code."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

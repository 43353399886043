/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Field Mappings",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Configuring Field Mapping",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Applying Field Mapping",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Fields in Create and Update methods",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Learn More",
    "attributes": {},
    "children": []
  }]
}];
import ExampleCodeBlock, {CodeParamType, CodeExamples} from 'components/Docs/ExampleCodeBlock';
import DocLink from "components/Docs/DocLink";
import {DocGrid} from "components/Docs/DocLinksGrid";
import {FIELD_MAPPINGS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
export const DESCRIPTION = 'Mapping fields between your app and external apps';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3",
    code: "code"
  }, _provideComponents(), props.components);
  if (!DocGrid) _missingMdxReference("DocGrid", false);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "field-mappings",
      children: "Field Mappings"
    }), "\n", _jsxs(_components.p, {
      children: ["Field Mappings allow you to map data fields between a ", _jsx(DocLink, {
        path: "engine/blueprints/data-sources",
        children: "Data Source"
      }), " and your app."]
    }), "\n", _jsx(_components.h2, {
      id: "configuring-field-mapping",
      children: "Configuring Field Mapping"
    }), "\n", _jsx(_components.p, {
      children: "To configure field mapping, you provide:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Data Source defines the ", _jsx(DocLink, {
          path: "engine/references/data-schemas",
          children: "Data Schema"
        }), " of the fields in the external app."]
      }), "\n", _jsxs(_components.li, {
        children: ["Internal Data Schema defines the fields in your app. It can be generated automatically based on the mapping configuration, defined statically, or calculated dynamically via ", _jsx(DocLink, {
          path: "engine/blueprints/app-data-schemas"
        })]
      }), "\n", _jsx(_components.li, {
        children: "Direction: import, export, or bi-directional."
      }), "\n", _jsx(_components.li, {
        children: "Export Value: mapping from your app to external app."
      }), "\n", _jsx(_components.li, {
        children: "Import Value: mapping from external app to your app."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Depending on the level of abstraction (universal, integration, or customer level), different set of fields will be available for mapping:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Universal: fields from the Universal Data Model configured in the data source will be available."
      }), "\n", _jsx(_components.li, {
        children: "Integration: standard fields from the Data Collection data source is pointing to will be available."
      }), "\n", _jsx(_components.li, {
        children: "Customer: connection-specific fields from the data collection that data source is pointing to will be available, including any custom fields."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "applying-field-mapping",
      children: "Applying Field Mapping"
    }), "\n", _jsx(_components.p, {
      children: "When field mapping is applied in a Flow or an Action, the data will be transformed based on the customer-level field mapping configuration."
    }), "\n", _jsx(_components.p, {
      children: "You will see the mapping and the result in the Flow or Action logs."
    }), "\n", _jsx(_components.h3, {
      id: "fields-in-create-and-update-methods",
      children: "Fields in Create and Update methods"
    }), "\n", _jsxs(_components.p, {
      children: ["If ", _jsx(_components.code, {
        children: "create"
      }), " or ", _jsx(_components.code, {
        children: "update"
      }), " methods for a Data Collection mapping is used for specify supported fields, only those fields will stay in the mapping, ignoring values of the rest of the fields."]
    }), "\n", _jsx(_components.p, {
      children: "This is done to let you use the same mapping for different methods, and to avoid errors due to extra fields."
    }), "\n", _jsxs(_components.p, {
      children: ["For example, if ", _jsx(_components.code, {
        children: "type"
      }), " field is supported in the ", _jsx(_components.code, {
        children: "create"
      }), " method, but not supported in the ", _jsx(_components.code, {
        children: "update"
      }), " method and you have this field in the field mapping, it will not be sent to the ", _jsx(_components.code, {
        children: "update"
      }), " method."]
    }), "\n", _jsx(_components.h2, {
      id: "learn-more",
      children: "Learn More"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "ux/field-mappings"
      }), _jsx(DocGrid.Link, {
        path: "engine/references/data-schemas"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/data-sources"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/app-data-schemas"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/app-events"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

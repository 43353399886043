import { useEffect, useState } from 'react'
import {
  CreateDataSourceRequest,
  DataSource,
  DEFAULT_FULL_SYNC_INTERVAL_SECONDS,
  DEFAULT_PULL_UPDATES_INTERVAL_SECONDS,
} from '@integration-app/sdk'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import Button from '@integration-app/ui/Button'
import { Cell, Row } from '@integration-app/ui/DataBuilderUI'
import UdmSelect from 'components/DataSources/UdmSelect'
import { useSyncKeyWithName } from 'components/IntegrationElements/hooks/useSyncKeyWithName'
import { IntegrationComboboxSelect } from 'components/IntegrationElements/ComboboxSelects/IntegrationComboboxSelect'
import useIntegrationElement from 'components/IntegrationElements/hooks/useIntegrationElement'
import { Popup } from 'routes/components/Popup'
import { ContentRows } from 'components/ContentRows'
import { CreateDataSourceHeader } from './CreateDataSourceHeader'
import { toHeaderCase } from 'js-convert-case'
import { AdvancedOptions, SimpleInput } from '@integration-app/ui'

export function StepCreateDataSource({
  title,
  onBack,
  onCreate,
  integrationId,
  readOnlyIntegration,
  udm,
  universal,
}: {
  title?: string
  onBack?: () => void
  onCreate(item: DataSource): void
  integrationId?: string
  readOnlyIntegration?: boolean
  udm?: string
  universal?: boolean
}) {
  const { createItem } = useIntegrationElement<DataSource>({
    route: 'data-sources',
  })
  const [item, setItem] = useState<Partial<CreateDataSourceRequest>>({
    integrationId,
    udm,
  })

  function handleChange(data) {
    setItem((prevItem) => ({ ...prevItem, ...data }))
  }

  const handleNameChange = useSyncKeyWithName(handleChange)

  async function handleCreate() {
    const createdDataSource = await createItem({ ...item })
    onCreate(createdDataSource)
  }

  const isCreateDataValid = !!item.key && !!item.name

  useEffect(() => {
    if (item.udm && !item.name && !item.key) {
      handleNameChange(toHeaderCase(item.udm))
    }
  }, [item.udm])

  return (
    <form onSubmit={useEventStopPropagation(handleCreate)}>
      <Popup.Header>
        <CreateDataSourceHeader
          title={title}
          onBack={onBack}
          integrationId={item.integrationId}
        />
      </Popup.Header>

      <ContentRows>
        <SimpleInput
          label={'Name'}
          value={item.name}
          onChange={handleNameChange}
          autoFocus
        />

        <SimpleInput
          label={'Key'}
          value={item.key}
          onChange={(key) => handleChange({ key })}
        />

        {integrationId && (
          <Row>
            <Cell.Name>Integration</Cell.Name>
            <Cell.Input>
              <IntegrationComboboxSelect
                useId
                value={item.integrationId}
                onChange={(integrationId) => handleChange({ integrationId })}
                deletable={false}
                editable={!readOnlyIntegration}
              />
            </Cell.Input>
          </Row>
        )}

        <SelectUdmRow
          udm={udm}
          universal={universal}
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          value={item.udm}
          onChange={handleChange}
        />

        <AdvancedOptions>
          <SimpleInput
            label={'Pull Updates Interval'}
            placeholder={`Default: ${DEFAULT_PULL_UPDATES_INTERVAL_SECONDS}`}
            value={item.pullUpdatesIntervalSeconds?.toString()}
            onChange={(value) =>
              handleChange({
                pullUpdatesIntervalSeconds: Number(value) ?? undefined,
              })
            }
          />

          <SimpleInput
            label={'Full Sync Interval'}
            placeholder={`Default: ${DEFAULT_FULL_SYNC_INTERVAL_SECONDS}`}
            value={item.fullSyncIntervalSeconds?.toString()}
            onChange={(value) =>
              handleChange({
                fullSyncIntervalSeconds: Number(value) ?? undefined,
              })
            }
          />
        </AdvancedOptions>
      </ContentRows>
      <Popup.Footer>
        <Button
          disabled={!isCreateDataValid}
          onClick={handleCreate}
          type={'submit'}
        >
          Create Data Source
        </Button>
      </Popup.Footer>
    </form>
  )
}

function SelectUdmRow({
  udm,
  universal,
  value,
  onChange,
}: {
  udm?: string
  universal?: boolean
  value: string
  onChange: (value) => void
}) {
  if (udm) {
    return <UdmSelect value={value} onChange={(udm) => onChange({ udm })} />
  }

  if (universal && !udm) {
    return (
      <Row>
        <Cell.Name>UDM</Cell.Name>
        <Cell.Value>Any Data</Cell.Value>
      </Row>
    )
  }

  return null
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Connection UI",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Multiple Connections for the same Integration",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "See Also",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Connection UI';
export const DESCRIPTION = 'Create a new connection to a specific integration';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    code: "code"
  }, _provideComponents(), props.components), {DocGrid, CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.OpenNewConnection) _missingMdxReference("CodeExample.OpenNewConnection", true);
  if (!CodeExample.OpenNewConnectionAllowMultipleConnections) _missingMdxReference("CodeExample.OpenNewConnectionAllowMultipleConnections", true);
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "connection-ui",
      children: "Connection UI"
    }), "\n", _jsx(CodeExample.OpenNewConnection, {}), "\n", _jsx(_components.h2, {
      id: "multiple-connections-for-the-same-integration",
      children: "Multiple Connections for the same Integration"
    }), "\n", _jsxs(_components.p, {
      children: ["If you want to let users create multiple connections for the same integration, set ", _jsx(_components.code, {
        children: "allowMultipleConnections"
      }), " option\nof the ", _jsx(_components.code, {
        children: "createNewConnection"
      }), " method to ", _jsx(_components.code, {
        children: "true"
      }), ":"]
    }), "\n", _jsx(CodeExample.OpenNewConnectionAllowMultipleConnections, {}), "\n", _jsx(_components.h2, {
      id: "see-also",
      children: "See Also"
    }), "\n", _jsx(DocGrid, {
      children: _jsx(DocGrid.Link, {
        path: "ux/connection/custom"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "App Event Subscriptions",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Global Webhook",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Per-user Subscriptions",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Monitoring Events",
    "attributes": {},
    "children": []
  }]
}, {
  "depth": 1,
  "value": "Troubleshooting App Webhooks",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Flow was not launched",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Step 1: Check Logs",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Step 2: Check Flow Node Configuration",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Webhook events do not appear in logs",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Step 1: Check Subscription",
      "attributes": {},
      "children": []
    }, {
      "depth": 3,
      "value": "Step 2: Trigger the webhook manually",
      "attributes": {},
      "children": []
    }]
  }, {
    "depth": 2,
    "value": "Webhook subscription is not created",
    "attributes": {},
    "children": []
  }]
}];
import {APP_EVENTS_ROUTE, APP_EVENT_SUBSCRIPTIONS_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {DocLink, Link, CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.TriggerGlobalWebhook) _missingMdxReference("CodeExample.TriggerGlobalWebhook", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "app-event-subscriptions",
      children: "App Event Subscriptions"
    }), "\n", _jsxs(_components.p, {
      children: ["App Event Subscription is created whenever ", _jsx(DocLink, {
        path: "engine/blueprints/app-events",
        children: "App Event"
      }), "\nis used in a ", _jsx(DocLink, {
        path: "engine/deployments/flow-instances",
        children: "Flow Instance"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "global-webhook",
      children: "Global Webhook"
    }), "\n", _jsx(_components.p, {
      children: "If you use Global Webhook for your App Events, you need to make this request to trigger relevant flows:"
    }), "\n", _jsx(CodeExample.TriggerGlobalWebhook, {}), "\n", _jsx(_components.h2, {
      id: "per-user-subscriptions",
      children: "Per-user Subscriptions"
    }), "\n", _jsx(_components.p, {
      children: "If you use per-user subscriptions, flow instances will be triggered automatically when an event happens."
    }), "\n", _jsxs(_components.p, {
      children: ["You can explore the user subscriptions here: ", _jsx(Link, {
        path: APP_EVENT_SUBSCRIPTIONS_ROUTE,
        children: "App Event Subscriptions"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "monitoring-events",
      children: "Monitoring Events"
    }), "\n", _jsxs(_components.p, {
      children: ["You can monitor events and flow instances they launch on the ", _jsx(Link, {
        path: APP_EVENTS_ROUTE,
        children: "App Events"
      }), " page."]
    }), "\n", _jsx(_components.h1, {
      id: "troubleshooting-app-webhooks",
      children: "Troubleshooting App Webhooks"
    }), "\n", _jsx(_components.p, {
      children: "Here is a list of common issues and how you can troubleshoot them."
    }), "\n", _jsx(_components.h2, {
      id: "flow-was-not-launched",
      children: "Flow was not launched"
    }), "\n", _jsx(_components.p, {
      children: "If you expected a webhook to launch a flow, but it didn't, do the following steps:"
    }), "\n", _jsx(_components.h3, {
      id: "step-1-check-logs",
      children: "Step 1: Check Logs"
    }), "\n", _jsxs(_components.p, {
      children: ["Go to the ", _jsx(Link, {
        path: APP_EVENTS_ROUTE,
        children: "Webhook Logs"
      }), " and check if the events you expected are there.\nUse filters to narrow down the list."]
    }), "\n", _jsx(_components.p, {
      children: "If you didn't find the event you were looking for - check the next section (\"Webhook events do not appear in logs\")."
    }), "\n", _jsx(_components.p, {
      children: "If you found the record you were looking for in the logs, check out its details:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Is it associated to the correct App User?"
      }), "\n", _jsx(_components.li, {
        children: "Does it have the data you expected?"
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "step-2-check-flow-node-configuration",
      children: "Step 2: Check Flow Node Configuration"
    }), "\n", _jsx(_components.p, {
      children: "If you found the event in logs, but it didn't trigger the flow you expected, check the node configuration:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Does it use the right webhook? You can see it in the node dependencies on the Flow Instance page."
      }), "\n", _jsx(_components.li, {
        children: "Does it have a filter that excludes the event you found?"
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "If you found the event, checked the node configuration, but still cannot find the problem - please reach out to support@integration.app."
    }), "\n", _jsx(_components.h2, {
      id: "webhook-events-do-not-appear-in-logs",
      children: "Webhook events do not appear in logs"
    }), "\n", _jsx(_components.p, {
      children: "If you expected a webhook event to appear in the platform, but it didn't - here is what you could try to do:"
    }), "\n", _jsx(_components.h3, {
      id: "step-1-check-subscription",
      children: "Step 1: Check Subscription"
    }), "\n", _jsx(_components.p, {
      children: "If you are using a webhook with subscription, find the subscription by Webhook and User, and check its status.\nIs it subscribed successfully?"
    }), "\n", _jsx(_components.p, {
      children: "Skip this step if you don't use a subscription."
    }), "\n", _jsx(_components.h3, {
      id: "step-2-trigger-the-webhook-manually",
      children: "Step 2: Trigger the webhook manually"
    }), "\n", _jsx(_components.p, {
      children: "Make an HTTP request to the webhook URL:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "If using a subscription - find the webhook URL on the corresponding subscription page."
      }), "\n", _jsx(_components.li, {
        children: "If not - use the URL from the webhook page."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Did the request succeed?"
    }), "\n", _jsx(_components.p, {
      children: "If the request succeeded, but you still don't see the event in logs - please reach out to support@integration.app."
    }), "\n", _jsx(_components.h2, {
      id: "webhook-subscription-is-not-created",
      children: "Webhook subscription is not created"
    }), "\n", _jsxs(_components.p, {
      children: ["If you expect a webhook subscription to be created, but it was not - find it on the ", _jsx(Link, {
        path: APP_EVENT_SUBSCRIPTIONS_ROUTE,
        children: "Subscriptions"
      }), " page\nand check the latest request and response. Do they look correct?"]
    }), "\n", _jsx(_components.p, {
      children: "If everything looks good in the request and the response - the problem is likely on your app's side - it didn't create the subscriptions."
    }), "\n", _jsx(_components.p, {
      children: "You can try deleting the subscription and creating it again to troubleshoot."
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

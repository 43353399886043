import { DataSource } from '@integration-app/sdk'
import {
  PopupCreateIntegrationSpecificDataSource,
  PopupCreateUniversalDataSource,
} from '../../routes/Workspaces/Workspace/Blueprints/DataSources/components/PopupCreateDataSourceForm'
import { useState } from 'react'
import { v4 as uuid4 } from 'uuid'
import { GoToElementLink } from '../GoToElemenLink'
import { routeTo } from '../../routes/Workspaces/Workspace/routes-constants'
import { Cell, Row } from '@integration-app/ui/DataBuilderUI'
import ComboBox from '@integration-app/ui/ComboBox'
import { ValueType } from '@integration-app/ui/ComboBox/value-spec'
import { ComboBoxOptionType } from '@integration-app/ui/ComboBox/types'
import { useDataSources } from '@integration-app/react'

const CREATE_OPTION_ID = uuid4()

export default function DataSourceSelect({
  dataSourceKey,
  integrationId,
  onChange,
  disabled,
  withCreateOption = true,
}: {
  integrationId?: string
  dataSourceKey: string
  onChange?: (value: string | undefined) => void
  disabled?: boolean
  withCreateOption?: boolean
}) {
  const [open, setOpen] = useState(false)

  const { dataSources } = useDataSources({
    integrationId: integrationId ?? 'null',
  })

  const options: ComboBoxOptionType[] = dataSources.map(({ name, key }) => ({
    label: name,
    value: key,
  }))

  if (withCreateOption) {
    options.push({
      label: '+ Create New Data Source',
      value: CREATE_OPTION_ID,
    })
  }

  async function handleChange(value: string | undefined) {
    if (value === CREATE_OPTION_ID) {
      setOpen(true)
      return
    }

    onChange?.(value)
  }

  const selected = dataSources.find(({ key }) => key === dataSourceKey)

  return (
    <div className='flex items-center gap-4'>
      <Row>
        <Cell.Name shrink>Data Source</Cell.Name>
        <Cell.Combobox grow>
          <ComboBox
            value={dataSourceKey}
            valueSpec={{ type: ValueType.STRING, allowCustom: false }}
            options={options}
            onChange={handleChange}
            disabled={disabled}
            placeholder='Select Data Source'
          />
        </Cell.Combobox>
      </Row>

      {selected && (
        <GoToElementLink to={routeTo.dataSource(selected)}>
          View/Edit Data Source
        </GoToElementLink>
      )}

      <DataSourceCreatePopup
        open={open}
        onClose={() => setOpen(false)}
        onCreate={(dataSource: DataSource) => handleChange(dataSource.key)}
        integrationId={integrationId}
      />
    </div>
  )
}

function DataSourceCreatePopup({
  open,
  onClose,
  onCreate,
  integrationId,
}: {
  open: boolean
  onClose: () => void
  onCreate: (dataSource: DataSource) => void
  integrationId?: string
}) {
  const title = `Create Data Source`

  if (!integrationId) {
    return (
      <PopupCreateUniversalDataSource
        title={title}
        open={open}
        onClose={onClose}
        onCreate={onCreate}
      />
    )
  }

  return (
    <PopupCreateIntegrationSpecificDataSource
      title={title}
      open={open}
      onClose={onClose}
      onCreate={onCreate}
      integrationId={integrationId}
      readOnlyIntegration
    />
  )
}

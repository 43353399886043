import ExampleCodeBlock, { CodeParamType } from '../../Docs/ExampleCodeBlock'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .fieldMapping('{FIELD_MAPPING_KEY}')
  .openConfiguration()`

export function OpenFieldMappingInstanceConfigurationCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FIELD_MAPPING_KEY: CodeParamType.FieldMappingKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .fieldMapping(parameters.FIELD_MAPPING_KEY)
          .openConfiguration()
      }}
    />
  )
}

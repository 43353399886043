/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Import User-selected Data",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Data Source",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Full Import",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Change Events",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Selecting Data Collection",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Multiple Imports",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Collection Metadata",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Import User-selected Data Collections';
export const DESCRIPTION = 'Importing one or more data sources picked by your customer.';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    img: "img",
    code: "code",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "import-user-selected-data",
      children: "Import User-selected Data"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(DocLink, {
        path: "solutions/data-import/continuous-import"
      }), " describes how to import data collection and keep it in sync when you know ahead of time what data you want to import."]
    }), "\n", _jsx(_components.p, {
      children: "This guide will help you import one or more data collections picked by your customer out of available data collections in an external app."
    }), "\n", _jsx(_components.h2, {
      id: "data-source",
      children: "Data Source"
    }), "\n", _jsx(_components.p, {
      children: "To store which data collection user selected to import, we will create a Data Source called \"Any Data\"."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/any-data-data-source.png",
      alt: "Any Data"
    }), "\n", _jsx(_components.p, {
      children: "It will not point to any data collections by default - we'll have to configure it later based on what user selected."
    }), "\n", _jsx(_components.h2, {
      id: "full-import",
      children: "Full Import"
    }), "\n", _jsx(_components.p, {
      children: "To import all the records from the user-selected collection, we'll create \"Get All Records\" action that simply lists records from the Any Data data source without any filtering or mapping."
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/get-all-records-any-data.png",
      alt: "Get All Records"
    }), "\n", _jsx(_components.h2, {
      id: "change-events",
      children: "Change Events"
    }), "\n", _jsx(_components.p, {
      children: "To get notified when data records are created, updated, or deleted in the user-selected collection, we'll create a Flow that listens to these events. It looks like this:"
    }), "\n", _jsx(_components.img, {
      src: "/images/docs/get-changes-any-data.png",
      alt: "Get Changes"
    }), "\n", _jsxs(_components.p, {
      children: ["This flow subscribes to ", _jsx(_components.code, {
        children: "data-record-created"
      }), ", ", _jsx(_components.code, {
        children: "data-record-updated"
      }), ", and ", _jsx(_components.code, {
        children: "data-record-deleted"
      }), " events in the Any Data data source, fetches the full record for Created and Updated events\n(they don't always contain the full record by default), and sends the data to your application."]
    }), "\n", _jsx(_components.h2, {
      id: "selecting-data-collection",
      children: "Selecting Data Collection"
    }), "\n", _jsx(_components.p, {
      children: "To make this work, you need to let your user select data collection. For this, you should use metadata form the connector."
    }), "\n", _jsx(_components.p, {
      children: "To let your users select any data collection from any available integration, you can do the following:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Show available integrations to the user: ", _jsx(DocLink, {
          path: "ux/integration-list/custom"
        }), "."]
      }), "\n", _jsxs(_components.li, {
        children: ["Let them connect to the selected integration if they haven't done it yet: ", _jsx(DocLink, {
          path: "ux/connection/custom"
        }), "."]
      }), "\n", _jsxs(_components.li, {
        children: ["List data collections available in the selected connection and patch \"Any Data\" data source witht he collection selected by the user: ", _jsx(DocLink, {
          path: "ux/data-sources/custom"
        })]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "multiple-imports",
      children: "Multiple Imports"
    }), "\n", _jsx(_components.p, {
      children: "If you need to import not one, but possibly many data collections, you should:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Create a unique ID for each import / data collection."
      }), "\n", _jsxs(_components.li, {
        children: ["Use this id as ", _jsx(_components.code, {
          children: "instanceKey"
        }), " when referring to ", _jsx(DocLink, {
          path: "engine/deployments/data-source-instances"
        }), ", ", _jsx(DocLink, {
          path: "engine/deployments/flow-instances"
        }), ", ", _jsx(DocLink, {
          path: "engine/deployments/action-instances"
        }), "."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "This will let you create as many individually configured imports per user as you need."
    }), "\n", _jsx(_components.h2, {
      id: "collection-metadata",
      children: "Collection Metadata"
    }), "\n", _jsxs(_components.p, {
      children: ["If you need to understand the structure of the data collection that is being imported, you should use ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/metadata",
        children: "Connector Metadata"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "It will let your application understand:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "What parameters does collection have or require."
      }), "\n", _jsx(_components.li, {
        children: "What fields are available in the collection."
      }), "\n", _jsx(_components.li, {
        children: "What is the structure of those fields."
      }), "\n", _jsx(_components.li, {
        children: "Which oteher collections does this one reference."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

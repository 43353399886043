/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [];
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code"
  }, _provideComponents(), props.components), {CodeExample} = _components;
  if (!CodeExample) _missingMdxReference("CodeExample", false);
  if (!CodeExample.GetFlowRunOutput) _missingMdxReference("CodeExample.GetFlowRunOutput", true);
  if (!CodeExample.GetFlowRunOutputWithNodeKey) _missingMdxReference("CodeExample.GetFlowRunOutputWithNodeKey", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "When running the flow, you will get a Flow Run object as a response."
    }), "\n", _jsxs(_components.p, {
      children: ["Using ", _jsx(_components.code, {
        children: "id"
      }), " of that object, you can get the output of the flow run:"]
    }), "\n", _jsx(CodeExample.GetFlowRunOutput, {}), "\n", _jsx(_components.p, {
      children: "By default, this method will return the output of the final node in the flow."
    }), "\n", _jsxs(_components.p, {
      children: ["If there are multiple final nodes in the flow or you want to get the output of a specific node, you can use the ", _jsx(_components.code, {
        children: "nodeKey"
      }), " parameter:"]
    }), "\n", _jsx(CodeExample.GetFlowRunOutputWithNodeKey, {})]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

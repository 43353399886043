/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "List Data Records",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Output",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "See also",
    "attributes": {},
    "children": []
  }]
}];
export const DESCRIPTION = 'Get all data records from a Data Source with an optional filter';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    blockquote: "blockquote",
    h2: "h2",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {DocLink, DocGrid} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "list-data-records",
      children: "List Data Records"
    }), "\n", _jsx(_components.p, {
      children: "This node lists all records in a data collection and returns their list as an output."
    }), "\n", _jsx(_components.p, {
      children: "If data collection has multiple pages, this node will request all pages and return all records."
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsxs(_components.p, {
        children: ["Note: if too many records are returned, the execution may fail due to the limit of the node run output size.", _jsx("br", {}), "\nFor listing large data collections you should use ", _jsx(DocLink, {
          path: "engine/blueprints/actions",
          children: "Actions"
        }), " and paginate on your side.", _jsx("br", {}), "\nMore information on flow run limits: ", _jsx(DocLink, {
          path: "engine/activity-log/flow-runs"
        }), "."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "output",
      children: "Output"
    }), "\n", _jsxs(_components.p, {
      children: ["The output of this node is a list of ", _jsx(DocLink, {
        path: "engine/external-apps/connectors/data/data-records",
        children: "Data Record"
      }), " that look like this:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-yaml",
        children: "id: <unique record id>\nname: <human-readable record name>\nurl: <link to the record if exists>\nfields:\n    ...fields mapped with fieldMapping\nrawFields:\n    ...fields returned by the third-party app\nunifiedFields:\n    ...Universal Data Model fields if includeUdmFields is set to true\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "see-also",
      children: "See also"
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/data-sources"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/field-mappings"
      })]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

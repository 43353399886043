import ExampleCodeBlock, {
  CodeParamType,
} from 'components/Docs/ExampleCodeBlock'
import { httpCodeExamples } from '../http-code-examples'

const js = `await integrationApp
  .connection('{INTEGRATION_KEY}')
  .fieldMapping('{FIELD_MAPPINGS_KEY}')
  .get()
`
const rest = httpCodeExamples(
  '/connections/{INTEGRATION_KEY}/field-mappings/{FIELD_MAPPINGS_KEY}',
)

export function GetFieldMappingInstanceCodeExample() {
  return (
    <ExampleCodeBlock
      customCodes={{ js, ...rest }}
      parameters={{
        INTEGRATION_KEY: CodeParamType.IntegrationKey,
        FIELD_MAPPINGS_KEY: CodeParamType.FieldMappingKey,
      }}
      run={async ({ integrationApp, parameters }) => {
        return await integrationApp
          .connection(parameters.INTEGRATION_KEY)
          .fieldMapping(parameters.FIELD_MAPPINGS_KEY)
          .get()
      }}
    />
  )
}

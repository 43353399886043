import {
  excludeFieldsFromSchema,
  pickFieldsFromSchema,
  schemaWithTitle,
  UNIFIED_DATA_MODELS,
  UnifiedDataModel,
} from '@integration-app/sdk'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { CollapsibleSection } from '../../Section'
import { useGenericConfig } from '../contexts/generic-config-context'
import { SimpleRadio } from '@integration-app/ui'
import { SharedExportFieldMapping } from './SharedExportFieldMapping'
import { toHeaderCase } from 'js-convert-case'
import { GenerateFieldMappingButton } from '../../../Blueprints/FieldMappings/FieldMapping/components/GenerateFieldMappingButton'

enum ExportFieldMappingUnitConfigOption {
  CustomUdm = 'custom-udm',
  CustomVariables = 'custom-variables',
  Shared = 'shared',
}

export function UniversalExportFieldMappingUnitConfig({
  isUpdate = false,
}: {
  isUpdate?: boolean
}) {
  const {
    patchConfig,
    fieldMappingConfig,
    patchFieldMappingConfig,
    udm,
    udmSpec,
    variablesSchema,
    editableVariablesSchemaLocators,
    handleAddVariable,
  } = useGenericConfig()

  async function handleSelectOption(
    option: ExportFieldMappingUnitConfigOption,
  ) {
    switch (option) {
      case ExportFieldMappingUnitConfigOption.CustomUdm:
        return patchConfig({
          fieldMapping: {
            defaultUnifiedValue:
              fieldMappingConfig.defaultUnifiedValue ??
              fieldMappingConfig?.default ??
              {},
          },
        })
      case ExportFieldMappingUnitConfigOption.CustomVariables:
        return patchConfig({
          fieldMapping: {
            defaultValue: fieldMappingConfig.defaultValue ?? {},
          },
        })
      case ExportFieldMappingUnitConfigOption.Shared:
        return patchConfig({
          fieldMapping: {
            key: null,
          },
        })
    }
  }

  async function handleMappingChange(value) {
    return patchFieldMappingConfig({ defaultUnifiedValue: value })
  }

  let selectedOption = ExportFieldMappingUnitConfigOption.CustomUdm

  if (fieldMappingConfig?.defaultValue !== undefined) {
    selectedOption = ExportFieldMappingUnitConfigOption.CustomVariables
  }

  if (fieldMappingConfig?.key !== undefined) {
    selectedOption = ExportFieldMappingUnitConfigOption.Shared
  }

  const unifiedSchema = isUpdate
    ? getUdmUpdateFields(udmSpec)
    : getUdmCreateFields(udmSpec)

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Fields</CollapsibleSection.Header>

      <SimpleRadio
        label={
          <>
            Provide fields for universal data model{' '}
            {toHeaderCase(udmSpec?.pluralName)}
          </>
        }
        checked={
          selectedOption === ExportFieldMappingUnitConfigOption.CustomUdm
        }
        onCheck={() =>
          handleSelectOption(ExportFieldMappingUnitConfigOption.CustomUdm)
        }
      />
      <SimpleRadio
        label='Use shared field mapping config'
        checked={selectedOption === ExportFieldMappingUnitConfigOption.Shared}
        onCheck={() =>
          handleSelectOption(ExportFieldMappingUnitConfigOption.Shared)
        }
      />
      <SimpleRadio
        label={<>Use application-specific fields from input variables</>}
        checked={
          selectedOption === ExportFieldMappingUnitConfigOption.CustomVariables
        }
        onCheck={() =>
          handleSelectOption(ExportFieldMappingUnitConfigOption.CustomVariables)
        }
      />

      {selectedOption === ExportFieldMappingUnitConfigOption.Shared && (
        <SharedExportFieldMapping />
      )}

      {selectedOption === ExportFieldMappingUnitConfigOption.CustomUdm && (
        <div>
          {udm ? (
            <>
              {!UNIFIED_DATA_MODELS[udm]?.fieldsSchema ? (
                <p>UDM "{udm}" does not support creating records.</p>
              ) : (
                <>
                  <p>
                    These values will be used by default when the integration is
                    enabled. They can be changed later in the User Interface.
                  </p>

                  {unifiedSchema && variablesSchema?.properties?.input && (
                    <GenerateFieldMappingButton
                      fromSchema={variablesSchema}
                      toSchema={unifiedSchema}
                      onGenerate={handleMappingChange}
                    />
                  )}

                  <DataBuilderForm
                    onChange={handleMappingChange}
                    field={makeDataField({
                      schema: schemaWithTitle(
                        unifiedSchema,
                        'Universal Fields',
                      ),
                      variablesSchema: variablesSchema,
                      value:
                        fieldMappingConfig.defaultUnifiedValue ??
                        fieldMappingConfig.default, // fallback to `default` for backward compatibility
                    })}
                    editableVariablesSchemaLocators={
                      editableVariablesSchemaLocators
                    }
                    onAddVariable={handleAddVariable}
                    hideReadOnlyFields
                  />
                </>
              )}
            </>
          ) : (
            <p>
              Please select a data source that has has a Universal Data Model
              (UDM) configured. Alternatively, you can configure fields on
              individual application level.
            </p>
          )}
        </div>
      )}

      {selectedOption ===
        ExportFieldMappingUnitConfigOption.CustomVariables && (
        <div>
          <DataBuilderForm
            onChange={(defaultValue) =>
              patchFieldMappingConfig({ defaultValue })
            }
            field={makeDataField({
              schema: { title: 'Raw Fields', type: 'object' },
              variablesSchema: variablesSchema,
              value: fieldMappingConfig.defaultValue,
            })}
            editableVariablesSchemaLocators={editableVariablesSchemaLocators}
            onAddVariable={handleAddVariable}
          />
        </div>
      )}
    </CollapsibleSection>
  )
}

function getUdmCreateFields(
  udmSpec: UnifiedDataModel | undefined,
): any | undefined {
  if (!udmSpec || !udmSpec.fieldsSchema) {
    return null
  }

  let fieldsSchema = udmSpec.fieldsSchema

  if (udmSpec.create?.fields) {
    fieldsSchema = pickFieldsFromSchema(
      udmSpec.fieldsSchema,
      udmSpec.create.fields,
    )
  } else if (udmSpec.create?.excludedFields) {
    fieldsSchema = excludeFieldsFromSchema(
      udmSpec.fieldsSchema,
      udmSpec.create.excludedFields,
    )
  }

  return fieldsSchema
}

function getUdmUpdateFields(
  udmSpec: UnifiedDataModel | undefined,
): any | undefined {
  if (!udmSpec || !udmSpec.fieldsSchema) {
    return null
  }

  let fieldsSchema = udmSpec.fieldsSchema

  if (udmSpec.update?.fields) {
    fieldsSchema = pickFieldsFromSchema(
      udmSpec.fieldsSchema,
      udmSpec.update.fields,
    )
  } else if (udmSpec.update?.excludedFields) {
    fieldsSchema = excludeFieldsFromSchema(
      udmSpec.fieldsSchema,
      udmSpec.update.excludedFields,
    )
  }

  return fieldsSchema
}

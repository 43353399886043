/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Global Webhooks",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "Configuration",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Implementation",
    "attributes": {},
    "children": [{
      "depth": 3,
      "value": "Handle",
      "attributes": {},
      "children": []
    }]
  }]
}];
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li",
    code: "code"
  }, _provideComponents(), props.components), {DocLink} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "global-webhooks",
      children: "Global Webhooks"
    }), "\n", _jsx(_components.p, {
      children: "Some external apps require webhooks to be configured on the global level rather than per-connection."
    }), "\n", _jsx(_components.p, {
      children: "Global Webhooks are a way to handle this."
    }), "\n", _jsx(_components.p, {
      children: "Each global webhook has a key and provides a function to handle data sent to the webhook."
    }), "\n", _jsx(_components.h2, {
      id: "configuration",
      children: "Configuration"
    }), "\n", _jsx(_components.p, {
      children: "When a connector added to a workspace, integration.app generates a global webhook URL unique to the connector and workspace."
    }), "\n", _jsx(_components.p, {
      children: "Developer needs to take this URL and provide it to the external app as a webhook (usually as a part of oAuth application configuration).\nYou should provide detals on how to do it in the connector documentation."
    }), "\n", _jsx(_components.h2, {
      id: "implementation",
      children: "Implementation"
    }), "\n", _jsx(_components.h3, {
      id: "handle",
      children: "Handle"
    }), "\n", _jsx(_components.p, {
      children: "The handle function is called when data is sent to the webhook."
    }), "\n", _jsx(_components.p, {
      children: "Arguments:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "query"
        }), " - object, webhook request query received from External App"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "data"
        }), " - object, webhook request body received from External App"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "headers"
        }), " - object, webhook request headers received from External App"]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "Result:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "events"
        }), " - list of the events. Each event has the following properties:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "selector"
            }), " (string) - selector to find matching external event subscriptions (see ", _jsx(DocLink, {
              path: "connector-builder/events"
            }), ")"]
          }), "\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "payload"
            }), " - arbitrary event payload that will be passed to matched subscriptions."]
          }), "\n"]
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.code, {
          children: "response"
        }), " - object, optional http response with properties:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "headers"
            }), " - object"]
          }), "\n", _jsxs(_components.li, {
            children: [_jsx(_components.code, {
              children: "data"
            }), " - http body (based on ", _jsx(_components.code, {
              children: "Content-Type"
            }), ")"]
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

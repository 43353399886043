import useAuth from 'contexts/auth'
import { useEffect } from 'react'

export function Intercom() {
  const { self } = useAuth()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const Intercom = (window as any).Intercom
      if (Intercom && self?.user) {
        Intercom('boot', {
          app_id: 'jb2d8o6i',
          name: self.user.name,
          email: self.user.email,
        })
      }
    }
  }, [typeof window])

  return null
}

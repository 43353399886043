/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Integration Engine",
  "attributes": {},
  "children": [{
    "depth": 2,
    "value": "External Apps",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Integration Scenarios and Blueprints",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Customer-level Integrations",
    "attributes": {},
    "children": []
  }, {
    "depth": 2,
    "value": "Monitoring and Troubleshooting",
    "attributes": {},
    "children": []
  }]
}];
export const TITLE = 'Integration Engine';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components), {DocLink, Link, DocGrid} = _components;
  if (!DocGrid) _missingMdxReference("DocGrid", true);
  if (!DocGrid.Link) _missingMdxReference("DocGrid.Link", true);
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "integration-engine",
      children: "Integration Engine"
    }), "\n", _jsx(_components.p, {
      children: "Integration Engine is headless highly configurable API-first service that runs your integrations."
    }), "\n", _jsx(_components.p, {
      children: "You configure your engine by:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Installing ", _jsx(DocLink, {
          path: "engine/external-apps/connectors",
          children: "Connectors"
        })]
      }), "\n", _jsxs(_components.li, {
        children: ["Building ", _jsx(DocLink, {
          path: "engine/blueprints",
          children: "Integration Blueprints"
        })]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(DocLink, {
          path: "engine/deployments",
          children: "Deploying"
        }), " integrations to ", _jsx(DocLink, {
          path: "engine/deployments/customers",
          children: "customers"
        }), " and ", _jsx(DocLink, {
          path: "engine/deployments/connections",
          children: "connections"
        }), ", and configuring each deployment."]
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["You can interact with engine using its ", _jsx(DocLink, {
        path: "overview/getting-started/backend/rest-api",
        children: "Rest API"
      }), " or ", _jsx(DocLink, {
        path: "overview/getting-started/front-end/javascript",
        children: "Front-end SDK"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      id: "external-apps",
      children: "External Apps"
    }), "\n", _jsxs(_components.p, {
      children: ["Integration Engine connects your apps to external apps via connectors.\nYou can use pre-built connector from ", _jsx(Link, {
        path: "https://integration.app/connectors",
        target: "_blank",
        children: "our library"
      }), "\nor build your own using ", _jsx(Link, {
        target: "_blank",
        path: "https://integration.app/connector-builder",
        children: "Connector Builder"
      }), "."]
    }), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/external-apps/connectors"
      }), _jsx(DocGrid.Link, {
        path: "engine/external-apps/integrations"
      }), _jsx(DocGrid.Link, {
        path: "connector-builder"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "integration-scenarios-and-blueprints",
      children: "Integration Scenarios and Blueprints"
    }), "\n", _jsx(_components.p, {
      children: "When you want an integration to work in a similar way across multiple customers or external apps, use integration blueprints."
    }), "\n", _jsxs(DocGrid, {
      cols: 2,
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/actions"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/flows"
      })]
    }), "\n", _jsx("br", {}), "\n", _jsxs(DocGrid, {
      children: [_jsx(DocGrid.Link, {
        path: "engine/blueprints/data-sources"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/field-mappings"
      }), _jsx(DocGrid.Link, {
        path: "engine/blueprints/data-links"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "customer-level-integrations",
      children: "Customer-level Integrations"
    }), "\n", _jsx(_components.p, {
      children: "Connect your customers' accounts in external apps and deploy integration logic to them."
    }), "\n", _jsxs(DocGrid, {
      cols: 2,
      children: [_jsx(DocGrid.Link, {
        path: "engine/deployments/customers"
      }), _jsx(DocGrid.Link, {
        path: "engine/deployments/connections"
      })]
    }), "\n", _jsx(_components.p, {
      children: "You can deploy integration blueprints and other building blocks to connections:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/deployments/action-instances"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/deployments/flow-instances"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/deployments/data-source-instances"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/deployments/field-mapping-instances"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/deployments/app-event-subscriptions"
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["\n", _jsx(DocLink, {
          path: "engine/deployments/data-link-table-instances"
        }), "\n"]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "monitoring-and-troubleshooting",
      children: "Monitoring and Troubleshooting"
    }), "\n", _jsx(_components.p, {
      children: "We provide a set of tools to help you monitor and troubleshoot your integrations:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Detailed ", _jsx(DocLink, {
          path: "engine/activity-log"
        }), "."]
      }), "\n", _jsx(_components.li, {
        children: "Workspace-level webhooks about events that happen in your integrations."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

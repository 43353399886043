import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import { DataLinkTableAndDirectionConfig } from '../../../../../../../components/common-configs/DataLinkTableAndDirectionConfig'
import AdminDataBuilderForm from '../../../../../../../../../../components/AdminDataBuilderForm'
import { makeDataField } from '@integration-app/ui/DataBuilder'
import { NodeConfigurationErrorsGetter } from '../configuration-errors/types'
import { getBrokenVariablesConfigurationErrors } from '../configuration-errors/geBrokenVariablesConfigurationErrors'

export function FindDataLink() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()

  return (
    <div>
      <h3>Link Parameters</h3>

      <DataLinkTableAndDirectionConfig />

      <AdminDataBuilderForm
        onChange={(recordId) => patchConfig({ recordId })}
        field={makeDataField({
          schema: {
            type: 'string',
            title: 'Record ID',
          },
          value: config.recordId,
          variablesSchema,
        })}
      />
    </div>
  )
}

export const getFindDataLinkConfigErrors: NodeConfigurationErrorsGetter = ({
  runTimeVariablesSchema,
  config,
}) => {
  return getBrokenVariablesConfigurationErrors(config.recordId, [
    runTimeVariablesSchema,
  ])
}

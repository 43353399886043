/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
export const toc = [{
  "depth": 1,
  "value": "Flow Instance Setup Timeout",
  "attributes": {},
  "children": []
}];
import {FLOW_INSTANCES_ROUTE} from 'routes/Workspaces/Workspace/routes-constants';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li",
    ol: "ol"
  }, _provideComponents(), props.components), {DocLink, Link} = _components;
  if (!DocLink) _missingMdxReference("DocLink", true);
  if (!Link) _missingMdxReference("Link", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h1, {
      id: "flow-instance-setup-timeout",
      children: "Flow Instance Setup Timeout"
    }), "\n", _jsxs(_components.p, {
      children: ["When you see this error, it means ", _jsx(DocLink, {
        path: "engine/deployments/flow-instances",
        children: "Flow Instance"
      }), " took too long to set up."]
    }), "\n", _jsx(_components.p, {
      children: "It usually means that connection it's created with is too slow."
    }), "\n", _jsx(_components.p, {
      children: "You can:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Wait for it to finish setting up and try running it again later."
      }), "\n", _jsx(_components.li, {
        children: "Try re-running the Flow Instance setup by going to its page and clicking \"Setup\" button."
      }), "\n", _jsx(_components.li, {
        children: "Go to the Flow Instance page and see which element was not set up."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "To find the Flow Instance page, do the following:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Going to the ", _jsx(Link, {
          path: FLOW_INSTANCES_ROUTE,
          children: "Flow Instances"
        }), " page."]
      }), "\n", _jsx(_components.li, {
        children: "Finding the Flow Instance by ID you see in the error."
      }), "\n", _jsx(_components.li, {
        children: "Looking at the error message you see on its page."
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}

import { ContentRows } from '../../../../../../../../../../components/ContentRows'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import DataBuilderFilter from '@integration-app/ui/DataBuilder/Filter'
import { useAppEventType } from '@integration-app/react'
import AppEventTypeSelect from 'routes/Workspaces/Workspace/YourApp/AppEventTypes/components/AppEventTypeSelect'

export function AppEventTrigger() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()
  const appEventConfig = config.appEvent ?? {}

  const { appEventType } = useAppEventType(appEventConfig.key)

  function patchAppEventConfig(value: any) {
    return patchConfig({
      ...config,
      appEvent: {
        ...appEventConfig,
        ...(value ?? {}),
      },
    })
  }

  return (
    <>
      <h3>App Event</h3>
      <p>Select app event this node is triggered by</p>
      <ContentRows>
        <AppEventTypeSelect
          value={appEventConfig.key ?? config.eventKey} // .eventKey is for backwards compatibility
          onChange={(key) => patchAppEventConfig({ key })}
        />
      </ContentRows>

      {appEventType && (
        <>
          <h3>Filter</h3>
          <p>Only events that match the filter will trigger the flow</p>

          <DataBuilderFilter
            value={config.filter}
            variablesSchema={variablesSchema}
            onChange={(filter) => patchConfig({ filter })}
          />
        </>
      )}
    </>
  )
}

import { AnonymousLoginAndTryWrapper } from 'components/Docs/AnonymousLoginAndTryWrapper'
import { DocsContextProvider } from 'routes/Docs/components/docs-context'
import { getArticle } from 'routes/Docs/helpers/articleList'
// ----

import { useRestoreDocScrollPosition } from 'hooks/useRestoreDocScrollPosition'
import { useRef } from 'react'
import { getSlug } from 'utils/slug'
import {
  DocFullPageHeader,
  DocFullPageMain,
  DocFullPageSidebar,
  DocFullPageContent,
  DocFullPageArticle,
  DocFullPageToc,
} from './DocsLayout'
import { DocsHeaderBreadcrumbs } from './DocBreadcrumbsFromPath'
import { DocsSearchInput } from './DocsSearch'
import { BackToWorkspaceLink } from './BackToWorkspaceLink'
import { ArticleMdx } from './Article'
import { ArticleToc } from './ArticleToc'
import { DocsMenu } from './DocsMenu'
import { Intercom } from 'components/Intercom'

export default function RenderFullDoc({
  path,
  parameters = {},
}: {
  path: string
  parameters?: any
}) {
  const article = getArticle(path)

  const containerRef = useRef<Window>(window)
  useRestoreDocScrollPosition(containerRef, path)

  const contentContainerId = `doc-page-${getSlug(path)}`

  return (
    <>
      <DocFullPageHeader>
        <DocsHeaderBreadcrumbs path={path} />
        <DocsSearchInput />
      </DocFullPageHeader>
      <DocFullPageMain>
        <DocFullPageSidebar>
          <BackToWorkspaceLink />
          <DocsMenu />
        </DocFullPageSidebar>
        <DocFullPageContent>
          <DocFullPageArticle id={contentContainerId}>
            <DocsContextProvider parameters={parameters}>
              <AnonymousLoginAndTryWrapper>
                <ArticleMdx article={article} parameters={parameters} />
              </AnonymousLoginAndTryWrapper>
            </DocsContextProvider>
          </DocFullPageArticle>
          <DocFullPageToc>
            <ArticleToc
              article={article}
              articleContainerId={contentContainerId}
              scrollableContainerSelector={'window'}
            />
          </DocFullPageToc>
        </DocFullPageContent>
      </DocFullPageMain>
      <Intercom />
    </>
  )
}

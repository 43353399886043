import { makeDataField } from '@integration-app/ui/DataBuilder'
import AdminDataBuilderForm from 'components/AdminDataBuilderForm'
import { ContentRows } from 'components/ContentRows'
import { getBrokenVariablesConfigurationErrors } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/geBrokenVariablesConfigurationErrors'
import { NodeConfigurationErrorsGetter } from '../../Blueprints/Flows/Flow/Build/SidePanels/nodes/configuration-errors/types'
import { CollapsibleSection } from '../Section'
import { DataLinkTableAndDirectionConfig } from './DataLinkTableAndDirectionConfig'
import { useGenericConfig } from './contexts/generic-config-context'

export function DataLinkConfig() {
  const { config, patchConfig, variablesSchema } = useGenericConfig()

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>Configure Data Link</CollapsibleSection.Header>

      <p>Select Data Link Table and the link data to create</p>

      <DataLinkTableAndDirectionConfig />

      <ContentRows>
        <AdminDataBuilderForm
          onChange={(externalRecordId) => patchConfig({ externalRecordId })}
          field={makeDataField({
            schema: {
              type: 'string',
              title: 'External Record ID',
            },
            value: config.externalRecordId,
            variablesSchema: variablesSchema,
          })}
        />

        <AdminDataBuilderForm
          onChange={(appRecordId) => patchConfig({ appRecordId })}
          field={makeDataField({
            schema: {
              type: 'string',
              title: 'App Record ID',
            },
            value: config.appRecordId,
            variablesSchema: variablesSchema,
          })}
        />
      </ContentRows>
    </CollapsibleSection>
  )
}

export const getDataLinkConfigErrors: NodeConfigurationErrorsGetter = ({
  runTimeVariablesSchema,
  config,
}) => {
  return [
    ...getBrokenVariablesConfigurationErrors(config.externalRecordId, [
      runTimeVariablesSchema,
    ]),
    ...getBrokenVariablesConfigurationErrors(config.appRecordId, [
      runTimeVariablesSchema,
    ]),
  ]
}

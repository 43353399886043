import Editor from '@monaco-editor/react'
import yaml from 'js-yaml'
import { useEffect, useState } from 'react'

export default function YAMLEditor({ value, onChange, managed = false }) {
  const [isValid, setIsValid] = useState(true)
  const [strValue, setStrValue] = useState(yaml.dump(value))

  if (managed) {
    useEffect(() => {
      setStrValue(yaml.dump(value))
      setIsValid(true)
    }, [value])
  }

  function handleChange(newStrValue) {
    setStrValue(newStrValue)
    try {
      onChange(yaml.load(newStrValue))
      setIsValid(true)
    } catch (e) {
      console.error(e)
      setIsValid(false)
      // ToDo: show validation error
    }
  }

  return (
    <Editor
      width='100%'
      height='100%'
      language='yaml'
      className={
        isValid ? 'border border-transparent' : 'border-error border-opacity-50'
      }
      theme='vs'
      value={strValue}
      options={{
        minimap: {
          enabled: false,
        },
      }}
      onChange={handleChange}
    />
  )
}

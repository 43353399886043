import useSWR from 'swr'
import ConnectorEventSelect from '../../../../../../../components/ConnectorEvents/ConnectorEventSelect'
import { useGenericConfig } from '../../../../../../../components/common-configs/contexts/generic-config-context'
import useWorkspace from '../../../../../../../../../../components/Workspaces/workspace-context'
import DataBuilderForm from '@integration-app/ui/DataBuilder/Form'
import { makeDataField } from '@integration-app/ui'
import { schemaWithTitle } from '@integration-app/sdk'

export function ConnectorEventTrigger() {
  const { engineAdminFetcher } = useWorkspace()
  const { config, patchConfig, integrationId } = useGenericConfig()

  const eventKey = config?.eventKey

  const { data: eventSpec } = useSWR(
    integrationId && eventKey
      ? `/integrations/${integrationId}/events/${eventKey}`
      : null,
    engineAdminFetcher,
  )

  return (
    <div>
      <ConnectorEventSelect
        value={eventKey}
        onChange={(eventKey) => patchConfig({ eventKey })}
      />
      {eventSpec?.parametersSchema && (
        <DataBuilderForm
          field={makeDataField({
            schema: schemaWithTitle(eventSpec.parametersSchema, 'Parameters'),
            value: config?.eventParameters,
          })}
          onChange={(eventParameters) => patchConfig({ eventParameters })}
        />
      )}
    </div>
  )
}

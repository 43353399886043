import {
  Action,
  Connection,
  DataSource,
  DataSourceInstance,
  FieldMapping,
  FieldMappingInstance,
  Flow,
  FlowInstance,
} from '@integration-app/sdk'

export const EXTERNAL_APPS_ROUTE = '/external-apps'
export const INTEGRATIONS_ROUTE = `${EXTERNAL_APPS_ROUTE}/integrations`
export const CONNECTORS_ROUTE = '/connectors'

export const BLUEPRINTS_ROUTE = '/blueprints'
export const SCENARIOS_ROUTE = `${BLUEPRINTS_ROUTE}/scenarios`
export const PUBLIC_CONNECTORS_ROUTE = `${CONNECTORS_ROUTE}/public`
export const LOCAL_CONNECTORS_ROUTE = `${CONNECTORS_ROUTE}/local`
export const ACTIONS_ROUTE = `${BLUEPRINTS_ROUTE}/actions`
export const DATA_SOURCES_ROUTE = `${BLUEPRINTS_ROUTE}/data-sources`
export const DATA_LINK_TABLES_ROUTE = `${BLUEPRINTS_ROUTE}/data-link-tables`
export const FIELD_MAPPINGS_ROUTE = `${BLUEPRINTS_ROUTE}/field-mappings`
export const FLOWS_ROUTE = `${BLUEPRINTS_ROUTE}/flows`

export const YOUR_APP_ROUTE = `/your-app`
export const YOUR_APP_API_ROUTE = `${YOUR_APP_ROUTE}/api`
export const APP_DATA_SCHEMAS_ROUTE = `${YOUR_APP_ROUTE}/data-schemas`
export const APP_EVENT_TYPES_ROUTE = `${YOUR_APP_ROUTE}/events`
export const USER_INTERFACE_ROUTE = `${YOUR_APP_ROUTE}/ui`
export const SCREENS_ROUTE = `${USER_INTERFACE_ROUTE}/screens`

export const DEPLOYMENTS_ROUTE = '/deployments'
export const CUSTOMERS_ROUTE = `${DEPLOYMENTS_ROUTE}/customers`
export const CONNECTIONS_ROUTE = `${DEPLOYMENTS_ROUTE}/connections`
export const ACTION_INSTANCES_ROUTE = `${DEPLOYMENTS_ROUTE}/action-instances`
export const FLOW_INSTANCES_ROUTE = `${DEPLOYMENTS_ROUTE}/flow-instances`
export const FIELD_MAPPING_INSTANCES_ROUTE = `${DEPLOYMENTS_ROUTE}/field-mapping-instances`
export const DATA_SOURCE_INSTANCES_ROUTE = `${DEPLOYMENTS_ROUTE}/data-source-instances`
export const DATA_LINK_TABLE_INSTANCES_ROUTE = `${DEPLOYMENTS_ROUTE}/data-link-table-instances`
export const APP_EVENT_SUBSCRIPTIONS_ROUTE = `${DEPLOYMENTS_ROUTE}/app-event-subscriptions`
export const EXTERNAL_EVENT_SUBSCRIPTIONS_ROUTE = `${DEPLOYMENTS_ROUTE}/external-event-subscriptions`
export const APP_DATA_SCHEMA_INSTANCES_ROUTE = `${DEPLOYMENTS_ROUTE}/app-data-schema-instances`

export const ACTIVITY_LOG_ROUTE = '/activity-log'

export const DASHBOARD_ROUTE = '/dashboard'

export const FLOW_RUNS_ROUTE = `${ACTIVITY_LOG_ROUTE}/flow-runs`
export const EXTERNAL_EVENT_LOG_RECORDS_ROUTE = `${ACTIVITY_LOG_ROUTE}/external-events`
export const EXTERNAL_EVENT_PULLS_ROUTE = `${ACTIVITY_LOG_ROUTE}/external-event-pulls`
export const APP_EVENTS_ROUTE = `${ACTIVITY_LOG_ROUTE}/app-events`

export const SETTINGS_ROUTE = '/settings'
export const SETTINGS_TESTING_ROUTE = `${SETTINGS_ROUTE}/testing`

export const routeTo = {
  customer: (id: string) => `${CUSTOMERS_ROUTE}/${id}`,

  integrations: () => INTEGRATIONS_ROUTE,
  app: (id: string) => `${INTEGRATIONS_ROUTE}/${id}`,

  connection: (connection: Connection | string) => {
    if (typeof connection === 'string') {
      return `${CONNECTIONS_ROUTE}/${connection}`
    }

    return `${CONNECTIONS_ROUTE}/${connection.id}`
  },

  flowRun: (id: string) => `${FLOW_RUNS_ROUTE}/${id}`,

  flows: () => FLOWS_ROUTE,
  flow(flow: Flow | string): string {
    if (typeof flow === 'string') {
      return `${FLOWS_ROUTE}/${flow}`
    }

    if (flow.universalFlowId) {
      return `${FLOWS_ROUTE}/${flow.universalFlowId}/${flow.id}`
    }

    return `${FLOWS_ROUTE}/${flow.id}`
  },

  flowInstances: () => FLOW_INSTANCES_ROUTE,
  flowInstance(flowInstance: string | FlowInstance) {
    if (typeof flowInstance === 'string') {
      return `${FLOW_INSTANCES_ROUTE}/${flowInstance}`
    }

    return `${FLOW_INSTANCES_ROUTE}/${flowInstance.id}`
  },

  fieldMappings: () => FIELD_MAPPINGS_ROUTE,
  fieldMapping: (fieldMapping: string | FieldMapping) => {
    if (typeof fieldMapping === 'string') {
      return `${FIELD_MAPPINGS_ROUTE}/${fieldMapping}`
    }

    if (fieldMapping.universalFieldMappingId) {
      return `${FIELD_MAPPINGS_ROUTE}/${fieldMapping.universalFieldMappingId}/${fieldMapping.id}`
    }

    return `${FIELD_MAPPINGS_ROUTE}/${fieldMapping.id}`
  },

  fieldMappingInstances: () => FIELD_MAPPING_INSTANCES_ROUTE,
  fieldMappingInstance: (
    fieldMappingInstance: string | FieldMappingInstance,
  ) => {
    if (typeof fieldMappingInstance === 'string') {
      return `${FIELD_MAPPING_INSTANCES_ROUTE}/${fieldMappingInstance}`
    }

    return `${FIELD_MAPPING_INSTANCES_ROUTE}/${fieldMappingInstance.id}`
  },

  dataSources: () => DATA_SOURCES_ROUTE,
  dataSource: (dataSource: string | DataSource) => {
    if (typeof dataSource === 'string') {
      return `${DATA_SOURCES_ROUTE}/${dataSource}`
    }

    if (dataSource.universalDataSourceId) {
      return `${DATA_SOURCES_ROUTE}/${dataSource.universalDataSourceId}/${dataSource.id}`
    }

    return `${DATA_SOURCES_ROUTE}/${dataSource.id}`
  },

  dataSourceInstances: () => DATA_SOURCE_INSTANCES_ROUTE,
  dataSourceInstance: (dataSourceInstance: string | DataSourceInstance) => {
    if (typeof dataSourceInstance === 'string') {
      return `${DATA_SOURCE_INSTANCES_ROUTE}/${dataSourceInstance}`
    }

    return `${DATA_SOURCE_INSTANCES_ROUTE}/${dataSourceInstance.id}`
  },

  externalEventLogRecord: (id: string) =>
    `${EXTERNAL_EVENT_LOG_RECORDS_ROUTE}/${id}`,
  externalEventPull: (id: string) => `${EXTERNAL_EVENT_PULLS_ROUTE}/${id}`,

  actions: () => ACTIONS_ROUTE,
  action: (action: Action | string) => {
    if (typeof action === 'string') {
      return `${ACTIONS_ROUTE}/${action}`
    }

    if (action.parentId) {
      return `${ACTIONS_ROUTE}/${action.parentId}/${action.id}`
    }

    return `${ACTIONS_ROUTE}/${action.id}`
  },

  actionInstances: () => ACTION_INSTANCES_ROUTE,
  actionInstance: (id: string) => `${ACTION_INSTANCES_ROUTE}/${id}`,

  dataLinkTable: (id: string) => `${DATA_LINK_TABLES_ROUTE}/${id}`,

  appEvent: (id: string) => `${APP_EVENTS_ROUTE}/${id}`,
  appEventType: (id: string) => `${APP_EVENT_TYPES_ROUTE}/${id}`,
  appEventSubscription: (id: string) =>
    `${APP_EVENT_SUBSCRIPTIONS_ROUTE}/${id}`,

  externalEventSubscription: (id: string) =>
    `${EXTERNAL_EVENT_SUBSCRIPTIONS_ROUTE}/${id}`,

  appDataSchema: (id: string) => `${APP_DATA_SCHEMAS_ROUTE}/${id}`,
  appDataSchemaInstance: (id: string) =>
    `${APP_DATA_SCHEMA_INSTANCES_ROUTE}/${id}`,

  scenario: (id: string) => `${SCENARIOS_ROUTE}/${id}`,
  scenarios: () => SCENARIOS_ROUTE,
}

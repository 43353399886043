import ExampleCodeBlock from 'components/Docs/ExampleCodeBlock'

export function InitSdkWithFetchCredentialsCodeExample() {
  const js = `import { IntegrationAppClient } from '@integration-app/sdk'

const integrationApp = new IntegrationAppClient({
  token: '{TOKEN}',
  fetchCredentials: () => fetch('/user-credentials').then(res => res.json())
})
`

  return <ExampleCodeBlock customCodes={{ js }} />
}

import { makeDataField } from '@integration-app/ui/DataBuilder'
import {
  DataSchema,
  UNIFIED_DATA_MODELS,
  schemaWithTitle,
} from '@integration-app/sdk'
import { useFieldMappingContext } from '../contexts/field-mapping-context'
import AdminDataBuilderForm from '../../../../../../../components/AdminDataBuilderForm'
import { toHeaderCase } from 'js-convert-case'
import { CollapsibleSection } from '../../../../components/Section'
import { GenerateFieldMappingButton } from './GenerateFieldMappingButton'

export function ExportFieldMappingForm() {
  const {
    fieldMapping,
    isUniversal,
    appSchema,
    onChange,
    dataSource,
    dataCollectionSpec,
  } = useFieldMappingContext()

  const fromCollectionName = dataSource
    ? `Fields in data collection "${dataSource.name}"`
    : 'Fields in my application'
  let toCollectionName = 'Fields in an external application'

  let toSchema: DataSchema | undefined

  if (isUniversal) {
    const udm = dataSource?.udm
      ? UNIFIED_DATA_MODELS[dataSource?.udm]
      : undefined
    if (udm) {
      toSchema = schemaWithTitle(
        udm.fieldsSchema,
        `Universal ${toHeaderCase(udm.singularName)} Fields`,
      )
      toCollectionName = `Universal (standard) ${udm.singularName} Fields`
    } else {
      return (
        <p>
          No Universal Data Model selected. Please select a Data Source with UDM
          or keep these fields empty and set them on the application level.
        </p>
      )
    }
  } else {
    if (dataCollectionSpec) {
      toSchema = schemaWithTitle(
        dataCollectionSpec.fieldsSchema,
        `${dataCollectionSpec.name} Fields`,
      )
      toCollectionName = `data collection "${
        dataCollectionSpec.name ?? dataCollectionSpec.key
      }"`
    } else {
      return (
        <p>
          No data collection selected. Please select a Data Collection in your
          Data Source to set up mapping.
        </p>
      )
    }
  }

  const fromSchema = schemaWithTitle(
    appSchema ?? { type: 'object' },
    'Internal Fields',
  )

  const field = makeDataField({
    schema: toSchema,
    variablesSchema: fromSchema,
    value: isUniversal
      ? fieldMapping.defaultExportValue
      : fieldMapping.exportValue,
  })

  async function handleChange(value: any) {
    return await onChange(
      isUniversal ? { defaultExportValue: value } : { exportValue: value },
    )
  }

  return (
    <CollapsibleSection open>
      <CollapsibleSection.Header>
        Export: Your App → External App
      </CollapsibleSection.Header>

      {toSchema && appSchema && (
        <CollapsibleSection.ActionBar>
          <GenerateFieldMappingButton
            fromSchema={appSchema}
            toSchema={toSchema}
            extraPrompt={`This mapping transforms fields from ${fromCollectionName} to ${toCollectionName}`}
            onGenerate={handleChange}
          />
        </CollapsibleSection.ActionBar>
      )}

      <div className='-mt-2'>
        <AdminDataBuilderForm
          field={field}
          hideReadOnlyFields
          onChange={handleChange}
        />
      </div>
    </CollapsibleSection>
  )
}
